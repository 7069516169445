import React from 'react';

import './style.css';

export default (props) => (
	<div className='courseHeadMainContainer'>
		<h3 className='title'>{props.courseDetails.title}</h3>
		<p className='courseDescription'>{props.courseDetails.coverDescription}</p>
		<p className='subPointers' ref={props.subPointersRef}></p>
	</div>
);
