import React, { useState } from 'react';

import * as LoginServices from '@services/Login';
import NameAndPhoneNoOtpContentView from './view';

function NameAndPhoneNoOtpContent(props) {

	const [tempCountryCode, setTempCountryCode] = useState('');
	const [tempPhoneNo, setTempPhoneNo] = useState('');
	const [tempNameAndPhoneNoOtpStage, setTempNameAndPhoneNoOtpStage] = useState('');
	const [otpToken, setOtpToken] = useState('');
	const [stage, setStage] = useState('init');


	const dubLicateOpenOtpPopUp = async(countryCode, phoneNo, stageX) => {
		setTempCountryCode(countryCode)
		setTempPhoneNo(phoneNo)
		setStage(stageX);

		const response = await LoginServices.login(phoneNo);
		if (!response.success) {
			alert(response.message);
			return;
		}
		setOtpToken(response.data.token);

	}

	const goBack = () => {
		setStage('init');
	}


	const intermediateHandleSuccessOtp = (otp) => {
		props.handleSuccessOtp(tempCountryCode, tempPhoneNo, otp, otpToken);
	};


	return (
		<>
			<NameAndPhoneNoOtpContentView
				stage={stage}
				tempCountryCode={tempCountryCode}
				tempPhoneNo={tempPhoneNo}
				dubLicateOpenOtpPopUp={dubLicateOpenOtpPopUp}
				tempNameAndPhoneNoOtpStage={tempNameAndPhoneNoOtpStage}
				goBack={goBack}
				intermediateHandleSuccessOtp={intermediateHandleSuccessOtp}
				{...props}
			/>
		</>
	);
}

export default NameAndPhoneNoOtpContent;
