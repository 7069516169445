import React, { useEffect, useState } from 'react';
import EnrolledView from './view';

import { getOrderDetailsByOrderId } from '@library/helpers';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isMobile } from 'react-device-detect';
import { jsPDF } from "jspdf";
import InvoiceMobile from '@components/InvoiceMobile';
import { renderToStaticMarkup } from "react-dom/server";
import DownloadIcon from "@assets/timeline/icons/download.png";
import LoaderGif from "@assets/course/loading.gif";

function Enrolled(props) {
	const navigate = useNavigate();
	const [enrolledData, setEnrolledData] = useState({});
	const timelineData = useSelector((state) => state.dashboard.timelineData);
	const [showPayBalanceUIStatus, setShowPayBalanceUIStatus] = useState('');

	useEffect(() => {
		showPayBalanceUI(timelineData?.orderDetails?.installmentOption)
	}, [timelineData])

	const installmentNoConverter = (no) => {
		let number = no;
		number = no + 1;
		const suffixes = ['th', 'st', 'nd', 'rd'];
		const lastDigit = number % 10;
		const suffix =
			number % 100 > 10 && number % 100 < 14 ? 'th' : suffixes[lastDigit] || 'th';
		return number + suffix;
	};

	const handlePay = async (action, amount) => {
		try {
			const orderId = timelineData.orderId;
			let orderDetails = await getOrderDetailsByOrderId(orderId);
			if (Object.entries(orderDetails).length > 0) {
				orderDetails.data.grandTotal = amount;
				navigate('/otherPay', {
					state: {
						source: {
							expenseType: 'installment',
							installmentNo: action === 'payFullBalance' ? action : action + 1
						},
						purchaseDetails: timelineData,
						orderDetails: orderDetails.data
					},
				});
			}
			else {
				alert('Issue in getting order details, try again after refreshing the page');
			}

		} catch (err) {
			console.log('Error coming from handlePay()', err);
		}
	};


	const filterAndGetInstallmentOptionDetails = (installmentNo, findKey) => {
		installmentNo++
		let returnValue = null;
		if (timelineData.installmentDetails && timelineData.installmentDetails.length > 0) {
			for (let i = 0; i < timelineData.installmentDetails.length; i++) {
				if (installmentNo == timelineData.installmentDetails[i].installmentNumber) {
					returnValue = timelineData.installmentDetails[i][findKey]
					break;
				}
			}
		}
		return returnValue
	}

	const showPayBalanceUI = (installmentOption) => {
		// have paid full installment in one go
		if (filterAndGetInstallmentOptionDetails(-1, 'invoiceId')) {
			setShowPayBalanceUIStatus('paymentDone')
			return
		}
		// no onstallment pending
		if (installmentOption && Object.values(installmentOption).some(payment => !payment.isPaid)) {
			setShowPayBalanceUIStatus('paymentPending')
			return
		}
		setShowPayBalanceUIStatus('hidePayBalanceUI')
	}

	const handleInvoiceRedirect = (orderId, installmentType, event) => {
		if (installmentType === "laterInstallments") {
			if (timelineData.installmentDetails && timelineData.installmentDetails.length > 0) {
				for (let i = 0; i < timelineData.installmentDetails.length; i++) {
					if (orderId == timelineData.installmentDetails[i].orderId) {
						let invoiceDetails = {
							data: timelineData.installmentDetails[i]
						}
						if (isMobile) {
							generatePdf(event, invoiceDetails)
						}
						else {
							navigate('/invoice', { state: { invoice: invoiceDetails } });
						}
						break;
					}
				}
			}
		}
		else {
			if (timelineData && timelineData.orderDetails) {
				let invoiceDetails = {
					data: timelineData.orderDetails
				}
				if (isMobile) {
					generatePdf(event, invoiceDetails)
				}
				else {
					navigate('/invoice', { state: { invoice: invoiceDetails } });
				}
			}
		}
	}



	const generatePdf = async (event, invoice) => {
		try {
			event.target.src = LoaderGif

			const doc = new jsPDF("p", "pt", "a4", true);

			var InvoiceComponent = <InvoiceMobile invoice={invoice} />
			var htmlPDFPage = renderToStaticMarkup(InvoiceComponent);

			doc.internal.write(0, "Tw");
			const pdfPromise = new Promise((resolve) => {
				doc.html(htmlPDFPage, {
					callback: function () {
						resolve(doc);
					},
				});
			});

			// Wait for the promise to resolve before continuing
			const generatedDoc = await pdfPromise;
			generatedDoc.save("invoice.pdf");


			setTimeout(function () {
				event.target.src = DownloadIcon
			}, 100)

		} catch (err) {
			console.log("Error", err);
		}
	};



	const getBalanceAmount = (enrolledDetails) => {

		if (filterAndGetInstallmentOptionDetails(-1, 'invoiceId')) {
			return filterAndGetInstallmentOptionDetails(-1, 'orderAmountInNumbers')
		}

		const discountTotal = Object.values(enrolledDetails.installmentOption)
			.map((option, index) => (index > 0 ? option.discount : 0))
			.reduce((total, amount) => total + amount, 0);

		const course = enrolledDetails?.courses[0];
		const price = course.isInOffer ? course.offerPriceInNumbers : course.crossedPriceInNumbers;

		const payBalance = (discountTotal * price) / 100;

		let installmentAmountPaid = 0
		Object.values(enrolledDetails.installmentOption).map((installment, index) => {
			if (index > 0 && installment.isPaid) {
				if (enrolledDetails?.courses[0].isInOffer) {
					installmentAmountPaid = installmentAmountPaid + (enrolledDetails?.courses[0].offerPriceInNumbers * installment.discount) / 100
				} else {
					installmentAmountPaid = installmentAmountPaid + (enrolledDetails?.courses[0].crossedPriceInNumbers * installment.discount) / 100
				}
			}
		})

		return (payBalance - installmentAmountPaid).toFixed(1);
	};
	return (
		<>
			<EnrolledView
				enrolledDetails={timelineData?.orderDetails}
				installmentNoConverter={installmentNoConverter}
				handlePay={handlePay}
				timelineData={timelineData}
				filterAndGetInstallmentOptionDetails={filterAndGetInstallmentOptionDetails}
				showPayBalanceUI={showPayBalanceUI}
				showPayBalanceUIStatus={showPayBalanceUIStatus}
				handleInvoiceRedirect={handleInvoiceRedirect}
				getBalanceAmount={getBalanceAmount}
				{...props}
			/>
		</>
	);
}

export default Enrolled;
