import React from 'react';

import SwipeButton from '@components/SwipeButton';
import moment from 'moment';

import './style.css';

export default (props) => (
	<div
		className={`examCard ${props.examIndex % 2 !== 0 ? 'examCardOddMargin' : 'examCardEvenMargin'
			}`}>
		<div className='shineOnCourseCard col-4 col-sm-3'>

			<img className='w-100' src={props.exam.examThumbnailUrl} alt='thumbnailUrl' />
		</div>
		<div className='examInnerContainer col-8 col-sm-9'>
			<p className='examTitle'>{props.exam.examTitle}</p>

			{['locked', 'unlocked', 'started'].includes(props.exam.examStatus) ?
				(
					<>
						<SwipeButton
							id={props.exam.examId}
							accordanceConfig={props.accordanceConfig}
							status={props.exam.examStatus}
							statusText={props.exam.examStatusText}
							handleExam={() => props.handleExam(props.exam,props.popUpType)}
						/>
					</>
				) : (
					<>
						{moment().isBetween(props.exam?.examResultAt, props.exam?.examResultHideAt) ?
							(
								<>
									{props.exam?.resultDetails?.result === 'failed' ? (
										<div>
											<p className='pfHeading'>
												<span className='failHeading'>Failed</span>
												<span> - {props.exam?.resultDetails?.percentage}%</span>
											</p>
											<div className='row' style={{ '--bs-gutter-x': '0.6rem' }}>
												<div className='col-6'>
													<button
														onClick={() => props.showResult(props.exam)}
														className='w-100 btnTxt viewResultBtn'>
														View Result
													</button>
												</div>
												<div className='col-6'>
													<button
														onClick={() => props.handleF1Exam(props.exam)}
														className='w-100 btnTxt reAttemptBtn'>
														Re-attempt
													</button>
												</div>
											</div>
										</div>
									) : (
										<div>
											<p className='pfHeading'>
												<span className='passHeading'>Passed</span>
												<span> - {props.exam?.resultDetails?.percentage}%</span>
											</p>
											<button
												onClick={() => props.showResult(props.exam)}
												className='col-12 btnTxt viewResultBtn'>
												View Result
											</button>
										</div>
									)}
								</>
							) : (
								<>
									<p style={{ fontSize: '15px', lineHeight: 'normal' }}>{props.exam?.examStatusText}</p>
								</>
							)
						}
					</>
				)
			}

		</div>
	</div>
);
