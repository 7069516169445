import React, { useState, useEffect } from "react";

import ETCard from "@components/ETCard";
import ETPopup from "@components/ETPopup";
import noExam from "@assets/exams/exam.png";
import "./style.css";

function ETView(props) {
  return (
    <>
      <div className="examContainer row">
        <h3 className="cHeading">{props.popUpType}</h3>
        {props.d && props.d.data && props.d.data.length > 0 ? (
          <>
            {props.d.data.map((exam, examIndex) => (
              <React.Fragment key={examIndex}>
                <ETCard
                  popUpType={props.popUpType}
                  showResult={() => props.showResult(exam)}
                  handleF1Exam={() => props.handleF1Exam(exam)}
                  examIndex={examIndex}
                  exam={exam}
                />
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            <center>
              <img
                id="noProduct"
                src={noExam}
                alt="noProduct"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>No Exams Found</h3>
            </center>
          </>
        )}

        <ETPopup
          type={props.popUpType}
          data={props.etPopupPurchaseData}
          openPopupStatus={props.openPopupStatus}
          handleRedirectToEaxm={(d) => props.handleRedirectToEaxm(d)}
          closeEtPopupBtnRef={props.closeEtPopupBtnRef}
          etPoupOkBtnLoading={props.etPoupOkBtnLoading}
        />
      </div>
    </>
  );
}

export default ETView;
