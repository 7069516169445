import React from 'react';
import './style.css';

import CoursesHolderMobileCart from '@components/CoursesHolderMobileCart';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';
import GrayBgWhitePlusBtn from '@assets/course/grayBgWhitePlusBtn.png';
import offerGroupOf2 from "@assets/offers/offerGroupOf2.png";
import offerGroupOf3 from "@assets/offers/offerGroupOf3.png";
import offerGroupOf4 from "@assets/offers/offerGroupOf4.png";
import offerGroupOf5 from "@assets/offers/offerGroupOf5.png";


const groupOfferThumbnail = (numberOfCartItems) => {
	let thumbnail = offerGroupOf2;
	if (numberOfCartItems === 2) {
		thumbnail = offerGroupOf2
	}
	if (numberOfCartItems === 3) {
		thumbnail = offerGroupOf3
	}
	if (numberOfCartItems === 4) {
		thumbnail = offerGroupOf4
	}
	if (numberOfCartItems === 5) {
		thumbnail = offerGroupOf5
	}
	return thumbnail
}

const OfferCard = (props) => (
	<div
		onClick={props.handleOfferChange}
		className={`col-4 col-sm-4 offerContainer  ${props.index === props.currentIndex && 'selectBlueBorder'
			}`}>
		{props.numberOfCartItems ? (
			<>
				<img
					className='offerThumbnail w-100'
					src={groupOfferThumbnail(props.numberOfCartItems)}
					alt='offerThumbnail' />
			</>
		) : (
			<>
				<img
					className='offerThumbnail w-100'
					src={props.thumbnailUrl}
					alt='offerThumbnail' />

			</>
		)}

		<div className='textContent'>
			<p className='title'>{props.title}</p>
			{props.discount > 0 ? (
				<p className='discount'>Discount: {props.discount} %</p>
			) : (
				<p className='discount'>No discount</p>
			)}

			{window.innerWidth > 768 && (
				<p className='discription'>{props.description}</p>
			)}
		</div>
	</div>
);

function OfferView(props) {
	return (
		<>
			<div className='offerView'>
				{props.currentView === 'init' ? (
					<>
						<div className='header'>
							<p className='head'>
								Choose your <span className='green'>package</span>...
							</p>
							<button className='closeBtn' data-bs-dismiss='modal'>
								x
							</button>
						</div>
						<p className='headerNote'>
							Courses purchased separately have no offers...
						</p>

						<div
							style={{ maxHeight: window.innerHeight - 200 + 'px' }}
							className='d-flex overflow-auto contentForShowOffersView'>

							{/* Handling Cart Here for Offers */}
							{props.courseDataInUse && props.courseDataInUse.length === 1 && (
								// {props.cartItems && props.cartItems.length === 1 && (
								<>
									<OfferCard
										currentIndex={props.currentIndex}
										index={-1}
										thumbnailUrl={props.courseDataInUse[0].squareThumbnailUrl}
										// thumbnailUrl={props.cartItems[0].squareThumbnailUrl}
										title={'Single Course'}
										description={'Courses purchased separately have no offers...'}
										discount={0}
										handleOfferChange={(event) => props.handleOfferChange(-1, null)}
									/>

									{props.courseDataInUse[0].offers[0].discounts.map((discount, index) => (
										<>
											{discount.discount > 0 &&
												<OfferCard
													key={index}
													currentIndex={props.currentIndex}
													index={index}
													numberOfCartItems={discount.numberOfCartItems}
													thumbnailUrl={props.courseDataInUse[0].offers[0].thumbnailUrl}
													// thumbnailUrl={props.cartItems[0].offers[0].thumbnailUrl}
													title={`${discount?.numberOfCartItems} courses bundle`}
													description={'Courses purchased separately have no offers...'}
													discount={discount?.discount}
													handleOfferChange={(event) =>
														props.handleOfferChange(index, discount)
													}
												/>
											}
										</>
									))}
								</>
							)}
						</div>

						<button
							onClick={() => props.handleContinueToSelectCourses()}
							className='actionBtn'>
							{props.isDiscountSelected ? (
								<>Continue to choose courses</>
							) : (
								<>Continue to pay {props.actualPrice}</>
							)}
							<img src={RightArrow} alt='RightArrow' />
						</button>
					</>
				) : (
					<>
						<div className='header'>
							<p className='head'>
								<span className='green'>Course Discount</span>
								&nbsp;{props.currentDiscountedPercentage}%
							</p>
							<button className='closeBtn' data-bs-dismiss='modal'>
								x
							</button>
						</div>
						<p className='headerNote'>
							Courses purchased separately have no offers...
						</p>

						<div className='contentAddNewCourseView'>
							<div className='addedCourse'>
								<CoursesHolderMobileCart
									source="mobileOffer"
									selectedCourses={props.selectedCourses}
									removeCartItem={props.removeCartItem}
								/>
							</div>

							{window.innerWidth > 768 ? (
								<>
									<div
										style={{ maxHeight: window.innerHeight - 450 + 'px' }}
										className='desktopRelatedCourse'>

										{props.currentRelatedCourses.length > 0 &&
											props.currentRelatedCourses.map((course, index) => (
												course.isPurchasedCourse === false && (
													<div key={index} className='courseCardDiv'>
														<div className='div1'>
															<img
																className='thumbnail'
																src={course.squareThumbnailUrl}
																alt='squareThumbnail'
															/>
															<div style={{ marginLeft: '7px' }}>
																<p className='name'>{course.title}</p>
																<p className='price'>
																	{course.isInOffer ? course.offerPrice : course.crossedPrice}
																	{course.isInOffer && (
																		<>
																			<span className='crossedPrice'>
																				<strike>{course.crossedPrice}</strike>
																			</span>
																			<span className='offerPrice'>
																				{Math.round(
																					((course.crossedPriceInNumbers -
																						course.offerPriceInNumbers) /
																						course.crossedPriceInNumbers) *
																					100
																				)}
																				%{''}off
																			</span>
																		</>
																	)}
																</p>
															</div>
														</div>
														<img
															onClick={() => {
																props.addCourse(index);
															}}
															className='pluseBtn'
															src={GrayBgWhitePlusBtn}
															alt='grayBgWhitePlusBtn'
														/>
													</div>
												)))}
									</div>
								</>
							) : (
								<>
									<div className='d-flex overflow-auto relatedCourse'>
										{props.currentRelatedCourses.length > 0 &&
											props.currentRelatedCourses.map((course, index) => (
												course.isPurchasedCourse === false && (
													<div className='col-3 div1' key={index}>
														<img
															className='w-100'
															src={course.squareThumbnailUrl}
															alt='squareThumbnail'
														/>
														<img
															onClick={() => {
																props.addCourse(index);
															}}
															className='pluseBtn'
															src={GrayBgWhitePlusBtn}
															alt='grayBgWhitePlusBtn'
														/>
													</div>
												)
											))}
									</div>
								</>
							)}

							<div className='bottomPriceNoteContainer'>
								<p className='priceNote'>Add courses to update package discount</p>
								<p className='priceContainer'>
									{props.discountedPriceInNumbers > 0 && (
										<span className='discountedPrice'>{props.discountedPrice}&nbsp;</span>
									)}

									<span
										className={`${props.discountedPriceInNumbers > 0
											? 'actualPrice'
											: 'discountedPrice'
											} `}>
										{props.currentDiscountedPercentage > 0 && props.actualPrice}
									</span>
									<span className='discountedPercentage'>
										&nbsp;&nbsp;
										{props.currentDiscountedPercentage > 0 &&
											props.currentDiscountedPercentage + '%'}
									</span>
								</p>
							</div>
						</div>

						<button
							onClick={() => props.handleContinueToPay()}
							className={`actionBtn ${props.actualPrice <= 0 && 'disableActionBtn'}`}>
							{props.discountedPriceInNumbers > 0 ? (
								<>Continue to pay {props.discountedPrice}</>
							) : (
								<>
									{props.actualPriceInNumbers > 0 ? (
										<>Continue to pay {props.actualPrice}</>
									) : (
										<>Cart is empty</>
									)}
								</>
							)}
							{props.actualPrice > 0 && <img src={RightArrow} alt='RightArrow' />}
						</button>
					</>
				)}
			</div>
		</>
	);
}

export default OfferView;
