export const updateBssQualifications = (value) => {
	return {
		type: 'UPDATE_BSS_QUALIFICATIONS',
		payload: value,
	};
};

export const updateAllQualifications = (value) => {
	return {
		type: 'UPDATE_ALL_QUALIFICATIONS',
		payload: value,
	};
};


export const resetQualifications = () => {
	return {
		type: 'RESET_QUALIFICATIONS',
	};
};
