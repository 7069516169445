const initialState = {
	userDetails: null,
	cartItems: [],
	cartItemCount: 0,
	cartTotalValue: '',
	cartTotalValueInNumbers: 0,
	discountPercentage: 0,
	discountValue: '0',
	discountValueInNumbers: 0,
	offerDetails: null,
	installmentOption: null,
	selectedPaymentGateway: null,
	gatewayDiscountValue: '0',
	gatewayDiscountValueInNumbers: 0,
	backUpCartDetails: {}
};

const CartReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CART_ITEMS':
			return {
				...state,
				cartItems: action.payload.cartItems,
			};
		case 'UPDATE_CART_ITEM_COUNT':
			return {
				...state,
				cartItemCount: action.payload.cartItemCount,
			};
		case 'UPDATE_CART_TOTAL_VALUE':
			return {
				...state,
				cartTotalValue: action.payload.cartTotalValue,
			};
		case 'UPDATE_USER_DETAILS':
			return {
				...state,
				userDetails: action.payload.userDetails,
			};

		case 'UPDATE_CART_TOTAL_VALUE_IN_NUMBERS':
			return {
				...state,
				cartTotalValueInNumbers: action.payload.cartTotalValueInNumbers,
			};
		case 'UPDATE_INSTALLMENT_OPTION':
			return {
				...state,
				installmentOption: action.payload.installmentOption,
			};
		case 'UPDATE_CART_DETAILS':
			return {
				...state,
				cartItems: action.payload.cartItems,
				cartItemCount: action.payload.cartItemCount,
				cartTotalValue: action.payload.cartTotalValue,
				cartTotalValueInNumbers: action.payload.cartTotalValueInNumbers,
				discountPercentage: action.payload.discountPercentage,
				discountValue: action.payload.discountValue,
				discountValueInNumbers: action.payload.discountValueInNumbers,
				offerDetails: action.payload.offerDetails,
			};
		case 'UPDATE_SELECTED_PAYMENT_GATEWAY':
			return {
				...state,
				selectedPaymentGateway: action.payload.selectedPaymentGateway,
			};
		case 'UPDATE_GATEWAY_DISCOUNT':
			return {
				...state,
				gatewayDiscountValue: action.payload.gatewayDiscountValue,
				gatewayDiscountValueInNumbers: action.payload.gatewayDiscountValueInNumbers,
			};
		case 'RESET_CART_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default CartReducer;
