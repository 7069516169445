import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as DashboardServices from '@services/UserDashboard';
import * as DashboardActions from '@redux/actions/UserDashboard/MyLearning';

import { decryptText } from '@library/enc-dec';
import HistoryFavView from './view';

function HistoryFav() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dataFetchedRef = useRef(false);
	const userHistory = useSelector((state) => state.dashboard.userHistory);
	const userFav = useSelector((state) => state.dashboard.userFav);
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		window.scroll(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		getUserHistoryVideos();
		getFavVideos();
	}, []);


	useEffect(() => {
		if(userHistory && userHistory.success && userFav && userFav.success){
			setIsLoading(false)
			// setTimeout(function(){ // testing
			// 	setIsLoading(false)
			// },3000)
		}
	}, [userHistory, userFav]);


	const getUserHistoryVideos = async () => {
		try {
			if (!userHistory || Object.entries(userHistory).length === 0) {
				let authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await DashboardServices.getHistoryVideos(authToken);
				if (response.success) {
					dispatch(DashboardActions.updateUserHistort(response));
					return;
				}
			}
		} catch (err) {
			console.log('Error coming while fetching history videos', err);
		}
	};

	const getFavVideos = async () => {
		try {
			if (!userFav || Object.entries(userFav).length === 0) {
				let authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await DashboardServices.getFavVidoes(authToken);

				if (response.success) {
					dispatch(DashboardActions.updateUserFav(response));
					return;
				}
			}
		} catch (err) {
			console.log('Error coming while fetching favourite videos', err);
		}
	};

	const handleVideoRedirect = (event, videoId) => {
		let n = '/video/v/' + videoId;
		navigate(n);
	};

	return (
		<>
			<HistoryFavView
				isLoading={isLoading}
				historyVideos={userHistory}
				favoriteVideos={userFav}
				handleVideoRedirect={handleVideoRedirect}
			/>
		</>
	);
}

export default HistoryFav;
