import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import CartView from './view';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import TopLoadingBar from '@components/TopLoadingBar';

import { decryptText } from '@library/enc-dec';
import { getPurchasesCount } from '../../library/helpers';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';

import * as CartActions from '@redux/actions/Cart';
import * as NavbarActions from '@redux/actions/Navbar';
import * as CartServices from '@services/Cart';
import * as CourseServices from '@services/Course';
import { isUserLogedIn } from '@library/auth';

function Cart(props) {
	const navigate = useNavigate();
	const dataFetchedRef = useRef(false);

	const [isLoading, setIsLoading] = useState(true);
	const [relatedCourses, setRelatedCourses] = useState({});
	const [copyAlterIsVisible, setCopyAlterIsVisible] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		handlePage();
	}, []);

	const handlePage = async () => {
		try {
			const userLogedIn = await isUserLogedIn();
			if (userLogedIn) {
				const purchasesCount = await getPurchasesCount();
				props.updatePurchasesCount(purchasesCount);

				// localStorage.removeItem('eMediat');
				// localStorage.removeItem('eMediatt');
				// navigate('/login');
			}

			props.updateLoginState(userLogedIn);
			geteMediCart(userLogedIn);
		} catch (err) {
			console.log('Error coming while handling cart page', err);
		}
	};

	// Get related product
	const getRelatedCourses = async (courseDetails) => {
		const courseCategories = courseDetails.data?.itemsList.map(
			(item) => item.category
		);
		const cartCourseIds = courseDetails.data?.itemsList.map(
			(item) => item.courseId
		);

		let allRelatedCourses = await CourseServices.getRelatedCourses(
			courseCategories
		);

		UpdateTopLoadingBarForThisPage(60);
		if (allRelatedCourses.success) {
			const filteredData = allRelatedCourses.data.filter(
				(item) => !cartCourseIds.includes(item._id)
			);

			allRelatedCourses.data = filteredData;
			setRelatedCourses(allRelatedCourses);
		}

		setIsLoading(false);

		UpdateTopLoadingBarForThisPage(100);
	};

	const geteMediCart = async (userLogedIn) => {
		UpdateTopLoadingBarForThisPage(0);
		setIsLoading(true);
		let getRelatedCoursesCheck = false;
		let localStorageCart = {};

		if (localStorage.getItem('eMediCart')) {
			localStorageCart = JSON.parse(localStorage.getItem('eMediCart'));
			getRelatedCourses(localStorageCart);
			getRelatedCoursesCheck = true;
			localStorageCart = checkItemsIsInOffer(localStorageCart);
			props.updateCartItems(localStorageCart);
		}

		UpdateTopLoadingBarForThisPage(30);

		let continent = decryptText(localStorage.getItem('cont'));
		let countryName = decryptText(localStorage.getItem('c'));
		let currencySymbol = decryptText(localStorage.getItem('cs'));
		let currencyValue = decryptText(localStorage.getItem('cv'));

		if (userLogedIn) {
			let authToken = decryptText(localStorage.getItem('eMediat'));

			// Merging local storage cart with database cart
			if (localStorageCart?.data) {
				const courseIds = localStorageCart.data.itemsList.map((item) => {
					return { courseId: item.courseId, isInOffer: item.isInOffer };
				});

				const updatedCoursesList = await CartServices.addToCart(
					courseIds,
					continent,
					countryName,
					currencySymbol,
					currencyValue,
					authToken
				);

				if (updatedCoursesList.success) {
					// Deleting the eMediCart key from localstorage
					localStorage.removeItem('eMediCart');
					let payload = {
						countryName: countryName,
						currencySymbol: currencySymbol,
					};

					// Getting new cart items from database
					let itemsInDB = await CartServices.getCartItems(payload, authToken);
					itemsInDB = checkItemsIsInOffer(itemsInDB);
					props.updateCartItems(itemsInDB);
				}
			} else {
				let payload = {
					countryName: countryName,
					currencySymbol: currencySymbol,
				};

				// If any item is not added in localstorage cart then only this block will execute
				let itemsInDB = await CartServices.getCartItems(payload, authToken);
				itemsInDB = checkItemsIsInOffer(itemsInDB);

				props.updateCartItems(itemsInDB);
				getRelatedCourses(itemsInDB);
				getRelatedCoursesCheck = true;
			}
		}

		if (!getRelatedCoursesCheck) {
			setIsLoading(false);
		}
	};

	const checkItemsIsInOffer = (itemsInDB) => {
		let itemList = [];
		let totalValueInNumbers = 0;
		let totalValue = '';

		itemsInDB?.data?.itemsList?.map((item) => {
			item = handlePaidCourse(item);
			itemList.push(item);
			totalValueInNumbers += item.isInOffer
				? item.offerPriceInNumbers
				: item.crossedPriceInNumbers;
		});

		let countryName = decryptText(localStorage.getItem('c'));
		let currencySymbol = decryptText(localStorage.getItem('cs'));
		//check user country show sign
		if (countryName === 'India') {
			totalValue = `${currencySymbol} ${totalValueInNumbers}/-`;
		} else {
			totalValue = `${currencySymbol} ${totalValueInNumbers}`;
		}

		itemsInDB.data.itemsList = itemList;
		itemsInDB.data.totalValueInNumbers = totalValueInNumbers;
		itemsInDB.data.totalValue = totalValue;
		return itemsInDB;
	};

	const handlePaidCourse = (courseDetails) => {
		try {
			let localDbCoursesList = localStorage.getItem('crsl');

			if (localDbCoursesList) {
				localDbCoursesList = decryptText(localDbCoursesList)
					? JSON.parse(decryptText(localDbCoursesList))
					: [];

				const isExists = localDbCoursesList.filter(
					(item) => item._id === courseDetails.courseId
				);

				if (isExists.length > 0) {
					let todayDate = moment();
					let offerEndDate = moment(isExists[0].offerEndDate);
					if (moment(todayDate).isAfter(offerEndDate)) {
						courseDetails['isInOffer'] = false;
						return courseDetails;
					} else {
						courseDetails['isInOffer'] = true;
						return courseDetails;
					}
				} else {
					courseDetails['isInOffer'] = false;
					return courseDetails;
				}
			} else {
				courseDetails['isInOffer'] = false;
				return courseDetails;
			}
		} catch (err) {
			localStorage.removeItem('crsl');
			navigate('/');
			console.log('Error coming from handlePaidCourse()', err);
		}
	};

	const handleShare = (event) => {
		let id = event.target.id;
		document.getElementById('sharePopupBtn').click();
		let currentUrl = window.location.href;
		currentUrl = currentUrl.replace('/cart', '');
		let shareUrl = `${currentUrl}/course/${id}`;
		document.getElementById('shareInput').value = shareUrl;
	};

	const handleCopy = () => {
		let copyText = document.getElementById('shareInput').value;
		navigator.clipboard.writeText(copyText);
		setCopyAlterIsVisible(true);
		setTimeout(function () {
			setCopyAlterIsVisible(false);
		}, 2000);
	};

	const handleWhatAppShare = (event) => {
		let shareUrl = document.getElementById('shareInput').value;
		if (shareUrl != '') {
			if (event.target.id === 'whatsApp') {
				const whatsappUrl = `https://web.whatsapp.com/send/?text=${shareUrl}`;
				window.open(whatsappUrl, '_blank');
			}

			if (event.target.id === 'gmail') {
				const email = 'recipient@example.com';
				const subject = 'Check out this link';
				const body = `Hey, check out this link: ${shareUrl}`;
				const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
					subject
				)}&body=${encodeURIComponent(body)}`;
				window.location.href = mailtoUrl;
			}

			if (event.target.id == 'facebook') {
				const facebook = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
				window.open(facebook, '_blank');
			}

			if (event.target.id == 'twitter') {
				const tshareUrl = 'https://twitter.com/intent/tweet?url=<URL>&text=<TEXT>';
				const url = encodeURIComponent(shareUrl);
				const text = encodeURIComponent('Check out eMedi');
				window.open(
					tshareUrl.replace('<URL>', url).replace('<TEXT>', text),
					'_blank'
				);
			}
		}
	};

	const handleRedirectToCourse = (event, productId) => {
		navigate(`/course/${productId}`);
	};

	const handleDeleteItem = async (item) => {
		const { isLogedIn, cartItems } = props;
		if (isLogedIn) {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			const deletedItemStatus = await CartServices.removeCartItem(
				item._id,
				authToken
			);

			if (deletedItemStatus.success) {
				// Removing from local state
				removeItemFromCart(cartItems, item, 'loggedIn');
			} else {
				alert(deletedItemStatus.message);
			}
		} else {
			let localStorageCart = JSON.parse(localStorage.getItem('eMediCart'));
			removeItemFromCart(localStorageCart, item);
		}
	};

	const removeItemFromCart = (cartItems, selectedItem, state = 'local') => {
		let totalItems = cartItems.data.totalItems - 1;

		if (totalItems === 0) {
			setRelatedCourses({});
		}

		let countryName = decryptText(localStorage.getItem('c'));
		let currencySymbol = decryptText(localStorage.getItem('cs'));

		let itemPrice = parseInt(
			selectedItem.isInOffer
				? selectedItem.offerPriceInNumbers
				: selectedItem.crossedPriceInNumbers
		);

		let totalValueInNumbers = cartItems.data.totalValueInNumbers - itemPrice;

		totalValueInNumbers = totalValueInNumbers <= 0 ? 0 : totalValueInNumbers;

		let itemsList = cartItems.data.itemsList.filter((cartItem) =>
			state === 'loggedIn'
				? cartItem._id !== selectedItem._id
				: cartItem.courseId !== selectedItem.courseId
		);

		let updatedCartItems = {};
		updatedCartItems.success = cartItems.success;
		updatedCartItems.message = cartItems.message;

		let totalValue = '';

		if (countryName === 'India') {
			totalValue = `${currencySymbol} ${totalValueInNumbers}/-`;
		} else {
			totalValue = `${currencySymbol} ${totalValueInNumbers}`;
		}

		updatedCartItems.data = {
			totalItems: totalItems,
			totalValue: totalValue,
			totalValueInNumbers: totalValueInNumbers,
			itemsList: itemsList,
		};

		props.updateCartItemCount(totalItems);

		if (!props.isLogedIn) {
			localStorage.setItem('eMediCart', JSON.stringify(updatedCartItems));
		}
		props.updateCartItems(updatedCartItems);
	};

	const handleProcessToPay = () => {
		const { isLogedIn } = props;
		if (isLogedIn) {
			navigate('/checkout');
		} else {
			navigate('/login', {
				state: { message: 'Please login to purchase course' },
			});
		}
	};

	//START: Code for setting the progressive top bar loader
	const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
		if (interval) {
			setTimeout(function () {
				props.updateLoadingBarProgress(value);
			}, 500);
		} else {
			props.updateLoadingBarProgress(value);
		}
	};
	//END: Code for setting the progressive top bar loader

	return (
		<>
			<Navbar />
			<TopLoadingBar />

			<CartView
				cartItems={props.cartItems}
				relatedCourses={relatedCourses}
				handleShare={handleShare}
				handleCopy={handleCopy}
				copyAlterIsVisible={copyAlterIsVisible}
				handleWhatAppShare={handleWhatAppShare}
				handleRedirectToCourse={handleRedirectToCourse}
				handleProcessToPay={handleProcessToPay}
				handleDeleteItem={handleDeleteItem}
				isLoading={isLoading}
			/>
			<Footer />
		</>
	);
}

function mapStateToProps(state) {
	return {
		isLogedIn: state.navbar.loginState,
		cartItems: state.cart.cartItems,
		loadingBarProgress: state.topLoadingBar.loadingBarProgress,
		cartItemCount: state.cart.cartItemCount,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateCartItems: CartActions.updateCartItems,
			updateLoginState: NavbarActions.updateLoginState,
			updateLoadingBarProgress: TopLoadingBarActions.updateLoadingBarProgress,
			updateCartItemCount: CartActions.updateCartItemCount,
			updatePurchasesCount: NavbarActions.updatePurchasesCount,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
