import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { decryptText } from "@library/enc-dec";
import * as CheckoutServices from "@services/Checkout";
import TransactionsView from "./view";
import { isMobile } from 'react-device-detect';

import { renderToStaticMarkup } from "react-dom/server";
import { jsPDF } from "jspdf";
import store from "@redux/store";

import { Provider } from "react-redux";
import ReactPDF from '@react-pdf/renderer';

import { PDFViewer } from '@react-pdf/renderer';
import InvoiceMobile from '@components/InvoiceMobile';

import PdfIcon from "@assets/userDashboard/Icons/pdfIcon.png";
import LoaderGif from "@assets/course/loading.gif";


function Transactions(props) {
  const navigate = useNavigate();

  const handleRedirectToInvoicePage = async (event, transactionIndex) => {
    try {
      let currentTransaction = props.transactionsDetails.data[transactionIndex];

      let authToken = decryptText(localStorage.getItem("eMediat"));
      const details = await CheckoutServices.getOrderById(
        currentTransaction.orderId,
        authToken
      );
      if (details.success) {
        if (isMobile) {
          generatePdf(event, details)
          // ReactPDF.render(<Invoice invoice={details} />, `example.pdf`);
        } else {
          navigate("/invoice", { state: { invoice: details } });
        }
      } else {
        alert("Issue in generating invoice");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generatePdf = async (event, invoice) => {
    try {
      event.target.src = LoaderGif

      const doc = new jsPDF("p", "pt", "a4", true);

      var InvoiceComponent = <InvoiceMobile invoice={invoice} />
      var htmlPDFPage = renderToStaticMarkup(InvoiceComponent);

      doc.internal.write(0, "Tw");
      const pdfPromise = new Promise((resolve) => {
        doc.html(htmlPDFPage, {
          callback: function () {
            resolve(doc);
          },
        });
      });

      // Wait for the promise to resolve before continuing
      const generatedDoc = await pdfPromise;
      generatedDoc.save("invoice.pdf");

      setTimeout(function () {
        event.target.src = PdfIcon
      }, 100)

    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <>
      <TransactionsView
        transactionsDetails={props.transactionsDetails}
        handleRedirectToInvoicePage={handleRedirectToInvoicePage}
      />
    </>
  );
}

export default Transactions;
