import React from 'react';

export default function TestData() {
	const cartLoalStrageData = {
		success: true,
		message: 'List of cart items',
		data: {
			totalValue: '₹ 7744/-',
			totalItems: 2,
			itemsList: [
				{
					courseId: '644e084139ca4043e8b14733',
					title: 'CGO',
					image:
						'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png',
					description:
						'Basic skills in non-complex obstetric procedures & gynaecology..',
					type: 'Certificate Courses',
					price: '₹ 6313/-',
					isInOffer: false,
				},
				{
					courseId: '644e092639ca4043e8b1474b',
					title: 'CSVD',
					image:
						'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/csvd.png',
					description:
						'Learn the diagnosis of health conditions of the hair, nails, and skin',
					type: 'Advanced Diploma Courses',
					price: '₹ 1431/-',
					isInOffer: false,
				},
			],
		},
	};

	const recentlyViewProductData = {
		data: {
			'644e13134cc89d5a7bd04621': {
				courseId: '644e13134cc89d5a7bd04621',
				image:
					'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png',
				description:
					'Produce a competent skill developed doctor who recognizes the',
				title: 'CCH',
			},
			'644e13134cc89d5a7bd04622': {
				courseId: '644e13134cc89d5a7bd04622',
				image:
					'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png',
				description:
					'Produce a competent skill developed doctor who recognizes the',
				title: 'CCH',
			},
			'644e13134cc89d5a7bd04623': {
				courseId: '644e13134cc89d5a7bd04623',
				image:
					'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png',
				description:
					'Produce a competent skill developed doctor who recognizes the',
				title: 'CCH',
			},
			'644e13134cc89d5a7bd04624': {
				courseId: '644e13134cc89d5a7bd04624',
				image:
					'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png',
				description:
					'Produce a competent skill developed doctor who recognizes the',
				title: 'CCH',
			},
			'644e13134cc89d5a7bd04625': {
				courseId: '644e13134cc89d5a7bd04625',
				image:
					'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png',
				description:
					'Produce a competent skill developed doctor who recognizes the',
				title: 'CCH',
			},
			'644e13134cc89d5a7bd04626': {
				courseId: '644e13134cc89d5a7bd04626',
				image:
					'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png',
				description:
					'Produce a competent skill developed doctor who recognizes the',
				title: 'CCH',
			},
			'644e13134cc89d5a7bd04627': {
				courseId: '644e13134cc89d5a7bd04627',
				image:
					'https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png',
				description:
					'Produce a competent skill developed doctor who recognizes the',
				title: 'CCH',
			},
		},
	};

	const addDataOfcart = () => {
		localStorage.setItem('eMediCart', JSON.stringify(cartLoalStrageData));
		alert('data added');
	};

	const addDataOfRecentlyViewProduct = () => {
		localStorage.setItem('eMediRVPD', JSON.stringify(recentlyViewProductData));
		alert('data added');
	};
	return (
		<div>
			<button onClick={addDataOfcart}>add data of cart</button>
			<br />
			<br />
			<button onClick={addDataOfRecentlyViewProduct}>
				add data of recently view product
			</button>
		</div>
	);
}
