import React from 'react';

import { Link } from 'react-router-dom';
import '@css/SignupLogin.css';
import './forgotPassword.css';

export default (props) => (
	<>
		<div className='container'>
			<div className='row'>
				<center id='centerTagConatiner' className='forgetPwdCenterTagConatiner'>
					{!props.showOTP && !props.showChangePassword ? (
						<form
							style={{ textAlign: 'left' }}
							className='col-10 col-sm-10 col-md-8 col-lg-5'
							onSubmit={props.handleContactConfirmation}>
							<p style={{ textAlign: 'left' }}>Registered contact number</p>
							{props.errorMessage ? (
								<p
									style={{ width: '100%' }}
									className='class="col-10 col-sm-10 col-md-8 col-lg-5 alert alert-danger'>
									{props.errorMessage}
								</p>
							) : null}

							<div className='input-group'>
								<input
									type='number'
									className='form-control signUpInputBoxes'
									placeholder='Contact Number'
									id='contactNumber'
									value={props.contactNumber}
									required
									onChange={(e) => props.handleContactNumber(e.target.value)}
								/>
							</div>

							{props.isBtnLoading ?
								(
									<>
										<button
											id='loginSubmit'
											className='col-12 loginSignupPageLoadingBtn'
											disabled >
											<i className="fa fa-spinner fa-spin"></i>
										</button>

									</>
								) : (
									<>
										<input
											type='submit'
											value='Confirm'
											id='confirmationBtn1'
											className='col-12'
										/>
									</>
								)
							}

							<br />
						</form>
					) : null}
					{props.showOTP ? (
						<>
							<p
								className='col-10 col-sm-10 col-md-8 col-lg-5'
								style={{ textAlign: 'left' }}>
								OTP
							</p>
							{props.errorMessage ? (
								<p className='class="col-10 col-sm-10 col-md-8 col-lg-5 alert alert-danger'>
									{props.errorMessage}
								</p>
							) : null}
							<form
								className='col-10 col-sm-10 col-md-8 col-lg-5'
								style={{ textAlign: 'left' }}
								onSubmit={props.handleOTPConfirmation}>
								<input
									className='text-center otp'
									type='number'
									id='otp_1'
									name='otp_1'
									maxLength='1'
									value={props.OTP1}
									onChange={(event) => props.handleOtpChange(event, 'otp_1', 0)}
									onKeyDown={(event) => props.handleKeyDownOtpChange(event, 0)}
									ref={(el) => (props.otpInputRefs.current[0] = el)}
								/>
								<input
									className='text-center otp'
									type='number'
									id='otp_2'
									name='otp_2'
									maxLength='1'
									value={props.OTP2}
									onChange={(event) => props.handleOtpChange(event, 'otp_2', 1)}
									onKeyDown={(event) => props.handleKeyDownOtpChange(event, 1)}
									ref={(el) => (props.otpInputRefs.current[1] = el)}
								/>
								<input
									className='text-center otp'
									type='number'
									id='otp_3'
									name='otp_3'
									maxLength='1'
									value={props.OTP3}
									onChange={(event) => props.handleOtpChange(event, 'otp_3', 2)}
									onKeyDown={(event) => props.handleKeyDownOtpChange(event, 2)}
									ref={(el) => (props.otpInputRefs.current[2] = el)}
								/>
								<input
									className='text-center otp'
									type='number'
									id='otp_4'
									name='otp_4'
									maxLength='1'
									value={props.OTP4}
									onChange={(event) => props.handleOtpChange(event, 'otp_4', 3)}
									onKeyDown={(event) => props.handleKeyDownOtpChange(event, 3)}
									ref={(el) => (props.otpInputRefs.current[3] = el)}
								/>
								<input
									className='text-center otp'
									type='number'
									id='otp_5'
									name='otp_5'
									maxLength='1'
									value={props.OTP5}
									onChange={(event) => props.handleOtpChange(event, 'otp_5', 4)}
									onKeyDown={(event) => props.handleKeyDownOtpChange(event, 4)}
									ref={(el) => (props.otpInputRefs.current[4] = el)}
								/>
								<br />
								<Link to='#' onClick={props.handlOTPResend}>
									Resent OTP?
								</Link>
								{props.OTPResend ? (
									<>
										<br />
										<p style={{ color: 'green', marginTop: '5px', marginBottom: '5px' }}>
											OTP sent successfully
										</p>
									</>
								) : null}

								{props.isBtnLoading ?
									(
										<>
											<button
												id='loginSubmit'
												className='col-12 loginSignupPageLoadingBtn'
												disabled >
												<i className="fa fa-spinner fa-spin"></i>
											</button>

										</>
									) : (
										<>
											<input
												type='submit'
												value='Confirmation'
												id='confirmationBtn2'
												className='col-12'
												style={{ marginTop: '6px' }}
											/>
										</>
									)
								}

							</form>
						</>
					) : null}
					{props.showChangePassword ? (
						<form
							className='col-10 col-sm-10 col-md-8 col-lg-5'
							style={{ textAlign: 'left' }}
							onSubmit={props.handleCreatePassword}>
							<p>Create new password</p>
							{props.errorMessage ? (
								<p className='class="col-10 col-sm-10 col-md-8 col-lg-5 alert alert-danger'>
									{props.errorMessage}
								</p>
							) : null}
							<div className='input-group'>
								<input
									type='password'
									className='form-control signUpInputBoxes'
									placeholder='Password'
									id='spassword'
									onChange={(e) => {
										props.handlePaswordValidation(e.target.value);
									}}
									required
								/>
							</div>

							<div className='input-group'>
								<input
									type='password'
									className='form-control signUpInputBoxes'
									placeholder='Confirm Password'
									id='confirmPassword'
									onChange={(e) => {
										props.handleConfirmPasword(e.target.value);
									}}
									required
								/>
							</div>

							<ul className='list-group list-group-horizontal'>
								{props.poorPassword === true ? (
									<li
										className='list-group-item bg-danger col-4'
										style={{ padding: '1px 0px' }}></li>
								) : (
									''
								)}
								{props.weakPassword === true ? (
									<li
										className='list-group-item bg-warning col-4'
										style={{ padding: '1px 0px' }}></li>
								) : (
									''
								)}
								{props.strongPassword === true ? (
									<li
										className='list-group-item bg-success col-4'
										style={{ padding: '1px 0px' }}></li>
								) : (
									''
								)}
							</ul>
							<p style={{ textAlign: 'left' }}> {props.passwordError}</p>

							{props.isBtnLoading ?
								(
									<>
										<button
											id='loginSubmit'
											className='col-12 loginSignupPageLoadingBtn'
											disabled >
											<i className="fa fa-spinner fa-spin"></i>
										</button>

									</>
								) : (
									<>
										<input
											disabled={props.disableCreatePassword}
											type='submit'
											value='Create Password'
											id='createPassword'
											className={props.disableCreatePassword ? 'col-12 disabledCreatePassword' : 'col-12'}
										/>
									</>
								)
							}

							<br />
						</form>
					) : null}
				</center>
			</div>
		</div>
	</>
);
