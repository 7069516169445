import React, { useState } from 'react';
import UserCommentView from './view';
import { useNavigate } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';

import { decryptText } from '@library/enc-dec';
import { formatComment } from '@library/commentVideoTimeDuration';

import * as CourseContentServices from '@services/CourseContent';
import * as CourseDetailsActions from '@redux/actions/CourseDetails';

function UserComment(props) {
	const navigate = useNavigate();
	const [isUserCommentInFocus, setIsUserCommentInFocus] = useState(false);
	const [userComment, setUserComment] = useState('');
	const [isSorted, setIsSorted] = useState(true);

	const handleUserCommentFocus = (event) => {
		setIsUserCommentInFocus(true);
	};

	const handleUserCommentChange = (event) => {
		setUserComment(event.target.value);
	};

	const handleUserCommentCancleBtn = (event) => {
		setIsUserCommentInFocus(false);
		setUserComment('');
	};

	const handleUserCommentBtn = async (event) => {
		try {
			const { currentVideoData } = props;

			let videoDocId = currentVideoData._id;
			let formattedComment = formatComment(userComment, props.sendVideoDuration);
			let comentDetails = {
				videoDocId: videoDocId,
				comment: formattedComment,
			};

			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				navigate('/login');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));

			const response = await CourseContentServices.addComment(
				comentDetails,
				authToken
			);

			if (response.success) {
				handleAddNewComment(response.data);
				handleUserCommentCancleBtn();
			}
		} catch (err) {
			console.log('Error coming while adding comment:', err);
		}
	};

	const handleAddNewComment = (newComment) => {
		let updatedVideoComments = produce(props.currentVideoComments, (draft) => {
			draft.data.totalNumberOfComments++;
			draft.data.comments.push(newComment);
		});

		// Updating Redux
		props.updateCurrentVideoComments(updatedVideoComments);
	};

	const handleSort = () => {
		let updatedVideoComments = produce(props.currentVideoComments, (draft) => {
			draft.data.comments.reverse();
		});

		// Updating Redux
		props.updateCurrentVideoComments(updatedVideoComments);
		setIsSorted(!isSorted);
	};

	return (
		<>
			<UserCommentView
				handleUserCommentBtn={handleUserCommentBtn}
				isUserCommentInFocus={isUserCommentInFocus}
				handleUserCommentFocus={handleUserCommentFocus}
				handleUserCommentChange={handleUserCommentChange}
				userComment={userComment}
				handleUserCommentCancleBtn={handleUserCommentCancleBtn}
				currentVideoComments={props.currentVideoComments}
				handleSort={handleSort}
				isSorted={isSorted}
				userDisplayPhoto={props.userDetails ? props.userDetails.displayPhoto : null}
			/>
		</>
	);
}
function mapStateToProps(state) {
	return {
		currentVideoData: state.courseDetails.currentVideoData,
		currentVideoComments: state.courseDetails.currentVideoComments,
		userDetails: state.courseDetails.userDetails,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateCurrentVideoComments: CourseDetailsActions.updateCurrentVideoComments,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserComment);
