import React from "react";
import ContentLoader from "react-content-loader";

import NameAndPhoneNoOtp from "@components/Popups/NameAndPhoneNoOtp";

import LeftCarouselArrow from "@assets/course/leftCarouselArrow.png";
import RightCarouselArrow from "@assets/course/rightCarouselArrow.png";
import GrayBgWhitePlusBtn from "@assets/course/grayBgWhitePlusBtn.png";
import WhiteTickBlueBg from "@assets/course/whiteTickBlueBg.png";
import LoaderGif from "@assets/course/loading.gif";

import numeral from "numeral";
import "./style.css";

function DesktopTabView(props) {
	return (
		<>
			<div className="exploreDesktopTabView">
				{props.isLoading ? (
					<>
						<div className="learningPathDummyData">
							{[...Array(2)].map((_, index) => (
								<div key={index}>
									<ContentLoader
										// backgroundColor="#9e0505"
										// foregroundColor="#ff8585"
										viewBox="0 0 380 160">
										<rect x="0" y="10" rx="4" ry="4" width="50" height="10" />

										<rect x="0" y="30" rx="3" ry="3" width="70" height="5" />
										<rect x="0" y="40" rx="3" ry="3" width="70" height="5" />
										<rect x="0" y="50" rx="3" ry="3" width="70" height="5" />
										<rect x="0" y="65" rx="3" ry="3" width="250" height="10" />

										<rect x='0' y='95' rx='5' ry='5' width='90' height='60' />
										<rect x='110' y='95' rx='5' ry='5' width='90' height='60' />
										<rect x='220' y='95' rx='5' ry='5' width='90' height='60' />
										<rect x='330' y='95' rx='5' ry='5' width='90' height='60' />

										<rect x="270" y="0" rx="5" ry="5" width="110" height="70" />
									</ContentLoader>
									<br />
									<br />
									<br />
								</div>
							))}
						</div>
					</>
				) : (
					<>
						{props.exploreData &&
							Object.entries(props.exploreData).length > 0 ? (
							Object.entries(props.exploreData).map(([index, explore]) => (
								<div key={index}>
									<div className="row exploreContentContainer">
										<div className="col-md-7 col-lg-7 textContainer">
											<p className="title">{explore.title}</p>
											{explore.pointers &&
												explore.pointers.length > 0 &&
												explore.pointers.map((pointer, pointerIndex) => (
													<p className="pointerContainer" key={pointerIndex}>
														<img src={pointer.icon} alt="icon" />
														<span>{pointer.pointer}</span>
													</p>
												))}
											<p className="description">{explore.description}</p>
										</div>

										{explore.videoUrl && explore.videoUrl !== "" && (
											<div className="col-md-5 col-lg-4 videoCol">
												<div className="videoContainer">
													<iframe
														src={explore.videoUrl}
														title="YouTube video player"
														frameBorder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
														allowFullScreen
													></iframe>
												</div>
											</div>
										)}
									</div>

									<div className="relatedCoursesMainContainer">
										<div
											id={`relatedCourses_${explore.categoryId}`}
											className="col-12 relatedCourses d-flex overflow-auto"
										>
											{window.innerWidth > 992 &&
												explore.courses &&
												explore.courses.length > 3 ? (
												<>
													<img
														onClick={() =>
															props.handleRelatedCoursesScroll(
																explore.categoryId,
																"left"
															)
														}
														className="leftCarouselArrow"
														src={LeftCarouselArrow}
														alt="leftCarouselArrow"
													/>
													<img
														onClick={() =>
															props.handleRelatedCoursesScroll(
																explore.categoryId,
																"right"
															)
														}
														className="rightCarouselArrow"
														src={RightCarouselArrow}
														alt="rightCarouselArrow"
													/>
												</>
											) : (
												window.innerWidth < 992 &&
												explore.courses &&
												explore.courses.length > 2 && (
													<>
														<img
															onClick={() =>
																props.handleRelatedCoursesScroll(
																	explore.categoryId,
																	"left"
																)
															}
															className="leftCarouselArrow"
															src={LeftCarouselArrow}
															alt="leftCarouselArrow"
														/>
														<img
															onClick={() =>
																props.handleRelatedCoursesScroll(
																	explore.categoryId,
																	"right"
																)
															}
															className="rightCarouselArrow"
															src={RightCarouselArrow}
															alt="rightCarouselArrow"
														/>
													</>
												)
											)}
											{explore.courses &&
												explore.courses.length > 0 &&
												explore.courses.map((course, courseIndex) => {
													return (
														<div
															className="col-6 col-sm-4 col-lg-3 courseContainer"
															key={courseIndex}
														>
															<img
																src={course.rectangleThumbnailUrl}
																alt={course.title}
																onClick={() =>
																	props.handleRedirectToCoursePage(course._id)
																}
																className="w-100 courseThumbnail"
															/>

															{props.purchasedCourses && Object.entries(props.purchasedCourses).length > 0 && props.purchasedCourses.some(item => item.courseId === course._id) ? null :
																(
																	<>
																		{props.cartItems.some(item => item.courseId === course._id) ?
																			(
																				<>
																					<img
																						className='notifyIcon notifyIcon_whiteTickBlueBg'
																						src={WhiteTickBlueBg}
																						alt="whiteTickBlueBg"
																					/>
																				</>
																			) : (
																				<>
																					{props.addToCartIconIsLoading && props.addToCartIconIsLoading === course._id ?
																						(
																							<>
																								<img
																									className="notifyIcon"
																									src={LoaderGif}
																									alt="loading"
																								/>
																							</>
																						) : (
																							<>
																								<img
																									onClick={() => props.handleAddToCart(course)} // this fn is defined in src\pages\Explore\Main\index.js
																									className="notifyIcon"
																									src={GrayBgWhitePlusBtn}
																									alt="grayBgWhitePlusBtn"
																								/>
																							</>
																						)}
																				</>
																			)
																		}
																	</>
																)
															}

															<p className="title">{course.title}</p>
															<p className="priceContainer">
																<span className="priceAlwaysDisplyed">			
																	{props.globalCurrencySymbol}{' '}{course.isInOffer && course.offerPrice > 0
																		? new Intl.NumberFormat('en-IN').format(course.offerPrice)
																		: new Intl.NumberFormat('en-IN').format(course.crossedPrice)}
																</span>
																{course.isInOffer && course.offerPrice > 0 && (
																	<>
																		<span className="crossedPrice">
																			<strike>
																			{props.globalCurrencySymbol}{' '}{new Intl.NumberFormat('en-IN').format(course.crossedPrice)}
																			</strike>
																		</span>
																		<span className="offerPrice">
																			{Math.round(
																				((course.crossedPrice -
																					course.offerPrice) /
																					course.crossedPrice) *
																				100
																			)}
																			%{""}off
																		</span>
																	</>
																)}
															</p>
														</div>
													);
												})}
										</div>
									</div>
									<hr />
								</div>
							))
						) : (
							<center>
								<img
									className="w-50"
									src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=2000"
									alt="noCourses"
								/>
							</center>
						)}
						<NameAndPhoneNoOtp
							nameAndPhoneNoOtpOpenBtnRef={props.nameAndPhoneNoOtpOpenBtnRef}
							nameAndPhoneNoOtpStage={props.nameAndPhoneNoOtpStage}
							countryCode={props.countryCode}
							phoneNo={props.phoneNo}
							handleLeadsVerifyOTP={props.handleLeadsVerifyOTP}
							handleResendOTP={props.handleResendOTP}
							handleEnrollSendOTP={props.handleEnrollSendOTP}
							flushNameAndPhoneNoOtpStage={props.flushNameAndPhoneNoOtpStage}
						/>
					</>
				)}
			</div>
		</>
	);
}

export default DesktopTabView;
