import React, { useState, useEffect } from 'react';

import { decryptText } from '@library/enc-dec';
import { useDispatch, useSelector } from 'react-redux';
import EvaluationTabView from './view';

import * as UserDashboardServices from '@services/UserDashboard';
import * as ExamServices from '@services/Exam';

import * as UserDashboardActions from '@redux/actions/UserDashboard';

function EvaluationTab() {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.dashboard.user);
	const examDetails = useSelector((state) => state.dashboard.examDetails);
	const testDetails = useSelector((state) => state.dashboard.testDetails);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (!user || Object.entries(user).length === 0) {
            getUserDetails();
        }
        else {
            getExamORTest('exam', user);
            getExamORTest('test', user);
        }
    }, []);

    useEffect(()=>{
        if(examDetails && examDetails.success && testDetails && testDetails.success){
            setIsLoading(false)
			// setTimeout(function(){ // testing
			// 	// setIsLoading(false)
			// },3000)
        }
    },[examDetails,testDetails])


    const getExamORTest = async (action, userData) => {
        if(action === "exam" && examDetails && examDetails.success){
            return
        }
        if(action === "test" && testDetails && testDetails.success){
            return
        }

        if (userData && userData._id) {
            let authToken = decryptText(localStorage.getItem('eMediat'));
            let payload = {
                userId: userData._id,
                authToken: authToken,
                action: action
            };

            try {
                const response = await ExamServices.getExamORTest(payload);
                if (response.success) {
                    if (action === "exam") {
    					dispatch(UserDashboardActions.updateExamDetails(response));
                    }
                    else {
    					dispatch(UserDashboardActions.updateTestDetails(response));
                    }
                }
                else {
                    console.log('Exam tab: Error coming from getExamORTest()', response);
                }

            } catch (err) {
                console.log('Exam tab: Error coming from getExamORTest()', err);
            }
        }
    }

    const getUserDetails = async () => {
        try {
            let authToken = decryptText(localStorage.getItem('eMediat'));
            const response = await UserDashboardServices.getUser(authToken);
            if (response.success) {
                dispatch(UserDashboardActions.updateUserDetails(response.data));
                getExamORTest('exam', response.data);
                getExamORTest('test', response.data);
            }
        } catch (err) {
            console.log('Profile: Error coming from getUserDetails()', err);
        }
    };


    return (
        <>
            <EvaluationTabView
                examDetails={examDetails}
                testDetails={testDetails}
                isLoading={isLoading}
            />
        </>
    );
}

export default EvaluationTab;
