//------------------------------------------------------------
// START : Fn to handle input box and convert it to links for video timeline
//------------------------------------------------------------
const timeDurationToNumFn = (colonSeparated) => {
    let s = [];
    for (let x = 0; x < colonSeparated.length; x++) {
        if (colonSeparated[x].length < 3) {
            let num = parseInt(colonSeparated[x]);
            if (!isNaN(num) && num >= 0 && num <= 61) {
                s.push(num)
            }
        }
    }
    return s;
}


const generateHtmlTagFn = (timeDurationToNum, videoDuration) => {
    // let tt = 115000; // test
    let anchorText;
    let anchorHref;
    if (timeDurationToNum.length === 2) {
        // mins
        anchorHref = timeDurationToNum[0] * 60;
        anchorText = timeDurationToNum[0].toString();

        // seconds
        anchorHref = anchorHref + timeDurationToNum[1];
        anchorText = anchorText + ":" + timeDurationToNum[1].toString()
    }
    else if (timeDurationToNum.length === 3) {
        // hrs
        anchorHref = timeDurationToNum[0] * 60 * 60;
        anchorText = timeDurationToNum[0].toString();

        // mins
        anchorHref = anchorHref + (timeDurationToNum[1] * 60);
        anchorText = anchorText + ":" + timeDurationToNum[1].toString();

        // seconds
        anchorHref = anchorHref + timeDurationToNum[2];
        anchorText = anchorText + ":" + timeDurationToNum[2].toString()
    }

    if (videoDuration > anchorHref) {
        let colonSeparated = "<commentvideoduration href='" + anchorHref + "'>" + anchorText + "</commentvideoduration>"
        return {
            success: true,
            colonSeparated: colonSeparated
        }
    }
    else {
        return {
            success: false
        }
    }
}


const updateCommentFn = (comment, thingToAdd)=>{
    let lComment;
    if(comment === ''){
        lComment = thingToAdd;
    }
    else{
        lComment = comment + ' ' + thingToAdd;
    }
    return lComment;
}

// test string
// inputString = 'nice ok kaka:kaka 111 :111 good 4: nano 1:1:1:1 lalalla 10:9:9 && 9:21 jj';

export const formatComment = (inputString ,videoDuration) => {
    const spaceSeparated = inputString.split(" ");
    let comment = '';
    let check = false;
    for (let i = 0; i < spaceSeparated.length; i++) {
        if (!check) {
            const hasColon = spaceSeparated[i].indexOf(":") !== -1;
            if (hasColon) {
                let colonSeparated = spaceSeparated[i].split(":");
                if (colonSeparated.length === 2 || colonSeparated.length === 3) {
                    let timeDurationToNum = timeDurationToNumFn(colonSeparated);
                    if (colonSeparated.length === timeDurationToNum.length) {
                        let generateHtmlTag = generateHtmlTagFn(timeDurationToNum,videoDuration)
                        if (generateHtmlTag.success) {
                            colonSeparated = generateHtmlTag.colonSeparated
                            check = true;
                        }
                    }
                }
                if (Array.isArray(colonSeparated)) {
                    let updatedComment = updateCommentFn(comment, colonSeparated.join(':'))
                    comment = updatedComment
                }
                else {
                    let updatedComment = updateCommentFn(comment, colonSeparated)
                    comment = updatedComment
                }
            }
            else {
                let updatedComment = updateCommentFn(comment, spaceSeparated[i])
                comment = updatedComment
            }
        }
        else{
            let updateComment = updateCommentFn(comment, spaceSeparated[i])
            comment = updateComment
        }
    }
    return comment;
}
//------------------------------------------------------------
// END : Fn to handle input box and convert it to links for video timeline
//------------------------------------------------------------



