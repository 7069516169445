import React from 'react';

import MobileView from './view';
import { useSelector } from 'react-redux';

function Mobile(props) {
	const cartItems = useSelector((state) => state.cart.cartItems);
	return <MobileView cartItems={cartItems} {...props} />;
}

export default Mobile;
