export const updateQuestionBank = (value) => {
    return {
      type: "UPDATE_QUESTION_BANK",
      payload: value,
    };
  };
  
  export const updateTotalNumberOfQuestions = (value) => {
    return {
      type: "UPDATE_TOTAL_NUMBER_OF_QUESTIONS",
      payload: value,
    };
  };
  
  export const updateAllQuestions = (value) => {
    return {
      type: "UPDATE_ALL_QUESTIONS",
      payload: value,
    };
  };