import React from 'react';

import { Link } from 'react-router-dom';
import numeral from 'numeral';

import NameAndPhoneNoOtp from '@components/Popups/NameAndPhoneNoOtp';
import ContentLoader from 'react-content-loader';
import CartAndEnrollView from '@components/Popups/CartAndEnrollView';

import './style.css';
import GrayBgWhitePlusBtn from '@assets/course/grayBgWhitePlusBtn.png';
import WhiteTickBlueBg from '@assets/course/whiteTickBlueBg.png';
import LoaderGif from "@assets/course/loading.gif";

function ExploreCourseCategoryiew(props) {
	return (
		<>
			<div className='exploreCourseCategoryiew commonexplorePageAlignmentContainerHelper'>
				{props.isLoading ? (
					<>
						<ContentLoader
							// backgroundColor="#9e0505"
							// foregroundColor="#ff8585"
							viewBox='0 0 380 150'>
							<rect x='0' y='10' rx='4' ry='4' width='150' height='12' />
							<rect x='0' y='35' rx='3' ry='3' width='380' height='10' />
							<rect x='0' y='51' rx='3' ry='3' width='380' height='10' />
							<rect x='0' y='67' rx='3' ry='3' width='380' height='10' />
							<rect x='0' y='88' rx='3' ry='3' width='200' height='10' />
							<rect x='0' y='105' rx='3' ry='3' width='200' height='10' />
							<rect x='0' y='121' rx='3' ry='3' width='200' height='10' />
						</ContentLoader>

						{[...Array(4)].map((_, index) => (
							<React.Fragment key={index}>
								<ContentLoader
									// backgroundColor="#9e0505"
									// foregroundColor="#ff8585"
									viewBox='0 0 380 120'>
									<rect x='0' y='10' rx='4' ry='4' width='100' height='100' />
									<rect x='110' y='30' rx='4' ry='4' width='250' height='8' />
									<rect x='110' y='45' rx='4' ry='4' width='150' height='8' />
									<rect x='110' y='70' rx='4' ry='4' width='50' height='7' />
									<rect x='170' y='70' rx='4' ry='4' width='50' height='7' />
									<rect x='230' y='70' rx='4' ry='4' width='50' height='7' />

									<circle cx='370' cy='60' r='9' />
								</ContentLoader>
							</React.Fragment>
						))}
					</>
				) : (
					<>
						{props.exploreData ? (
							<>
								<div className='exploreContentContainer'>
									<p className='title'>{props.exploreData.title}</p>
									<p className='description'>{props.exploreData.description}</p>

									{props.exploreData &&
										props.exploreData.pointers.length > 0 &&
										props.exploreData.pointers.map((pointer, pointerIndex) => (
											<p className='pointerContainer' key={pointerIndex}>
												<img src={pointer.icon} alt='icon' />
												<span>{pointer.pointer}</span>
											</p>
										))}

									<br />

									{props.exploreData.courses &&
										props.exploreData.courses.length > 0 &&
										props.exploreData.courses.map((course, courseIndex) => (
											<div key={courseIndex} className='courseCardDiv'>
												<Link to={`/course/` + course._id}>
													<div className='div1'>
														<img
															className='thumbnail'
															src={course.squareThumbnailUrl}
															alt='squareThumbnail'
														/>
														<div style={{ marginLeft: '7px' }}>
															<p className='name'>{course.title}</p>
															<p className='price'>

																{props.globalCurrencySymbol}{' '} {course.isInOffer && course.offerPrice > 0
																	? new Intl.NumberFormat('en-IN').format(course.offerPrice)
																	: new Intl.NumberFormat('en-IN').format(course.crossedPrice)}
																{course.isInOffer && course.offerPrice > 0 && (
																	<>
																		<span className='crossedPrice'>
																			<strike>
																				{props.globalCurrencySymbol}{' '} {new Intl.NumberFormat('en-IN').format(course.crossedPrice)}</strike>
																		</span>
																		<span className='offerPrice'>
																			{Math.round(
																				((course.crossedPrice - course.offerPrice) /
																					course.crossedPrice) *
																				100
																			)}
																			%{''}off
																		</span>
																	</>
																)}
															</p>
														</div>
													</div>
												</Link>

												{props.purchasedCourses && Object.entries(props.purchasedCourses).length > 0 && props.purchasedCourses.some(item => item.courseId === course._id) ? null :
													(
														<>
															{props.cartItems.some(item => item.courseId === course._id) ?
																(
																	<>
																		<img
																			className='notifyIcon notifyIcon_whiteTickBlueBg'
																			src={WhiteTickBlueBg}
																			alt="whiteTickBlueBg"
																		/>
																	</>
																) : (
																	<>
																		{props.addToCartIconIsLoading && props.addToCartIconIsLoading === course._id ?
																			(
																				<>
																					<img
																						className="notifyIcon"
																						src={LoaderGif}
																						alt="loading"
																					/>
																				</>
																			) : (
																				<>
																					<img
																						onClick={() => props.handleAddToCart(course)} // this fn is defined in src\pages\Explore\ExploreCourseCategory\index.js
																						className="notifyIcon"
																						src={GrayBgWhitePlusBtn}
																						alt="grayBgWhitePlusBtn"
																					/>

																				</>
																			)
																		}
																	</>
																)
															}
														</>
													)
												}
											</div>
										))}
								</div>
							</>
						) : (
							<center>
								<img
									className='w-50'
									src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=2000'
									alt='noCourses'
								/>
							</center>
						)}

						<NameAndPhoneNoOtp
							countryCode={props.countryCode}
							phoneNo={props.phoneNo}
							nameAndPhoneNoOtpOpenBtnRef={props.nameAndPhoneNoOtpOpenBtnRef}
							nameAndPhoneNoOtpStage={props.nameAndPhoneNoOtpStage}
							flushNameAndPhoneNoOtpStage={props.flushNameAndPhoneNoOtpStage}
							handleEnrollSendOTP={props.handleEnrollSendOTP}
							handleResendOTP={props.handleResendOTP}
							handleLeadsVerifyOTP={props.handleLeadsVerifyOTP}
						/>

						<CartAndEnrollView />
					</>
				)}
			</div>
		</>
	);
}

export default ExploreCourseCategoryiew;
