import React from 'react';
import ContentLoader from "react-content-loader";

function DesktopLoader(props) {
	return (
		<>
			<ContentLoader
				viewBox='0 0 400 200'>
				
				<rect x='0' y='0' rx='0' ry='0' width='400' height='6' />

				<rect x='40' y='20' rx='2' ry='2' width='220' height='15' />

				{/* START : Right hand side content loader */}
				<rect x='265' y='20' rx='2' ry='2' width='90' height='80' />
				<rect x='265' y='110' rx='2' ry='2' width='90' height='50' />
				{/* END : Right hand side content loader */}

				<rect x='40' y='45' rx='2' ry='2' width='220' height='15' />
				<rect x='40' y='70' rx='2' ry='2' width='220' height='15' />
				<rect x='40' y='95' rx='2' ry='2' width='220' height='15' />
				<rect x='40' y='120' rx='2' ry='2' width='220' height='15' />
				<rect x='40' y='145' rx='2' ry='2' width='220' height='15' />
				<rect x='40' y='170' rx='2' ry='2' width='220' height='15' />

			</ContentLoader>
		</>
	);
}

export default DesktopLoader;
