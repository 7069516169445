import React from 'react';
import ContentLoader from "react-content-loader";
import './style.css';

export default () => (
    <div className='settingTabTransactionsDetailsCouserLoader'>

        {/* START Desktop */}
        <ContentLoader
            style={{ marginTop: '50px' }}
            className='settingTabTransactionsDetailsCouserLoaderDesktop'
            backgroundColor="#ffffff"
            // foregroundColor="#a09d9d"
            viewBox="0 0 395 10">
            <rect x='0' y='0' rx='2' ry='2' width='80' height='8' />
        </ContentLoader>

        {[...Array(10)].map((_, index) => (
            <ContentLoader
                key={index}
                className='settingTabTransactionsDetailsCouserLoaderDesktop'
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 395 20">
                <rect x='0' y='10' rx='2' ry='2' width='20' height='8' />
                <rect x='30' y='10' rx='2' ry='2' width='50' height='8' />
                <rect x='90' y='10' rx='2' ry='2' width='120' height='8' />
                <rect x='220' y='10' rx='2' ry='2' width='120' height='8' />
                <rect x='350' y='10' rx='2' ry='2' width='30' height='8' />
            </ContentLoader>
        ))}
        {/* END Desktop */}



        {/* START Mobile */}
        <ContentLoader
            style={{ marginTop: '50px' }}
            className='settingTabTransactionsDetailsCouserLoaderMobile'
            backgroundColor="#ffffff"
            // foregroundColor="#a09d9d"
            viewBox="0 0 100 8">
            <rect x='0' y='0' rx='2' ry='2' width='50' height='4' />
        </ContentLoader>

        {[...Array(10)].map((_, index) => (
            <ContentLoader
                key={index}
                className='settingTabTransactionsDetailsCouserLoaderMobile'
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 100 10">
                <rect x='0' y='1' rx='2' ry='2' width='20' height='4' />
                <rect x='30' y='1' rx='2' ry='2' width='50' height='4' />
                <rect x='90' y='1' rx='2' ry='2' width='120' height='4' />
            </ContentLoader>
        ))}
        {/* END Mobile */}
    </div>
);
