export const getAllOffers = async () => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllOffers`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Home services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getUserSpecificOffer = async (authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getUserSpecificOffer`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course services : Get Course by Id Auth',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

