import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CourseDetailsView from "./view";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import TopLoadingBar from "@components/TopLoadingBar";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { isUserLogedIn } from "@library/auth";
import { decryptText } from "@library/enc-dec";
import { getPurchasesCount } from "../../library/helpers";

import * as SeriesDetailsActions from "@redux/actions/SeriesDetails";
import * as CourseContentServices from "@services/CourseContent";
import * as SeriesServices from "@services/Series";
import * as DashboardServices from "@services/UserDashboard";
import * as NavbarActions from "@redux/actions/Navbar";
import * as UserDashboardActions from "@redux/actions/UserDashboard";
import { useDispatch } from "react-redux";

import * as BasicFormServices from "@services/BasicForm";
import * as TopLoadingBarActions from "@redux/actions/TopLoadingBar";
import { activateSeries } from "@services/UserDashboard";

import { produce } from "immer";
import { useSelector } from "react-redux";

let increaseViewCount = 0;
let increaseWatchedVideoCount = 0;

function CourseDetails(props) {
  const dispatch = useDispatch();
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [isVideoPlayerLoading, setIsVideoPlayerLoading] = useState(false);
  const [sendVideoDuration, setSendVideoDuration] = useState(0);

  // Mahdi :keep for video duration from comment
  // const [updateVideoDuration, setUpdateVideoDuration] = useState(0);
  const { identifier, id, purchaseId } = useParams();

  const seriesActivation = useSelector(
    (state) => state.dashboard.seriesActivation
  );

  var getUrlParameters;
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    //Fn to get the url parameters
    getUrlParameters = getUrlParametersFn();
    handlePage();
  }, []);

  const activateSeriesFn = async (seriesId, userLogedIn) => {
    try {
      const authToken = decryptText(localStorage.getItem("eMediat"));
      const response = await activateSeries(seriesId, authToken);
      if (response.success) {
        // Check user is eligible to watch the series content or not
        const isElegible =
          await DashboardServices.checkUserEligibilityToWatchSeries(
            seriesId,
            authToken
          );

        if (isElegible.success) {
          dispatch(UserDashboardActions.updateMyProfileError(""));
          dispatch(
            UserDashboardActions.updateSeriesActivation({
              seriesId: seriesId,
              isActivated: "true",
            })
          );
          getSeriesDetails(getUrlParameters, userLogedIn);
          return;
        }
        dispatch(UserDashboardActions.updateMyProfileError(isElegible.message));
        navigate(`/dashboard`);
        return;
      }
      dispatch(UserDashboardActions.updateMyProfileError(response.message));
      navigate(`/dashboard`);
    } catch (err) {
      console.log("Error coming from activateSeries() in userDashboard", err);
    }
  };

  const handlePage = async () => {
    try {
      const userLogedIn = await isUserLogedIn();
      init(userLogedIn);
      props.updateLoginState(userLogedIn);
      if (userLogedIn) {
        const purchasesCount = await getPurchasesCount();
        props.updatePurchasesCount(purchasesCount);
        geUserDetails();
      }
    } catch (err) {
      console.log("Error coming while handling courseDetails page", err);
    }
  };

  const init = async (userLogedIn) => {
    // User coming from direct URL
    if (
      !(
        seriesActivation &&
        seriesActivation.isActivated === "true" &&
        seriesActivation.seriesId === getUrlParameters.id
      )
    ) {
      if (getUrlParameters.type === "v") {
        getSeriesDetails(getUrlParameters, userLogedIn);
      } else {
        activateSeriesFn(getUrlParameters.id, userLogedIn);
      }
    } else {
      // User coming from series card
      const authToken = decryptText(localStorage.getItem("eMediat"));
      const response =
        await DashboardServices.checkUserEligibilityToWatchSeries(
          seriesActivation?.seriesId,
          authToken
        );

      if (response.success) {
        dispatch(UserDashboardActions.updateMyProfileError(""));
        dispatch(
          UserDashboardActions.updateSeriesActivation({
            seriesId: seriesActivation?.seriesId,
            isActivated: "true",
          })
        );
        getSeriesDetails(getUrlParameters, userLogedIn);
        return;
      }
      dispatch(UserDashboardActions.updateMyProfileError(response.message));
      navigate(`/dashboard`);
      return;
    }
  };

  const geUserDetails = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("eMediat"));
      let response = await BasicFormServices.getUser(authToken);
      if (response.success) {
        props.updateUserDetails(response.data);
      }
    } catch (err) {
      console.log("Error coming from geUserDetails() in course details", err);
    }
  };

  // START : Fn to get the url parameters
  const getUrlParametersFn = () => {
    // getCommentSecondsFromUrl()
    let isVideoId, isSeriesId;
    if (identifier === "v" && id) {
      props.updateCurrentContentType("v");
      isVideoId = identifier === "v" && id;
      return {
        success: true,
        type: "v",
        id: isVideoId,
      };
    } else if (identifier === "s" && id) {
      props.updateCurrentContentType("s");
      isSeriesId = identifier === "s" && id;
      return {
        success: true,
        type: "s",
        id: isSeriesId,
      };
    } else {
      return {
        success: false,
        message: "No id found",
      };
    }
  };

  const getSeriesDetails = async (getUrlParameters, userLogedIn) => {
    UpdateTopLoadingBarForThisPage(20);
    if (getUrlParameters && getUrlParameters.success) {
      try {
        if (getUrlParameters.type === "s") {
          const seriesId = getUrlParameters.id;
          const authToken = decryptText(localStorage.getItem("eMediat"));

          const response = await SeriesServices.getSeries(seriesId, authToken);

          if (response.success) {
            props.updateSeriesDetails(response);
            getVideoIdToRender(response, getUrlParameters.type);
          } else {
            navigate(`/course/${response.data._id}`);
          }
        } else if (getUrlParameters.type === "v") {
          const videoDocId = getUrlParameters.id;
          let response = null;

          // http://localhost:3001/video/v/6489b351d84131a4b610f2c7
          if (userLogedIn) {
            // Get course details
            const authToken = decryptText(localStorage.getItem("eMediat"));
            response = await CourseContentServices.getVideo(
              authToken,
              videoDocId
            );
          } else {
            response = await CourseContentServices.getPublicVideo(videoDocId);
          }

          if (response.success) {
            if (response.data.isPublic || response.data.isPurchasedCourse) {
              props.updateSeriesDetails(response);
              getVideoIdToRender(response, getUrlParameters.type);
            } else {
              // Video required purchase
              // When the video is not public and user is not loged-in
              if (!response.data.isPublic && !userLogedIn) {
                localStorage.removeItem("eMediat");
                localStorage.removeItem("eMediatt");
                navigate("/login");
                return;
              } else {
                alert(response.message);
                navigate(`/`);
                return;
              }
            }
          } else {
            // TODO show some error page when video id is Invalid
            alert(response.message);
            navigate("/");
          }
        }
      } catch (err) {
        console.log("Error while fetching series  details", err);
      }
    } else {
      console.log(getUrlParameters.message);
    }
  };

  const getVideoIdToRender = (
    details,
    contentType,
    seriesIndex = 0,
    videoIndex = 0
  ) => {
    UpdateTopLoadingBarForThisPage(40);
    if (details.success && details.data) {
      let currentVideo = null;
      if (contentType === "s") {
        currentVideo = details.data[seriesIndex].videos[videoIndex];
      } else if (contentType === "v") {
        //Here replace this with the data of video
        currentVideo = details.data;
      }
      if (currentVideo !== null) {
        props.updateCurrentVideoData(currentVideo);
        // GET the current video comments
        const videoDocId = currentVideo._id;
        getCurrentVideoComments(videoDocId);
      }
    }
  };

  const getCurrentVideoComments = async (videoDocId) => {
    UpdateTopLoadingBarForThisPage(60);
    try {
      let response = null;
      if (localStorage.getItem("eMediat")) {
        const authToken = decryptText(localStorage.getItem("eMediat"));
        response = await CourseContentServices.getComments(
          authToken,
          videoDocId
        );
      } else {
        response = await CourseContentServices.getPublicVideoComments(
          videoDocId
        );
      }

      if (response.success) {
        props.updateCurrentVideoComments(response);
        setIsCommentsLoading(false);
      }
    } catch (err) {
      console.log("Error coming while fetching current video comments", err);
    }
  };

  //Vishal here the code could come to render new video
  const handleVideoReRender = (selectedItem) => {
    try {
      if (selectedItem) {
        UpdateTopLoadingBarForThisPage(70);

        const { seriesDetails, currentContentType } = props;
        const { seriesIndex, videoIndex } = selectedItem;

        getVideoIdToRender(
          seriesDetails,
          currentContentType,
          seriesIndex,
          videoIndex
        );

        UpdateTopLoadingBarForThisPage(80);
      }
    } catch (err) {
      console.log("Error coming while rendering video", err);
    }
  };

  // START : fn to play next video
  const findVideoIndexFn = (videoId, data) => {
    try {
      data = data.data;

      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const series = data[i];
          for (let j = 0; j < series.videos.length; j++) {
            const video = series.videos[j];
            if (video.videoId === videoId) {
              if (series.videos[j + 1]) {
                return { seriesIndex: i, videoIndex: j + 1 };
              } else if (data[i + 1]) {
                return { seriesIndex: i + 1, videoIndex: 0 };
              } else {
                return null;
              }
            }
          }
        }
      }
      return null;
    } catch (err) {
      console.log("Error coming while finding video index", err);
    }
  };

  const handleNextVideo = (videoId) => {
    const { seriesDetails } = props;
    let nextVideoData = findVideoIndexFn(videoId, seriesDetails);
    if (nextVideoData !== null) {
      handleVideoReRender(nextVideoData);
    }
  };
  // END : fn to play next video

  //Fn for handling loader of the page and video player
  const IsVideoPlayerRenderend = (data) => {
    if (data) {
      setIsLoading(false);
      setIsVideoPlayerLoading(false);
      displayAllEle();
      setTimeout(function () {
        UpdateTopLoadingBarForThisPage(100);
      }, 1000);
    }
  };

  const displayAllEle = () => {
    document
      .getElementById("videoPlayerDiscriptionContainer")
      .classList.remove("helpLoaderForVideoPlayer");
    document
      .getElementById("playListMainContainer")
      .classList.remove("helpLoaderForVideoPlayer");
    document
      .getElementById("playListMainContainerFIXED")
      .classList.remove("helpLoaderForVideoPlayer");
  };

  const addAllEle = () => {
    document
      .getElementById("videoPlayerDiscriptionContainer")
      .classList.add("helpLoaderForVideoPlayer");
  };

  //START: Code for setting the progressof top bar loader
  const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
    if (interval) {
      setTimeout(function () {
        props.updateLoadingBarProgress(props.loadingBarProgress + value);
      }, 500);
    } else {
      props.updateLoadingBarProgress(props.loadingBarProgress + value);
    }
  };
  //END: Code for setting the progressof top bar loader

  const updateVideoViewCount = async () => {
    try {
      const { currentVideoData, seriesDetails } = props;

      if (increaseViewCount === 0) {
        increaseViewCount = 1;
        let response = null;
        if (localStorage.getItem("eMediat")) {
          const authToken = decryptText(localStorage.getItem("eMediat"));
          response = await CourseContentServices.updateViewCount(
            currentVideoData._id,
            authToken
          );
        } else {
          response = await CourseContentServices.updatePublicVideoViewCount(
            currentVideoData._id
          );
        }

        if (response.success) {
          let updatedVideoDetails = produce(currentVideoData, (draft) => {
            draft.viewsCount += 1;
          });

          props.updateCurrentVideoData(updatedVideoDetails);

          if (seriesDetails?.data?.courseModules) {
            //  Updating current playlist data
            let updatedCourseDetails = produce(seriesDetails, (draft) => {
              draft.data.courseModules.map((_module) => {
                _module.subModules.map((_submodule) => {
                  if (_submodule._id === updatedVideoDetails._id) {
                    _submodule.viewsCount += 1;
                  }
                });
              });
            });

            props.updateSeriesDetails(updatedCourseDetails);
          }
        }
      }
    } catch (err) {
      console.log("Error coming while updating video view count", err);
    }
  };

  const resetViewCount = () => {
    increaseViewCount = 0;
  };

  const updateWatchedVideoCount = async () => {
    try {
      const { currentVideoData, seriesDetails } = props;

      if (increaseWatchedVideoCount === 0) {
        increaseWatchedVideoCount = 1;

        let payload = {
          videoDocId: currentVideoData._id,
          purchaseId: purchaseId,
          seriesId: seriesDetails.data[0]._id,
        };
        if (payload.videoDocId !== payload.courseId) {
          const authToken = decryptText(localStorage.getItem("eMediat"));
          const response = await CourseContentServices.updateVideoWatchCount(
            payload,
            authToken
          );

          if (response.success) {
            return;
          }
        }
      }
    } catch (err) {
      console.log("Error coming while updating watched video count", err);
    }
  };

  const resetWatchedVideoCount = () => {
    increaseWatchedVideoCount = 0;
  };

  const getVideoDuration = (videoDuration) => {
    setSendVideoDuration(videoDuration);
  };

  const handleVideoIdChanged = (data) => {
    setIsCommentsLoading(true);
    setIsLoading(true);
    setIsVideoPlayerLoading(true);
    addAllEle();
  };

  return (
    <>
      <Navbar />
      <TopLoadingBar />
      <CourseDetailsView
        currentVideoData={props.currentVideoData}
        isLoading={isLoading}
        isCommentsLoading={isCommentsLoading}
        isVideoPlayerLoading={isVideoPlayerLoading}
        seriesDetails={props.seriesDetails}
        currentVideoComments={props.currentVideoComments}
        currentContentType={props.currentContentType}
        handleVideoReRender={handleVideoReRender}
        IsVideoPlayerRenderend={IsVideoPlayerRenderend}
        updateVideoViewCount={updateVideoViewCount}
        updateWatchedVideoCount={updateWatchedVideoCount}
        resetViewCount={resetViewCount}
        resetWatchedVideoCount={resetWatchedVideoCount}
        getVideoDuration={getVideoDuration}
        sendVideoDuration={sendVideoDuration}
        handleVideoIdChanged={handleVideoIdChanged}
        handleNextVideo={handleNextVideo}

        // Mahdi :keep for video duration from comment
        // updateVideoDuration={updateVideoDuration}
      />
      <Footer />
    </>
  );
}
function mapStateToProps(state) {
  return {
    // courseDetails: state.courseDetails.courseDetails,
    // currentVideoData: state.courseDetails.currentVideoData,
    // currentVideoComments: state.courseDetails.currentVideoComments,
    // currentContentType: state.courseDetails.currentContentType,
    loadingBarProgress: state.topLoadingBar.loadingBarProgress,
    isLogedIn: state.navbar.loginState,

    seriesDetails: state.seriesDetails.seriesDetails,
    currentVideoData: state.seriesDetails.currentVideoData,
    currentVideoComments: state.seriesDetails.currentVideoComments,
    currentContentType: state.seriesDetails.currentContentType,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // updateCourseDetails: CourseDetailsActions.updateCourseDetails,
      // updateCurrentVideoData: CourseDetailsActions.updateCurrentVideoData,
      // updateCurrentVideoComments: CourseDetailsActions.updateCurrentVideoComments,
      // updateCurrentContentType: CourseDetailsActions.updateCurrentContentType,
      // updateUserDetails: CourseDetailsActions.updateUserDetails,
      updateLoadingBarProgress: TopLoadingBarActions.updateLoadingBarProgress,
      updateLoginState: NavbarActions.updateLoginState,
      updatePurchasesCount: NavbarActions.updatePurchasesCount,

      updateSeriesDetails: SeriesDetailsActions.updateSeriesDetails,
      updateCurrentVideoData: SeriesDetailsActions.updateCurrentVideoData,
      updateCurrentVideoComments:
        SeriesDetailsActions.updateCurrentVideoComments,
      updateCurrentContentType: SeriesDetailsActions.updateCurrentContentType,
      updateUserDetails: SeriesDetailsActions.updateUserDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
