export const checkEligibilityForExam = async (examType, purchaseId, authToken) => {
	try {
		let endpoint = "checkEligibilityForExam";
		let payload = {
			purchaseId: purchaseId,
		}
		if(examType === "Test"){
			endpoint = "checkEligibilityForTestExam"
			payload = {
				testExamDocId: purchaseId,
			}
		}		
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/${endpoint}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Exam services : checkEligibilityForExam()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getQuestionBank = async (examType, courseId, authToken) => {
	try {
		let endpoint = "getQuestionBank";
		if(examType === "Test"){
			endpoint = "getTestQuestionBank"
		}		
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/${endpoint}/${courseId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : getQuestionBank()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getExamTimings = async (examType, purchaseId, authToken) => {
	try {
		let endpoint = "getExamTimings";
		if(examType === "Test"){
			endpoint = "getTestExamTimings"
		}		
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/${endpoint}/${purchaseId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : getExamTimings()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateExamTimings = async (
	examType,
	examDuration,
	purchaseId,
	authToken
) => {
	try {
		let endpoint = "updateExamTimings";
		let payload = {
			examDuration: examDuration,
			purchaseId: purchaseId,
		}
		if(examType === "Test"){
			endpoint = "updateTestExamTimings"
			payload = {
				examDuration: examDuration,
				testExamDocId: purchaseId,
			}
		}		
		return await fetch(`${process.env.REACT_APP_BASE_URL}/${endpoint}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Exam services : updateExamTimings()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateExamSubmition = async (examType, purchaseId, examId, authToken) => {
	try {
		let endpoint = "updateExamSubmition";
		let payload = {
			purchaseId: purchaseId,
			examId: examId,
		}
		if(examType === "Test"){
			endpoint = "updateTestExamSubmition"
			payload = {
				testExamDocId: purchaseId,
				testExamId: examId,
			}
		}		
		return await fetch(`${process.env.REACT_APP_BASE_URL}/${endpoint}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Exam services : updateExamSubmition()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addToResult = async (examType, payload, authToken) => {
	try {
		let endpoint = "addToResult";
		if(examType === "Test"){
			endpoint = "addToTestResult"
		}		
		return await fetch(`${process.env.REACT_APP_BASE_URL}/${endpoint}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : addToResult()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getResult = async (examType, purchaseId, authToken) => {
	try {
		let endpoint = "getResult";
		if(examType === "Test"){
			endpoint = "getTestResult"
		}		
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/${endpoint}/${purchaseId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : getResult()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateExamStrike = async (examType, purchaseId, authToken) => {
	try {
		let endpoint = "updateExamStrike";
		let payload = {
			purchaseId: purchaseId,
		}
		if(examType === "Test"){
			endpoint = "updateTestExamStrike"
			payload = {
				testExamDocId: purchaseId,
			}
		}
		return await fetch(`${process.env.REACT_APP_BASE_URL}/${endpoint}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : addToResult()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


export const getExamORTest = async (payload) => {
	try {
		let action = 'getUserQuestionBank';
		if(payload.action === "test"){
			action =  'getUserTestExams'
		}
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/${action}?userId=${payload.userId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: payload.authToken,
				}
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam => getExamORTest services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};




export const reAttemptExam = async (examType, authToken, payload) => {
	try {
		let endpoint = "reAttemptExam";
		if(examType === "Test"){
			endpoint = "reAttemptTestExam"
		}
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/${endpoint}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),				
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : reAttemptExam()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
