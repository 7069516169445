import React from 'react';

import ContentLoader from 'react-content-loader';

import './offers.css';
import EIcon from '@assets/checkout/eIcon.png';
import adIcon from '@assets/checkout/adIcon.png';
import adIconTM from '@assets/checkout/adIconTM.png';

import OfferBadgeBg from '@assets/offers/offerBadgeBg.png';
import OfferScratchCard from '@components/OfferScratchCard';
import NoOffer from '@assets/offers/noOffer.png';
import { Link } from 'react-router-dom';

function ViewOffers(props) {
	return (
		<>
			<center>
				<div className='mainOfferPage1'>
					<div className='adContainer'>
						<div className='row container1' style={{ maxWidth: '100%' }}>
							<img
								src={EIcon}
								alt='eIcon'
								style={{ width: '80px', height: '50px' }}
								className='col-1 d-none d-sm-inline-block'
							/>
							<div className='adDescriptionContainer col-12 col-sm-5 col-xl-4'>
								<p className='p1'>Upto 50% Off</p>
								<p className='p2'>
									eMediSkill: Elevate Your Medical Education. Enroll Now!
								</p>
							</div>
							<div className='adPriceContainer col-12 col-sm-3'>
								<p>Start at 6499/-</p>
								<button>
									<Link
										style={{
											color: 'unset',
											textDecoration: 'none',
										}}
										to='/explore'>
										Explore Courses
									</Link>
								</button>
							</div>
							<img
								src={adIconTM}
								alt='adIconTM'
								className='col-12 d-inline-block  d-sm-none'
							/>
							<img
								src={adIcon}
								alt='adIcon'
								style={{ paddingTop: '5px' }}
								className='col-2 d-none d-sm-inline-block'
							/>
						</div>
					</div>
				</div>

				{props.isLoading ? (
					<>
						<div className='mainOfferPage2'>
							<div className='d-none d-lg-block'>
								<ContentLoader viewBox='0 0 395 120'>
									<rect x='0' y='10' rx='5' ry='5' width='95' height='100' />
									<rect x='100' y='10' rx='5' ry='5' width='95' height='100' />
									<rect x='200' y='10' rx='5' ry='5' width='95' height='100' />
									<rect x='300' y='10' rx='5' ry='5' width='95' height='100' />
								</ContentLoader>

								<ContentLoader viewBox='0 0 395 120'>
									<rect x='0' y='10' rx='5' ry='5' width='95' height='100' />
									<rect x='100' y='10' rx='5' ry='5' width='95' height='100' />
									<rect x='200' y='10' rx='5' ry='5' width='95' height='100' />
									<rect x='300' y='10' rx='5' ry='5' width='95' height='100' />
								</ContentLoader>
							</div>

							<div className='d-none d-md-block d-lg-none'>
								<ContentLoader viewBox='0 0 410 140'>
									<rect x='0' y='10' rx='5' ry='5' width='130' height='140' />
									<rect x='140' y='10' rx='5' ry='5' width='130' height='140' />
									<rect x='280' y='10' rx='5' ry='5' width='130' height='140' />
								</ContentLoader>
								<ContentLoader viewBox='0 0 410 140'>
									<rect x='0' y='10' rx='5' ry='5' width='130' height='140' />
									<rect x='140' y='10' rx='5' ry='5' width='130' height='140' />
									<rect x='280' y='10' rx='5' ry='5' width='130' height='140' />
								</ContentLoader>
							</div>

							<div className='d-none d-sm-block d-md-none'>
								<ContentLoader viewBox='0 0 410 210'>
									<rect x='0' y='10' rx='5' ry='5' width='200' height='200' />
									<rect x='210' y='10' rx='5' ry='5' width='200' height='200' />
								</ContentLoader>
								<ContentLoader viewBox='0 0 410 210'>
									<rect x='0' y='10' rx='5' ry='5' width='200' height='200' />
									<rect x='210' y='10' rx='5' ry='5' width='200' height='200' />
								</ContentLoader>
							</div>

							<div className='d-block d-sm-none'>
								<ContentLoader viewBox='0 0 410 410'>
									<rect x='0' y='10' rx='5' ry='5' width='410' height='400' />
								</ContentLoader>
								<ContentLoader viewBox='0 0 410 410'>
									<rect x='0' y='10' rx='5' ry='5' width='410' height='400' />
								</ContentLoader>
							</div>
						</div>
					</>
				) : (
					<>
						<div className='mainOfferPage2'>
							<div className='row'>
								{props.offerData &&
									props.offerData.success &&
									props.offerData.data.map((offer, index) => (
										<div
											key={index}
											className='offerContainer col-12 col-sm-6 col-md-4 col-lg-3'>
											{props.isOfferImageLoaded && (
												<>
													{offer?.discounts.length > 0 && (
														<div className='offerContainer1'>
															<img
																className='offerPercentageImg'
																src={OfferBadgeBg}
																alt='offer'
															/>

															<p className='offerDiscountPercentage'>
																{offer?.discounts[offer?.discounts.length - 1].discount}%
															</p>
														</div>
													)}
												</>
											)}
											<img
												onLoad={props.handleOfferImageLoading}
												id={'offerImgM_' + index}
												onTouchStart={(event) => {
													props.handleTouchStart(event, offer.thumbnailUrl, index, 'show');
												}}
												onMouseEnter={(event) => {
													props.handleMouseEnter(event, index);
												}}
												className='w-100'
												src={offer.thumbnailUrl}
												alt='offerCard'
											/>

											{props.isOfferImageLoaded && (
												<>
													<OfferScratchCard offerCode={offer.couponCode} />

													<p
														id={'offerDetailsM_' + index}
														onTouchStart={(event) => {
															props.handleTouchStart(event, offer.thumbnailUrl, index, 'hide');
														}}
														onMouseLeave={(event) => {
															props.handleMouseLeave(event, offer.thumbnailUrl, index);
														}}
														className='offerDetails'>
														{offer.description}
													</p>

													<p className='offerDescription'> {offer.title}</p>
												</>
											)}
										</div>
									))}

								<>
									{props.offerData && props.offerData?.data?.length === 0 ? (
										<>
											<center>
												<h1>No Offers Found!</h1>
												<img id='emptyOffer' src={NoOffer} alt='emptyOffer' />
											</center>
										</>
									) : null}
								</>
							</div>
						</div>
					</>
				)}
			</center>
		</>
	);
}

export default ViewOffers;
