export const updateOTPNumber = (value) => {
	return {
		type: 'UDPATE_OTP_NUMBER',
		payload: {
			OTPNumber: value.OTPNumber,
			colNumber: value.colNumber,
		},
	};
};

export const updateOTPErrorMsg = (value) => {
	return {
		type: 'UPDATE_OTP_ERROR_MESSAGE',
		payload: {
			OTPErrorMsg: value,
		},
	};
};
