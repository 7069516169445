import React from "react";
import DisplayDetails from "@pages/UserDashboard/Setting/DisplayDetails";
import Transactions from "@pages/UserDashboard/Setting/Transactions";
import DisplayDetailsCouserLoader from "./displayDetailsCouserLoader";
import TransactionsDetailsCouserLoader from "./transactionsDetailsCouserLoader";

import "./style.css";

function SettingView(props) {
  return (
    <>
      <div className="profileTab settingTab">
        {props.userDetailsIsLoading ? (
          <>
            <DisplayDetailsCouserLoader />
          </>
        ) : (
          <>
            <DisplayDetails userData={props.userData} />
          </>
        )}
        {props.transactionDetailsIsLoading ? (
          <>
            <TransactionsDetailsCouserLoader />
          </>
        ) : (
          <>
            <Transactions transactionsDetails={props.transactionsDetails} />
          </>
        )}
      </div>

      {/* Dev note : Keep this code and comomponet will need late */}
      {/* <Preferences
                handleSavePreference={props.handleSavePreference}
                openPreferencesPopup={props.openPreferencesPopup}
                preferencesModalOpenBtn={props.preferencesModalOpenBtn}
                preferencesModal={props.preferencesModal}
                preferencesModalCloseBtn={props.preferencesModalCloseBtn}                    
            /> */}
    </>
  );
}

export default SettingView;
