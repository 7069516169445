import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import CourseCardView from "./view";
import { handleRichTextEditorCourseDiscription } from "@pages/Course/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";

import { encryptText } from "@library/enc-dec";

import {
  updateCurrencyValue,
  handlePaidCourse,
} from "@library/helpers";


import * as CourseActions from "@redux/actions/Course";

function CourseCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseDetails = useSelector((state) => state.course.courseDetails);
  const cartItems = useSelector((state) => state.cart.cartItems);

  var offerTimer;
  const cardPointersRef = useRef(null);
  const [dealEndingTime, setDealEndingTime] = useState(0);
  const [isValidDealEndingTime, setIsValidDealEndingTime] = useState(false);
  const [offerDiscountPercentage, setOfferDiscountPercentage] = useState(0);
  const loginState = useSelector((state) => state.navbar.loginState);
  const userSpecificOfferDetails = useSelector((state) => state.userSpecificOffer.userSpecificOfferDetails);

  useEffect(() => {
    initCourseCard();
    return () => {
      clearInterval(offerTimer);
    };
  }, [
    courseDetails?._id,
    courseDetails?.isExistsInCart,
    // isValidDealEndingTime,
    loginState,
  ]);

  useEffect(() => {
    updateCardPointer();
  }, [courseDetails]);

  const handleRedirectToOfferPage = () => {
    navigate("/offers", {});
  };

  const updateCardPointer = () => {
    if (cardPointersRef.current) {
      let html = handleRichTextEditorCourseDiscription(
        courseDetails?.cardPointers.pointer,
        courseDetails?.cardPointers.icon,
        ""
      );
      cardPointersRef.current.innerHTML = html;
    }
  };

  const initCourseCard = async () => {
    try {
      // Handling the course card if user is not logged in
      if (loginState && courseDetails?.isExistsInCart) {
        setIsValidDealEndingTime(false);
      }

      if (courseDetails?.pricingDetails?.courseType === "paid") {
        // Checking this course is added to the local storage or not
        let localDbCoursesList = localStorage.getItem("crsl");

        if (!localDbCoursesList) {
          localDbCoursesList = localStorage.setItem("crsl", encryptText("[]"));
        }

        await updateCurrencyValue();
        // debugger
        const updatedCourseDetails = await handlePaidCourse(userSpecificOfferDetails, courseDetails);

        dispatch(CourseActions.updateCourseDetails(updatedCourseDetails));
        calculateOfferPercentage(updatedCourseDetails);
        updateCourseDealEndingTime(updatedCourseDetails?.offerEndAt);
        offerTimer = setInterval(function () {
          updateCourseDealEndingTime(updatedCourseDetails?.offerEndAt);
        }, 1000);
      }
    } catch (err) {
      console.log("Error coming from initCourseCard()", err);
    }
  };

  const calculateOfferPercentage = (updatedCourseDetails) => {
    if (updatedCourseDetails?.isInOffer) {
      const { offerPriceInNumbers, crossedPriceInNumbers } =
        updatedCourseDetails;

      let offerPercentage = crossedPriceInNumbers - offerPriceInNumbers;
      offerPercentage =
        Math.floor((offerPercentage * 100) / crossedPriceInNumbers) + "% off";
      setOfferDiscountPercentage(offerPercentage);
    }
  };

  const updateCourseDealEndingTime = (offerEndingDate) => {
    try {
      const currentDate = new Date();
      const offerEndAtObj = new Date(offerEndingDate);

      const difference = offerEndAtObj.getTime() - currentDate.getTime();

      if (difference > 0) {
        const seconds = Math.floor(difference / 1000) % 60;
        const minutes = Math.floor(difference / 1000 / 60) % 60;
        const hours = Math.floor(difference / 1000 / 60 / 60) % 24;
        const days = Math.floor(difference / 1000 / 60 / 60 / 24);

        // Construct the output string
        let output = "";
        if (days > 0) {
          output += `${days.toString().padStart(2, "0")}d `;
        }
        if (hours > 0) {
          output += `${hours.toString().padStart(2, "0")}h `;
        }
        if (minutes > 0) {
          output += `${minutes.toString().padStart(2, "0")}m `;
        }
        output += `${seconds.toString().padStart(2, "0")}s`;

        setDealEndingTime(output);
        setIsValidDealEndingTime(true);
      } else {
        setIsValidDealEndingTime(false);
      }
    } catch (err) {
      console.log("Error coming from updateCourseDealEndingTime()", err);
    }
  };




  const handleContactNow = () => {
    navigate("/request-a-call", {});
  };


  return (
    <CourseCardView
      cartItems={cartItems}
      cardPointersRef={cardPointersRef}
      isValidDealEndingTime={isValidDealEndingTime}
      dealEndingTime={dealEndingTime}
      offerDiscountPercentage={offerDiscountPercentage}
      courseDetails={courseDetails}
      handleEnrollAndAddToCart={props.handleEnrollAndAddToCart} // path : @pages\Course\Main\index.js
      addToCartLoading={props.addToCartLoading} // path : @pages\Course\Main\index.js
      handleRedirectToOfferPage={handleRedirectToOfferPage}
      handleContactNow={handleContactNow}
      handleFreeCourse={props.handleFreeCourse} // path : @pages\Course\Main\index.js
    />
  );
}

export default CourseCard;
