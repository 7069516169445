export const updateTimelineRedirectDetails = (value) => {
	return {
		type: 'UPDATE_TIMELINE_REDIRECT_DETAILS',
		payload: value,
	};
};


export const resetTimelineRedirectReducers = () => {
	return {
		type: 'RESET_TIMELINE_REDIRECT_REDUCER',
	};
};
