import React from 'react';

import './style.css';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import Select from 'react-select';

function CountryCodeFlagPhoneNoView(props) {
	const customStyles = {
		control: (provided) => ({
			...provided,
			width: 65,
			marginRight: '20px',
			border: '1px solid #E9E9E9',
		}),
	};

	return (
		<>
			{props.validationError !== '' && (
				<Tags
					span_font_size='9px'
					img_margin='5px'
					bg='#FFE9E9'
					c='#962020'
					bc='#A62323'
					text={props.validationError}
					imgs={InputError}
				/>
			)}
			<div className='countryCodeFlagPhoneNoViewMainContainer'>
				<Select
					value={props.selectedOption}
					options={props.options}
					onChange={props.handleCountryChange}
					styles={customStyles}
					getOptionLabel={(option) => (
						<div>
							<img
								src={option.image}
								alt={option.label}
								style={{ width: 20, marginRight: 10 }}
							/>
							{option.label}
						</div>
					)}
				/>

				<div className='w-100'>
					<input
						ref={props.phoneNoInputBox}
						onChange={(event) => props.handlePhoneNo(event)}
						onKeyDown={(event) => props.handleKeyDownOnPhoneNo(event)}
						id="CountryCodeFlagPhoneNoComponentContactNoElement"
						className='countryCodeFlagComponentPhoneNo w-100'
						type='tel'
						placeholder='Phone Number'
					/>
				</div>
			</div>
		</>
	);
}

export default CountryCodeFlagPhoneNoView;
