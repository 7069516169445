import React, { useEffect, useState } from 'react';
import SeriesView from './view';

import { useSelector } from 'react-redux';

function Series() {
	const seriesList = useSelector((state) => state.dashboard.seriesList);
	const [isLoading, setIsLoading] = useState(true)

	useEffect(()=>{
		if(seriesList && seriesList.success){
			setIsLoading(false)
			// setTimeout(function(){ // testing
			// 	// setIsLoading(false)
			// },3000)
		}
	},[seriesList])

	return <SeriesView 
		isLoading={isLoading}
		seriesList={seriesList} />;
}

export default Series;
