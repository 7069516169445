const initialState = {
	allQualifications: {},
	bssQualifications: {}
};

const QualificationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_BSS_QUALIFICATIONS':
			return {
				...state,
				bssQualifications: action.payload,
			};
		case 'UPDATE_ALL_QUALIFICATIONS':
			return {
				...state,
				allQualifications: action.payload,
			};
		case 'RESET_QUALIFICATIONS':
			return initialState;

		default:
			return state;
	}
};

export default QualificationsReducer;
