import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ETCardView from './view';

function ETCard(props) {
	const navigate = useNavigate();
	const timeLineData = useSelector((state) => state.dashboard.timelineData);

	const handleExam = (examData, examType) => {
		// if coming from timeline then this condition will wrk
		if(timeLineData && Object.entries(timeLineData).length > 0 ){
			examData._id = timeLineData._id
			examData.courseId = timeLineData.courseId
		}
		navigate('/exam', {
			state: {
				courseId: examType === "Test" ? examData.testExamId : examData.courseId,
				purchaseId: examData._id,
				examType : examType
			},
		});
	}

	return <ETCardView
		{...props}
		examIndex={props.examIndex}
		exam={props.exam}
		handleExam={handleExam}
		popUpType={props.popUpType}
	/>;
}

export default ETCard;
