import React from 'react';

import './style.css';

function RoadMapView(props) {
	return (
		<>
			<div className='roadMapView'>
				<h4 className='heading'>What is the procedure?</h4>
				{props.viewRoadMapData.length > 0 &&
					props.viewRoadMapData.map((item, index) => (
						<div className='row-my' key={index}>
							<div className='col-my-4 lhs'>
								{item.lhsImg !== null ? (
									<>
										<img className='actionImg' src={item.lhsImg} alt='lhs' />
									</>
								) : (
									<>
										<p className='heading'>{item.lhsHeading}</p>
										<p className='content'>{item.lhsContent}</p>
									</>
								)}
							</div>
							<div className='col-my-2'>
								<img className='numImg' src={item.num} alt='num' />
								{item.line !== null && (
									<img className='numLineImg' src={item.line} alt='numLine' />
								)}
							</div>
							<div className='col-my-4 rhs'>
								{item.rhsImg !== null ? (
									<>
										<img className='actionImg' src={item.rhsImg} alt='rhs' />
									</>
								) : (
									<>
										<p className='heading'>{item.rhsHeading}</p>
										<p className='content'>{item.rhsContent}</p>
									</>
								)}
							</div>
						</div>
					))}
			</div>
		</>
	);
}

export default RoadMapView;
