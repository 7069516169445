import React from 'react';

import Call from '@pages/Course/Sections/Call';
import Head from '@pages/Course/Sections/Head';
import CourseCard from '@pages/Course/Sections/CourseCard';
import ContentDescription from '@pages/Course/Sections/ContentDescription';
import Registration from '@pages/Course/Sections/Registration';
import PreviewVideo from '@pages/Course/Sections/PreviewVideo';
import SampleCertificate from '@pages/Course/Sections/SampleCertificate';
import RelatedCourses from '@pages/Course/Sections/RelatedCourses';
import RoadMapMain from '@pages/Course/Sections/RoadMapMain';
import Series from '@pages/Course/Sections/Series';
import ImageCarousel from '@pages/Course/Sections/ImageCarousel';
import FAQ from '@pages/Course/Sections/FAQ';
import Blog from '@pages/Course/Sections/Blog';
import Loaders from '@pages/Course/Loaders/mobile.js'

import './style.css';

export default (props) => (
	<>
		{props.isLoading ? (
			<center>
				<div className='commonProductPageAlignmentContainerHelper'>
					<Loaders />
				</div>
			</center>
		) : (
			<>
				<div
					onLoad={() => props.courseMobileTop()}
					className='commonProductPageAlignmentContainerHelper mobileProductPageAlignmentContainerHelper mb-3'
					id='courseMobileTopBg'>
					<center>
						<img
							className='courseThumbnail'
							src={props.courseDetails?.rectangleThumbnailUrl}
							alt='courseImg'
						/>
					</center>
					<div id='courseHeaderContainer'>
						<Head />
					</div>
				</div>

				<div className='bgLinesOverlapMobile' id="bgLinesOverlapMobile">
				</div>
				<div className='bgBc1OverlapMobile' id="bgBc1OverlapMobile">
				</div>

				<div className='commonProductPageAlignmentContainerHelper mobileProductPageAlignmentContainerHelper mobileDesktopTabCardHelper'>
					<CourseCard
						handleFreeCourse={props.handleFreeCourse}
						addToCartLoading={props.addToCartLoading}
						handleEnrollAndAddToCart={props.handleEnrollAndAddToCart} />
				</div>
				<center>
					{props.courseDetails && props.courseDetails.showPreviewVideoLink && props.courseDetails.previewVideoLink && props.courseDetails.previewVideoLink !== "null" && props.courseDetails.previewVideoLink !== null &&
						<div className='commonProductPageAlignmentContainerHelper previewVideoLinkMainContainer'>
							<PreviewVideo />
						</div>
					}
				</center>

				{props.courseDetails && props.courseDetails.showInfoParagraph &&
					<div className='commonProductPageAlignmentContainerHelper mobileProductPageAlignmentContainerHelper'>
						<ContentDescription />
					</div>
				}

				<div className='commonProductPageAlignmentContainerHelper mobileProductPageAlignmentContainerHelper'>
					<Registration {...props} buttonType={'interestButton'} />
				</div>

				<div className='commonProductPageAlignmentContainerHelper mobileProductPageAlignmentContainerHelper'>
					<Call />
				</div>

				{props.courseDetails && props.courseDetails.showCertificate && props.courseDetails.certificateThumbnailUrl &&
					<center>
						<div className='commonProductPageAlignmentContainerHelper sampleCertificateMainContainer mb-4'>
							<SampleCertificate
								openOtpPopUp={props.openOtpPopUp}
							/>
						</div>
					</center>
				}

				{props.courseDetails && props.courseDetails.roadmapType && props.courseDetails.roadmapType !== "none" &&
					<center>
						<div className='commonProductPageAlignmentContainerHelper roadMapMainContainer'>
							<RoadMapMain />
						</div>
					</center>
				}

				<div className='commonProductPageAlignmentContainerHelper seriesMainContainer'>
					<Series />
				</div>

				{props.courseDetails && props.courseDetails.showImageCarousel && props.courseDetails.imageCarousel && props.courseDetails.imageCarousel.length > 0 &&
					<center>
						<div className='commonProductPageAlignmentContainerHelper imageCarouselMainContainer'>
							<ImageCarousel />
						</div>
					</center>
				}

				<center>
					<div className='commonProductPageAlignmentContainerHelper relatedCoursesMainContainer'>
						<RelatedCourses />
					</div>
				</center>

				<center>
					<div className='commonProductPageAlignmentContainerHelper faqMainContainer'>
						<FAQ />
					</div>
				</center>

				<center>
					<div className='commonProductPageAlignmentContainerHelper blogMainContainer'>
						<Blog />
					</div>
				</center>
			</>
		)}
	</>
);
