import React, { useEffect, useState } from 'react';
import CoursesView from './view';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { decryptText } from '@library/enc-dec';

import * as DashboardServices from '@services/UserDashboard/MyLearning';
import * as DashboardActions from '@redux/actions/UserDashboard/MyLearning';

function Courses() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const purchasedCourses = useSelector(
		(state) => state.dashboard.purchasedCourses
	);
	const seriesList = useSelector((state) => state.dashboard.seriesList);

	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		getCoursesDetails();
	}, []);

	useEffect(()=>{
		if(purchasedCourses && purchasedCourses.success){
			setIsLoading(false)
			// setTimeout(function(){ // testing
			// 	setIsLoading(false)
			// },3000)
		}
	},[purchasedCourses])


	const getCoursesDetails = async () => {
		try {
			if (!purchasedCourses.success || !seriesList.success) {
				let authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await DashboardServices.getPurchasedCourses(authToken);
				if (response.success) {
					dispatch(DashboardActions.updatePurchasedCourses({
						success : response.success,
						data : response.data.courses
					}));
					dispatch(DashboardActions.updateSeriesList({
						success : response.success,
						data : response.data.series
					}));
				}
			}
		} catch (err) {
			console.log('Error coming from getCoursesDetails()', err);
		}
	};

	const handleCourse = (courseId) => {
		try {
			navigate(`/timeline/${courseId}`);
		} catch (err) {
			console.log('Error coming from handleCourse()', err);
		}
	};

	return <CoursesView
		isLoading={isLoading}
		courses={purchasedCourses}
		handleCourse={handleCourse} />;
}

export default Courses;
