export const updateCheckoutDetails = (value) => {
	return {
		type: 'UPDATE_CHECKOUT_DETAILS',
		payload: {
			details: value,
		},
	};
};

export const updateCouponDetails = (value) => {
	return {
		type: 'UPDATE_COUPON_DETAILS',
		payload: {
			couponDetails: value,
		},
	};
};

export const updateOrderId = (value) => {
	return {
		type: 'UPDATE_ORDER_ID',
		payload: {
			orderId: value,
		},
	};
};

export const resetCheckoutReducer = () => {
	return {
		type: 'RESET_CHECKOUT_REDUCER',
	};
};
