import React from "react";
import "./style.css";

import Call from "@pages/Course/Sections/Call";

import RightArrow from "@assets/userDashboard/Icons/rightArrowForVPopup.png";

function InstallmentView(props) {
  const numberWords = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];

  return (
    <>
      <div className="installmentView">
        {props.currentView === "init" ? (
          <>
            <div className="withoutInstallment">
              <div className="header">
                <p>Continue to enrollment</p>
                <button className="closeBtn" data-bs-dismiss="modal">
                  x
                </button>
              </div>
              <div className="content">
                <img
                  src={props.courseDetails?.squareThumbnailUrl}
                  alt="courseImg"
                />
                <div>
                  <p className="title">{props.courseDetails?.title}</p>
                  <p onClick={props.handleViewDetails} className="viewDetails">
                    View Details
                  </p>
                </div>
              </div>

              {window.innerWidth > 768 && props.showCallComponenet && (
                <div className="installmentCallContainer">
                  <Call />
                </div>
              )}

              <button
                onClick={props.handleInstallmentsOnPress}
                className="actionBtn"
              >
                Continue to pay {props.cartTotalValue}
                <img src={RightArrow} alt="RightArrow" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="withInstallment">
              <div className="header">
                <p>Pay in parts</p>
                <button className="closeBtn" data-bs-dismiss="modal">
                  x
                </button>
              </div>
              <div
                style={{ maxHeight: window.innerHeight - 200 + "px" }}
                className="content"
              >
                <div
                  onClick={() => {
                    props.handleInstallmentChange(-1, null);
                  }}
                  className={`contentInnerContainer contentInnerContainerD ${
                    props.installmentCounter === -1 && "selectBlueBorder"
                  }`}
                >
                  <div className="content1">
                    <p className="p1">1 Installment</p>
                    <p className="p2">No extra charges</p>
                  </div>
                </div>

                {props.courseDetails?.installments.map((item, index) => {
                  if (!item?.isPublic) {
                    return null;
                  }

                  return (
                    <div key={index}>
                      <div
                        onClick={() => {
                          props.handleInstallmentChange(index, item);
                        }}
                        key={index}
                        className={`contentInnerContainer contentInnerContainer_${index} ${
                          index === props.installmentCounter &&
                          "selectBlueBorder"
                        }`}
                      >
                        <div className="content1">
                          <p className="p1">{2 + index} Installments</p>
                          <p className="p2">
                            +{props.calculateExtraCharges(item?.options)}%
                            charges
                          </p>
                        </div>
                        <div className="content2">
                          {item?.options &&
                            item?.options.map((_, i) => (
                              <div className="innerContent2" key={i}>
                                <p style={i === 0 ? { fontWeight: 1000 } : {}}>
                                  {numberWords[i + 1]} Installment
                                </p>
                                <p style={i === 0 ? { fontWeight: 1000 } : {}}>
                                  {props.currencySymbol}
                                  {props.calculateInstallmentPrice(
                                    item?.options[i]
                                  )}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <button
                onClick={props.handleInstallmentsOnPress}
                className="actionBtn"
              >
                Continue to pay {props.cartTotalValue}
                {/* Continue to pay {numeral(props.courseDataInUse.price).format("0,0")} */}
                <img src={RightArrow} alt="RightArrow" />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default InstallmentView;
