import InfoIcon from '@assets/product/infoIcon.png';

export const handleRichTextEditorCourseDiscription = (htmlStr, icon, value) => {
	if (htmlStr !== undefined && icon !== null) {
		let newHtmlStr = '';
		if (value === 'courseContentDescriptionContainer') {
			const infoIcon =
				"<img class='infoIcon' src='" + InfoIcon + "' alt='infoIcon' />";
			newHtmlStr = '<div style="max-width:90%">' + htmlStr + '</div>' + infoIcon;
		} else {
			newHtmlStr = htmlStr;
		}

		return newHtmlStr.replace(
			/<li>/g,
			'<li style="background-image: url(' +
				icon +
				'); background-repeat: no-repeat; background-size: 20px 20px;">'
		);
	}
	return htmlStr;
};
