import React from 'react';

import MyLearningView from './view';

function MyLearning() {
	return (
		<>
			<MyLearningView />
		</>
	);
}

export default MyLearning;
