export const adjustPlayListMainContainer = (typeOfThisPage) => {

    const viewportWidth = window.innerWidth;
    if (document.getElementById("videoPlayer")
        && document.getElementById("playListMainContainer")
        && document.getElementById("playListMainContainerFIXED")
        && document.getElementById("mainCourseDetailsPageContainer")
        && document.getElementById('footer')) {

        let videoPlayer = document.getElementById("videoPlayer");
        let playListMainContainer = document.getElementById("playListMainContainer");
        let playListMainContainerFIXED = document.getElementById("playListMainContainerFIXED");
        let mainCourseDetailsPageContainer = document.getElementById("mainCourseDetailsPageContainer");
        let videoPlayerHeight = videoPlayer.getBoundingClientRect().height;
        const footer = document.getElementById('footer');

        // testing condition
        if("testPlayer" === "testPlayerX"){
            playListMainContainerFIXED.style.display = "block";
            playListMainContainer.style.display = "block";    
        }
        else{
            //Condition to handle how to treat this page
            if(typeOfThisPage && typeOfThisPage === "v"){
                playListMainContainerFIXED.style.display = "none";
                playListMainContainer.style.display = "none";
            }
            else if(typeOfThisPage && typeOfThisPage === "s"){
                playListMainContainerFIXED.style.display = "block";
                playListMainContainer.style.display = "block";
            }    
        }


        if (viewportWidth > 992) {
            playListMainContainer.style.height = videoPlayerHeight + 'px';
            playListMainContainerFIXED.style.height = videoPlayerHeight + 'px';


            //Setting postions & size for sticky sidebar
            playListMainContainer.style.position = "fixed"
            playListMainContainer.style.left = 15 + videoPlayer.getBoundingClientRect().right + 'px';

            //here
            setTimeout(function(){
                if(videoPlayer.getBoundingClientRect().top < 100){
                    playListMainContainer.style.top = '110px';
                }else{
                    playListMainContainer.style.top = videoPlayer.getBoundingClientRect().top + 'px';
                }
            },500)
            playListMainContainer.style.width = mainCourseDetailsPageContainer.getBoundingClientRect().width - videoPlayer.getBoundingClientRect().width + 'px';


            //Setting postions & size for fixed sidebar
            playListMainContainerFIXED.style.left = 15 + videoPlayer.getBoundingClientRect().right + 'px';
            playListMainContainerFIXED.style.top = footer.getBoundingClientRect().top - 20 + 'px';
            playListMainContainerFIXED.style.width = mainCourseDetailsPageContainer.getBoundingClientRect().width - videoPlayer.getBoundingClientRect().width + 'px';

        }
        else {
            playListMainContainer.style.position = 'inherit';
            playListMainContainer.style.width = '100%';
            playListMainContainer.style.height = 'fit-content';

            playListMainContainerFIXED.style.display = 'none'
        }
    }
}


export const handleScroll = () => {

    const viewportWidth = window.innerWidth;
    if (viewportWidth > 992) {

        if (document.getElementById('playListMainContainer')
            && document.getElementById('playListMainContainerFIXED')
            && document.getElementById('footer')) {


            const playListMainContainer = document.getElementById('playListMainContainer');
            const playListMainContainerFIXED = document.getElementById('playListMainContainerFIXED');
            const footer = document.getElementById('footer');
            const pageHeight = document.documentElement.scrollHeight;

            let distance = footer.getBoundingClientRect().top - playListMainContainer.getBoundingClientRect().bottom;

            playListMainContainerFIXED.style.display='block';
            if (distance < 20) {
                playListMainContainer.style.visibility = 'hidden';
                playListMainContainerFIXED.style.visibility = 'visible';
            }
            else {
                playListMainContainer.style.visibility = 'visible';
                playListMainContainerFIXED.style.visibility = 'hidden';
            }

            playListMainContainerFIXED.style.top = pageHeight - (footer.getBoundingClientRect().height + playListMainContainerFIXED.getBoundingClientRect().height) - 80 + 'px';
        }
    }
};


export const handleResize = () => {
    adjustPlayListMainContainer();
}
