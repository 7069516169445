import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";

import ConfirmOrderView from './view';

function ConfirmOrder(props) {
	const navigate = useNavigate();
	const modalRef = useRef(null)
	const closehomePageConfirmOrderViewModalBtn = useRef(null)

	const handleDownloadInvoiceFromHomePage = (confirmOrderDetails) =>{
		if(closehomePageConfirmOrderViewModalBtn.current){
			closehomePageConfirmOrderViewModalBtn.current.click()
			navigate('/invoice', { state: { invoice: confirmOrderDetails } });
		}
	}

	const handleRedirectToDashboard = () => {
		navigate("/dashboard", {});
	}
	const emptyLocationSateValues = () =>{
		navigate("/", {
			replace: true,
			state: {},
		});
	}

	return (
		<>
			<ConfirmOrderView
				modalRef={modalRef}
				handleRedirectToDashboard={handleRedirectToDashboard}
				handleDownloadInvoiceFromHomePage={handleDownloadInvoiceFromHomePage}
				emptyLocationSateValues={emptyLocationSateValues}
				closehomePageConfirmOrderViewModalBtn={closehomePageConfirmOrderViewModalBtn}
				showInVoicePossible={props.showInVoicePossible}
				openhomePageConfirmOrderViewModalBtn={props.openhomePageConfirmOrderViewModalBtn}
			/>
		</>
	);
}

export default ConfirmOrder;
