import React, { useState } from 'react';
import CartView from './view';

import { useDispatch, useSelector } from "react-redux";
import { produce } from 'immer';

import { decryptText } from '@library/enc-dec';
import * as CartServices from '@services/Cart';
import * as CartActions from '@redux/actions/Cart';
import * as BackUpCartAction from '@redux/actions/BackUpCart';

function Cart(props) {
	const dispatch = useDispatch();
	const constNumOfCoursePlaceholder = 4;
	const backUpCartDetails = useSelector((state) => state.backUpCart.backUpCartDetails);
	const [deleteCartItemIsLoading, setDeleteCartItemIsLoading] = useState('');

	const removeCartItem = async (removedItem) => {
		setDeleteCartItemIsLoading(removedItem.courseId)

		let authToken = decryptText(localStorage.getItem('eMediat'));

		const response = await CartServices.removeCartItem(
			removedItem?._id,
			authToken
		);
		if (response.success) {
			// Romoving selected course from cart items
			let updatedCartItems = produce(props.cartItems, (draft) =>
				draft.filter((item) => item._id !== removedItem._id)
			);

			// to close the cart container
			if(updatedCartItems.length === 0){
				props.handleOpenCloseCartForDesktop(); // fn in components\Popups\CartAndEnrollView\index.js
			}

			dispatch(CartActions.updateCartItems(updatedCartItems));


			let updatedbackUpCartDetails = produce(backUpCartDetails, (draft) =>
				draft.filter((item) => item.courseId !== removedItem.courseId)
			);
			// debugger;
			dispatch(BackUpCartAction.updateBackUpCartDetails(updatedbackUpCartDetails))

			const { crossedPriceInNumbers, offerPriceInNumbers, isInOffer } =
				removedItem;

			const { totalCartValueInNumbers, totalCartValue } = props;

			// Calculate the total Cart Value
			let currentPrice = crossedPriceInNumbers;
			if (offerPriceInNumbers > 0 && isInOffer) {
				currentPrice = offerPriceInNumbers;
			}
			// let updatedActualPrice = totalCartValue - rCourse.price;
			let updatedActualPrice = totalCartValueInNumbers - currentPrice;
			let currencySymbol = totalCartValue.split(' ')[0];
			if (updatedActualPrice <= 0) {
				dispatch(CartActions.updateCartTotalValue(`${currencySymbol} 0`));
				dispatch(CartActions.updateCartTotalValueInNumbers(0));
			} else {
				dispatch(
					CartActions.updateCartTotalValue(`${currencySymbol} ${updatedActualPrice}`)
				);
				dispatch(CartActions.updateCartTotalValueInNumbers(updatedActualPrice));
			}
			setDeleteCartItemIsLoading('')
			return;
		}
		setDeleteCartItemIsLoading('')
		alert(response.message);

		// Code to use if offers need to be plug
		// let discountedPercentage = getDiscountedValueAsPerNumberOfCoursesInCart(selectedOffer.discountPercentages, selectedCourses.length - 1);
		// setCurrentDiscountedPercentage(discountedPercentage);

		// let updatedActualPrice = actualPrice - Number(rCourse.price);
		// setActualPrice(updatedActualPrice);

		// let discountedValue = updatedActualPrice*discountedPercentage/100;
		// let updatedDiscountedPrice = updatedActualPrice - discountedValue;
		// setDiscountedPrice(updatedDiscountedPrice);
	};

	const handleContinueButton = () => {
		try {
			if (props.totalCartValueInNumbers > 0) {
				props.handleSidebarView('cart', {
					coursesInCart: props.cartItems,
				});
			}
		} catch (err) {
			console.log('Error coming from handleContinueButton()', err);
		}
	};

	return (
		<>
			<CartView
				removeCartItem={removeCartItem}
				handleContinueButton={handleContinueButton}
				selectedCourses={props.cartItems}
				currentCartValue={props.totalCartValue}
				constNumOfCoursePlaceholder={constNumOfCoursePlaceholder}
				deleteCartItemIsLoading={deleteCartItemIsLoading}
				{...props}
			/>
		</>
	);
}

export default Cart;
