import React from 'react';
import { useSelector } from 'react-redux';

import WatchContentView from './view';

function WatchContent(props) {
	const course = useSelector((state) => state.dashboard.course);

	return (
		<WatchContentView
			seriesList={course ? course?.content : []}
			accordanceConfig={props.accordanceConfig}
		/>
	);
}

export default WatchContent;
