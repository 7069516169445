import React, { useRef, useState } from 'react';

import ReviewPaymentView from './view';
import { useDispatch, useSelector } from 'react-redux';

import { decryptText } from '@library/enc-dec';

import * as CartServices from '@services/Cart';
import * as CartActions from '@redux/actions/Cart';

function ReviewPayment(props) {
	const dispatch = useDispatch();
	const midContainerRef = useRef(null);
	const couponContainerRef = useRef(null);
	const cartItems = useSelector((state) => state.cart.cartItems);
	const cartTotalValue = useSelector((state) => state.cart.cartTotalValue);
	const cartTotalValueInNumbers = useSelector(
		(state) => state.cart.cartTotalValueInNumbers
	);
	const discountValue = useSelector((state) => state.cart.discountValue);
	const discountValueInNumbers = useSelector(
		(state) => state.cart.discountValueInNumbers
	);
	const selectedPaymentGateway = useSelector(
		(state) => state.cart.selectedPaymentGateway
	);

	const gatewayDiscountValue = useSelector(
		(state) => state.cart.gatewayDiscountValue
	);

	const gatewayDiscountValueInNumbers = useSelector(
		(state) => state.cart.gatewayDiscountValueInNumbers
	);

	const [coupon, setCoupon] = useState('');
	const [couponText, setCouponText] = useState('Apply');

	const arraysEqual = (arr1, arr2) => {
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	};

	const checkCategories = (courseList) => {
		// Get the categories of the first course
		const firstCategories = courseList[0].categories;

		// Check if all other courses have the same categories as the first one
		for (let i = 1; i < courseList.length; i++) {
			if (!arraysEqual(firstCategories, courseList[i].categories)) {
				return false; // Different categories found
			}
		}

		return true; // All courses have the same categories
	};

	const getDiscountPercentage = (availableOffers, cartItemsCount) => {
		if (cartItemsCount === 1) {
			return 0;
		}

		// Find the offer with the highest numberOfCartItems that is less than or equal to cartItemsCount
		const offer = availableOffers
			.filter((offer) => offer.numberOfCartItems <= cartItemsCount)
			.reduce((prev, curr) =>
				prev.numberOfCartItems > curr.numberOfCartItems ? prev : curr
			);

		return offer ? offer.discount : 0; // Return the discount percentage if offer found, otherwise return 0
	};

	const applyCoupon = async () => {
		if (coupon) {
			// Combine all categories of all courses
			// First check which one is in different category then dont allow to use coupon
			// If all the courses have same categories then allow to use the coupon
			// Search or match the coupon in db for same categories
			const currencySymbol = decryptText(localStorage.getItem('cs'));
			const isValidCategories = checkCategories(cartItems);

			if (!isValidCategories) {
				updateApplyBtnColor('red');
				setCouponText('Different category courses.');
				setTimeout(function () {
					updateApplyBtnColor('#0073E6');
					setCouponText('Apply');
				}, 2500);

				return;
			}

			// Verify the coupon is valid coupon or not
			const authToken = decryptText(localStorage.getItem('eMediat'));

			const response = await CartServices.verifyCoupon(
				{
					categories: cartItems[0]?.categories,
					couponCode: coupon,
				},
				authToken
			);

			if (response.success) {
				// Apply the discount as per coupon
				// Save the applied Discound in state
				const discountInPercent = getDiscountPercentage(
					response.data?.discounts,
					cartItems.length
				);

				if (discountInPercent > 0) {
					updateApplyBtnColor('#008000');
					setCouponText('Applied!');

					const discountedValue = Math.round(
						(cartTotalValueInNumbers * discountInPercent) / 100
					);

					let priceAfterDiscount = Math.round(
						cartTotalValueInNumbers - discountedValue
					);

					const priceAfterDiscountText = currencySymbol + ' ' + priceAfterDiscount;

					dispatch(CartActions.updateCartTotalValue(priceAfterDiscountText));
					dispatch(CartActions.updateCartTotalValueInNumbers(priceAfterDiscount));

					dispatch(
						CartActions.updateCartDetails({
							cartItems: cartItems,
							cartItemCount: cartItems.length,
							cartTotalValue: priceAfterDiscountText,
							cartTotalValueInNumbers: priceAfterDiscount,
							discountPercentage: discountInPercent,
							discountValue: currencySymbol + ' ' + discountedValue,
							discountValueInNumbers: discountedValue,
							offerDetails: response.data,
						})
					);
					return;
				} else {
					updateApplyBtnColor('red');
					setCouponText('Discount not available for your cart items!');
					setTimeout(function () {
						updateApplyBtnColor('#0073E6');
						setCouponText('Apply');
					}, 2500);
					// Reset the cart values to initial
					resetCartValues();
					return;
				}
			} else {
				setCouponText('Coupon Invalid');
				updateApplyBtnColor('red');
				setTimeout(function () {
					updateApplyBtnColor('#0073E6');
					setCouponText('Apply');
				}, 2500);
				// Reset the cart values to initial
				resetCartValues();
				return;
			}
		} else {
			setCouponText('Coupon Invalid');
			updateApplyBtnColor('red');
			setTimeout(function () {
				updateApplyBtnColor('#0073E6');
				setCouponText('Apply');
			}, 2500);
			// Reset the cart values to initial
			resetCartValues();
			return;
		}
	};

	const resetCartValues = () => {
		try {
			const currencySymbol = decryptText(localStorage.getItem('cs'));
			let totalValueInNumbers = 0;
			cartItems.forEach((course) => {
				if (course?.isInOffer) {
					totalValueInNumbers += course?.offerPriceInNumbers;
				} else {
					totalValueInNumbers += course?.crossedPriceInNumbers;
				}
			});
			dispatch(
				CartActions.updateCartDetails({
					cartItems: cartItems,
					cartItemCount: cartItems.length,
					cartTotalValue: currencySymbol + ' ' + totalValueInNumbers,
					cartTotalValueInNumbers: totalValueInNumbers,
					discountPercentage: 0,
					discountValue: currencySymbol + ' ' + 0,
					discountValueInNumbers: 0,
					offerDetails: null,
				})
			);
		} catch (err) {
			console.log('Error coming from resetCartValues()', err);
		}
	};

	const updateApplyBtnColor = (color) => {
		if (couponContainerRef.current) {
			let ele = couponContainerRef.current.querySelector('span');
			if (ele) {
				ele.style.color = color;
			}
		}
	};

	const handleCouponChange = (event) => {
		setCoupon(event.target.value);
		updateApplyBtnColor('#0073E6');
		setCouponText('Apply');
		resetCartValues();
	};

	const handleContinueToPay = () => {
		try {
			props.handleSidebarView('reviewPayment');
		} catch (err) {
			console.log('Error coming from handleContinueToPay()', err);
		}
	};

	return (
		<ReviewPaymentView
			midContainerRef={midContainerRef}
			couponContainerRef={couponContainerRef}
			cartItems={cartItems}
			cartTotalValue={cartTotalValue}
			discountValue={discountValue}
			discountValueInNumbers={discountValueInNumbers}
			selectedPaymentGateway={selectedPaymentGateway}
			gatewayDiscountValue={gatewayDiscountValue}
			gatewayDiscountValueInNumbers={gatewayDiscountValueInNumbers}
			applyCoupon={applyCoupon}
			couponText={couponText}
			handleCouponChange={handleCouponChange}
			handleContinueToPay={handleContinueToPay}
		/>
	);
}

export default ReviewPayment;
