import React from 'react';
import './style.css';

import CartAndEnrollView from '@components/Popups/CartAndEnrollView';

import ExploreDesktopTabView from '@pages/Explore/DesktopTabView';
import ExploreMobileView from '@pages/Explore/MobileView';

function ExploreView(props) {
	return (
		<>
			<center>
				<div className='exploreView commonProductPageAlignmentContainerHelper'>
					<h3 style={{fontWeight:'700'}} className='mt-3 text-center veryTopHeading'>Explore Course</h3>
					{/* START : Main view */}
					{window.innerWidth > 768 ? (
						<ExploreDesktopTabView {...props} />
					) : (
						<ExploreMobileView {...props} />
					)}
				</div>
			</center>

			<CartAndEnrollView />
		</>
	);
}

export default ExploreView;
