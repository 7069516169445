import React from 'react';

import ETCard from '@components/ETCard';
import ETPopup from '@components/ETPopup';
import './style.css';

export default (props) => (
	<div className='examContainer row examContainerC'>
		{props.examDetails ? (
			<ETCard
				popUpType={props.popUpType}			
				examIndex={0}
				exam={props.examDetails}
				accordanceConfig={props.accordanceConfig}
				showResult={() => props.showResult(props.examDetails)}
				handleF1Exam={() => props.handleF1Exam(props.examDetails)}
			/>
		) : (
			<center>
				<img
					id='noProduct'
					src='https://stores.maxfashion.in/VendorpageTheme/Enterprise/EThemeForMax/images/product-not-found.jpg'
					alt='noProduct'
				/>
			</center>
		)}

		<ETPopup
			type={props.popUpType}
			data={props.examTestDetails}
			openPopupStatus={props.openPopupStatus}
			handleRedirectToEaxm={(d) => props.handleRedirectToEaxm(d)}
			closeEtPopupBtnRef={props.closeEtPopupBtnRef}
			etPoupOkBtnLoading={props.etPoupOkBtnLoading}

		/>
	</div>
);
