import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProbileTabView from './view';
import { useSelector, useDispatch } from 'react-redux';

import { decryptText } from '@library/enc-dec';

import * as UserDashboardServices from '@services/UserDashboard';
import * as UserDashboardActions from '@redux/actions/UserDashboard';

function ProbileTab() {
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector((state) => state.dashboard.user);
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
        if(!user || Object.entries(user).length === 0){
            getUserDetails();    
        }
	}, []);

	useEffect(()=>{
		if(user && Object.entries(user).length > 0 ){
			setIsLoading(false)
		}
	},[user])

	
    useEffect(() => {
        // Extract the hash part from the URL
        const hash = location.hash;
        if (hash) {
            // Remove the '#' character from the hash
            const sectionId = hash.substring(1);
            // Scroll to the section with the extracted id
            const sectionElement = document.getElementById(sectionId);
            if (sectionElement) {
                sectionElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]); // Run the effect whenever location.hash changes



	const getUserDetails = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			const response = await UserDashboardServices.getUser(authToken);
			if (response.success) {
				dispatch(UserDashboardActions.updateUserDetails(response.data));
			}
		} catch (err) {
			console.log('Profile: Error coming from getUserDetails()', err);
		}
	};

	return <ProbileTabView 
		isLoading={isLoading}
		/>;
}

export default ProbileTab;
