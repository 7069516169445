import React from 'react';

import ShareIcon from '@assets/video/share.png';
import UnFillHeartIcon from '@assets/video/unFillHeart.png';
import './videoPlaylist.css';

function VideoPlaylistView(props) {
	return (
		<>
			<div id="videoAccordionMain">
				{props.seriesDetails.data.map((series, index) => (
					<>
						<div className='playListContainer' key={index}>
							<p className='subHeading'>Module {index + 1}</p>

							{/* <div id={`videoAccordion${index}`}> */}
							<div id='videoAccordion'>
								<button
									className='accordion-button collapsed'
									data-bs-toggle='collapse'
									data-bs-target={`#videoCollapse${index}`}>
									{series.title}
								</button>
								<div
									id={`videoCollapse${index}`}
									className={`collapse ${props.inMobileView && index === 0 ? 'show' : ''} ${!props.inMobileView ? 'show' : ''}`}
									data-bs-parent='#videoAccordionMain'
								>
									<div className='customAccordionCollapse'>
										{series.videos.map((video, video_index) => (
											<>
												<div
													className='row customAccordionCollapseInner'
													key={video_index}
													style={{
														backgroundColor: props.videoId && video.videoId === props.videoId ? '#F2F2F2' : 'white'
													}}
												>
													<div className='col-5 col-sm-4 col-md-3 col-lg-5 col-xl-4 sideBarVideoThumbailContainer'>
														<img
															onClick={(event) => {
																props.handleVideoReRender(event, index + '_' + video_index);
															}}
															className='w-100'
															src={video.thumbnailUrl}
														/>
													</div>

													<p className='col-7 col-lg-7 col-xl-7 videoText'>
														<span className='videoTextSpan' onClick={(event) => {
															props.handleVideoReRender(event, index + '_' + video_index);
														}}>
														{video.title}

														</span>

														<br />
														<span style={{ color: '#BDBDBD' }}>Topic {video_index + 1}</span>

														<br />
														{video.isFavourite ? (
															<>
																<i
																	onClick={(event) => {
																		props.handleFavourite(event, video._id);
																	}}
																	className='fa-solid fa-heart fa-lg heartIcon'
																	style={{ color: 'red' }}></i>
															</>
														) : (
															<>
																<img
																	onClick={(event) => {
																		props.handleFavourite(event, video._id);
																	}}
																	src={UnFillHeartIcon}
																	alt='favrioute'
																	className='heartIcon'
																/>
															</>
														)}

														<img
															src={ShareIcon}
															alt='share'
															onClick={(event) => {
																props.handleShare(
																	event,
																	props.seriesDetails.data._id,
																	video._id
																);
															}}
															className='videoShareIcon'
															style={{ width: '16px', marginRight: '5px' }}
														/>
													</p>
												</div>
											</>
										))}
									</div>
								</div>
							</div>

							{/* Checking for last elemente */}
							{index !== props.seriesDetails.data.length - 1 ? (
								<>
									<hr style={{ marginTop: '0px' }} />
								</>
							) : null}
						</div>
					</>
				))}
			</div>
		</>
	);
}

export default VideoPlaylistView;
