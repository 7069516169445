export const updateCourseDetails = (value) => {
	return {
		type: 'UPDATE_COURSE_DETAILS',
		payload: {
			courseDetails: value,
		},
	};
};

export const resetCourseReducer = () => {
	return {
		type: 'RESET_COURSE_REDUCER',
	};
};
