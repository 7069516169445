import React from 'react';

import './style.css';
import IdentityVerification from '@pages/UserDashboard/Profile/IdentityVerification';
import '@pages/UserDashboard/Profile/Main/style.css';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';

import { useSelector } from 'react-redux';

import grayPlus from '@assets/timeline/icons/grayPlus.png';

function CompleteProfileView(props) {

	const user = useSelector((state) => state.dashboard.user);
	return (
		<>
			{!user ?
				(
					<>
						<h5>Is loading</h5>
					</>
				) : (
					<>
						<div className='profileTab profileTabC'>

							<IdentityVerification />

							{user.isIdentityInfoCompleted &&
								<>
									{/* START :  deliveryAddress */}
									<div className='formContainer'>
										<div className='fileInputMainContainer'>
											<p className='heading d-flex align-items-center'>
												Delivery Address
												{user.isDeliveryAddressRequired
													?
													<>
														<b className='asterisk profileTabCAsterisk'>*</b>
														<>{!user.isAddressInfoCompleted &&

															<Tags span_font_size="10px"
																img_margin="5px"
																bg="#FFE9E9"
																c="#962020"
																bc="#A62323"
																text="Input Required"
																imgs={InputError} />
														} </></>
													: ''
												}

											</p>

											<button
												onClick={(e) => props.handleDynamicRedirect(e, 'deliveryAddress')}>
												{user.isAddressInfoCompleted ? (
													<>Check</>
												) : (
													<>
														Add
														<img
															style={{ width: '13px', marginTop: '-2px' }}
															src={grayPlus}
															alt='grayPlus'
														/>
													</>
												)}
											</button>
										</div>
									</div>

									{/* START :  personalInformation */}
									<div className='formContainer'>
										<div className='fileInputMainContainer'>
											<p className='heading d-flex align-items-center'>
												Personal Information
												{user.isBasicDetailsRequired ?
													<>
														<b className='asterisk profileTabCAsterisk'>*</b>
														<>{!user.isPersonalInfoCompleted &&

															<Tags span_font_size="10px"
																img_margin="5px"
																bg="#FFE9E9"
																c="#962020"
																bc="#A62323"
																text="Input Required"
																imgs={InputError} />
														} </></>
													: ''
												}
											</p>

											<button
												onClick={(e) => props.handleDynamicRedirect(e, 'personalInformation')}>
												{user.isPersonalInfoCompleted ? (
													<>Check</>
												) : (
													<>
														Add
														<img
															style={{ width: '13px', marginTop: '-2px' }}
															src={grayPlus}
															alt='grayPlus'
														/>
													</>
												)}
											</button>
										</div>
									</div>

									{/* START :  LegalInfo */}
									<div className='formContainer'>
										<div className='fileInputMainContainer'>
											<p className='heading d-flex align-items-center'>
												Legal Information
												{user.isLegalInfoRequired
													?
													<>
														<b className='asterisk profileTabCAsterisk'>*</b>
														<>{!user.isLegalInfoCompleted &&

															<Tags span_font_size="10px"
																img_margin="5px"
																bg="#FFE9E9"
																c="#962020"
																bc="#A62323"
																text="Input Required"
																imgs={InputError} />
														} </></>
													: ''
												}

											</p>

											<button onClick={(e) => props.handleDynamicRedirect(e, 'legalInformation')}>
												{user.isLegalInfoCompleted ? (
													<>Check</>
												) : (
													<>
														Add
														<img
															style={{ width: '13px', marginTop: '-2px' }}
															src={grayPlus}
															alt='grayPlus'
														/>
													</>
												)}
											</button>
										</div>
									</div>

									{/* START :  bssDetails */}
									<div className='formContainer'>
										<div className='fileInputMainContainer'>
											<p className='heading d-flex align-items-center'>
												BSS Details
												{user.isBSSDetailsRequired ?
													<>
														<b className='asterisk profileTabCAsterisk'>*</b>
														<>{!user.bssFormDetails?.isBssInfoCompleted &&

															<Tags span_font_size="10px"
																img_margin="5px"
																bg="#FFE9E9"
																c="#962020"
																bc="#A62323"
																text="Input Required"
																imgs={InputError} />
														} </></>
													: ''
												}
											</p>

											<button onClick={(e) => props.handleDynamicRedirect(e, 'bssDetails')}>
												{user.bssFormDetails?.isBssInfoCompleted ? (
													<>Check</>
												) : (
													<>
														Add
														<img
															style={{ width: '13px', marginTop: '-2px' }}
															src={grayPlus}
															alt='grayPlus'
														/>
													</>
												)}
											</button>
										</div>
									</div>
								</>
							}
						</div>
					</>
				)
			}
		</>
	);
}

export default CompleteProfileView;
