export const updateFirstName = (value) => {
	return {
		type: 'UPDATE_FIRST_NAME',
		payload: {
			firstName: value,
		},
	};
};

export const updateLastName = (value) => {
	return {
		type: 'UPDATE_LAST_NAME',
		payload: {
			lastName: value,
		},
	};
};

export const updateContactNumber = (value) => {
	return {
		type: 'UPDATE_CONTACT_NUMBER',
		payload: {
			contactNumber: value,
		},
	};
};

export const updateEmail = (value) => {
	return {
		type: 'UPDATE_EMAIL',
		payload: {
			email: value,
		},
	};
};

export const updateCountryCode = (value) => {
	return {
		type: 'UPDATE_COUNTRY_CODE',
		payload: {
			countryCode: value,
		},
	};
};

export const updateCountryName = (value) => {
	return {
		type: 'UPDATE_COUNTRY_NAME',
		payload: {
			countryName: value,
		},
	};
};

export const updateUsername = (value) => {
	return {
		type: 'UPDATE_USERNAME',
		payload: {
			username: value,
		},
	};
};

export const updatePassword = (value) => {
	return {
		type: 'UPDATE_PASSWORD',
		payload: {
			password: value,
		},
	};
};

export const updateConfirmPassword = (value) => {
	return {
		type: 'UPDATE_CONFIRM_PASSWORD',
		payload: {
			confirmPassword: value,
		},
	};
};

export const resetSignUpReducer = (value) => {
	return {
		type: 'RESET_SIGNUP_REDUCER',
	};
};
