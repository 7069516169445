import React, { useState, useRef } from 'react';
import FinalExamView from './view';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { decryptText } from '@library/enc-dec';
import { getOrderDetailsByOrderId } from '@library/helpers';

import * as ExamServices from '@services/Exam';

function FinalExam(props) {
	const course = useSelector((state) => state.dashboard.course);
	const timeLineData = useSelector((state) => state.dashboard.timelineData);
	const navigate = useNavigate();
	const closeEtPopupBtnRef = useRef(null);
	const examType = "Exam";

	const [openPopupStatus, setOpenPopupStatus] = useState(false);
	const [etPoupOkBtnLoading, setEtPoupOkBtnLoading] = useState(false)

	const handleF1Exam = (purchase) => {
		setOpenPopupStatus(true);
	};

	const showResult = (purchase) => {
		navigate('/exam-result', {
			state: {
				purchaseId: purchase._id,
				examType: examType
			},
		});
	};



	const handleRedirectToEaxm = async (purchase) => {
		try {
			setOpenPopupStatus(false);
			if (purchase === null) {
				return;
			}

			const purchaseId = purchase._id;
			const courseId = purchase.courseId;

			if (purchase.examAttempts < 2) {
				setEtPoupOkBtnLoading(true)
				let payload = {
					purchaseId : purchaseId
				}
				const authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await ExamServices.reAttemptExam(examType, authToken, payload);

				if (response.success) {
					if (closeEtPopupBtnRef.current) {
						closeEtPopupBtnRef.current.click();
					}			
					navigate('/exam', {
						state: {
							courseId: courseId,
							purchaseId: purchaseId,
						},
					});					
				}
				else{
					console.log('Issue from handleRedirectToEaxm() ', response)
					alert('Issue while re-attempting exam, re-try after refreshing the page');
				}
			} else {
				if (closeEtPopupBtnRef.current) {
					closeEtPopupBtnRef.current.click();
					const orderId = purchase.resultDetails.orderId;
					let orderDetails = await getOrderDetailsByOrderId(orderId);
					if(Object.entries(orderDetails).length > 0){
						orderDetails.data.grandTotal = "200";
						navigate('/otherPay', {
							state: {
								source:{
									expenseType : 'examReattempt'
								},
								purchaseDetails: purchase,
								orderDetails: orderDetails.data
							},
						});								
					}
					else{
						alert('Issue in getting order details, try again after refreshing the page');
					}
				}			
			}
		} catch (err) {
			console.log('Error coming from handleRedirectToEaxm()', err);
		}
	};

	
	return (
		<FinalExamView
			popUpType={'Exam'}
			examTestDetails={timeLineData}
			openPopupStatus={openPopupStatus}
			examDetails={{
				examId: timeLineData?.examDetails?._id,
				examThumbnailUrl: timeLineData?.examDetails?.thumbnailUrl,
				examTitle: timeLineData?.examDetails?.title,
				examStatus: timeLineData?.examStatus,
				examStatusText: timeLineData?.examStatusText,
				examResultAt: timeLineData?.examResultAt,
				examResultHideAt: timeLineData?.examResultHideAt,
				resultDetails: timeLineData?.resultDetails,
				_id:timeLineData?._id
			}}
			handleF1Exam={handleF1Exam}
			handleRedirectToEaxm={handleRedirectToEaxm}
			showResult={showResult}
			accordanceConfig={props.accordanceConfig}
			timeLineData={timeLineData}
			closeEtPopupBtnRef={closeEtPopupBtnRef}
			etPoupOkBtnLoading={etPoupOkBtnLoading}

		/>
	);
}

export default FinalExam;
