const initialState = {
	userSpecificOfferDetails: []
};

const UserSpecificOfferReducers = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_USER_SPECIFIC_OFFER_DETAILS':
			return {
				...state,
				userSpecificOfferDetails: action.payload,
			};
		case 'RESET_USER_SPECIFIC_OFFER_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default UserSpecificOfferReducers;
