import React from 'react';

import numeral from 'numeral';
import './style.css';
import DeteleIcon from '@assets/checkout/deleteIcon.png'
import WhiteCrossBtn from '@assets/course/whiteCrossBtn.png';
import AddingCoursePlaceholder from '@assets/course/addingCoursePlaceholder.png';
import LoaderGif from "@assets/course/loading.gif";

function CoursesHolderMobileCartView(props) {
	return (
		<>
			{props.device && props.device === 'desktop' ? (
				<>
					<div
						style={{ maxHeight: window.innerHeight - 200 + 'px' }}
						className='coursesHolderDesktopCartView'>
						
						{props.selectedCourses &&
							props.selectedCourses.length > 0 &&
							props.selectedCourses.map((course, index) => (
								<div key={index} className='courseCardDiv'>
									<div className='div1'>
										<img
											className='thumbnail'
											src={course.squareThumbnailUrl}
											alt='squareThumbnail'
										/>
										<div style={{ marginLeft: '7px' }}>
											<p className='name'>{course.title}</p>
											<p className='price'>
												{course.offerPriceInNumbers > 0 && course.isInOffer
													? course.offerPrice
													: course.crossedPrice}

												{course.offerPriceInNumbers > 0 && course.isInOffer && (
													<>
														<span className='crossedPrice'>
															<strike>{course.crossedPrice}</strike>
														</span>
														<span className='offerPrice'>
															{Math.round(
																((course.crossedPriceInNumbers - course.offerPriceInNumbers) /
																	course.crossedPriceInNumbers) *
																100
															)}
															%{''}off
														</span>
													</>
												)}
											</p>
										</div>
									</div>

									{props.deleteCartItemIsLoading && props.deleteCartItemIsLoading === course.courseId ?
										(
											<>
												<img
													className='delete'
													src={LoaderGif}
													alt='delete'
												/>
											</>
										) : (
											<>
												<img
													onClick={() => props.handleRemoveCartItem(course)}
													className='delete'
													src={DeteleIcon}
													alt='delete'
												/>
											</>
										)
									}
								</div>
							))}
					</div>
				</>
			) : (
				<>
					<div className='d-flex overflow-auto coursesHolderMobileCartView'>
						{props.selectedCourses &&
							props.selectedCourses.length > 0 &&
							props.selectedCourses.map((course, index) => (
								<div key={index} className='col-2 courseCardDiv div1'>
									<img
										className='w-100'
										src={course.squareThumbnailUrl}
										alt='squareThumbnail'
									/>
									<div className='overlay'></div>

									{props.deleteCartItemIsLoading && props.deleteCartItemIsLoading === course.courseId ?
										(
											<>
												<img
													className='whiteCrossBtn'
													src={LoaderGif}
													alt='LoaderGif'
												/>
											</>
										) : (
											<>
												<img
													onClick={() => {
														if (props.source === "mobileOffer" && index === 0) {
														}
														else {
															props.handleRemoveCartItem(course);
														}
													}}
													className='whiteCrossBtn'
													src={WhiteCrossBtn}
													alt='WhiteCrossBtn'
												/>
											</>
										)
									}
								</div>
							))}

						{props.constNumOfCoursePlaceholder - props.selectedCourses.length < 1 ? (
							<>
								<div className='col-2 courseCardHolerDiv div1'>
									<img
										className='w-100'
										src={AddingCoursePlaceholder}
										alt='addingCoursePlaceholder'
									/>
								</div>
							</>
						) : (
							<>
								{Array.from({
									length:
										props.constNumOfCoursePlaceholder - props.selectedCourses.length,
								}).map((_, index) => (
									<div key={index} className='col-2 courseCardHolerDiv div1'>
										<img
											className='w-100'
											src={AddingCoursePlaceholder}
											alt='addingCoursePlaceholder'
										/>
									</div>
								))}
							</>
						)}
					</div>
				</>
			)}
		</>
	);
}

export default CoursesHolderMobileCartView;
