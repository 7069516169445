import React from 'react';
import ContentLoader from "react-content-loader";

import { Link } from 'react-router-dom';
import './style.css';
import RightWhiteArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';

export default (props) => (
	<div className='exploreMobileView'>
		{props.isLoading ? (
			<>
				{[...Array(3)].map((_, index) => (
					<>
						<ContentLoader
							// backgroundColor="#9e0505"
							// foregroundColor="#ff8585"
							viewBox="0 0 380 190">
							<rect x="0" y="10" rx="4" ry="4" width="150" height="12" />
							<rect x="0" y="34" rx="3" ry="3" width="200" height="9" />
							<rect x="0" y="50" rx="3" ry="3" width="200" height="9" />
							<rect x="0" y="66" rx="3" ry="3" width="200" height="9" />
							<rect x="0" y="88" rx="3" ry="3" width="380" height="9" />
							<rect x="0" y="104" rx="3" ry="3" width="380" height="9" />
							<rect x="0" y="120" rx="3" ry="3" width="380" height="9" />
							<rect x="0" y="140" rx="3" ry="3" width="380" height="30" />
						</ContentLoader>
					</>
				))}
			</>
		) : (
			<>
				{props.exploreData && Object.entries(props.exploreData).length > 0 ? (
					Object.entries(props.exploreData).map(([index, explore]) => (
						<div className='exploreContentContainer' key={index}>
							<p className='title'>{explore.title}</p>
							{explore.pointers &&
								explore.pointers.length > 0 &&
								explore.pointers.map((pointer, pointerIndex) => (
									<p className='pointerContainer' key={pointerIndex}>
										<img src={pointer.icon} alt='icon' />
										<span>{pointer.pointer}</span>
									</p>
								))}
							<p className='description'>{explore.description}</p>
							<Link
								className='viewCoursesCategory'
								to={`/exploreCourseCategory/${explore.categoryId}`}>
								View
								<img src={RightWhiteArrow} alt='RightWhiteArrow' />
							</Link>
						</div>
					))
				) : (
					<center>
						<img
							className='w-50'
							src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=2000'
							alt='noCourses'
						/>
					</center>
				)}
			</>
		)}
	</div>
);
