import React from 'react';

import './style.css';

export default (props) => (
	<div className='faqView'>
		<h4 className='heading'>Frequently Asked Questions</h4>
		{props.faqDetails &&
			props.faqDetails?.questionsSet.map((item, index) => (
				<div
					key={index}
					id={`faqAccordion${index}`}
					className='faqAccordionContainer'>
					<button
						className='accordion-button collapsed'
						data-bs-toggle='collapse'
						data-bs-target={`#faqCollapse${index}`}>
						<p>{item.question}</p>
					</button>
					<div
						id={`faqCollapse${index}`}
						className='collapse'
						data-bs-parent={`#faqAccordion${index}`}>
						<div className='customAccordionCollapse'>
							<p>{item.answer}</p>
						</div>
					</div>
				</div>
			))}
	</div>
);
