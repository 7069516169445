import React from "react";

import './videplayer.css';

import RightTickIcon from '@assets/videoPlayer/rightTick.png';
import Backward10Icon from '@assets/videoPlayer/backward10Icon.png';
import Forward10Icon from '@assets/videoPlayer/forward10Icon.png';
import QualityIcon from '@assets/videoPlayer/qualityIcon.png';
import ShrinkIcon from '@assets/videoPlayer/shrinkIcon.png';
import ExpandIcon from '@assets/videoPlayer/expandIcon.png';

import { MobileQtySpeedCCUiContainer } from './mobileViewFunctions.js';


function VideoPlayerView(props) {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const { playerRef, videoPlayerRef } = props;

    return (
        <>
          <div  class = {props.isManualFullScreen? "fullScreenBlackDiv": ""}>
            <div
                onMouseEnter={props.handleMouseEnter}
                onMouseLeave={props.handleMouseLeave}
                {...props.doubleTapHandlers}
                id="videoPlayer"
                ref={videoPlayerRef}
                class = {props.isManualFullScreen? "fullScreenMiddleDiv": ""}
            >
            {props.isFullScreen && props.inMobileView && props.inMobileView === "yes" &&
            <MobileQtySpeedCCUiContainer
                isHasCaptions={props.isHasCaptions}
                ccFlag={props.ccFlag}
                onValueChange={props.handlOk}
            />
                        }
            { props.isFullScreen && props.inMobileView && props.inMobileView === "yes"?
                <div
                    className='w-100'
                    id="videoPlayerX"
                    ref={playerRef}
                   
                    style={{position: 'fixed',
                            top: `${Number(window.innerHeight/2)}px` ,
                            left: `${Number(window.innerWidth/2)}px` ,
                            transform: 'translate(-50%, -50%)',
                        
                        }}
                > 
                </div> 
                : 
                <div
                    className='w-100'
                    id="videoPlayerX"
                    ref={playerRef}
                > 
                </div> 
            }


                <div
                    className={props.isVisibleVidePlayerInnerContainer || props.mouseInOut ? 'videPlayerInnerContainer_Shadow' : ''}
                    onClick={(event) => { props.handleVideoPlayerSingleClick(event, 7000) }}
                    id="videPlayerInnerContainer"
                    
                    style={{position: (props.isFullScreen && props.inMobileView && props.inMobileView === "yes")? 'fixed' : '',
                            top: (props.isFullScreen && props.inMobileView && props.inMobileView === "yes")?`${Number(window.innerHeight/2)}px` : '' ,
                            left: (props.isFullScreen && props.inMobileView && props.inMobileView === "yes")?`${Number(window.innerWidth/2)}px` : '',
                            transform: (props.isFullScreen && props.inMobileView && props.inMobileView === "yes")?'translate(-50%, -50%)' : '',
                        }}
                    >

                    {/* START: MOBILE TOP 3 DOTS */}
                    {props.inMobileView && props.inMobileView === "yes" &&
                        <div
                            style={{ opacity: `${props.isVisibleVidePlayerInnerContainer ? '1' : ''}` }}
                            className={props.isVisibleVidePlayerInnerContainer ? '' : 'customeFadeinoutX'}
                            id="div0">
                            <i
                                onClick={(event) => { props.handleMobileThreedots(event) }}
                                className="fa fa-ellipsis-v"></i>
                        </div>
                    }
                    {/* END: MOBILE TOP 3 DOTS */}



                    {/* START : Mobile playPause - forward - backard container */}
                    {/* {props.inMobileView && props.inMobileView === "yes" && */}
                    <div id="div0_1">
                        <img
                            style={{ visibility: `${props.isVisibleBackwardIcon ? 'visible' : 'hidden'}` }}
                            className={props.isVisibleBackwardIcon ? 'customeFadeinout' : ''}
                            src={Backward10Icon} alt='rewind' />

                        <img
                            style={{ opacity: `${props.isVisibleVidePlayerInnerContainer ? '1' : ''}` }}
                            className={props.isVisibleVidePlayerInnerContainer ? '' : 'customeFadeinoutX'}
                            onClick={(event) => { props.handlePlayPause(event) }}
                            id="playPauseIcon_div0_1"
                            src={props.playPauseIcon} alt="playIcon" />

                        <img
                            style={{ visibility: `${props.isVisibleForwardIcon ? 'visible' : 'hidden'}` }}
                            className={props.isVisibleForwardIcon ? 'customeFadeinout' : ''}
                            src={Forward10Icon} alt='forward' />
                    </div>
                    {/* } */}
                    {/* END : Mobile playPause - fr - br container */}


                    {/* START : div for handling play and pause on click */}
                    <div id="div1_d">
                    </div>
                    {/* START : div for handling play and pause on click */}

                    <div id="div1">

                        {/* START : Speed container */}
                        {props.is_SpeedContainerRef_Visible &&
                            <div
                                ref={props.speedContainerRef}
                                style={{ marginLeft: props.speedContainerRef_Position }}
                                id="videoSpeedContainer"
                            >
                                <p onClick={(event) => (props.handleSpeed(event, 0.25))} >
                                    <img
                                        className={props.speedValue && props.speedValue !== 0.25 && 'rightTickImgToggle'}
                                        id="speed_0.25"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>0.25x</span>
                                </p>

                                <p onClick={(event) => (props.handleSpeed(event, 0.5))}>
                                    <img
                                        className={props.speedValue && props.speedValue !== 0.5 && 'rightTickImgToggle'}
                                        id="speed_0.5"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>0.5x</span>
                                </p>

                                <p onClick={(event) => (props.handleSpeed(event, 0.75))}>
                                    <img
                                        className={props.speedValue && props.speedValue !== 0.75 && 'rightTickImgToggle'}
                                        id="speed_0.75"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>0.75x</span>
                                </p>

                                <p onClick={(event) => (props.handleSpeed(event, 1))}>
                                    <img
                                        className={props.speedValue && props.speedValue !== 1 && 'rightTickImgToggle'}
                                        id="speed_1"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>1x</span>
                                </p>

                                <p onClick={(event) => (props.handleSpeed(event, 1.25))}>
                                    <img
                                        className={props.speedValue && props.speedValue !== 1.25 && 'rightTickImgToggle'}
                                        id="speed_1.25"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>1.25x</span>
                                </p>

                                <p onClick={(event) => (props.handleSpeed(event, 1.5))}>
                                    <img
                                        className={props.speedValue && props.speedValue !== 1.5 && 'rightTickImgToggle'}
                                        id="speed_1.5"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>1.50x</span>
                                </p>


                                <p onClick={(event) => (props.handleSpeed(event, 1.75))}>
                                    <img
                                        className={props.speedValue && props.speedValue !== 1.75 && 'rightTickImgToggle'}
                                        id="speed_1.75"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>1.75x</span>
                                </p>

                                <p onClick={(event) => (props.handleSpeed(event, 2))}>
                                    <img
                                        className={props.speedValue && props.speedValue !== 2 && 'rightTickImgToggle'}
                                        id="speed_2"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>2x</span>
                                </p>
                            </div>
                        }
                        {/* END : Speed container */}



                        {/* START : Qty container */}
                        {props.is_QtyContainerRef_Visible &&
                            <div
                                ref={props.qtyContainerRef}
                                style={{ marginLeft: props.qtyContainerRef_Position }}
                                id="qualityContainer" >

                                <p onClick={(event) => (props.handleQuality(event, '1080p'))}>
                                    <img
                                        className={props.qtyValue && props.qtyValue !== '1080p' && 'rightTickImgToggle'}
                                        id="quality_1080p"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>1080p</span>
                                </p>


                                <p onClick={(event) => (props.handleQuality(event, '720p'))}>
                                    <img
                                        className={props.qtyValue && props.qtyValue !== '720p' && 'rightTickImgToggle'}
                                        id="quality_720p"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>720p</span>
                                </p>

                                <p onClick={(event) => (props.handleQuality(event, '540p'))}>
                                    <img
                                        className={props.qtyValue && props.qtyValue !== '540p' && 'rightTickImgToggle'}
                                        id="quality_540p"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>540p</span>
                                </p>

                                <p onClick={(event) => (props.handleQuality(event, '360p'))}>
                                    <img
                                        className={props.qtyValue && props.qtyValue !== '360p' && 'rightTickImgToggle'}
                                        id="quality_360p"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>360p</span>
                                </p>

                                <p onClick={(event) => (props.handleQuality(event, '240p'))}>
                                    <img
                                        className={props.qtyValue && props.qtyValue !== '240p' && 'rightTickImgToggle'}
                                        id="quality_240p"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>240p</span>
                                </p>

                                <p onClick={(event) => (props.handleQuality(event, 'auto'))}>
                                    <img
                                        className={props.qtyValue && props.qtyValue !== 'auto' && 'rightTickImgToggle'}
                                        id="quality_auto"
                                        src={RightTickIcon}
                                        alt="right-tick"
                                    />
                                    <span>Auto</span>
                                </p>
                            </div>
                        }
                        {/* END : Qty container */}

                    </div>



                    {/* <div
                        style={{
                            opacity: props.isVisibleVidePlayerInnerContainer || props.inMobileView === 'no' ? '1' : '0'
                        }}
                        className={props.isVisibleVidePlayerInnerContainer ? '' : 'customeFadeinoutX'}
                        id="div2"> */}

                    <div
                        style={{ 
                            opacity: 
                            `${props.isVisibleVidePlayerInnerContainer || props.mouseInOut 
                                ? '1' 
                                : ''}` }}
                        className={props.isVisibleVidePlayerInnerContainer || props.mouseInOut ? '' : 'customeFadeinoutX'}
                        id="div2">

                        <input
                            ref={props.timeBarRef}
                            id="timeBar"
                            type="range"
                            min="0"
                            max="0"
                            step="0.1"
                            className=""
                            style={{ backgroundSize: `${props.timeBarChangePer} 100%` }}
                            onMouseUp={(event) => { props.handleTimeBarChange(event); }}
                            onChange={(event) => { props.handleTimeBarRefChange(event); }}
                        />
                    </div>



                    <div
                        // style={{
                        //     opacity: props.isVisibleVidePlayerInnerContainer || props.inMobileView === 'no' ? '1' : '0'
                        // }}
                        style={{ 
                            opacity: 
                            `${props.isVisibleVidePlayerInnerContainer || props.mouseInOut 
                                ? '1' 
                                : ''}` }}
                        className={props.isVisibleVidePlayerInnerContainer || props.mouseInOut ? '' : 'customeFadeinoutX'}
                        id="div3">
                        <div id="leftContainer">

                            {props.inMobileView && props.inMobileView === "no" &&
                                <button id="playPauseButton"
                                    onClick={(event) => { props.handlePlayPause(event) }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Play" >
                                    <img
                                        id="playPauseIcong"
                                        src={props.playPauseIcon}
                                        alt='play-pause' />
                                </button>
                            }


                            {props.inMobileView && props.inMobileView === 'no' &&
                                <>
                                    <button id="backward10"
                                        onClick={props.handleBackward10}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Rewind 10s">
                                        <img src={Backward10Icon} alt='rewind' />
                                    </button>


                                    <button id="speedUp"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Speed" >
                                        <img
                                            onClick={props.handleSpeedContainerVisibility}
                                            id="speedIcon"
                                            src={props.speedIcon}
                                            alt='speedUp' />
                                    </button>


                                    <button id="forward10"
                                        onClick={props.handleForward10}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Forward 10s">
                                        <img src={Forward10Icon} alt='forward' />
                                    </button>
                                </>
                            }

                            <p id="videoTime">
                                <span id="remaningVideoTime">{props.currentVideoTime}</span>
                                /
                                <span id="completeVideoTime">{props.videoDuration}</span>
                            </p>
                        </div>


                        <div id="rightContainer">

                            <button id="volumeButton"
                                onClick={(event) => (props.handleVolumeBtn(event))}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Mute" >
                                <img
                                    id="volumeIcon"
                                    src={props.volumeIcon}
                                    alt='volume' />
                            </button>



                            {props.inMobileView && props.inMobileView === 'no' &&
                                <>
                                    <input
                                        id="volumeBar"
                                        type="range"
                                        min="0"
                                        max="1"
                                        step="0.1"
                                        value={props.volumeBarChangedValue}
                                        style={{ backgroundSize: `${props.volumeBarChangePer} 100%` }}
                                        onChange={(event) => { props.handleVolumeChange(event, 0); }}
                                    />


                                    <button id="qualityButton"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Quality" >
                                        <img
                                            onClick={props.handleQualityVisibility}
                                            id="qualityIcon"
                                            src={QualityIcon}
                                            alt='quality' />
                                    </button>


                                    <button id="ccButton"
                                        style={{ display: props.isCaption }}
                                        onClick={props.handleCC}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Caption" >
                                        <img

                                            id="ccIcon"
                                            src={props.ccIcon}
                                            alt='cc' />
                                    </button>
                                </>
                            }

                            <button id="expandShrinkButton"
                                onClick={()=>!props.isFullScreen ?props.enterFullscreen(): props.exitFullscreen()}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Full Screen" >
                                <img
                                    id="expandShrinkIcon"
                                    src={!props.isFullScreen ? ExpandIcon: ShrinkIcon}
                                    alt='expandShrink' />
                            </button>


                        </div>
                    </div>
                </div>
            </div>
          </div>

         {props.isFullScreen && props.inMobileView && props.inMobileView === "yes" ||
            <MobileQtySpeedCCUiContainer
                isHasCaptions={props.isHasCaptions}
                ccFlag={props.ccFlag}
                onValueChange={props.handlOk}
            />
                        }
        </>
    );
}

export default VideoPlayerView;