import React from 'react';
import './style.css';
import RightWhiteArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';

export default (props) => (
	<div className='sampleCertificate'>
		<h4 className='heading'>Add one more skill to your resume</h4>
		<div className='certificateHolder'>
			<img
				className={`certificateImg ${!props.userLogedIn && 'blurEffect'}`}
				src={props.certificate}
				alt='sampleCertificate'
			/>
			{!props.userLogedIn && (
				<button
					onClick={()=>props.openOtpPopUpX('', '', 'init','sampleCertificate')} // This fn is defined in src\pages\Course\Main\index.js
					className='viewCertificateBtn'>
					View sample certificate
					<img src={RightWhiteArrow} alt='RightWhiteArrow' />
				</button>
			)}
		</div>
	</div>
);
