import React, { useEffect } from 'react';
import './swipeButton.css';

const SwipeButton = (props) => {
	var isDragging = false;

	useEffect(() => {
		//resetButton()
		var swipeDiv = document.getElementById('swipeButtonDiv' + props.id);
		var swipeButton = document.getElementById('swipeButton' + props.id);

		swipeButton.addEventListener('mousedown', moveStart);
		document.addEventListener('mousemove', moving);
		document.addEventListener('mouseup', moveEnd);

		swipeButton.addEventListener('touchstart', moveStart);
		document.addEventListener('touchmove', moving);
		document.addEventListener('touchend', moveEnd);

		window.addEventListener('resize', resetButton);

		return () => {
			swipeButton.removeEventListener('mousedown', moveStart);
			document.removeEventListener('mousemove', moving);
			document.removeEventListener('mouseup', moveEnd);

			swipeButton.removeEventListener('touchstart', moveStart);
			document.removeEventListener('touchmove', moving);
			document.removeEventListener('touchend', moveEnd);
			window.removeEventListener('resize', resetButton);
		};
	}, []);

	const moveStart = (e) => {
		e.preventDefault();
		if (props.status !== 'locked') {
			let doX = true;
			if (props.accordanceConfig) {
				if (
					props.accordanceConfig.hasOwnProperty('superLock') &&
					props.accordanceConfig.superLock
				) {
					doX = false;
				} else if (
					props.accordanceConfig.hasOwnProperty('lock') &&
					props.accordanceConfig.lock
				) {
					doX = false;
				} else if (
					props.accordanceConfig.hasOwnProperty('underReview') &&
					props.accordanceConfig.underReview
				) {
					doX = false;
				} else if (
					props.accordanceConfig.hasOwnProperty('ineligible') &&
					props.accordanceConfig.ineligible
				) {
					doX = false;
				}
			}

			if (doX) {
				isDragging = true;
			}
		}
	};

	const moveEnd = (e) => {
		// e.preventDefault();
		if (isDragging) {
			var swipeDiv = document.getElementById('swipeButtonDiv' + props.id);
			var swipeButton = document.getElementById('swipeButton' + props.id);
			var swipeDivRect = swipeDiv.getBoundingClientRect();
			if (Number(swipeButton.style.left.slice(0, -2)) + 48 >= swipeDivRect.right) {
				props.handleExam(); 
			}

			isDragging = false;
			swipeButton.style.left = swipeDivRect.left + 5 + 'px';
		}
	};

	const moving = (e) => {
		e.preventDefault();
		if (isDragging) {
			var swipeDiv = document.getElementById('swipeButtonDiv' + props.id);
			var swipeButton = document.getElementById('swipeButton' + props.id);
			var swipeDivRect = swipeDiv.getBoundingClientRect();

			if (e.type == 'touchmove') {
				swipeButton.style.left = `${e.touches[0].clientX}px`;
			} else {
				swipeButton.style.left = `${e.clientX}px`;
			}

			if (Number(swipeButton.style.left.slice(0, -2)) + 45 > swipeDivRect.right) {
				swipeButton.style.left = swipeDivRect.right - 45 + 'px';
			} else if (Number(swipeButton.style.left.slice(0, -2)) < swipeDivRect.left) {
				swipeButton.style.left = swipeDivRect.left + 10 + 'px';
			}
		}
	};

	const resetButton = () => {
		var swipeDiv = document.getElementById('swipeButtonDiv' + props.id);
		var swipeButton = document.getElementById('swipeButton' + props.id);
		var swipeDivRect = swipeDiv.getBoundingClientRect();
		swipeButton.style.left = swipeDivRect.left + 10 + 'px';
	};

	return (
		<>
			<div
				id={'swipeButtonDiv' + props.id}
				className={`swipeButtonDiv ${
					props.status === 'locked' && 'swipeButtonDivLock'
				}`}>
				<div
					id={'swipeButton' + props.id}
					className={`swipeButton ${
						props.status === 'locked' && 'swipeButtonLock'
					}`}>
					<span
						className={`arrowLogo ${props.status === 'locked' && 'arrowLogoLock'}`}>
						&gt;&gt;&gt;
					</span>
				</div>
				<span>
					{props.accordanceConfig &&
					((props.accordanceConfig.hasOwnProperty('superLock') &&
						props.accordanceConfig.superLock) ||
						(props.accordanceConfig.hasOwnProperty('lock') &&
							props.accordanceConfig.lock)) ? (
						<>Exam is yet to be unlocked!</>
					) : (
						<>{props.statusText}</>
					)}
				</span>
			</div>
		</>
	);
};

export default SwipeButton;

//REF:
//https://codepen.io/huynhquocmy/details/xxxWWqG
//https://codepen.io/l-portet/pen/QWKbRQJ
//https://codepen.io/gpressutto5/pen/NjJobG
