import React from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import AlertIcon from '@assets/userDashboard/Icons/alertIcon.png';
import TickIcon from '@assets/userDashboard/Icons/tickGrey.png';
import InputText from '@components/UserDashboard/Profile/InputText';
import InputFile from '@components/UserDashboard/Profile/InputFile';
import InputCheckbox from '@components/UserDashboard/Profile/InputCheckbox';
import InputDate from '@components/UserDashboard/Profile/InputDate';
import InputSelect from '@components/UserDashboard/Profile/InputSelect';

function BSSFormView(props) {
	return (
		<>
			<div className='sTop' id="bssDetails">
				<div className='fHeadingContainer'>
					<div className='fHeadingContainer_1  justify-content-start gap-2'>
						<h3 className='fHeading'>BSS REQUIREMENTS</h3>

						{(props.userData.isBSSDetailsRequired && !props.userData?.bssFormDetails?.isBssInfoCompleted) && (
							<Tags span_font_size='13px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#BA131A'
								bc='#F7A8AD'
								text={'Pending'}
								imgs={AlertIcon}
							/>)}
						{props.userData?.bssFormDetails?.isBssInfoCompleted &&
							<Tags span_font_size='13px'
								img_margin='5px'
								bg='#F9F9F9'
								c='#626262'
								bc='#626262'
								text={'Submitted'}
								imgs={TickIcon}
							/>
						}
						<button className='ms-auto' onClick={props.handleSave}>Save</button>
					</div>
					<p>
						<b>Certain certificates</b> are reserved <b>exclusively for doctors,</b>{' '}
						and we may require them for <b>identity verification</b> purposes. If your
						course does not require doctor verification, kindly upload the highest
						qualification documents you possess.
					</p>
				</div>

				<div className='formContainer'>
					{/* --------------- */}
					<div className='row'>
						<InputText
							showError='true'
							iserror={props.iserror}
							text='Name of the father/guardian'
							isReq='false'
							value={props.userData?.bssFormDetails?.guardianName}
							onChangeAction='guardianName'
							readOnly={props.userData?.bssFormDetails?.guardianNameStatus === 'Verified'}
							verified={props.userData?.bssFormDetails?.guardianNameStatus === 'Verified'}
							rejected={props.userData?.bssFormDetails?.guardianNameStatus === 'Rejected'}
							{...props}
						/>

						<InputSelect
							showError='true'
							iserror={props.iserror}
							isReq='false'
							text='Blood Group'
							onChangeAction='bloodGroup'
							listToloop={props.listOfBloodGroups}
							value={props.userData?.bssFormDetails?.bloodGroup}
							{...props}
						/>
					</div>
					{/* --------------- */}

					{/* --------------- */}
					<div className='row'>
						<InputSelect
							showError='true'
							iserror={props.iserror}
							isReq='false'
							text='Select Undergraduate Degree'
							onChangeAction='underGraduateDegree'
							listToloop={props.bssQualifications}
							value={props.userData?.bssFormDetails?.underGraduateDegree}
							disabled={props.userData?.bssFormDetails?.underGraduateDegreeStatus === 'Verified'}
							verified={props.userData?.bssFormDetails?.underGraduateDegreeStatus === 'Verified'}
							rejected={props.userData?.bssFormDetails?.underGraduateDegreeStatus === 'Rejected'}
							{...props}
						/>

						<InputText
							showError='true'
							iserror={props.iserror}
							text='Name of Institute for Undergraduate Degree'
							isReq='false'
							value={props.userData?.bssFormDetails?.underGraduateInstituteName}
							onChangeAction='underGraduateInstituteName'
							readOnly={props.userData?.bssFormDetails?.underGraduateInstituteNameStatus === 'Verified'}
							verified={props.userData?.bssFormDetails?.underGraduateInstituteNameStatus === 'Verified'}
							rejected={props.userData?.bssFormDetails?.underGraduateInstituteNameStatus === 'Rejected'}
							{...props}
						/>
					</div>
					{/* --------------- */}

					{/* --------------- */}
					<div className='row'>
						<InputText
							showError='true'
							iserror={props.iserror}
							fullWidth='true'
							text='Address of Institute for Undergraduate Degree'
							isReq='false'
							value={props.userData?.bssFormDetails?.underGraduateInstituteAddress}
							onChangeAction='underGraduateInstituteAddress'
							readOnly={props.userData?.bssFormDetails?.underGraduateInstituteAddressStatus === 'Verified'}
							verified={props.userData?.bssFormDetails?.underGraduateInstituteAddressStatus === 'Verified'}
							rejected={props.userData?.bssFormDetails?.underGraduateInstituteAddressStatus === 'Rejected'}
							{...props}
						/>
					</div>
					{/* --------------- */}

					{/* --------------- */}
					<div className='row'>
						<InputText
							showError='true'
							iserror={props.iserror}
							text='Registration Number'
							isReq='false'
							value={props.userData?.bssFormDetails?.registrationNumber}
							onChangeAction='registrationNumber'
							readOnly={props.userData?.bssFormDetails?.registrationNumberStatus === 'Verified'}
							verified={props.userData?.bssFormDetails?.registrationNumberStatus === 'Verified'}
							rejected={props.userData?.bssFormDetails?.registrationNumberStatus === 'Rejected'}
							{...props}
						/>

						<InputSelect
							showError='true'
							iserror={props.iserror}
							isReq='false'
							text='Select Year of Passing'
							onChangeAction='passingYear'
							listToloop={props.listOfYearOfPassing}
							value={props.userData?.bssFormDetails?.passingYear}
							disabled={props.userData?.bssFormDetails?.passingYearStatus === 'Verified'}
							verified={props.userData?.bssFormDetails?.passingYearStatus === 'Verified'}
							rejected={props.userData?.bssFormDetails?.passingYearStatus === 'Rejected'}
							{...props}
						/>
					</div>
					{/* --------------- */}

					{/* --------------- */}
					<div className='subForm border-top-0'>
						<p className='subFormHeading'>ENCLOSED DOCUMENTS</p>
						<p className='subFormSubHeading'>
							This will be sent to BSS for verification purposes.
						</p>
					</div>

					<InputFile
						showError='true'
						iserror={props.iserror}
						value={props.userData?.bssFormDetails?.aadhaarCard}
						text='Aadhar card'
						isReq='true'
						buttonId='aadhaarCard_uploadBtn'
						inputFileId='aadhaarCard'
						docStatus={props.userData?.bssFormDetails?.aadhaarVerificationStatus}
						{...props}
					/>

					<InputFile
						showError='true'
						iserror={props.iserror}
						value={props.userData?.bssFormDetails?.panCard}
						text='PAN card'
						isReq='true'
						buttonId='panCard_uploadBtn'
						inputFileId='panCard'
						docStatus={props.userData?.bssFormDetails?.panVerificationStatus}
						{...props}
					/>

					<InputFile
						showError='true'
						iserror={props.iserror}
						value={props.userData?.bssFormDetails?.otherDocs}
						text='Other documents'
						isReq='false'
						buttonId='otherDocs_uploadBtn'
						inputFileId='otherDocs'
						docStatus={props.userData?.bssFormDetails?.otherDocsVerificationStatus}
						{...props}
					/>
					{/* --------------- */}

					{/* --------------- */}
					<div className='subForm'>
						<p className='subFormHeading'>SIGNATURE</p>
					</div>

					<InputFile
						showError='true'
						iserror={props.iserror}
						value={props.userData?.bssFormDetails?.signature}
						text='Signature of the Candidate'
						isReq='true'
						buttonId='signature_uploadBtn'
						inputFileId='signature'
						docStatus={props.userData?.bssFormDetails?.signatureVerificationStatus}
						{...props}
					/>
					<InputCheckbox
						showError='true'
						iserror={props.iserror}
						value={props.userData?.bssFormDetails?.bssAcknowledged}
						isReq='false'
						inputId='bssFormReqCheckBox'
						text='I here by declare that the entries made above are correct and that they have been made in my own handwriting'
						{...props}
					/>
					{/* --------------- */}

					<br />

					{/* --------------- */}
					<div className='row'>
						<InputText
							showError='true'
							iserror={props.iserror}
							text='Place of Signature'
							isReq='false'
							value={props.userData?.bssFormDetails?.placeOfSignature}
							onChangeAction='placeOfSignature'
							{...props}
						/>

						<InputDate
							showError='true'
							iserror={props.iserror}
							text='Date of Signature'
							isReq='false'
							value={props.userData?.bssFormDetails?.dateOfSignature}
							onChangeAction='dateOfSignature'
							{...props}
						/>
					</div>
					{/* --------------- */}
				</div>
			</div>
		</>
	);
}

export default BSSFormView;
