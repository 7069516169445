import React from 'react';
import { Tab, Nav } from 'react-bootstrap';

import CouserLoader from './loader';
import './style.css';

function HistoryFavView(props) {
    return (
        <>
            <div className='historyFavriouteContainer'>
                {props.isLoading ?
                    (
                        <>
                            <CouserLoader />
                        </>
                    ) : (
                        <>
                            <Tab.Container defaultActiveKey='historyBsTabContainer'>
                                <Nav variant='tabs' className='historyFavriouteNavContainer'>
                                    <Nav.Item className='col-6 col-sm-3 col-lg-2'>
                                        <Nav.Link eventKey='historyBsTabContainer'>History</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className='col-6 col-sm-3 col-lg-2'>
                                        <Nav.Link eventKey='favouriteBsTabContainer'>Favourites</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey='historyBsTabContainer'>
                                        <div
                                            className={
                                                props.historyVideos && props.historyVideos.data && props.historyVideos.data.length > 0
                                                    ? 'historyFavouriteMaincontainer'
                                                    : 'historyFavouriteMaincontainer historyFavouriteMaincontainerEmpty'
                                            }>
                                            {props.historyVideos &&
                                                props.historyVideos.success &&
                                                props.historyVideos.data &&
                                                props.historyVideos.data.length > 0 ? (
                                                <>
                                                    {props.historyVideos.data.map((videoData, videoDataIndex) => (
                                                        <React.Fragment key={videoDataIndex}>
                                                            <h5 style={{ marginBottom: '18px' }}>{videoData.date}</h5>
                                                            {videoData.videos.map((video, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div
                                                                        onClick={(event) => {
                                                                            props.handleVideoRedirect(event, video._id);
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                        className='row'
                                                                        key={index}>
                                                                        <div className='col-6 col-sm-5 col-md-3 col-lg-3 shineOnCourseCard'>
                                                                            <img
                                                                                className='w-100'
                                                                                src={video.thumbnailUrl}
                                                                                alt='video'
                                                                            />
                                                                        </div>
                                                                        <div className='col-6 col-sm-7 col-md-8 historyTextcontainer'>
                                                                            <p className='videoTitle'>{video.title}</p>
                                                                            <p className='videoCategoty'>{video.description}</p>
                                                                            <p className='views'>{video.viewsCount} Views</p>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            ) : (
                                                <div className='emptyContainer'>
                                                    <p>Nothing to see here...</p>
                                                </div>
                                            )}
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey='favouriteBsTabContainer'>
                                        <div
                                            className={
                                                props.favoriteVideos && props.favoriteVideos.data && props.favoriteVideos.data.length > 0
                                                    ? 'historyFavouriteMaincontainer'
                                                    : 'historyFavouriteMaincontainer historyFavouriteMaincontainerEmpty'
                                            }>
                                            {props.favoriteVideos &&
                                                props.favoriteVideos.success &&
                                                props.favoriteVideos.data &&
                                                props.favoriteVideos.data.length > 0 ? (
                                                <>
                                                    {props.favoriteVideos.data.map((videoData, videoDataIndex) => (
                                                        <React.Fragment key={videoDataIndex}>
                                                            <h5 style={{ marginBottom: '18px' }}>{videoData.date}</h5>
                                                            {videoData.videos.map((video, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div className='row' key={index}>
                                                                        <div
                                                                            onClick={(event) => {
                                                                                props.handleVideoRedirect(event, video._id);
                                                                            }}
                                                                            className='col-6 col-sm-5 col-md-3 col-lg-3 shineOnCourseCard'>
                                                                            <img
                                                                                className='w-100'
                                                                                src={video.thumbnailUrl}
                                                                                alt='video'
                                                                            />
                                                                        </div>
                                                                        <div className='col-6 col-sm-7 col-md-8 historyTextcontainer'>
                                                                            <p className='videoTitle'>{video.title}</p>
                                                                            <p className='videoCategoty'>{video.description} </p>
                                                                            <p className='views'>{video.viewsCount} Views</p>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            ) : (
                                                <div className='emptyContainer'>
                                                    <p>Nothing to see here...</p>
                                                </div>
                                            )}
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </>
                    )
                }
            </div>

        </>
    );
}

export default HistoryFavView;
