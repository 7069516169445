import React from 'react';

import './courseDetails.css';
import VideoPlayer from '@components/VideoPlayer';
import VideoPlaylist from '@components/VideoPlaylist';
import VideoDiscriptionSection from '@components/VideoDiscriptionSection';
import UserComment from '@components/UserComment';
import VideoComments from '@components/VideoComments';
import VideoSharePopup from '@components/VideoSharePopup';

import CourseLoader from '@components/CourseDetails/CourseLoader';
import CommentLoader from '@components/CourseDetails/CourseLoader/CommentLoader';
import VideoPlayerLoader from '@components/CourseDetails/CourseLoader/VideoPlayerLoader';

import VideoLoader from '@components/CourseDetails/VideoLoader';

function SeriesDetailsView(props) {
	return (
		<>
			{props.isLoading && (
				<>
					<center>
						{props.currentContentType && props.currentContentType === 's' && (
							<>
								{props.isVideoPlayerLoading ?
									(
										<>
											<VideoPlayerLoader />
										</>
									) : (
										<>
											<CourseLoader />
										</>
									)
								}
							</>
						)}

						{props.currentContentType && props.currentContentType === 'v' && (
							<>
								<VideoLoader />
							</>
						)}
					</center>
				</>
			)}

			<center>
				<div
					className='mainCourseDetailsPageContainer'
					id='mainCourseDetailsPageContainer'>
					{props.seriesDetails.success && props.seriesDetails.data && (
						<>
							<div className='row' id='r1'>
								<div
									id='videoPlayerDiscriptionContainer'
									className={`col-12 helpLoaderForVideoPlayer ${props.currentContentType === 's' ? 'col-lg-8' : 'col-lg-12'
										}`}>
									<VideoPlayer
										videoId={props.currentVideoData.videoId}
										IsVideoPlayerRenderend={props.IsVideoPlayerRenderend}
										handleVideoIdChanged={props.handleVideoIdChanged}
										updateVideoViewCount={props.updateVideoViewCount}
										updateWatchedVideoCount={props.updateWatchedVideoCount}
										sendVideoDuration={props.getVideoDuration}
										handleNextVideo={props.handleNextVideo}
									/>

									<VideoDiscriptionSection />
								</div>
							</div>
							{/* START : Sticky Playlist */}
							<div className='row' id='r2'>
								<div
									className='col-12 col-lg-4 playListMainContainer helpLoaderForVideoPlayer'
									id='playListMainContainer'
									>
									<VideoPlaylist
										onValueChange={props.handleVideoReRender}
										resetViewCount={props.resetViewCount}
										resetWatchedVideoCount={props.resetWatchedVideoCount}
										videoId={props.currentVideoData.videoId}
									/>
								</div>
								{/* End : Sticky Playlist */}

								{/* START fixed Playlist */}
								<div
									className='col-12 col-lg-4 playListMainContainer helpLoaderForVideoPlayer'
									id='playListMainContainerFIXED'
									>
									<VideoPlaylist
										onValueChange={props.handleVideoReRender}
										resetViewCount={props.resetViewCount}
										resetWatchedVideoCount={props.resetWatchedVideoCount}
										videoId={props.currentVideoData.videoId}
									/>
								</div>
								{/* End fixed Playlist */}
							</div>

							{!props.isCommentsLoading &&
								props.currentVideoData.commentsStatus !== 'disabled' && (
									<div className='row' id="outsideCommentsContainer">
										<div
											className='col-12 col-lg-8'
											style={{ margin: '30px 0px 20px 0px' }}>
											<UserComment
												sendVideoDuration={props.sendVideoDuration}
											/>
											<VideoComments
												videoDuration={props.sendVideoDuration} />
										</div>
									</div>
								)}
						</>
					)}
				</div>

				<VideoSharePopup />
			</center>

			{props.isCommentsLoading && (
				<>
					<center>
						{props.currentContentType && props.currentContentType === 's' && (
							<>
								<CommentLoader />
							</>
						)}
					</center>
				</>
			)}


		</>
	);
}
export default SeriesDetailsView;
