import React from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import moment from 'moment';

function InputDate(props) {
	const formatedDate = moment(props.value).format('YYYY-MM-DD');

	return (
		<>
			<div
				className={`inputContainer_x ${
					props.fullWidth && props.fullWidth === 'true'
						? 'col-12'
						: 'col-12 col-sm-6'
				}`}>
				<p className='inputLable d-flex'>
					{props.text}
					{props.isReq === 'true' && <b className='asterisk'>*</b>}

				</p>
				<div>
					<div style={{position:'absolute'}}>
						{props.isReq === 'true' &&
								props.showError &&
								props.showError === 'true' &&
								props.iserror &&
								(!props.value || props.value === '' || props.value === null) && (
									<Tags
										span_font_size='10px'
										img_margin='5px'
										bg='#FFE9E9'
										c='#962020'
										bc='#A62323'
										text='Input Required'
										imgs={InputError}
									/>
								)}
					</div>
					<input
						type='date'
						value={formatedDate}
						onChange={(event) => {
							props.handleInputChange(event, props.onChangeAction);
						}}
				/></div>
			</div>
		</>
	);
}

export default InputDate;
