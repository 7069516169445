export const countries = {
	DEVELOPING_ASIA: [
		'India',
		'Pakistan',
		'Bangladesh',
		'Iran',
		'Myanmar',
		'Iraq',
		'Afghanistan',
		'Uzbekistan',
		'Yemen',
		'Nepal',
		'Sri Lanka',
		'Kazakhstan',
		'Syria',
		'Cambodia',
		'Jordan',
		'Tajikistan',
		'Laos',
		'Lebanon',
		'Kyrgyzstan',
		'Turkmenistan',
		'State of Palestine',
		'Bhutan',
	],
	REST_OF_ASIA: [
		'China',
		'Indonesia',
		'Japan',
		'Philippines',
		'Vietnam',
		'Turkey',
		'Thailand',
		'South Korea',
		'Saudi Arabia',
		'Malaysia',
		'North Korea',
		'United Arab',
		'Emirates',
		'Israel',
		'Singapore',
		'Oman',
		'Kuwait',
		'Georgia',
		'Mongolia',
		'Qatar',
		'Bahrain',
		'Timor-Leste',
		'Cyprus',
		'Maldives',
		'Brunei',
	],
	AFRICA: [
		'Algeria',
		'Angola',
		'Benin',
		'Botswana',
		'Burkina Faso',
		'Burundi',
		'Cabo Verde',
		'Cameroon',
		'Central African Republic',
		'Chad',
		'Comoros',
		'Congo, Democratic Republic of the',
		'Congo, Republic of the',
		"Cote d'Ivoire",
		'Djibouti',
		'Egypt',
		'Equatorial Guinea',
		'Eritrea',
		'Eswatini (formerly Swaziland)',
		'Ethiopia',
		'Gabon',
		'Gambia',
		'Ghana',
		'Guinea',
		'Guinea-Bissau',
		'Kenya',
		'Lesotho',
		'Liberia',
		'Libya',
		'Madagascar',
		'Malawi',
		'Mali',
		'Mauritania',
		'Mauritius',
		'Morocco',
		'Mozambique',
		'Namibia',
		'Niger',
		'Nigeria',
		'Rwanda',
		'Sao Tome and Principe',
		'Senegal',
		'Seychelles',
		'Sierra Leone',
		'Somalia',
		'South Africa',
		'South Sudan',
		'Sudan',
		'Tanzania',
		'Togo',
		'Tunisia',
		'Uganda',
		'Zambia',
		'Zimbabwe',
	],
	EUROPE: [
		'Albania',
		'Andorra',
		'Armenia',
		'Austria',
		'Azerbaijan',
		'Belarus',
		'Belgium',
		'Bosnia and Herzegovina',
		'Bulgaria',
		'Croatia',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Estonia',
		'Finland',
		'France',
		'Georgia',
		'Germany',
		'Greece',
		'Hungary',
		'Iceland',
		'Ireland',
		'Italy',
		'Kazakhstan',
		'Kosovo',
		'Latvia',
		'Liechtenstein',
		'Lithuania',
		'Luxembourg',
		'Malta',
		'Moldova',
		'Monaco',
		'Montenegro',
		'Netherlands',
		'North Macedonia (formerly Macedonia)',
		'Norway',
		'Poland',
		'Portugal',
		'Romania',
		'Russia',
		'San Marino',
		'Serbia',
		'Slovakia',
		'Slovenia',
		'Spain',
		'Sweden',
		'Switzerland',
		'Turkey',
		'Ukraine',
		'United Kingdom',
		'Vatican City (Holy See)',
	],
	RUSSIA: [
		'Republic of Adygea',
		'Republic of Altai (Altai Republic)',
		'Republic of Bashkortostan',
		'Republic of Buryatia',
		'Chechen Republic (Chechnya)',
		'Chuvash Republic (Chuvashia)',
		'Republic of Crimea (annexed from Ukraine in 2014, not internationally recognized)',
		'Republic of Dagestan',
		'Republic of Ingushetia',
		'Kabardino-Balkar Republic',
		'Republic of Kalmykia',
		'Karachay-Cherkess Republic',
		'Republic of Karelia',
		'Komi Republic',
		'Mari El Republic',
		'Republic of Mordovia',
		'Republic of North Ossetia-Alania',
		'Republic of Sakha (Yakutia)',
		'Republic of Tatarstan',
		'Republic of Tyva (Tuva)',
		'Udmurt Republic',
		'Republic of Crimea (annexed from Ukraine in 2014, not internationally recognized)',
		'Amur Oblast',
		'Arkhangelsk Oblast',
		'Astrakhan Oblast',
		'Belgorod Oblast',
		'Bryansk Oblast',
		'Chelyabinsk Oblast',
		'Irkutsk Oblast',
		'Ivanovo Oblast',
		'Kaliningrad Oblast',
		'Kaluga Oblast',
		'Kamchatka Krai',
		'Kemerovo Oblast',
		'Kirov Oblast',
		'Kostroma Oblast',
		'Kurgan Oblast',
		'Kursk Oblast',
		'Leningrad Oblast',
		'Lipetsk Oblast',
		'Magadan Oblast',
		'Moscow Oblast',
		'Murmansk Oblast',
		'Nizhny Novgorod Oblast',
		'Novgorod Oblast',
		'Novosibirsk Oblast',
		'Omsk Oblast',
		'Orenburg Oblast',
		'Oryol Oblast',
		'Penza Oblast',
		'Perm Krai',
		'Primorsky Krai',
		'Pskov Oblast',
		'Rostov Oblast',
		'Ryazan Oblast',
		'Sakhalin Oblast',
		'Samara Oblast',
		'Saratov Oblast',
		'Smolensk Oblast',
		'Stavropol Krai',
		'Sverdlovsk Oblast',
		'Tambov Oblast',
		'Tomsk Oblast',
		'Tula Oblast',
		'Tver Oblast',
		'Tyumen Oblast',
		'Ulyanovsk Oblast',
		'Vladimir Oblast',
		'Volgograd Oblast',
		'Vologda Oblast',
		'Voronezh Oblast',
		'Yamalo-Nenets Autonomous Okrug',
		'Yaroslavl Oblast',
		'Moscow (Federal city)',
		'Saint Petersburg (Federal city)',
		'Jewish Autonomous Oblast',
		'Chukotka Autonomous Okrug',
		'Khanty-Mansi Autonomous Okrug - Yugra',
		'Nenets Autonomous Okrug',
		'Yam',
	],
	AUSTRALIA: [
		'Christmas Island',
		'Cocos (Keeling) Islands',
		'Norfolk Island',
		'Ashmore and Cartier Islands (uninhabited)',
		'Coral Sea Islands Territory (uninhabited)',
		'Heard Island and McDonald Islands (uninhabited)',
		'Australian Antarctic Territory (uninhabited)',
	],
	NORTH_AMERICA: [
		'Antigua and Barbuda',
		'Bahamas',
		'Barbados',
		'Belize',
		'Canada',
		'Costa Rica',
		'Cuba',
		'Dominica',
		'Dominican Republic',
		'El Salvador',
		'Grenada',
		'Guatemala',
		'Haiti',
		'Honduras',
		'Jamaica',
		'Mexico',
		'Nicaragua',
		'Panama',
		'Saint Kitts and Nevis',
		'Saint Lucia',
		'Saint Vincent and the Grenadines',
		'Trinidad and Tobago',
		'United States of America',
	],
	SOUTH_AMERICA: [
		'Argentina',
		'Bolivia',
		'Brazil',
		'Chile',
		'Colombia',
		'Ecuador',
		'Guyana',
		'Paraguay',
		'Peru',
		'Suriname',
		'Uruguay',
		'Venezuela',
	],
};
