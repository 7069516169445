import React from "react";

import LoadingBar from 'react-top-loading-bar'
import './topLoadingBar.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';

function TopLoadingBar(props){
    return(
        <>
            <LoadingBar 
                // color="#e41e23" 
                height="4px"
                className="loading-bar"
                progress={props.loadingBarProgress} 
                onLoaderFinished={() => props.updateLoadingBarProgress(props.loadingBarProgress)} />
        </>
    )
}

function mapStateToProps(state) {
	return {
		loadingBarProgress: state.topLoadingBar.loadingBarProgress,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateLoadingBarProgress: TopLoadingBarActions.updateLoadingBarProgress,
		},
		dispatch
	);
}
export default connect(mapStateToProps, mapDispatchToProps)(TopLoadingBar);
