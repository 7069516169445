import React, { useState } from 'react';

import PasswordView from './view';

import { decryptText, encryptText } from '@library/enc-dec';
import * as LoginServices from '@services/Login';

function Password(props) {
	const [showError, setShowError] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [poorPassword, setPoorPassword] = useState(false);
	const [weakPassword, setWeakPassword] = useState(false);
	const [strongPassword, setStrongPassword] = useState(false);
	const [passwordError, setPasswordErr] = useState('');

	const handlePasswordChange = (from, event) => {
		try {
			setShowError('');
			let currentEle = null;
			let val = event.target.value;
			if (from === 'password') {
				setPassword(val);
				//handlePaswordValidation(val);
				currentEle = document.getElementById('passwordInput');
			} else {
				setConfirmPassword(val);
				currentEle = document.getElementById('confirmPasswordInput');
			}

			if (currentEle) {
				currentEle.style.borderColor = val.length > 0 ? '#1A73E8' : '#8C8C8C';
			}
		} catch (err) {
			console.log('Error coming from handlePasswordChange()', err);
		}
	};

	// code for old passwrd
	const handlePaswordValidation = (value) => {
		try {
			const passwordValue = value;
			const passwordLength = passwordValue.length;
			const poorRegExp = /[a-z]/;
			const weakRegExp = /(?=.*?[0-9])/;
			const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
			const whitespaceRegExp = /^$|\s+/;
			const poorPassword = poorRegExp.test(passwordValue);
			const weakPassword = weakRegExp.test(passwordValue);
			const strongPassword = strongRegExp.test(passwordValue);
			const whiteSpace = whitespaceRegExp.test(passwordValue);

			if (passwordValue === '') {
				setPasswordErr('Password is Empty');
			} else {
				// to check whitespace
				if (whiteSpace) {
					setPasswordErr('Whitespaces are not allowed');
				}
				// to check poor password
				if (
					passwordLength <= 3 &&
					(poorPassword || weakPassword || strongPassword)
				) {
					setPoorPassword(true);
					setPasswordErr('Password is poor');
				}
				// to check weak password
				if (
					passwordLength >= 4 &&
					poorPassword &&
					(weakPassword || strongPassword)
				) {
					setWeakPassword(true);
					setPasswordErr('Password is weak');
				} else {
					setWeakPassword(false);
				}
				// to check strong Password
				if (passwordLength >= 6 && poorPassword && weakPassword && strongPassword) {
					setStrongPassword(true);
					setPasswordErr('Password is strong');
				} else {
					setStrongPassword(false);
				}
			}
		} catch (err) {
			console.log('Error coming from handlePaswordValidation()', err);
		}
	};

	const handleShowPassword = (event) => {
		try {
			let passwordInput = document.getElementById('passwordInput');
			let confirmPasswordInput = document.getElementById('confirmPasswordInput');
			if (passwordInput && confirmPasswordInput) {
				if (event.target.checked) {
					passwordInput.type = 'text';
					confirmPasswordInput.type = 'text';
				} else {
					passwordInput.type = 'password';
					confirmPasswordInput.type = 'password';
				}
			}
		} catch (err) {
			console.log('Error coming from handleShowPassword()', err);
		}
	};

	const handlePasswordSubmit = async () => {
		try {
			if (password && confirmPassword) {
				if(password === confirmPassword){
					let authToken = decryptText(localStorage.getItem('eMediat'));
					const response = await LoginServices.updateUserInitialDetails(
						{ password: encryptText(password) },
						authToken
					);

					if (response.success) {
						setShowError('');
						props.handleViewAfterLogin('password', response.data);
						return;
					}
				}
				else{
					setShowError('Confrim password is matching with password');
				}

				// Code for strong passowrd
				// if (strongPassword) {
				// 	if (password !== confirmPassword) {
				// 		setShowError('Confrim password is matching with password');
				// 		return;
				// 	}

				// 	let authToken = decryptText(localStorage.getItem('eMediat'));
				// 	const response = await LoginServices.updateUserInitialDetails(
				// 		{ password: encryptText(password) },
				// 		authToken
				// 	);

				// 	if (response.success) {
				// 		setShowError('');
				// 		props.handleViewAfterLogin('password', response.data);
				// 		return;
				// 	}
				// 	setShowError(response.messsage);
				// 	return;
				// }
				// setShowError('Make sure your password is strong');
				// return;
			}
			else{
				setShowError('Input Required');
			}
			return;
		} catch (err) {
			console.log('Error coming from handlePasswordSubmit()', err);
		}
	};

	return (
		<PasswordView
			poorPassword={poorPassword}
			weakPassword={weakPassword}
			strongPassword={strongPassword}
			passwordError={passwordError}
			handlePasswordChange={handlePasswordChange}
			handleShowPassword={handleShowPassword}
			handlePasswordSubmit={handlePasswordSubmit}
			showError={showError}
		/>
	);
}

export default Password;
