export const addToCart = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addToCart`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => getCartItems services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getCartItems = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getCartItems`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => getCartItems services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const removeCartItem = async (itemId, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/removeCartItem`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({
				itemId: itemId,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => getCartItems services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const clearCart = async (courseIds, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/clearCart`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({
				courseIds: courseIds,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Checkout => clearCart services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getCartReleatedCourses = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCartReleatedCourses`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from cart => getCartReleatedCourses services',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getCartItemByCourseId = async (courseId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCartItemByCourseId/${courseId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => getCartItems services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getCartItemsCount = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getCartItemsCount`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => getCartItemCount services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const verifyCoupon = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/verifyCoupon`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => verifyCoupon services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};





export const removeCartItemsInBulk = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/removeCartItemsInBulk`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => getCartItems services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


export const addToCartInBulk = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addToCartInBulk`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from cart => getCartItems services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

