export const content={
    "bannerSection" : {
        "description" : "Unlock your potential with eMediSkill's online medical courses"
    },
    "section1" : {
        "title1" : "Starting a new learning...",
        "description1" : "Every successful journey begins with a destination in mind.",
        "title2" : "Why eMediskill ?",
        "description2" : "Our platform is designed for convenience and accessibility, allowing you to study at your own pace, anytime and anywhere."
    },
    "section2" : {
        "titles" : [
            "Medicine?",
            "Anatomy?",
            "Pharmacology?",
            "Biochemistry?",
            "Physiology?",
        ],
        "description" : "Learning Online Medical Courses with eMediSkill Services is a simple and enriching process. Our user-friendly platform offers a wide array of expert-led courses designed to enhance your medical knowledge. With experienced faculty and flexible learning options, you can seamlessly advance your career from the comfort of your own space.",
    },
    "offers" : {
        "offerDescription" : "Explore eMediSkill's advantages: bundle discounts, early bird benefits, and limited-time access. Your enriched learning journey begins here. With expert-led courses and flexible online education, achieve your medical career goals efficiently.",
    },
    "startLearningSection" : {
        "title" : "Start learning with eMediskill",
        "description" : "Embark on your learning journey with eMediSkill. Dive into expert-led medical courses for comprehensive knowledge enhancement. Shape a successful medical career with us. Start today to unlock your full potential."
    },
    "testimonySection" : {
        "title" : "Hear What Our Students Say",
        "description" : "Happy Students, Thriving Careers: Our Online Medical Courses Bring Success and Satisfaction!",
        "testimonials" : [
            {
                "testimonial_Name" : "Dr. Rashmi Jain",
                "testimonial_Profession" : "(Master Diploma in Critical Care) BHMS, Madhya Pradesh",
                "testimony" : "A very interesting and valuable experience. I learnt a lot and it gave me a good grounding inthe basics. It really made me think, and I liked how it enabled me to interact with people through the practical work."    
            },    
            {
                "testimonial_Name" : "Dr. Aisha Siddiqa",
                "testimonial_Profession" : "BHMS, CGO, CCH & CSVD",
                "testimony" : "You prepare people to carry out skills in the real world. Keep up the good work. What I have learned in Advanced Diploma in Critical Care, I will be able to apply in the real world."    
            }, 

            {}, //left empty for a purpose

            {
                "testimonial_Name" : "Dr Pankaj R. Borkar",
                "testimonial_Profession" : "(CCH, CSVD) BAMS, PGDEMS (Symbiosis, Pune)",
                "testimony" : "It was a really valuable and enjoyable course, with a very engaging and enthusiastic teacher, which helped keep the energy going."    
            },    

            {}, //left empty for a purpose

            {
                "testimonial_Name" : "Dr. Dipali Relan",
                "testimonial_Profession" : "(Advanced Diploma in Critical care) BHMS, Dhule, Maharashtra",
                "testimony" : "I feel that over the duration of the courseI gained a lot of knowledge and practicalstrategies that will be beneficial to me, and those I deal with personally and professionally. The course exceeded my expectations significantly."    
            },    
            {
                "testimonial_Name" : "Akanksha Mane",
                "testimonial_Profession" : "BAMS, CGO, CCH & CSVD",
                "testimony" : "One of the best platform for all the medical student. Had amazing learning experience. Lecture were too precise and covering all the topic. Smart presentation and good explanation from doctor. Very easy to use and handy platform. Thank You."    
            },    
            {
                "testimonial_Name" : "Stafina Correns",
                "testimonial_Profession" : "BHMS, CGO & CCH",
                "testimony" : "It was a wonderful experience learning with eMediSkill. I was enrolled for CGO, CCH, and CSVD course and I really would suggest people to go forward with the courses as they are very informative and have a lot to teach you as a medico."    
            },    
            {
                "testimonial_Name" : "Dr. Melapati Laxmi",
                "testimonial_Profession" : "(Advanced Diploma in Critical Care) BHMS, Kadapa, A.P)",
                "testimony" : "You prepare people to carry out skills in the real world. Keep up the good work. What I have learned in Advanced Diploma in Critical Care, I will be able to apply in the real world."    
            },    
        ],
    }
}

export default content;
