import React, { useEffect } from 'react';
import './mobileViewFunctions.css';

let okPress = false;
let qtyDropDown = '';
let speedDropDown = '';
let captionDropDown = '';

export const MobileQtySpeedCCUiContainer = (props) => {

    useEffect(() => {
        var modalElement;
        if (document.getElementById('MobileQtySpeedCCUiContainer')) {
            modalElement = document.getElementById('MobileQtySpeedCCUiContainer');
            modalElement.addEventListener('hidden.bs.modal', handleModalClosed);
        }

        return () => {
            if (modalElement) {
                modalElement.addEventListener('hidden.bs.modal', handleModalClosed);
            }
        };
    }, [])


    const handleModalClosed = () => {
        if (okPress) {
            okPress = false
        }
        else {
            if (document.getElementById('qtyDropDown') && document.getElementById('speedDropDown')) {
                if (qtyDropDown !== '' && speedDropDown !== '') {
                    document.getElementById('qtyDropDown').value = qtyDropDown
                    document.getElementById('speedDropDown').value = speedDropDown
                }
                else{
                    document.getElementById('qtyDropDown').value = 'auto'
                    document.getElementById('speedDropDown').value = '1'
                }
            }

            if (document.getElementById('captionDropDown')) {
                if(captionDropDown !== ''){
                    document.getElementById('captionDropDown').value = captionDropDown
                }
                else{
                    document.getElementById('captionDropDown').value = 'off'
                }
            }
        }
    }

    const handlOk = () => {
        okPress = true;

        if (document.getElementById('qtyDropDown') && document.getElementById('speedDropDown')) {
            qtyDropDown = document.getElementById('qtyDropDown').value
            speedDropDown = document.getElementById('speedDropDown').value
        }

        if (document.getElementById('captionDropDown')) {
            captionDropDown = document.getElementById('captionDropDown').value
        }

        props.onValueChange({
            qty: qtyDropDown,
            speed: speedDropDown,
            caption: captionDropDown,
        })

    }

    return (
        <>
            <button
                style={{ display: 'none' }}
                id="MobileQtySpeedCCUiContainer_OpenBtn"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#MobileQtySpeedCCUiContainer">
                Three dots
            </button>


            <div className="modal fade mobileQtySpeedCCUiContainer" id="MobileQtySpeedCCUiContainer">
                <div
                    style={{ marginTop: '25%' }}
                    className="modal-dialog">
                    <div className="modal-content" style={{
                         position: 'fixed',
                         width:'60%',
                         top: '50%',
                         left: '50%',
                         transform: 'translate( -50%, -50%)'
                    }}>
                        <div
                            style={{ paddingBottom: '10px', borderBottom: '0px' }}
                            className="modal-header">
                            <h5 className="modal-title">Video Settings</h5>
                            <button
                                id="MobileQtySpeedCCUiContainer_CloseBtn"
                                style={{ visibility: 'hidden' }} className="close">
                                <span>&times;</span>
                            </button>
                        </div>
                        <div
                            style={{
                                paddingTop: '0px',
                                paddingBottom: '0px'
                            }}
                            className="modal-body">

                            <label>Quality</label>
                            <select id="qtyDropDown" className="form-select">
                                <option selected value="auto">Auto</option>
                                <option value="1080p">1080p</option>
                                <option value="720p">720p</option>
                                <option value="540p">540p</option>
                                <option value="360p">360p</option>
                                <option value="240p">240p</option>
                            </select>


                            <label style={{ marginTop: '15px' }}>Speed</label>
                            <select id="speedDropDown" className="form-select">
                                <option value="0.25">0.25</option>
                                <option value="0.5">0.5</option>
                                <option value="0.75">0.75</option>
                                <option selected value="1">1</option>
                                <option value="1.25">1.25</option>
                                <option value="1.5">1.5</option>
                                <option value="1.75">1.75</option>
                                <option value="2">2</option>
                            </select>


                            {props.isHasCaptions &&
                                <>
                                    <label style={{ marginTop: '15px' }}>Caption</label>
                                    <select id="captionDropDown" className="form-select">
                                        <option 
                                            value="off">Off</option>
                                        <option 
                                            value="on">On</option>
                                    </select>
                                </>
                            }


                        </div>
                        <div
                            style={{ borderTop: '0px' }}
                            className="modal-footer">
                            <button
                                onClick={handlOk}
                                data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

