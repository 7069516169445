export const getTermAndCondition = async (termAndConditionId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getTermAndCondition/${termAndConditionId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Terms and Condition services : Get Term and Condition by Id',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addTermsAndConditions = async (termsAndConditions) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/addTermsAndConditions/`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					
				},
                body: JSON.stringify(termsAndConditions),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from terms and conditions  services : Adding Terms and Conditions',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


