import React from "react";

import ExamLoader from "./ExamLoader/examLoader";

import "./exam.css";
import RightTick from "@assets/exams/rightTick.png";
import PreviousBtn from "@assets/exams/previousBtn.svg";
import NextBtn from "@assets/exams/nextBtn.png";
import TimerIcon from "@assets/exams/timer.svg";

import { Link } from "react-router-dom";

import Progress from "./progress";

function ExamView(props) {
  return (
    <>
      <center>
        {props.isLoading ? (
          <>
            <div className="examPageMainContainer">
              <ExamLoader />
            </div>
          </>
        ) : (
          <>
            <div className="examPageBgHolder" id="examPageBgHolder">
              {!props.isExamAttempted ? (
                // If Exam Not Attemped
                <>
                  <div className="examPageMainContainer">
                    {props.questionBank ? (
                      <>
                        <>
                          {props.isExamStarted && (
                            <div className="row examDurationContainer">
                              <div className="col-md-6 col-12 strikeContainer order-md-1 order-2">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    STRIKES ALLOWED:
                                  </span>
                                  <Progress
                                    windowBlurFlag={
                                      props.windowBlurFlag < 4
                                        ? props.windowBlurFlag
                                        : 4
                                    }
                                  />
                                </div>
                                <div className="examStrikeNoteContainer">
                                  <span>
                                    On the fourth strike exam will automatically
                                    get submitted
                                    <span style={{ color: "#FF7A00" }}>*</span>
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6 col-12 durationContainer order-md-2 order-1">
                                <span style={{ fontSize: "12px" }}>
                                  TIME REMAINING:
                                </span>

                                <span className="duration">
                                  {props.displayExamTimer}
                                </span>
                              </div>
                            </div>
                          )}

                          <div
                            style={{
                              marginBottom:
                                !props.displayExamTimer &&
                                props.isExamStarted &&
                                "30px",
                            }}
                            className="examPageHeadingContainer"
                          >
                            <h3>{props.questionBank?.title}</h3>
                            <hr style={{ margin: "15px 0px 10px 0px" }} />
                            <>
                              <div className="pointers">
                                <div
                                  className="courseContentDescriptionContainerNon"
                                  id="courseContentDescriptionContainer"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      props.handleRichTextEditorCourseDiscription(
                                        props.questionBank?.instructions
                                          .pointer,
                                        props.questionBank?.instructions.icon,
                                        ""
                                      ),
                                  }}
                                />
                              </div>
                            </>
                          </div>

                          {!props.isExamStarted && (
                            <div id="startExamBtnContainer">
                              <button
                                onClick={props.handleStartExam}
                                id="startExamBtn"
                              >
                                Start Exam
                              </button>
                            </div>
                          )}

                          <br />
                          {props.isExamStarted &&
                            props.allQuestions.map(
                              (questions, questionIndex) =>
                                questionIndex >= props.pageNumber * 10 - 10 &&
                                questionIndex < props.pageNumber * 10 && (
                                  <>
                                    <div
                                      className="questionContainer"
                                      key={questionIndex}
                                    >
                                      <p className="question">
                                        {questions.question}
                                      </p>
                                      {questions.thumbnailUrl && (
                                        <center>
                                          <img
                                            className="questionsThumbnailUrl"
                                            src={questions.thumbnailUrl}
                                            alt="thumbnailUrl"
                                          />
                                        </center>
                                      )}
                                      {questions.options.map(
                                        (option, optionIndex) => (
                                          <div
                                            className="form-check option"
                                            key={optionIndex}
                                          >
                                            <input
                                              onChange={(event) => {
                                                props.handleSelectedOption(
                                                  event,
                                                  questions._id
                                                );
                                              }}
                                              className="form-check-input"
                                              type="radio"
                                              value={option}
                                              name={"radio_" + questionIndex}
                                              id={
                                                questionIndex +
                                                "_" +
                                                optionIndex
                                              }
                                              checked={
                                                questions.selectedOption ===
                                                option
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={
                                                questionIndex +
                                                "_" +
                                                optionIndex
                                              }
                                            >
                                              {option}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )
                            )}

                          {props.isExamStarted && (
                            <div className="pageNavigationBtn">
                              <img
                                style={{
                                  visibility: props.isPreviousBtnVisible
                                    ? "visible"
                                    : "hidden",
                                }}
                                onClick={props.handlePreviousBtn}
                                src={PreviousBtn}
                                alt="PreviousBtn"
                              />

                              {props.isNextBtnVisible ? (
                                <>
                                  <img
                                    onClick={props.handleNextBtn}
                                    src={NextBtn}
                                    alt="NextBtn"
                                  />
                                </>
                              ) : (
                                <>
                                  <div className="submitExamContainer">
                                    <p>Please attempt all the questions*</p>
                                    <button onClick={props.handleSubmit}>
                                      Submit
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                // If Exam Attemped
                <>
                  <div className="examPageMainContainer">
                    {props.questionBank ? (
                      <>
                        <>
                          <div className="examPageHeadingContainer">
                            <h3>{props.questionBank?.title}</h3>
                            <hr style={{ margin: "15px 0px 10px 0px" }} />

                            <div style={{ display: "flex" }}>
                              <div style={{ marginRight: "15px" }}>
                                <img
                                  style={{ width: "22px" }}
                                  src={TimerIcon}
                                  alt="TimerIcon"
                                />
                              </div>

                              <div>
                                <p style={{ marginBottom: "5px" }}>
                                  Exam Submitted. Result will update
                                  automatically in{" "}
                                  {props.questionBank?.declarationResultInDays}{" "}
                                  days
                                </p>
                                <p style={{ color: "#717171" }}>
                                  <Link
                                    style={{ color: "#717171" }}
                                    to="/explore"
                                  >
                                    Click here{" "}
                                  </Link>
                                  to go to My learning
                                </p>
                              </div>
                            </div>

                            {/* <p>Exam Submitted. Result will update automatically in {props.daysRemainingResults} days</p>
                                                        <p style={{ color: '#717171' }}>
                                                            <Link style={{ color: '#717171' }} to="/explore" >Click here </Link>
                                                            to go to My learning
                                                        </p> */}
                          </div>
                        </>
                      </>
                    ) : null}
                  </div>
                  <div id="examSubmittedEmptyContainer"></div>
                </>
              )}
            </div>
          </>
        )}
      </center>
    </>
  );
}

export default ExamView;
