import React, { useState, useEffect } from 'react';
import OfferView from './view';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import {
	updateCurrencyValue,
	handlePaidCourse,
} from '@library/helpers';
import { decryptText, encryptText } from '@library/enc-dec';

import * as CartServices from '@services/Cart';
import * as CartActions from '@redux/actions/Cart';

function Offer(props) {
	const dispatch = useDispatch();
	const [actualPrice, setActualPrice] = useState('0');
	const [actualPriceInNumbers, setActualPriceInNumbers] = useState(0);
	const [discountedPrice, setDiscountedPrice] = useState(0);
	const [discountedPriceInNumbers, setDiscountedPriceInNumbers] = useState('0');

	const [currentIndex, setCurrentIndex] = useState(-1);
	const [isDiscountSelected, setIsDiscountSelected] = useState(false);
	const [selectedOffer, setSelectedOffer] = useState(null);

	const [currentDiscountedPercentage, setCurrentDiscountedPercentage] =
		useState(0);

	const [currentRelatedCourses, setCurrentRelatedCourses] = useState([]);

	const [selectedCourses, setSelectedCourses] = useState([]);

	const [currentView, setCurrentView] = useState('init');
	const cartItems = useSelector((state) => state.cart.cartItems);
	const userSpecificOfferDetails = useSelector((state) => state.userSpecificOffer.userSpecificOfferDetails);

	useEffect(() => {
		// Handling the single cart item intially
		setToActualPrice();
	}, []);

	const setToActualPrice = () => {
		try {
			if (cartItems.length === 1) {
				const {
					isInOffer,
					offerPrice,
					crossedPrice,
					offerPriceInNumbers,
					crossedPriceInNumbers,
				} = cartItems[0];

				const price = isInOffer ? offerPrice : crossedPrice;

				const priceInNumbers = isInOffer
					? offerPriceInNumbers
					: crossedPriceInNumbers;

				setActualPrice(price);
				setActualPriceInNumbers(priceInNumbers);
			}
		} catch (err) {
			console.log('Error coming from handelActualPrice()', err);
		}
	};


	const handleOfferChange = (offerIndex, discount) => {
		try {
			setCurrentIndex(offerIndex);
			// Calulate the discounted price
			if (discount) {
				setIsDiscountSelected(true);
				setSelectedOffer(discount);
			} else {
				setIsDiscountSelected(false);
				setToActualPrice();
				setSelectedOffer(null);
			}
		} catch (err) {
			console.log('Error coming from handleOfferChange()', err);
		}
	};

	const handleContinueToSelectCourses = async () => {
		// If not selected any offer or discount
		if (!selectedOffer) {
			handleContinueToPay();
			return;
		}

		// Add to selected courses list
		setSelectedCourses([cartItems[0]]);
		// Get the cart releated courses
		const categories = cartItems[0].categories;
		const authToken = decryptText(localStorage.getItem('eMediat'));
		const continent = decryptText(localStorage.getItem('cont'));
		const currencySymbol = decryptText(localStorage.getItem('cs'));

		const payload = {
			courseId: cartItems[0].courseId,
			continent: continent,
			currencySymbol: currencySymbol,
			categories: categories,
		};

		const response = await CartServices.getCartReleatedCourses(
			payload,
			authToken
		);

		if (response.success) {
			// Check inside the local storage for each course is in offer or not
			await updateCurrencyValue();
			let preFetchLocalDbCoursesList = []
			let jsonCheckDetails = {
				returnType: [],
			};
			const encryptedData = localStorage.getItem("crsl");
			if (encryptedData) {
				const decryptedData = decryptText(encryptedData, jsonCheckDetails);
				if (decryptedData && typeof decryptedData === "string") {
					try {
						preFetchLocalDbCoursesList = JSON.parse(decryptedData);
					} catch (error) {
						localStorage.removeItem("crsl");
						window.location.reload();
					}
				}
			}
			const updatedReleatedCoursesPromises = response.data.map(async (course) => {
				return await handlePaidCourse(userSpecificOfferDetails, course, preFetchLocalDbCoursesList);
			});

			// Wait for all promises to resolve
			const updatedReleatedCourses = await Promise.all(updatedReleatedCoursesPromises);
			// debugger
			setCurrentRelatedCourses(updatedReleatedCourses);
			setCurrentView('offerDetails');
			// Get the current discount in percentage as per number of cart items in cart list
			const cartItemsCount = cartItems.length;
			const availableOffers = cartItems[0].offers[0];

			const discountInPercent = getDiscountPercentage(
				availableOffers,
				cartItemsCount
			);
			setCurrentDiscountedPercentage(discountInPercent);

			let discountedValue = (actualPriceInNumbers * discountInPercent) / 100;
			let updatedDiscountedPrice = actualPriceInNumbers - discountedValue;
			let price = `${currencySymbol} ${updatedDiscountedPrice}`;
			setDiscountedPrice(price);
			setDiscountedPriceInNumbers(updatedDiscountedPrice);
		}
	};

	const getDiscountPercentage = (availableOffers, cartItemsCount) => {
		if (cartItemsCount === 1) {
			return 0;
		}

		// Find the offer with the highest numberOfCartItems that is less than or equal to cartItemsCount
		const offer = availableOffers
			.filter((offer) => offer.numberOfCartItems <= cartItemsCount)
			.reduce((prev, curr) =>
				prev.numberOfCartItems > curr.numberOfCartItems ? prev : curr
			);

		return offer ? offer.discount : 0; // Return the discount percentage if offer found, otherwise return 0
	};

	const addCourse = (index) => {
		// Adding choosed course in selectedCourses
		let choosedCourse = currentRelatedCourses[index];
		setSelectedCourses((prevArray) => [...prevArray, choosedCourse]);

		// Remove choosed course from currentRelatedCourses
		setCurrentRelatedCourses((prevCourses) =>
			prevCourses.filter((course) => course._id !== choosedCourse._id)
		);

		// Get the current discount in percentage as per number of cart items in cart list
		const cartItemsCount = selectedCourses.length + 1;

		let discountInPercent = 0
		if (selectedOffer !== null && selectedOffer.numberOfCartItems && selectedOffer.discount) {
			if (cartItemsCount >= selectedOffer.numberOfCartItems) {
				discountInPercent = selectedOffer.discount
			}
		}

		// old way to calculate discount...
		// const availableOffers = cartItems[0].offers[0]?.discounts;
		// const discountInPercent = getDiscountPercentage(
		// 	availableOffers,
		// 	cartItemsCount
		// );

		setCurrentDiscountedPercentage(discountInPercent);

		// Calculating total price of the current cart items
		const { crossedPriceInNumbers, offerPriceInNumbers } = choosedCourse;
		let currentCoursePriceInNumbers = choosedCourse?.isInOffer
			? offerPriceInNumbers
			: crossedPriceInNumbers;

		let totalPirceInNumbers = actualPriceInNumbers + currentCoursePriceInNumbers;

		setActualPriceInNumbers(totalPirceInNumbers);

		// Setting the text value to render
		const currencySymbol = decryptText(localStorage.getItem('cs'));
		let totalPrice = currencySymbol + ' ' + totalPirceInNumbers;
		setActualPrice(totalPrice);

		// Calculate the discounted price in numbers and text if there is any
		// debugger
		const discountedValue = (totalPirceInNumbers * discountInPercent) / 100;
		let updatedDiscountedPrice = Math.round(
			totalPirceInNumbers - discountedValue
		);

		setDiscountedPriceInNumbers(updatedDiscountedPrice);

		const discountPriceText = currencySymbol + ' ' + updatedDiscountedPrice;

		setDiscountedPrice(discountPriceText);

		const totalPirceInText = currencySymbol + ' ' + totalPirceInNumbers;
		dispatch(
			CartActions.updateCartDetails({
				cartItems: [...selectedCourses, choosedCourse],
				cartItemCount: selectedCourses.length + 1,
				cartTotalValue: totalPirceInText,
				cartTotalValueInNumbers: totalPirceInNumbers,
				discountPercentage: discountInPercent,
				discountValue: currencySymbol + ' ' + discountedValue,
				discountValueInNumbers: discountedValue,
				offerDetails: selectedOffer,
			})
		);
	};

	const removeCartItem = (rCourse) => {
		// remove selected course from selectedCourses
		setSelectedCourses((prevCourses) =>
			prevCourses.filter((course) => course._id !== rCourse._id)
		);

		// add : removed selected course in selectedCourses
		setCurrentRelatedCourses((prevArray) => [rCourse, ...prevArray]);

		// Get the current discount in percentage as per number of cart items in cart list
		const cartItemsCount = selectedCourses.length - 1;
		const availableOffers = cartItems[0].offers[0]?.discounts;

		const discountInPercent = getDiscountPercentage(
			availableOffers,
			cartItemsCount
		);

		setCurrentDiscountedPercentage(discountInPercent);

		// Calculating total price of the current cart items
		const { crossedPriceInNumbers, offerPriceInNumbers } = rCourse;

		let currentCoursePriceInNumbers = rCourse?.isInOffer
			? offerPriceInNumbers
			: crossedPriceInNumbers;

		let totalPirceInNumbers = actualPriceInNumbers - currentCoursePriceInNumbers;

		setActualPriceInNumbers(totalPirceInNumbers);

		// Setting the text value to render
		const currencySymbol = decryptText(localStorage.getItem('cs'));
		let totalPrice = currencySymbol + ' ' + totalPirceInNumbers;
		setActualPrice(totalPrice);

		// Calculate the discounted price in numbers and text if there is any
		const discountedValue = (totalPirceInNumbers * discountInPercent) / 100;
		let updatedDiscountedPrice = Math.round(
			totalPirceInNumbers - discountedValue
		);

		setDiscountedPriceInNumbers(updatedDiscountedPrice);

		const discountPriceText = currencySymbol + ' ' + updatedDiscountedPrice;

		setDiscountedPrice(discountPriceText);

		const totalPirceInText = currencySymbol + ' ' + totalPirceInNumbers;

		dispatch(
			CartActions.updateCartDetails({
				cartItems: [
					...selectedCourses.filter((course) => course._id !== rCourse._id),
				],
				cartItemCount: selectedCourses.length - 1,
				cartTotalValue: totalPirceInText,
				cartTotalValueInNumbers: totalPirceInNumbers,
				discountPercentage: discountInPercent,
				discountValue: discountPriceText,
				discountValueInNumbers: discountedPriceInNumbers,
				offerDetails: selectedOffer,
			})
		);
	};

	const handleContinueToPay = () => {
		if (actualPriceInNumbers > 0) {
			props.handleSidebarView('offer', {
				coursesInCart: props.cartItems,
			});
		}
	};

	return (
		<>
			<OfferView
				currentIndex={currentIndex}
				actualPrice={actualPrice}
				discountedPrice={discountedPrice}
				isDiscountSelected={isDiscountSelected}
				handleContinueToSelectCourses={handleContinueToSelectCourses}
				discountedPriceInNumbers={discountedPriceInNumbers}
				currentDiscountedPercentage={currentDiscountedPercentage}
				currentRelatedCourses={currentRelatedCourses}
				selectedCourses={selectedCourses}
				handleOfferChange={handleOfferChange}
				addCourse={addCourse}
				removeCartItem={removeCartItem}
				///
				currentView={currentView}
				handleContinueToPay={handleContinueToPay}
				{...props}
			/>
		</>
	);
}

export default Offer;
