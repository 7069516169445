import React, { useEffect } from 'react';

// import { useSelector } from 'react-redux';

import BgLines from '@assets/course/bgLines.png';
import Bgc1 from '@assets/course/bgc1.png';
import DesktopTabView from './view';

function DesktopTab(props) {
	// const courseDetails = useSelector((state) => state.course.courseDetails);

	useEffect(()=>{
		if(!props.isLoading){
			setTopBgOverlapHeight()			
		}
	},[props.isLoading])

	
	const setTopBgOverlapHeight = () => {
		let courseHeaderContainer = document.getElementById('courseHeaderContainer');
		let topBgOverlap = document.getElementById('topBgOverlap');
		if (courseHeaderContainer && topBgOverlap) {
			let useThisBgColor = "#6f0031";
			if(props.courseDetails.cardBackgroundColor){
				useThisBgColor = props.courseDetails.cardBackgroundColor;
			}
			// topBgOverlap.style.background = 'linear-gradient(90deg, rgba(0,0,0,1) 23%, ' + useThisBgColor + ' 48%, rgba(0,0,0,1) 70%)';
			topBgOverlap.style.background = 'linear-gradient(90deg, rgba(0,0,0,1) 37%, ' + useThisBgColor + ' 71%, rgba(0,0,0,1) 95%)';
			
			// background: linear-gradient(90deg, rgba(0,0,0,1) 30%, rgba(111,0,49,1) 71%, rgba(0,0,0,1) 100%);
	
			// OLD code remove in next iteration
			// let height =
			// 	courseHeaderContainer.getBoundingClientRect().top +
			// 	courseHeaderContainer.getBoundingClientRect().height;

			let height = courseHeaderContainer.getBoundingClientRect().height + 90;

			topBgOverlap.style.height = height + 'px';
			setBgLineOverLap(height);
		}
	};

	const setBgLineOverLap = (topBgOverlapHeight) =>{
		const bgLinesOverlap = document.getElementById('bgLinesOverlap');
		if(bgLinesOverlap){
			bgLinesOverlap.style.backgroundImage = `url(${BgLines})`;
			let bgLinesOverlapTop = topBgOverlapHeight;
			bgLinesOverlap.style.top = bgLinesOverlapTop + 'px';
			let height = topBgOverlapHeight + bgLinesOverlap.getBoundingClientRect().height;
			setBgBc1Overlap(height);
		}
	}

	const setBgBc1Overlap = (bgLinesOverlapHeight) =>{
		const bgBc1Overlap = document.getElementById('bgBc1Overlap');
		if(bgBc1Overlap){
			bgBc1Overlap.style.backgroundImage = `url(${Bgc1})`;
			bgBc1Overlap.style.top = bgLinesOverlapHeight + 'px';
		}
	}


	return <DesktopTabView {...props} />;
}

export default DesktopTab;
