import React from 'react';

import './style.css';

import Tags from '@components/Tags';

import Enrolled from '@pages/UserDashboard/MyLearning/Timeline/Sections/Enrolled';
import CompleteProfile from '@pages/UserDashboard/MyLearning/Timeline/Sections/CompleteProfile';
import WatchContent from '@pages/UserDashboard/MyLearning/Timeline/Sections/WatchContent';
import FinalExam from '@pages/UserDashboard/MyLearning/Timeline/Sections/FinalExam';
import ClaimCertificate from '@pages/UserDashboard/MyLearning/Timeline/Sections/ClaimCertificate/Main';

export default (props) => (

	<>
		<div
			onClick={(e)=>{props.accordanceItemClick(e)}}
			style={props.lastEleHeight > 10 ? { height: props.lastEleHeight + 'px' } : null}
			className={`accordion-item 
							${props.status.classN && props.status.classN}

                            ${props.topRightCusBorderRadius &&
				props.topRightCusBorderRadius === 'true' &&
				'topRightCusBorderRadius'
				}
                            ${props.bottomRightCusBorderRadius &&
				props.bottomRightCusBorderRadius === 'true' &&
				'bottomRightCusBorderRadius'
				}
                            `}>



			<div className={`timelineContainer

					${props.lastEle &&
				props.lastEle === 'true' &&
				'timelineContainerHanldeHeight'
				}

                                ${props.topLeftCusBorderRadius &&
				props.topLeftCusBorderRadius === 'true' &&
				'topLeftCusBorderRadius'
				}
                                ${props.bottomLeftCusBorderRadius &&
				props.bottomLeftCusBorderRadius === 'true' &&
				'bottomLeftCusBorderRadius'
				}
                                `}>
				<img src={props.identifyIcon} alt='identifyIcon' className='identificationIcon' />


				<span className={`${props.lastEle && props.lastEle === 'true' && 'lastEleLineHelper'} line`}>
				</span>

				{props.lineHelper && props.lineHelper === 'true' && (
					<span className='lineHelper'></span>
				)}
			</div>
		


		<h2 className='accordion-header'>
			<button
				// onClick={() => props.handleInview_intermediate_accordance_main(props.tabName)}
				className={`accordion-button 
									${props.openAccordance ? '' : 'collapsed'}
                                    ${props.topRightCusBorderRadius &&
					props.topRightCusBorderRadius === 'true' &&
					'topRightCusBorderRadius'
					}
                                    ${props.bottomRightCusBorderRadius &&
					props.bottomRightCusBorderRadius ===
					'true' &&
					'bottomRightCusBorderRadius'
					}
                                    `}
				data-bs-toggle='collapse'
				data-bs-target={`#${props.tabName}_accordionF`}>
				<div className='tagSupportContainer'>
					<span>
						{props.camelCaseToWords(props.tabName)}
					</span>

					{Object.entries(props.status).length > 0 && (
						<Tags
							span_font_size='10px'
							img_margin='5px'
							bg={props.status.bg}
							c={props.status.c}
							bc={props.status.bc}
							text={props.status.text === "SuperLock" ? "Locked" : props.status.text}
							imgs={props.status.icon}
						/>
					)}
				</div>
			</button>
		</h2>

		<div
			id={`${props.tabName}_accordionF`}
			// className='accordion-collapse collapse'
			className={`accordion-collapse collapse ${props.tabName === 'completeProfile' && props.openAccordance ? 'show' : ''}`}
			
			data-bs-parent='#allDetailsAccordionFlush'>
			<div className='accordion-body'>

				{/* {props.inView === props.tabName && props.tabName === 'enrolled' && (
							<Enrolled {...props} />
						)} */}

				{props.tabName === 'enrolled' && <Enrolled {...props} />}

				{props.tabName === 'completeProfile' && <CompleteProfile {...props} />}

				{props.tabName === 'watchContent' && <WatchContent />}

				{props.tabName === 'finalExam' && <FinalExam {...props} />}

				{props.tabName === 'claimCertification' && <ClaimCertificate {...props} />}
				
			</div>
		</div>
	</div >
	</>
);
