import React from 'react';

import DesktopTabView from './view';
import { useSelector } from 'react-redux';

function DesktopTab(props) {
	const cartItems = useSelector((state) => state.cart.cartItems);
	return <DesktopTabView cartItems={cartItems} {...props} />;
}

export default DesktopTab;
