import React, { useState } from "react";
import LegalView from "./view";

import { useDispatch, useSelector } from "react-redux";
import { produce } from "immer";
import { useNavigate } from 'react-router-dom';

import * as UserDashboardServices from "@services/UserDashboard";
import * as UserDashboardActions from "@redux/actions/UserDashboard";
import * as TimelineRedirectActions from '@redux/actions/TimelineRedirect';

import { decryptText } from "@library/enc-dec";

function Legal() {
  const dispatch = useDispatch();
	const navigate = useNavigate();
	const timelineRedirectDetails = useSelector((state) => state.timelineRedirect.timelineRedirectDetails);

  const [iserror, setIserror] = useState(false);
  const user = useSelector((state) => state.dashboard.user);

  const handleUserUpdate = (key, value) => {
    try {
      setIserror(false);
      let updatedUser = produce(user, (draft) => {
        draft[key] = value;
      });

      dispatch(UserDashboardActions.updateUserDetails(updatedUser));
    } catch (err) {
      console.log("Legal Info : Error coming from handleTextInput()", err);
    }
  };

  const handleInputChange = (event, inputAction) => {
    try {
      let eventType = event.target.type;
      let inputValue = event.target.value;
      if (eventType === "checkbox") {
        inputValue = event.target.checked;

        handleUserUpdate("legalInfoAcknowledged", inputValue);
      } else {
        handleUserUpdate(inputAction, inputValue);
      }
    } catch (err) {
      console.log("Legal Info : Error coming from handleInputChange()", err);
    }
  };

  const handleSave = async () => {
    try {
      const { firstName, lastName, middleName, legalInfoAcknowledged } = user;
      const payload = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        legalInfoAcknowledged: legalInfoAcknowledged,
      };
      if (!firstName || !lastName || !legalInfoAcknowledged) {
        setIserror(true);
        return;
      }
      let authToken = decryptText(localStorage.getItem("eMediat"));
      const response = await UserDashboardServices.updateLegalInfo(
        payload,
        authToken
      );

      if (response.success) {
        alert(response.message);
        setIserror(false);
        dispatch(
          UserDashboardActions.updateUserDetails({
            ...response.data,
            bssFormDetails: user.bssFormDetails,
          })
        );
        if(timelineRedirectDetails && timelineRedirectDetails.purchaseId && timelineRedirectDetails.purchaseId !=="" && timelineRedirectDetails.section === "legalInformation"){
          navigate(`/timeline/${timelineRedirectDetails.purchaseId}`);
        }
        else{
          dispatch(TimelineRedirectActions.resetTimelineRedirectReducers())
        }
        return;
      }
      alert(response.message);
    } catch (err) {
      console.log("Legal Info: Error coming from handleSave()", err);
    }
  };

  return (
    <>
      {user && (
        <LegalView
          iserror={iserror}
          handleInputChange={handleInputChange}
          handleSave={handleSave}
          userData={user}
        />
      )}
    </>
  );
}

export default Legal;
