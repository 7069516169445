import React from 'react';

import OTP from '@components/Otp';
import './style.css';

import LeftArrow from '@assets/userDashboard/Icons/leftArrowForVPopup.png';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';
import Registration from '@pages/Course/Sections/Registration';

function NameAndPhoneNoOtpView(props) {
	return (
		<>
			<div className='nameAndPhoneNoOtpView'>
				<button
					ref={props.nameAndPhoneNoOtpOpenBtnRef}
					className='d-none'
					data-bs-toggle='modal'
					data-bs-target='#nameAndPhoneNoOtpView'>
					Launch demo modal
				</button>

				<div
					ref={props.nameAndPhoneNoOtpModalRef}
					className='modal fade'
					id='nameAndPhoneNoOtpView'>
					<div className='modal-dialog modal-dialog-centered'>
						<div className='modal-content'>
							<div className='modal-header'>
								<p className='modal-title'>
									{props.tempNameAndPhoneNoOtpStage &&
										props.tempNameAndPhoneNoOtpStage === 'otpStage' && (
											<img
												onClick={() => props.goBack()}
												src={LeftArrow}
												alt='LeftArrow'
											/>
										)}
									Enter your details
								</p>
								<button
									ref={props.closePopBtnRef}
									className='closeBtn'
									data-bs-dismiss='modal'>
									x
								</button>
							</div>

							<div className='modal-body'>
								{props.tempNameAndPhoneNoOtpStage &&
									props.tempNameAndPhoneNoOtpStage === 'init' && (
										<div className='initContainer'>
											{/* Also used in :components\Popups\CoursePagePopupsContent\WriteToUs\view.js */}
											<Registration
												actionBtnText='Next'
												customId='tempNameAndPhoneNoOtpStage'
												openOtpPopUp={props.dubLicateOpenOtpPopUp}
												fullNameSetter={props.fullNameSetter}
												buttonType={props.buttonType ? props.buttonType : 'enrollAndAddToCartButton'} // buttonType is defined in @pages\Course\Main\index.js and it will be only set if comign from sample certificat btn
											/>
										</div>
									)}

								{props.tempNameAndPhoneNoOtpStage &&
									props.tempNameAndPhoneNoOtpStage === 'otpStage' && (
										<div className='otpStageContainer'>
											<p className='verifyNumText'>
												Verify using OTP sent to {props.tempCountryCode} {props.tempPhoneNo}
											</p>

											<OTP
												ref={props.OTPRef}
												nextBtnIcon={RightArrow}
												countryCode={props.tempCountryCode}
												contactNumber={props.tempPhoneNo}
												handleSuccessOTP={props.intermediateHandleSuccessOtp}
												flushOTPValues={props.flushOTPValues}
												handleResendOTP={props.handleResendOTP}
											/>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default NameAndPhoneNoOtpView;
