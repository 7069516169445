import React from 'react';
import ImageCarouselView from './view';

import { useSelector } from 'react-redux';

function ImageCarousel() {
	const courseDetails = useSelector((state) => state.course.courseDetails);
	return <ImageCarouselView imageCarousel={courseDetails?.imageCarousel} />;
}

export default ImageCarousel;
