export const updateFinalPaymentDetails = (value) => {
  return {
    type: "UPDATE_FINAL_PAYMENT_DETAILS",
    payload: {
      paymentDetails: value,
    },
  };
};

export const resetPayReducers = () => {
  return {
    type: "RESET_PAY_REDUCER",
  };
};
