import React, { useState } from 'react';


import { useDispatch, useSelector } from 'react-redux';
import { encryptText, decryptText } from '@library/enc-dec';
import { getPurchasesCount } from '@library/helpers';

import * as PageViewServices from '@services/PageView';
import * as LoginServices from '@services/Login';
import * as CourseContentServices from "@services/CourseContent";
import * as NavbarActions from '@redux/actions/Navbar';
import * as UserdashboardServices from "@services/UserDashboard";
import * as CartActions from "@redux/actions/Cart";

import WriteToUsView from './view';

function WriteToUs(props) {
	const dispatch = useDispatch();

	const loginState = useSelector((state) => state.navbar.loginState);
	const userDetails = useSelector((state) => state.cart.userDetails);

	const [writeToUs, setWriteToUs] = useState('');
	const [writeToUseError, setWriteToUseError] = useState('');
	const [writeUseSubmitted, setWriteUseSubmitted] = useState(false);
	const [openRegistration, setOpenRegistration] = useState(false);
	const pageViewDocId = useSelector((state) => state.pageView.pageViewDocId);


	const handleWriteToUsChange = (event) => {
		setWriteToUseError('');
		setWriteToUs(event.target.value);
	}

	const handleWriteToUsSubmit = () => {
		if (writeToUs === "") {
			setWriteToUseError('Input Required');
		}
		else {
			if (!loginState) {
				setOpenRegistration(true);
			}
			else {
				sendWriteToUs()
			}
		}
	}
	const sendWriteToUs = async () => {
		try {
			let useThisDetails = {}
			let messageSentStatus = false;
			if (userDetails && userDetails !== null && Object.entries(userDetails).length > 0) {
				useThisDetails = userDetails
			}
			else {
				let authToken = decryptText(localStorage.getItem("eMediat"));
				const userDetailsFromDb = await UserdashboardServices.getUser(authToken); 
				if (userDetailsFromDb.success) {
					useThisDetails = userDetailsFromDb.data;
					dispatch(CartActions.updateUserDetails(userDetailsFromDb.data));					
				}
			}

			if(Object.entries(useThisDetails).length > 0 ){
				let payload = {
					contactNumber: useThisDetails.contactNumber,
					countryCode: useThisDetails.countryCode,
					description : writeToUs,
					displayPhoto : useThisDetails.displayPhoto,
					email: useThisDetails.email,
					firstName: useThisDetails.firstName,
					lastName: useThisDetails.lastName,
					location : 'Product Page',
					title : "general_Query",
					userId : useThisDetails._id,
					username : useThisDetails.username,
					whatsAppCountryCode: useThisDetails.whatsAppCountryCode,
					whatsAppNumber: useThisDetails.whatsAppNumber,
				}

				const response = await CourseContentServices.reportQuery(payload);
				if (response.success) {
					messageSentStatus = true;
				}		  
			}

			if(messageSentStatus){
				setWriteUseSubmitted(true);
				setWriteToUs('')
				setTimeout(function () {
					setWriteUseSubmitted(false);
				}, 4000);	
			}
			else{
				setWriteUseSubmitted(false);				
				alert("Issue in sending query, please refresh the page and try again")
			}

		}
		catch (err) {
			setWriteUseSubmitted(false);
			alert("Issue in sending query, please refresh the page and try again")
			console.log("Error Coming from sendWriteToUs()", err);
		}
	}


	const handleSuccessOtp = async (countryCode, phoneNo, OTP, OTPToken) => {
		try {
			const response = await LoginServices.verifyOTP(OTP, OTPToken, phoneNo, countryCode);

			if (response.success) {
				setOpenRegistration(false)
				actionAfterSuccessfulLogin(response);
				updatePageViewAfterSignUp(response.data)
				return;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming from handleSuccessOTP()', err);
		}
	};


	const updatePageViewAfterSignUp = async (userDetails)=>{
		if(userDetails.newUser && pageViewDocId !== ""){
			let authToken = userDetails.authToken
			let userId = userDetails._id;
			let country = userDetails.country;
			let payload = {
				pageViewDocId : pageViewDocId,
				isSignedUp : true,
				userId : userId,
				country : country
			}
			const response = await PageViewServices.updatePageViewAfterSignUp(authToken, payload);
		}
	}



	const actionAfterSuccessfulLogin = (response) => {
		saveToken(response.data?.authToken);
		sendWriteToUs();

		setTimeout(async () => {
			const purchasesCount = await getPurchasesCount();
			dispatch(NavbarActions.updatePurchasesCount(purchasesCount));
			dispatch(NavbarActions.updateLoginState(true));
		}, 2000);
	}

	const saveToken = (token) => {
		try {
			window.localStorage.setItem('eMediat', encryptText(token));
			window.localStorage.setItem('eMediatt', encryptText(Date.now()));
		} catch (err) {
			console.log('Error coming from  saveToken()', err);
		}
	};

	return (
		<>
			<WriteToUsView
				writeToUseError={writeToUseError}
				handleWriteToUsChange={handleWriteToUsChange}
				handleWriteToUsSubmit={handleWriteToUsSubmit}
				writeUseSubmitted={writeUseSubmitted}
				openRegistration={openRegistration}
				newHandleSuccessOtp={handleSuccessOtp}
				{...props}
			/>
		</>
	);
}

export default WriteToUs;
