import React from 'react';
import ContentLoader from "react-content-loader";
import './style.css';

export default () => (
    <div className='historyFavriouteContainerLoader'>

        <div className='historyFavriouteContainerLoaderDesktop'>
            {/* START : Tabs : history & fav */}
            <ContentLoader
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 395 20">

                <rect x='0' y='0' rx='3' ry='3' width='40' height='10' />
                <rect x='45' y='0' rx='3' ry='3' width='40' height='10' />
            </ContentLoader>
            {/* END : Tabs : history & fav */}

            {/* START Desktop */}
            {[...Array(5)].map((_, index) => (
                <div key={index}>
                    <ContentLoader
                        className='seriesContainerLoaderDesktop'
                        backgroundColor="#ffffff"
                        // foregroundColor="#a09d9d"
                        viewBox="0 0 395 60">

                        {/* START : History cards */}
                        {/* END : Card 1 */}
                        <rect x='0' y='0' rx='5' ry='5' width='85' height='50' />
                        <rect x='90' y='5' rx='2' ry='2' width='60' height='6' />
                        <rect x='90' y='17' rx='2' ry='2' width='85' height='6' />
                        <rect x='90' y='29' rx='2' ry='2' width='30' height='6' />
                    </ContentLoader>
                </div>
            ))}
        </div>
        {/* END Desktop */}



        {/* START Mobile */}
        <div className='historyFavriouteContainerLoaderMobile'>
            {/* START : Tabs : history & fav */}
            <ContentLoader
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 200 20">

                <rect x='0' y='0' rx='3' ry='3' width='40' height='10' />
                <rect x='45' y='0' rx='3' ry='3' width='40' height='10' />
            </ContentLoader>
            {/* END : Tabs : history & fav */}

            {[...Array(4)].map((_, index) => (
                <div key={index}>
                    <ContentLoader
                        backgroundColor="#ffffff"
                        // foregroundColor="#a09d9d"
                        viewBox="0 0 100 30">
                        {/* Card 1 */}
                        <rect x="0" y="0" rx="3" ry="3" width="40" height="25" />
                        <rect x="42" y="4" rx="1" ry="1" width="57" height="3" />
                        <rect x="42" y="10" rx="1" ry="1" width="47" height="3" />
                        <rect x="42" y="16" rx="1" ry="1" width="37" height="3" />
                    </ContentLoader>
                </div>
            ))}
        </div>
        {/* END Mobile */}
    </div>
);
