export const updateDisplayPhoto = (value) => {
	return {
		type: 'UPDATE_DISPLAY_PHOTO',
		payload: {
			displayPhoto: value,
		},
	};
};

export const updateFirstName = (value) => {
	return {
		type: 'UPDATE_FIRST_NAME',
		payload: {
			firstName: value,
		},
	};
};

export const updateLastName = (value) => {
	return {
		type: 'UPDATE_LAST_NAME',
		payload: {
			lastName: value,
		},
	};
};

export const updateDOB = (value) => {
	return {
		type: 'UPDATE_DOB',
		payload: {
			dob: value,
		},
	};
};

export const updateGender = (value) => {
	return {
		type: 'UPDATE_GENDER',
		payload: {
			gender: value,
		},
	};
};

export const updateEmail = (value) => {
	return {
		type: 'UPDATE_EMAIL',
		payload: {
			email: value,
		},
	};
};

export const updateCountry = (value) => {
	return {
		type: 'UPDATE_COUNTRY',
		payload: {
			country: value,
		},
	};
};

export const updateCountryCode = (value) => {
	return {
		type: 'UPDATE_COUNTRY_CODE',
		payload: {
			countryCode: value,
		},
	};
};

export const updateContactNumber = (value) => {
	return {
		type: 'UPDATE_CONTACT_NUMBER',
		payload: {
			contactNumber: value,
		},
	};
};

export const updateWhatsAppNumber = (value) => {
	return {
		type: 'UPDATE_WHATSAPP_NUMBER',
		payload: {
			whatsAppNumber: value,
		},
	};
};

export const updateWhatsAppCountry = (value) => {
	return {
		type: 'UPDATE_WHATSAPP_COUNTRY',
		payload: {
			whatsAppCountry: value,
		},
	};
};

export const updateWhatsAppCountryCode = (value) => {
	return {
		type: 'UPDATE_WHATSAPP_COUNTRY_CODE',
		payload: {
			whatsAppCountryCode: value,
		},
	};
};

export const updateFlatHouseNumber = (value) => {
	return {
		type: 'UPDATE_FLAT_HOUSE_NUMBER',
		payload: {
			flatHouseNumber: value,
		},
	};
};

export const updateBuildingName = (value) => {
	return {
		type: 'UPDATE_BUILDING_NAME',
		payload: {
			buildingName: value,
		},
	};
};

export const updateColonyName = (value) => {
	return {
		type: 'UPDATE_COLONY_NAME',
		payload: {
			colonyName: value,
		},
	};
};

export const updateAreaName = (value) => {
	return {
		type: 'UPDATE_AREA_NAME',
		payload: {
			areaName: value,
		},
	};
};

export const updateLandmark = (value) => {
	return {
		type: 'UPDATE_LANDMARK',
		payload: {
			landmark: value,
		},
	};
};

export const updateCity = (value) => {
	return {
		type: 'UPDATE_CITY',
		payload: {
			city: value,
		},
	};
};

export const updateUserState = (value) => {
	return {
		type: 'UPDATE_USER_STATE',
		payload: {
			userState: value,
		},
	};
};

export const updatePincode = (value) => {
	return {
		type: 'UPDATE_PINCODE',
		payload: {
			pincode: value,
		},
	};
};

export const updateFullName = (value) => {
	return {
		type: 'UPDATE_FULL_NAME',
		payload: {
			fullName: value,
		},
	};
};

export const updateMarksheetOrDegreeCert = (value) => {
	return {
		type: 'UPDATE_MARKSHEET_OR_DEGREE_CERT',
		payload: {
			marksheetOrDegreeCert: value,
		},
	};
};

export const updateAddharOrIdProof = (value) => {
	return {
		type: 'UPDATE_ADDHAR_OR_ID_PROOF',
		payload: {
			addharOrIdProof: value,
		},
	};
};

export const updateQualification = (value) => {
	return {
		type: 'UPDATE_QUALIFICATION',
		payload: {
			qualification: value,
		},
	};
};

export const updatePassportPhoto = (value) => {
	return {
		type: 'UPDATE_PASSPORT_PHOTO',
		payload: {
			passportPhoto: value,
		},
	};
};

export const updateDetails = (value) => {
	return {
		type: 'UPDATE_DETAILS',
		payload: {
			details: value,
		},
	};
};

export const resetBasicFormReducer = (value) => {
	return {
		type: 'RESET_BASIC_FORM_REDUCER',
	};
};
