const initialState = {
    questionBank: null,
    totalNumberOfQuestions: 0,
    allQuestions: [],
  };
  
  const ExamReducer = (state = initialState, action) => {
    switch (action.type) {
      case "UPDATE_QUESTION_BANK":
        return {
          ...state,
          questionBank: action.payload,
        };
      case "UPDATE_TOTAL_NUMBER_OF_QUESTIONS":
        return {
          ...state,
          totalNumberOfQuestions: action.payload,
        };
      case "UPDATE_ALL_QUESTIONS":
        return {
          ...state,
          allQuestions: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default ExamReducer;