const initialState = {
	email: '',
	password: '',
	OTPToken: '',
	contactNumber: '',
	forgotPassToken: '',
	currentLoginStage: 'phoneNo',
	loginSubHeader: 'Enter your mobile number',
};

const LoginReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_EMAIL':
			return {
				...state,
				email: action.payload.email,
			};
		case 'UPDATE_PASSWORD':
			return {
				...state,
				password: action.payload.password,
			};
		case 'UPDATE_OTP_TOKEN':
			return {
				...state,
				OTPToken: action.payload.OTPToken,
			};
		case 'UPDATE_CONTACT_NUMBER':
			return {
				...state,
				contactNumber: action.payload.contactNumber,
			};
		case 'UPDATE_FORGOT_PASS_TOKEN':
			return {
				...state,
				forgotPassToken: action.payload.forgotPassToken,
			};
		case 'UPDATE_CURRENT_LOGIN_STAGE':
			return {
				...state,
				currentLoginStage: action.payload.currentLoginStage,
			};
		case 'UPDATE_LOGIN_SUBHEADER':
			return {
				...state,
				loginSubHeader: action.payload.loginSubHeader,
			};
		case 'RESET_LOGIN_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default LoginReducer;
