import React from 'react';

import VideoCommentsView from './view';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { useNavigate } from 'react-router-dom';

import { decryptText } from '@library/enc-dec';
import { handleCommentReply } from '@library/ReplyInputbox';
import { handleReplyOfReply } from '@components/Reply/ReplyHandler';

import * as CourseContentServices from '@services/CourseContent';
import * as CourseDetailsActions from '@redux/actions/CourseDetails';

function VideoComments(props) {
	const navigate = useNavigate();

	const handleLikeComment = async (event, commentId) => {
		try {
			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				navigate('/login');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const videoDocId = props.currentVideoData._id;

			let payload = {
				commentId: commentId,
				videoDocId: videoDocId,
			};
			const response = await CourseContentServices.likeComment(payload, authToken);
			if (response.success) {
				const status = response.data ? true : false;
				handleCommentLikeStatus(status, commentId);
			}
		} catch (err) {
			console.log('Error coming while handling like comment', err);
		}
	};

	const handleCommentLikeStatus = (status, commentId) => {
		let updatedVideoComments = produce(props.currentVideoComments, (draft) => {
			let comment = draft.data.comments.find((c) => c._id === commentId);
			if (comment) {
				if (comment._id === commentId) {
					comment.isLiked = status;
					if (status) {
						comment.likesCount++;
					} else {
						comment.likesCount--;
					}

					if (comment.isDisliked) {
						comment.isDisliked = false;
						comment.dislikesCount--;
					}
				}
			}
		});

		// Updating Redux
		props.updateCurrentVideoComments(updatedVideoComments);
	};

	const handleDislikeComment = async (event, commentId) => {
		try {
			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				navigate('/login');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const videoDocId = props.currentVideoData._id;

			let payload = {
				commentId: commentId,
				videoDocId: videoDocId,
			};
			const response = await CourseContentServices.dislikeComment(
				payload,
				authToken
			);

			if (response.success) {
				const status = response.data ? true : false;
				handleCommentDislikeStatus(status, commentId);
			}
		} catch (err) {
			console.log('Error coming while handling dislike comment', err);
		}
	};

	const handleCommentDislikeStatus = (status, commentId) => {
		let updatedVideoComments = produce(props.currentVideoComments, (draft) => {
			let comment = draft.data.comments.find((c) => c._id === commentId);
			if (comment) {
				if (comment._id === commentId) {
					comment.isDisliked = status;
					if (status) {
						comment.dislikesCount++;
					} else {
						comment.dislikesCount--;
					}

					if (comment.isLiked) {
						comment.isLiked = false;
						comment.likesCount--;
					}
				}
			}
		});

		// Updating Redux
		props.updateCurrentVideoComments(updatedVideoComments);
	};

	const handleAddNewReply = async (newReply, commentId) => {
		// Deep copy of current video comments
		props.updateCommentReply({ reply: newReply, commentId: commentId });
	};

	const navigateToLogin = () => {
		navigate('/login');
	};

	return (
		<>
			<VideoCommentsView
				currentVideoComments={props.currentVideoComments}
				handleLikeComment={handleLikeComment}
				handleDislikeComment={handleDislikeComment}
				currentVideoData={props.currentVideoData}
				userDetails={props.userDetails}
				handleReply={(event) =>
					handleCommentReply(
						event,
						props.userDetails.displayPhoto,
						props.videoDuration,
						handleAddNewReply,
						navigateToLogin
					)
				}
				handleReplyForReply={(event, commentId) =>
					handleReplyOfReply(
						event,
						props.userDetails.displayPhoto,
						commentId,
						props.videoDuration,
						handleAddNewReply,
						navigateToLogin
					)
				}
			/>
		</>
	);
}

function mapStateToProps(state) {
	return {
		currentVideoData: state.courseDetails.currentVideoData,
		currentVideoComments: state.courseDetails.currentVideoComments,
		userDetails: state.courseDetails.userDetails,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateCurrentVideoComments: CourseDetailsActions.updateCurrentVideoComments,
			updateCommentReply: CourseDetailsActions.updateCommentReply,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoComments);
