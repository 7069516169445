import React, { useEffect, useState, useRef } from 'react';

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import CourseView from './view';
import { isUserLogedIn } from '@library/auth';
import {
	getPurchasesCount,
	updateCurrencyValue,
	handlePaidCourse,
} from '@library/helpers';

import { encryptText, decryptText } from '@library/enc-dec';

import * as UserdashboardServices from "@services/UserDashboard";
import * as LoginServices from '@services/Login';
import * as CourseServices from '@services/Course';
import * as LeadsServices from '@services/Leads';
import * as CartServices from '@services/Cart';
import * as PageViewServices from '@services/PageView';
import * as OfferServices from "@services/Offers";
import * as CheckoutServices from "@services/Checkout";

import * as CourseActions from '@redux/actions/Course';
import * as NavbarActions from '@redux/actions/Navbar';
import * as OTPActions from '@redux/actions/OTP';
import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';
import * as CartActions from '@redux/actions/Cart';
import * as BackUpCartAction from '@redux/actions/BackUpCart';
import * as PageViewAction from '@redux/actions/PageView';
import * as UserSpecificOfferAction from '@redux/actions/UserSpecificOffer';

import TopLoadingBar from '@components/TopLoadingBar';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

function Course(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const viewportWidth = window.innerWidth;
	const [isLoading, setIsLoading] = useState(true);

	const nameAndPhoneNoOtpOpenBtnRef = useRef(null);
	const courseId = window.location.href.split('/').pop();
	const [nameAndPhoneNoOtpStage, setNameAndPhoneNoOtpStage] = useState('');
	const [userLogedIn, setUserLogedIn] = useState(false);

	const [fullName, setFullName] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [countryCode, setCountryCode] = useState('');

	// Saving details for addToCart and Enroll
	const [enrollContactNumber, setEnrollContactNumber] = useState('');
	const [enrollCountryCode, setEnrollCountryCode] = useState('');

	// START : states to maintain the cart view
	const [cartView, setCartView] = useState('');
	const [tempCartView, setTempCartView] = useState('');

	const courseDetails = useSelector((state) => state.course.courseDetails);
	const cartItems = useSelector((state) => state.cart.cartItems);
	const [OTPToken, setOTPToken] = useState(null);
	const [buttonType, setButtontype] = useState('');
	const userDetails = useSelector((state) => state.cart.userDetails);
	const pageViewDocId = useSelector((state) => state.pageView.pageViewDocId);

	const [addToCartLoading, setAddToCartLoading] = useState('')
	const userSpecificOfferDetails = useSelector((state) => state.userSpecificOffer.userSpecificOfferDetails);

	useEffect(() => {
		// First get the course by courseId
		// Then Show the UI
		// Then handle Then Handle Then Buying proccess
		window.scroll(0, 0);
		initializePage();
		return () => {
			document.title = "eMediSkill"
			dispatch(CourseActions.updateCourseDetails({}));
		};
	}, []);



	const initializePage = async () => {
		try {
			dispatch(TopLoadingBarActions.updateLoadingBarProgress(20));
			const logedInStatus = await isUserLogedIn();
			setUserLogedIn(logedInStatus);
			dispatch(NavbarActions.updateLoginState(logedInStatus));
			if (logedInStatus) {
				const purchasesCount = await getPurchasesCount();
				dispatch(NavbarActions.updatePurchasesCount(purchasesCount));
			}
			dispatch(TopLoadingBarActions.updateLoadingBarProgress(40));
			getCourseDetails(courseId, logedInStatus);
		} catch (err) {
			console.log('Error coming from initializePage()', err);
		}
	};

	const getCourseDetails = async (courseId, logedInStatus) => {
		dispatch(TopLoadingBarActions.updateLoadingBarProgress(70));
		try {
			if (logedInStatus) {
				let authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await CourseServices.getCourseByIdAuth(
					courseId,
					authToken
				);
				if (response.success) {
					updateSeoContent(response.data)
					dispatch(CourseActions.updateCourseDetails(response.data));
					dispatch(TopLoadingBarActions.updateLoadingBarProgress(100));

					const userSpecificOffer = await getUserSpecificOffer();
					if (userSpecificOffer.length > 0) {
						dispatch(
							UserSpecificOfferAction.updateUserSpecificOfferDetails(userSpecificOffer)
						)
					}

					setTimeout(function () {
						setIsLoading(false);
					}, 500);

					setTimeout(function () {
						addPageView(courseId, logedInStatus)
					}, 5000)

					return;
				}
				alert(response.message);
			} else {
				const response = await CourseServices.getCourseById(courseId);
				if (response.success) {
					updateSeoContent(response.data)
					dispatch(CourseActions.updateCourseDetails(response.data));
					dispatch(TopLoadingBarActions.updateLoadingBarProgress(100));

					setTimeout(function () {
						setIsLoading(false);
					}, 500);

					setTimeout(function () {
						addPageView(courseId, logedInStatus)
					}, 5000)

					return;
				}
				alert(response.message);
			}
		} catch (err) {
			dispatch(TopLoadingBarActions.updateLoadingBarProgress(100));
			console.log('Error coming from getCourseDetails()', err);
		}
	};


	const updateSeoContent = (courseDetails) => {
		// courseDetails.seoUrlSlug		
		const head = document.getElementsByTagName('head');
		if (courseDetails.seoTitleTag) {
			document.title = courseDetails.seoTitleTag;
		}
		if (courseDetails.seoMetaDescription && head && head.length > 0) {
			let meta = document.createElement('meta');
			meta.setAttribute('name', 'description');
			meta.setAttribute('content', courseDetails.seoMetaDescription);
			head[0].appendChild(meta);
		}
		if (courseDetails.seoOGDescription && head && head.length > 0) {
			let meta = document.createElement('meta');
			meta.setAttribute('property', 'og:description');
			meta.setAttribute('content', courseDetails.seoOGDescription);
			head[0].appendChild(meta);
		}
		if (courseDetails.seoOGTitle && head && head.length > 0) {
			let meta = document.createElement('meta');
			meta.setAttribute('property', 'og:title');
			meta.setAttribute('content', courseDetails.seoOGTitle);
			head[0].appendChild(meta);
		}
		if (courseDetails.seoThumbnailUrl && head && head.length > 0) {
			let meta = document.createElement('meta');
			meta.setAttribute('property', 'og:image');
			meta.setAttribute('content', courseDetails.seoThumbnailUrl);
			head[0].appendChild(meta);
		}
	}

	const addPageView = async (courseId, logedInStatus) => {
		let payload = {
			courseId: courseId,
			isSignedUp: false,
			userId: null,
			country: null
		}
		if (logedInStatus) {
			if (userDetails && userDetails !== null && Object.entries(userDetails).length > 0) {
				payload.country = userDetails.country
				payload.userId = userDetails._id
			}
			else {
				let authToken = decryptText(localStorage.getItem("eMediat"));
				const userDetailsFromDb = await UserdashboardServices.getUser(authToken);
				if (userDetailsFromDb.success) {
					payload.country = userDetailsFromDb.data.country
					payload.userId = userDetailsFromDb.data._id
				}
			}
		}

		const response = await PageViewServices.addPageView(payload);
		if (response.success && !logedInStatus) {
			dispatch(PageViewAction.updatePageViewDocId(response.data._id))
		}

	}


	const flushNameAndPhoneNoOtpStage = () => {
		setNameAndPhoneNoOtpStage('');
	};

	const clickRefBtn = (r) => {
		if (r.current) {
			r.current.click();
		}
	};

	const fullNameSetter = (name) => {
		setFullName(name);
	};

	// Here the button type=> intresetButton => enrollAndAddToCartButton
	const openOtpPopUp = async (countryCode, contactNumber, stage, buttonType) => {
		try {
			// Send OTP Call login API
			if (stage === 'otpStage') {
				setCountryCode(countryCode);
				setPhoneNo(contactNumber);
				const response = await LoginServices.login(contactNumber);
				if (!response.success) {
					alert(response.message);
					return;
				}
				setOTPToken(response.data.token);
			}
			setButtontype(buttonType);
			setNameAndPhoneNoOtpStage(stage);
			clickRefBtn(nameAndPhoneNoOtpOpenBtnRef);
		} catch (err) {
			console.log('Error coming from openOtpPopUp()', err);
		}
	};

	const handleLeadsVerifyOTP = async (OTP) => {
		try {
			if (buttonType === 'sampleCertificate' || buttonType === 'addFreeCourse') {
				let payload = {
					otp: OTP,
					contactNumber: enrollContactNumber,
					countryCode: enrollCountryCode,
					courseId: courseId,
					source: "onlyVerifyOtp"
				};

				const response = await LeadsServices.verifyLeadsOTP(payload, OTPToken);
				if (!response.success) {
					setUserLogedIn(false);
					dispatch(OTPActions.updateOTPErrorMsg(response.message));
					return response;
				}

				// Here mark user as logged in and get the cart details of the user
				saveToken(response.data?.authToken);
				setUserLogedIn(true);
				setTimeout(async () => {
					const purchasesCount = await getPurchasesCount();
					dispatch(NavbarActions.updatePurchasesCount(purchasesCount));
					dispatch(NavbarActions.updateLoginState(true));
				}, 2000);
				updatePageViewAfterSignUp(response.data)
				if (buttonType === 'addFreeCourse') {
					handleFreeCourse(courseDetails, 'comingfromLogin')
				}
				return response;
			}

			// Full name is the display name of the user
			let payload = {};
			if (buttonType === 'interestButton') {
				payload = {
					otp: OTP,
					contactNumber: phoneNo,
					countryCode: countryCode,
					displyaName: fullName,
					courseId: courseId,
					source: "interest"
				};
			} else {
				payload = {
					otp: OTP,
					contactNumber: enrollContactNumber,
					countryCode: enrollCountryCode,
					courseId: courseId,
					source: tempCartView === "enroll" ? "enroll" : "onlyVerifyOtp"
				};
			}

			const response = await LeadsServices.verifyLeadsOTP(payload, OTPToken);
			if (!response.success) {
				setUserLogedIn(false);
				dispatch(OTPActions.updateOTPErrorMsg(response.message));
				return response;
			}

			// // Here mark user as logged in and get the cart details of the user
			saveToken(response.data?.authToken);
			setUserLogedIn(true);

			setTimeout(async () => {
				isCoursePurchased();
				const purchasesCount = await getPurchasesCount();
				dispatch(NavbarActions.updatePurchasesCount(purchasesCount));
				dispatch(NavbarActions.updateLoginState(true));
			}, 2000);

			if (buttonType === 'interestButton') {
				const authToken = response.data?.authToken;
				// Create Lead on I'am interested button press
				createLead(courseId, authToken);
			} else {
				takeCartViewAction(tempCartView, 'afterLogin');
			}
			updatePageViewAfterSignUp(response.data)
			return response;
		} catch (err) {
			console.log('Error coming from leadsVerifyOTP()', err);
		}
	};

	const isCoursePurchased = async () => {
		let authToken = decryptText(localStorage.getItem('eMediat'));
		const response = await CourseServices.getCourseByIdAuth(
			courseId,
			authToken
		);
		if (response.success) {
			if(response.data && response.data.isPurchasedCourse){
				window.location.reload();
			}
		}
		else {
			alert(response.message);
		}
	}

	const updatePageViewAfterSignUp = async (userDetails) => {
		if (userDetails.newUser && pageViewDocId !== "") {
			let authToken = userDetails.authToken
			let userId = userDetails._id;
			let country = userDetails.country;
			let payload = {
				pageViewDocId: pageViewDocId,
				isSignedUp: true,
				userId: userId,
				country: country
			}
			const response = await PageViewServices.updatePageViewAfterSignUp(authToken, payload);
		}
	}

	const saveToken = (token) => {
		try {
			window.localStorage.setItem('eMediat', encryptText(token));
			window.localStorage.setItem('eMediatt', encryptText(Date.now()));
		} catch (err) {
			console.log('Error coming from  saveToken()', err);
		}
	};

	const handleResendOTP = async (userContactNumber) => {
		try {
			dispatch(OTPActions.updateOTPErrorMsg(''));
			for (var i = 1; i <= 5; i++) {
				dispatch(OTPActions.updateOTPNumber({ OTPNumber: '', colNumber: i }));
			}

			const response = await LoginServices.login(userContactNumber);
			if (!response.success) {
				alert(response.message);
				return;
			}
			setOTPToken(response.data.token);
		} catch (err) {
			console.log('Error coming from handleResendOTP()', err);
		}
	};

	const createLead = async (courseId, authToken) => {
		const leadResponse = await LeadsServices.addToLead(courseId, authToken);
		alert(leadResponse.message);
	};

	const handleEnrollAndAddToCart = (action) => {
		try {
			// debugger;
			setTempCartView(action);
			if (!userLogedIn) {
				openOtpPopUp('', '', 'init');
			} else {
				takeCartViewAction(action);
			}
		} catch (err) {
			console.log('Error coming from handleEnrollAndAddToCart()', err);
		}
	};


	// enrollORAddToCart this will come null if coming from mobile
	const handleAddToCart = async (courseDetails, enrollORAddToCart = null) => {
		try {
			// debugger
			setAddToCartLoading(courseDetails._id)
			if (enrollORAddToCart === "addOrViewCart" && isCourseExistsInCart(cartItems, courseDetails)) {
				setCartView(enrollORAddToCart);
			}
			else {
				await updateCurrencyValue();
				let authToken = decryptText(localStorage.getItem('eMediat'));

				let continent = decryptText(localStorage.getItem('cont'));
				let countryName = decryptText(localStorage.getItem('c'));
				let currencySymbol = decryptText(localStorage.getItem('cs'));
				let currencyValue = decryptText(localStorage.getItem('cv'));
				// debugger
				const updatedCourseDetails = await handlePaidCourse(userSpecificOfferDetails, courseDetails);
				const payload = {
					courseId: updatedCourseDetails?._id,
					isInOffer: updatedCourseDetails?.isInOffer,
					continent: continent,
					countryName: countryName,
					currencySymbol: currencySymbol,
					currencyValue: currencyValue,
					offerStartAt: updatedCourseDetails?.offerStartAt,
					offerEndAt: updatedCourseDetails?.offerEndAt,
				};

				const response = await CartServices.addToCart(payload, authToken);

				if (response.success) {
					const getCartItemsResponse = await CartServices.getCartItems(
						payload,
						authToken
					);
					if (getCartItemsResponse.success) {
						dispatch(
							CartActions.updateCartDetails({
								cartItems: getCartItemsResponse.data?.itemsList,
								cartItemCount: getCartItemsResponse.data?.totalItems,
								cartTotalValue: getCartItemsResponse.data?.totalValue,
								cartTotalValueInNumbers: getCartItemsResponse.data?.totalValueInNumbers,
								discountPercentage: 0,
								discountValue: '0',
								discountValueInNumbers: 0,
								offerDetails: null,
							})
						);

						if (enrollORAddToCart === "enroll") {
							setCartView(enrollORAddToCart);
						}

						setAddToCartLoading('')
						return {
							success: getCartItemsResponse.success,
							cartItems: getCartItemsResponse.data?.itemsList
						};
					}

					// Getting lastest data
					// const courseReponse = await CourseServices.getCourseByIdAuth(
					// 	courseId,
					// 	authToken
					// );
					// if (courseReponse.success) {
					// 	dispatch(CourseActions.updateCourseDetails(courseReponse.data));
					// }

				}
			}
			setAddToCartLoading('')
		} catch (err) {
			setAddToCartLoading('')
			console.log('Error coming from handleAddToCart()', err);
		}
	};


	const getUserSpecificOffer = async () => {
		let userSpecificOffer = [];
		try {
			if (
				localStorage.getItem("eMediat")
			) {
				let authToken = decryptText(localStorage.getItem("eMediat"));
				const response = await OfferServices.getUserSpecificOffer(
					authToken
				);
				if (response.success) {
					if (
						response.data &&
						response.data.userSpecificOffer &&
						response.data.userSpecificOffer.length > 0
					) {
						userSpecificOffer = response.data.userSpecificOffer;
					}
				} else {
					if (response.message && response.message !== "User specific offer not found") {
						console.log("Issue coming from getUserSpecificOffer()", response);
					}
				}
			}
		} catch (err) {
			console.log("Error coming from getUserSpecificOffer()", err);
		}
		return userSpecificOffer;
	};


	const takeCartViewAction = async (action, source = null, device = null) => {
		try {
			// debugger

			setTempCartView(action);
			let useThisCartItems = cartItems
			if (source === "afterLogin") {
				let authToken = decryptText(localStorage.getItem("eMediat"));
				let countryName = decryptText(localStorage.getItem("c"));
				let currencySymbol = decryptText(localStorage.getItem("cs"));

				let payload = {
					countryName: countryName,
					currencySymbol: currencySymbol,
				};

				const response = await CartServices.getCartItems(payload, authToken);
				if (response.success) {
					useThisCartItems = response.data?.itemsList
				}
			}
			let runHandleAddToCart = false;
			if (action === "enroll" && useThisCartItems && useThisCartItems.length > 0) {
				// debugger
				let cleanCartAndMakeABackUpStatus = await cleanCartAndMakeABackUp(useThisCartItems)
				if (cleanCartAndMakeABackUpStatus) {
					runHandleAddToCart = true
				}
				else {
					alert('Issue in cleaning cart data')
				}
			}
			else {
				runHandleAddToCart = true
			}
			// dXebugger;
			if (runHandleAddToCart) {
				if (device === "mobile") {
					let response = await handleAddToCart(courseDetails);
					return response;
				}
				else {
					handleAddToCart(courseDetails, action);
				}
			}
			setTimeout(function () {
				setCartView('');
			}, 2000);

		} catch (err) {
			console.log('Error coming from takeCartViewAction()', err);
		}
	};

	const cleanCartAndMakeABackUp = async (useThisCartItems) => {
		let status = false;
		try {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			let payload = {
				cartItems: useThisCartItems
			}
			const response = await CartServices.removeCartItemsInBulk(payload, authToken);
			// dXebugger
			if (response.success) {
				dispatch(
					BackUpCartAction.updateBackUpCartDetails(useThisCartItems)
				)
				dispatch(
					CartActions.resetCartReducers()
				)
				dispatch(CartActions.updateUserDetails(userDetails));
				status = true
			}
			return status
		}
		catch (err) {
			console.log("Error coming from cleanCartAndMakeABackUp()", err);
			return status
		}
	}

	const isCourseExistsInCart = (cartItems, courseDetails) => {
		let courseExists = false;
		if (cartItems.length > 0) {
			for (let i = 0; i < cartItems.length; i++) {
				let cartItem = cartItems[i];
				if (
					cartItem.courseId &&
					courseDetails._id &&
					cartItem.courseId === courseDetails._id
				) {
					courseExists = true;
					break;
				}
			}
		}
		return courseExists;
	};

	const handleEnrollSendOTP = async (countryCode, contactNumber, buttonType) => {
		try {
			setButtontype(buttonType);
			setEnrollCountryCode(countryCode);
			setEnrollContactNumber(contactNumber);
			const response = await LoginServices.login(contactNumber);
			if (!response.success) {
				alert(response.message);
				return;
			}
			setOTPToken(response.data.token);
		} catch (err) {
			console.log('Error coming from handleEnrollSendOTP()', err);
		}
	};


	const handleFreeCourse = async (courseData, source = null) => {
		try {
			setAddToCartLoading('addingToMyLearning');
			if (source === null && !userLogedIn) {
				openOtpPopUp('', '', 'init', 'addFreeCourse');
			}
			else {
				let useThisCourseData = courseData
				if (Object.entries(courseData).length === 0) {
					useThisCourseData = courseDetails
				}
				const continent = decryptText(localStorage.getItem("cont"));
				const countryName = decryptText(localStorage.getItem("c"));
				const currencySymbol = decryptText(localStorage.getItem("cs"));
				const currencyCode = decryptText(localStorage.getItem("cc"));

				const order = {
					paymentMethod: "free",
					orderAmount: `${currencySymbol} 0`,
					orderAmountInNumbers: 0,
					coursesMnemonic: courseDetails.mnemonic,
					courses: [{ ...courseDetails, courseId: courseDetails._id.toString() }],
					continent: continent,
					countryName: countryName,
					currencySymbol: currencySymbol,
					currencyCode: currencyCode,
					GST: 0,
					grandTotal: 0,
					netTotal: 0,
					subTotal: 0,
					discount: "0",
					discountInNumbers: 0,
					discountInPercentage: 0,
					offerDetails: null,
					gatewayDiscount: "0",
					gatewayDiscountInNumber: 0,
					gatewayDetails: null,
					paymentStatus: "free",
					installmentOption: null,
					installmentNumber: 0,
					expenseType: "course",
				};
				const authToken = decryptText(localStorage.getItem("eMediat"));

				const response = await CheckoutServices.addFreeOrder(order, authToken);

				if (response.success) {
					const purchasesCount = await getPurchasesCount();
					dispatch(NavbarActions.updatePurchasesCount(purchasesCount));
					setAddToCartLoading('');
					navigate("/dashboard", {});
					return;
				}
				alert(response.message);
			}

		} catch (err) {
			console.log("Error coming from handleFreeCourse()", err);
		}
	};


	return (
		<>
			<Navbar />
			<TopLoadingBar />
			<CourseView
				isLoading={isLoading}
				viewportWidth={viewportWidth}
				userLogedIn={userLogedIn}
				courseDetails={courseDetails}
				openOtpPopUp={openOtpPopUp}
				handleEnrollSendOTP={handleEnrollSendOTP}
				nameAndPhoneNoOtpOpenBtnRef={nameAndPhoneNoOtpOpenBtnRef}
				nameAndPhoneNoOtpStage={nameAndPhoneNoOtpStage}
				handleResendOTP={() =>
					handleResendOTP(
						buttonType === 'interestButton' ? phoneNo : enrollContactNumber
					)
				}
				countryCode={countryCode}
				phoneNo={phoneNo}
				handleLeadsVerifyOTP={handleLeadsVerifyOTP}
				fullNameSetter={fullNameSetter}
				flushNameAndPhoneNoOtpStage={flushNameAndPhoneNoOtpStage}
				handleEnrollAndAddToCart={handleEnrollAndAddToCart}
				addToCartLoading={addToCartLoading}
				handleFreeCourse={handleFreeCourse}
				cartView={cartView}
				handleAddToCart={handleAddToCart}
				takeCartViewAction={takeCartViewAction}
				createLead={createLead}
				buttonType={buttonType}
			/>
			<Footer />
		</>
	);
}

export default Course;
