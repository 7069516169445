import { createRoot } from 'react-dom/client';
import ProfileImgPlaceholder from '@assets/video/profileImgPlaceholder.png';
import CommentReplyInputV2 from '@components/CommentReplyInputV2';

export const handleCommentReply = (
	event,
	displayPhoto,
	videoDuration,
	handleAddNewReply,
	navigateToLogin
) => {
	let replyContainerId = event.target.dataset.reply_container_id;
	let hasReplyContainer = event.target.dataset.has_reply_container;
	if (hasReplyContainer === 'false') {
		event.target.dataset.has_reply_container = 'true';
		let parentElement = event.target.parentNode;
		let commentReplyInputContainer = document.createElement('div');
		parentElement.insertAdjacentElement('afterend', commentReplyInputContainer);
		createRoot(commentReplyInputContainer).render(
			<CommentReplyInputV2
				replyContainerId={replyContainerId}
				ProfileImgPlaceholder={displayPhoto ? displayPhoto : ProfileImgPlaceholder}
				videoDuration={videoDuration}
				handleAddNewReply={handleAddNewReply}
				navigateToLogin={navigateToLogin}
			/>
		);
	} else {
		if (document.getElementById('replyContainerId_' + replyContainerId)) {
			let replyContainer = document.getElementById(
				'replyContainerId_' + replyContainerId
			);
			replyContainer.style.display = 'block';
		}
	}
};
