import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as UserDashboardActions from '@redux/actions/UserDashboard';
import * as TimelineRedirectActions from '@redux/actions/TimelineRedirect';
import CompleteProfileView from './view';

function CompleteProfile() {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const course = useSelector((state) => state.dashboard.course);
	const purchaseId = window.location.href.split('/').pop();

	const handleDynamicRedirect = (event, action) => {
		const yCoordinate = event.clientY;
		dispatch(UserDashboardActions.updateActiveTab('profileTab'));
		dispatch(TimelineRedirectActions.updateTimelineRedirectDetails({
			courseId: course._id,
			section: action,
			purchaseId: purchaseId,
			yCoordinate : yCoordinate
		}));
		navigate(`/dashboard#${action}`);		
	};

	return (
		<>
			<CompleteProfileView
				handleDynamicRedirect={handleDynamicRedirect}
			/>
		</>
	);
}

export default CompleteProfile;
