import React from 'react';

import SortIcon from '@assets/video/sort.png';
import ProfileImgPlaceholder from '@assets/video/profileImgPlaceholder.png';

import './userComment.css';

function UserCommentView(props) {
	return (
		<>
			{/* START : Top section */}
			<div className='row commentTopSection'>
				{props.currentVideoComments && props.currentVideoComments.success && (
					<p>Comments ({props.currentVideoComments.data.totalNumberOfComments}) </p>
				)}
				<button onClick={props.handleSort}>
					<img
						src={SortIcon}
						alt='sort'
						style={{
							width: '15px',
							marginRight: '5px',
							transform: props.isSorted ? 'scaleY(1)' : 'scaleY(-1)',
						}}
					/>
					Sort
				</button>
			</div>
			{/* END : Top section */}

			{/* START : User comment section */}
			<div className='userCommentContainer'>
				<div className='row' style={{ margin: '20px 0px 10px 0px' }}>
					<div className='col-profileImgPlaceholder'>
						<img
							src={
								props.userDisplayPhoto 
									? props.userDisplayPhoto
									: ProfileImgPlaceholder
							}
							alt='Profile Placeholder'
							style={{ width: '30px' }}
						/>
					</div>
					<div className='col-userCommentInput' style={{ paddingLeft: '10px' }}>
						<input
							type='text'
							className='w-100'
							placeholder='Add a comment'
							value={props.userComment}
							onFocus={(event) => props.handleUserCommentFocus(event)}
							onChange={(event) => props.handleUserCommentChange(event)}
						/>
					</div>
				</div>

				{props.isUserCommentInFocus && (
					<div className='row CCBtnContainer'>
						<button
							className='cancleBtn'
							onClick={(event) => props.handleUserCommentCancleBtn(event)}>
							Cancel
						</button>

						<button
							className={`continueBtn ${
								props.userComment.length > 0 && 'continueBtnAddOn'
							}`}
							disabled={props.userComment.length > 0 ? false : true}
							onClick={(event) => {
								props.handleUserCommentBtn(event);
							}}>
							Comment
						</button>
					</div>
				)}
			</div>
			{/* END : User comment section */}
		</>
	);
}

export default UserCommentView;
