import React from 'react';
import { useSelector } from 'react-redux';

import './style.css';

import CouserLoader from './loader';

import BasicInfo from '@pages/UserDashboard/Profile/BasicInfo';
import LegalInfo from '@pages/UserDashboard/Profile/LegalInfo';
import DeliveryAddress from '@pages/UserDashboard/Profile/DeliveryAddress';
import IdentityVerification from '@pages/UserDashboard/Profile/IdentityVerification';
import BSSForm from '@pages/UserDashboard/Profile/BSSForm';
import AlertIcon from '@assets/userDashboard/alertIcon.png';

function ProbileTabView(props) {
	const user = useSelector((state) => state.dashboard.user);

	let msg = ''
	if (user?.isBasicDetailsRequired && !user?.isPersonalInfoCompleted) {
		msg = 'Please complete Basic Details to unlock the Course'
	} else if (user?.isLegalInfoRequired && !user?.isLegalInfoCompleted) {
		msg = 'Please complete Legal Information to unlock the Course'
	} else if (user?.isDeliveryAddressRequired && !user?.isAddressInfoCompleted) {
		msg = 'Please complete Delivery Address to unlock the Course'
	} else if (user?.isIdentityVerificationRequired && !user?.isIdentityInfoCompleted) {
		msg = 'Please complete Identity Verification to unlock the Course'
	} else if (user?.isBSSDetailsRequired && !user?.bssFormDetails?.isBssInfoCompleted) {
		msg = 'Please complete BSS Details to unlock the Course'
	}

	return (
		<>
			<div className='profileTab'>
				{props.isLoading ?
					(
						<>
							<CouserLoader />
						</>
					) : (
						<>
							{msg ? <div class="alert alert-danger d-flex flex-row justify-content-between" role="alert">{msg}
								<img src={AlertIcon} style={{ height: '20px' }} />
							</div> : null}

							<BasicInfo />
							<LegalInfo />
							<DeliveryAddress />
							<IdentityVerification />
							<BSSForm />

						</>
					)
				}
			</div>
		</>
	);
}

export default ProbileTabView;
