import React from 'react';
import moment from 'moment';
import PdfIcon from "@assets/userDashboard/Icons/pdfIcon.png";
import './style.css';

function TransactionsView(props) {
    return (
        <>
            {props.transactionsDetails && props.transactionsDetails.data && props.transactionsDetails.data.length > 0 &&
                <div className='transactionsView'>
                    <div className='sTop'>
                        <div className='fHeadingContainer' style={{ marginBottom: '12px' }}>
                            <div className='fHeadingContainer_1'>
                                <h3 className='fHeading'>TRANSACTIONS</h3>
                            </div>
                        </div>

                        <div className='formContainer'>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr style={{ borderBottom: '2px solid #B1B1B1'}}>
                                        <th className='thParticular'>Particular</th>
                                        <th className='thCost'>Cost</th>
                                        <th className='thGateway'>Gateway</th>
                                        <th className='thVendor'>Vendor</th>
                                        <th className='thDate'>Date and Time</th>
                                        <th className='thInvoice'>Invoice</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.transactionsDetails.data.map((transaction, index) => (
                                        <tr key={index}>
                                            <td className='tdParticular'>
                                                <span>
                                                    {transaction.coursesMnemonic}
                                                </span>
                                            </td>
                                            <td className='tdCost'>{transaction.amtPaid.replace(" ", "")}</td>
                                            <td className='tdGateway'>{transaction.paymentGateway}</td>
                                            <td className='tdVendor'>
                                                <span>
                                                    eMediskill
                                                    {/* {transaction.vendor} */}
                                                </span>
                                            </td>
                                            <td className='tdDate'>
                                                <span className='d1'>{moment(transaction.purchaseDate).format('Do MMM YYYY')}</span>
                                                <span className='d2'>{moment(transaction.purchaseDate).format('HH:mm')}</span>
                                            </td>
                                            <td className='tdInvoice'>
                                                <img
                                                    onClick={(event) => props.handleRedirectToInvoicePage(event, index)}
                                                    className='pdfIcon' src={PdfIcon} alt='pdfIcon' />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default TransactionsView;
