import React from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import AlertIcon from '@assets/userDashboard/Icons/alertIcon.png';
import TickIcon from '@assets/userDashboard/Icons/verifiedTick.png';

import FileUpload from '@assets/userDashboard/Icons/fileUpload.png';

function InputFile(props) {
	return (
		<>
			<div className='fileInputMainContainer justify-content-start gap-2'>
				<p className='heading d-flex align-items-center'>
					<span style={{marginRight:'7px'}}>
						{props.text}
						{props.isReq === 'true' && <b className='asterisk'>*</b>}
					</span>

					{props.isReq === 'true' &&
						props.showError &&
						props.showError === 'true' &&
						props.iserror &&
						(!props.value || props.value === '' || props.value === null) && (
							<Tags
								span_font_size='10px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#962020'
								bc='#A62323'
								text='Input Required'
								imgs={InputError}
							/>
						)}
				</p>
				
				<div className='ms-auto'></div>
				{ props.docStatus === 'Verified' ? 
					<Tags span_font_size='13px'
							img_margin='5px'
							bg='#EEFFE4'
							c='#139528'
							bc='#139528'
							text={'Verified'}
							imgs={TickIcon}
						/>
				: props.docStatus === 'Rejected' ?
					<Tags span_font_size='13px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#BA131A'
								bc='#F7A8AD'
								text={'Rejected'}
								imgs={AlertIcon}
							/>
					
				: props.value ?
				<a href={props.value} target="_blank" style={{textDecoration:'none'}}>
					<Tags span_font_size='13px'
						img_margin='15px'
						bg='#f5f5f5'
						c='#999'
						bc='#999'
						text={'Uploaded'}
						imgs={''}
					/></a> :''

				} 
				{/* <span className='fileName'>s</span> */}
				{props.docStatus !== 'Verified' && 
					<button
						id={props.buttonId}
						onClick={(e) => props.handleFileUpload(e.target.id)}>
						Attach
						<img src={FileUpload} alt='FileUpload' 
						onClick={(e) => props.handleFileUpload(props.buttonId)}
						/>
					</button>
				}
				
				<input 
					id={props.inputFileId} 
					className='d-none' 
					type='file' 
					accept='image/jpeg, image/png, image/jpg' 
					/>
			</div>
		</>
	);
}

export default InputFile;
