const initialState = {
	GST: 0,
	grandTotal: 0,
	netTotal: 0,
	subTotal: 0,
	discount: 0,
	discountInPercentage: 0,
	couponDetails: null,
	orderId: null,
};

const CheckoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CHECKOUT_DETAILS':
			return {
				...state,
				GST: action.payload.details.GST,
				grandTotal: action.payload.details.grandTotal,
				netTotal: action.payload.details.netTotal,
				subTotal: action.payload.details.subTotal,
				discount: action.payload.details.discount,
				discountInPercentage: action.payload.details.discountInPercentage,
			};
		case 'UPDATE_COUPON_DETAILS':
			return {
				...state,
				couponDetails: action.payload.couponDetails,
			};
		case 'UPDATE_ORDER_ID':
			return {
				...state,
				orderId: action.payload.orderId,
			};
		case 'RESET_CHECKOUT_REDUCER':
			return initialState;
		default:
			return state;
	}
};

export default CheckoutReducer;
