import React from 'react';
import ContentLoader from "react-content-loader";

import './style.css';

function MobileLoader(props) {
	return (
		<>
			<div className='contentLoaderForProductPageScreenGreaterThen576'>
				<ContentLoader
					// backgroundColor="#9e0505"
					// foregroundColor="#ff8585"
					viewBox='0 0 400 900' style={{ marginTop: '20px' }}>
					{/* Product Card */}
					<rect x='100' y='10' rx='3' ry='3' width='200' height='120' />

					<rect x='60' y='140' rx='3' ry='3' width='280' height='13' />
					<rect x='60' y='165' rx='3' ry='3' width='210' height='13' />
					<rect x='60' y='190' rx='3' ry='3' width='170' height='13' />

					<circle cx='70' cy='230' r='9' />
					<rect x='90' y='227.5' rx='1' ry='10' width='60' height='6' />

					<circle cx='70' cy='255' r='9' />
					<rect x='90' y='252.5' rx='1' ry='10' width='60' height='6' />

					<circle cx='70' cy='280' r='9' />
					<rect x='90' y='277.5' rx='1' ry='10' width='60' height='6' />

					<circle cx='70' cy='305' r='9' />
					<rect x='90' y='302.5' rx='1' ry='10' width='60' height='6' />

					<rect x='60' y='345' rx='3' ry='3' width='280' height='13' />
					<rect x='60' y='370' rx='3' ry='3' width='210' height='13' />

					<circle cx='70' cy='405' r='9' />
					<rect x='90' y='402' rx='1' ry='10' width='60' height='6' />

					<circle cx='70' cy='435' r='9' />
					<rect x='90' y='432' rx='1' ry='10' width='60' height='6' />

					{/* Product Icons */}
					<rect x='80' y='465' rx='1' ry='10' width='40' height='30' />
					<rect x='88' y='500' rx='1' ry='10' width='25' height='5' />

					<rect x='150' y='465' rx='1' ry='10' width='40' height='30' />
					<rect x='158' y='500' rx='1' ry='10' width='25' height='5' />

					<rect x='220' y='465' rx='1' ry='10' width='40' height='30' />
					<rect x='228' y='500' rx='1' ry='10' width='25' height='5' />

					<rect x='290' y='465' rx='1' ry='10' width='40' height='30' />
					<rect x='298' y='500' rx='1' ry='10' width='25' height='5' />

					<rect x='60' y='520' rx='3' ry='3' width='280' height='18' />

					{/* Preview Video */}
					<rect x='60' y='560' rx='3' ry='3' width='280' height='200' />
				</ContentLoader>
			</div>

			<div className='contentLoaderForProductPageScreenLesserThen576'>
			<ContentLoader
					// backgroundColor="#9e0505"
					// foregroundColor="#ff8585"
					viewBox='0 0 400 880'>
					{/* Product Card */}
					<rect x='50' y='10' rx='3' ry='3' width='300' height='170' />

					<rect x='50' y='195' rx='3' ry='3' width='280' height='13' />
					<rect x='50' y='220' rx='3' ry='3' width='210' height='13' />
					<rect x='50' y='245' rx='3' ry='3' width='170' height='13' />


					<circle cx='60' cy='285' r='9' />
					<rect x='80' y='283.5' rx='1' ry='10' width='60' height='6' />

					<circle cx='60' cy='310' r='9' />
					<rect x='80' y='308.5' rx='1' ry='10' width='60' height='6' />

					<circle cx='60' cy='335' r='9' />
					<rect x='80' y='333.5' rx='1' ry='10' width='60' height='6' />

					<circle cx='60' cy='360' r='9' />
					<rect x='80' y='357.5' rx='1' ry='10' width='60' height='6' />

					<rect x='50' y='400' rx='3' ry='3' width='280' height='13' />
					<rect x='50' y='425' rx='3' ry='3' width='210' height='13' />

					<circle cx='60' cy='460' r='9' />
					<rect x='80' y='457' rx='1' ry='10' width='60' height='6' />

					<circle cx='60' cy='490' r='9' />
					<rect x='80' y='487' rx='1' ry='10' width='60' height='6' />

					{/* Product Icons */}
					<rect x='70' y='520' rx='1' ry='10' width='40' height='30' />
					<rect x='78' y='555' rx='1' ry='10' width='25' height='5' />

					<rect x='140' y='520' rx='1' ry='10' width='40' height='30' />
					<rect x='148' y='555' rx='1' ry='10' width='25' height='5' />

					<rect x='210' y='520' rx='1' ry='10' width='40' height='30' />
					<rect x='218' y='555' rx='1' ry='10' width='25' height='5' />

					<rect x='280' y='520' rx='1' ry='10' width='40' height='30' />
					<rect x='288' y='555' rx='1' ry='10' width='25' height='5' />

					<rect x='50' y='580' rx='3' ry='3' width='280' height='18' />

					{/* Preview Video */}
					<rect x='0' y='620' rx='3' ry='3' width='400' height='300' />
				</ContentLoader>
			</div>
		</>
	);
}

export default MobileLoader;
