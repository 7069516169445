import React from 'react';
import ContentLoader from "react-content-loader";
import './style.css';

export default () => (
    <div className='settingTabDisplayDetailsCouserLoader'>

        {/* START Desktop */}
        <ContentLoader
            className='settingTabDisplayDetailsCouserLoaderDesktop'
            backgroundColor="#ffffff"
            // foregroundColor="#a09d9d"
            viewBox="0 0 395 60">

            <rect x='0' y='0' rx='2' ry='2' width='80' height='8' />
            <rect x='0' y='20' rx='2' ry='2' width='40' height='8' />
            <rect x='0' y='34' rx='2' ry='2' width='240' height='8' />
            <rect x='0' y='48' rx='2' ry='2' width='120' height='8' />
            <circle cx="360" cy="25" r="20" />
        </ContentLoader>
        {/* END Desktop */}



        {/* START Mobile */}
        <ContentLoader
            className='settingTabDisplayDetailsCouserLoaderMobile'
            backgroundColor="#ffffff"
            // foregroundColor="#a09d9d"
            viewBox="0 0 100 30">
            <rect x='0' y='0' rx='2' ry='2' width='50' height='4' />
            <rect x='0' y='10' rx='2' ry='2' width='30' height='4' />
            <rect x='0' y='18' rx='2' ry='2' width='80' height='4' />
            <rect x='0' y='26' rx='2' ry='2' width='50' height='4' />
            <circle cx="88" cy="8" r="8" />
        </ContentLoader>
        {/* END Mobile */}
    </div>
);
