import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { decryptText } from '@library/enc-dec';

import * as ExamServices from '@services/Exam';
import ETView from './view';

function Test(props) {
	const navigate = useNavigate();
	const [etPopupPurchaseData, setETPopupPurchaseData] = useState({});
	const [openPopupStatus, setOpenPopupStatus] = useState(false);
	const closeEtPopupBtnRef = useRef(null);
	const [etPoupOkBtnLoading, setEtPoupOkBtnLoading] = useState(false)
	const examType = "Test"

	const handleF1Exam = (purchase) => {
		setOpenPopupStatus(true);
		setETPopupPurchaseData(purchase);
	};
	const showResult = (purchase) => {
		navigate('/exam-result', {
			state: {
				purchaseId: purchase._id,
				examType: examType
			},
		});
	};

	const handleRedirectToEaxm = async (purchase) => {
		try {
			setOpenPopupStatus(false);
			if (purchase === null) {
				return;
			}

			const courseId = purchase.testExamId; // in case of examType as test courseId will hold testExamId
			const purchaseId = purchase._id; // in case of examType as test purchaseId will hold testExamDocId
		
			// This logic is not used for test let the user attempt exams
			// if (purchase.reAttempt < 2) {
			// } else {
			// }

			setEtPoupOkBtnLoading(true)
			let payload = {
				testExamDocId: purchaseId
			}
			const authToken = decryptText(localStorage.getItem('eMediat'));
			const response = await ExamServices.reAttemptExam(examType, authToken, payload);

			if (response.success) {
				debugger;
				if (closeEtPopupBtnRef.current) {
					closeEtPopupBtnRef.current.click();
				}
				navigate('/exam', {
					state: {
						courseId: courseId,
						purchaseId: purchaseId,
					},
				});
			}
			else {
				console.log('Issue from handleRedirectToEaxm() ', response)
				alert('Issue while re-attempting exam, re-try after refreshing the page');
			}


			/* OLD CODE(V1) :
					try {
						// Navigate to exam page
						navigate('/exam', {
							state: {
								courseId: purchase.courseId,
								purchaseId: purchase._id,
							},
						});
					} catch (err) {
						console.log('Error coming from handleExamUnlocking()', err);
					}
			*/
		} catch (err) {
			console.log('Error coming from handleRedirectToEaxm()', err);
		}
	};

	return (
		<>
			<ETView
				handleF1Exam={handleF1Exam}
				etPopupPurchaseData={etPopupPurchaseData}
				openPopupStatus={openPopupStatus}
				handleRedirectToEaxm={handleRedirectToEaxm}
				showResult={showResult}
				popUpType={'Test'}
				closeEtPopupBtnRef={closeEtPopupBtnRef}
				etPoupOkBtnLoading={etPoupOkBtnLoading}
				d={props.testDetails}
			/>
		</>
	);
}

export default Test;
