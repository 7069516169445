import React from 'react';

import Tags from '@components/Tags';

import './style.css';
import infoIcon from '@assets/timeline/icons/infoIcon.png';
import UnderProgress from '@assets/timeline/icons/underProgress.png';
import PurpleHeart from '@assets/timeline/icons/purpleHeart.png';

export default (props) => (
	<div className='courseCardView'>
		<p className='topHeading'>
			<span>Course Details</span>
			<img src={infoIcon} alt='infoIcon' />
		</p>

		<div className='row xrow'>
			<img
				className='col-4 col-sm-4 col-md-12'
				// src='https://emediskill.s3.ap-south-1.amazonaws.com/courseThumbnails/cch.png'
				src={
					props.timelineData?.courseDetails? window.innerWidth > 576
						? props.timelineData?.courseDetails?.rectangleThumbnailUrl
						: props.timelineData?.courseDetails?.squareThumbnailUrl
						: ''
				}
				alt='courseThumbnail'
			/>

			<hr className='d-none d-md-block' style={{ margin: '1rem 0px' }} />

			<div className='col-8 col-sm-8 col-md-12'>
				<p className='courseTitle'>{props.timelineData?.title}</p>
				<div className='d-flex' style={{ marginBottom: '7px' }}>
					{props.timelineData?.courseStatus === 'active' && (
						<Tags
							bg='#FDF2DC'
							c='#845201'
							bc='#845201'
							text='Under Progress'
							imgs={UnderProgress}
						/>
					)}
					{props.timelineData?.paymentMethod === 'free' && (
						<Tags
							bg='#F4E5EF'
							c='#A91A90'
							bc='#A91A90'
							text='Free Access'
							imgs={PurpleHeart}
						/>
					)}
				</div>
			</div>
		</div>
	</div>
);
