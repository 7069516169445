import React from "react";

import './aboutUs.css'
import '@css/common.css';

import Content from '@content/AboutUs';

import DesktopBanner from '@assets/aboutUs/desktopBanner.svg';
import WhatIseMediSkillImg from '@assets/aboutUs/whatIseMediSkill.svg';
import ManImg from '@assets/aboutUs/man.png';
import WomenImg from '@assets/aboutUs/women.png';


import ContentLoader from 'react-content-loader';

function AboutUsView(props) {
    return (
        <>
            <center>
                <div id='aboutUsBanner' className='position-relative homePageMainBannerContainer'>
                    <img
                        alt='Banner'
                        onLoad={props.handleBannerImageLoading}
                        style={{ display: props.isBannerLoaded ? 'block' : 'none' }}
                        src={DesktopBanner}
                    />
                    {props.isBannerLoaded ? (
                        <>
                            <div className='position-absolute d-none d-lg-block'
                                id='aboutUsBannerTextDivDektop'>
                                <h3>{Content.banner.title1}</h3>
                                <div>
                                    <p>
                                        {Content.banner.title2}
                                    </p>
                                </div>
                            </div>

                            <div className='position-absolute d-block d-lg-none'
                                id='aboutUsBannerTextDivTablet'>
                                <h3>{Content.banner.title1}</h3>
                                <div>
                                    <p>
                                        {Content.banner.title2}
                                    </p>
                                </div>
                            </div>

                            <div className="bottomNote">
                                <span>
                                    {Content.banner.bottomNote}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='d-none d-sm-block' style={{ marginTop: '-10px' }}>
                                <ContentLoader viewBox='0 0 500 130'>
                                    <rect x='0' y='0' rx='0' ry='0' width='500' height='130' />
                                </ContentLoader>
                            </div>

                            <div className='d-block d-sm-none'>
                                <ContentLoader viewBox='0 0 500 500'>
                                    <rect x='0' y='0' rx='0' ry='0' width='500' height='500' />
                                </ContentLoader>
                            </div>
                        </>
                    )}
                </div>


                <div className="aboutUsePageMainContainer">
                    <div className="reasonsToStartContainer text-center">
                        <h3 style={{ marginTop: '40px', marginBottom: '40px' }}>{Content.section1.title}</h3>
                        <div className="row customRowFixer">
                            {Content.section1.data.map((content, index) => (
                                <>
                                    <div key={index} className="col-12 col-sm-4 singleReason">
                                        <img src={content.img} alt="DocGif" />
                                        <p className="heading">
                                            {content.title}
                                        </p>
                                        <p className="subHeading">
                                            {content.description}
                                        </p>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>



                    <div className="whatIseMediSkillContainer">
                        <div className="row customRowFixer whatIseMediSkillInnerContainer">
                            <div className="col-12 col-sm-6 imgContainer">
                                <img src={WhatIseMediSkillImg} alt="WhatIseMediSkillImg" />
                            </div>
                            <div className="col-12 col-sm-6 textContainer">
                                <h1>
                                    {Content.section2.title}
                                </h1>
                                <p>
                                    {Content.section2.description}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="careerContainer">
                        <div className="row customRowFixer careerInnerContainer">
                            <div className="d-none d-sm-block col-3 imgContainer">
                                <img src={WomenImg} alt="WomenImg" />
                            </div>

                            <div className="col-12 col-sm-6 text-center textContainer">
                                <h4>
                                    {Content.section3.title}

                                </h4>
                                <p>
                                    {Content.section3.description}
                                </p>
                            </div>

                            <div className="d-none d-sm-block col-3 imgContainer">
                                <img src={ManImg} alt="ManImg" />
                            </div>
                        </div>
                    </div>

                </div>
            </center>
        </>
    )
}

export default AboutUsView;