const initialState = {
	typeOfCourses: {},
	currentCourseDetails: null,
	coursesByCategory: {},
};

const ExploreReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_TYPE_OF_COURSES':
			return {
				...state,
				typeOfCourses: action.payload.typeOfCourses,
			};
		case 'UPDATE_CURRENT_COURSE_DETAILS':
			return {
				...state,
				currentCourseDetails: action.payload.currentCourseDetails,
			};

		case 'UPDATE_COURSE_BY_CATEGORY_ID':
			return {
				...state,
				coursesByCategory: action.payload.coursesByCategory,
			};
		case 'RESET_EXPLORE_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default ExploreReducer;
