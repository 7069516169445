export const signUp = async (
	firstName,
	lastName,
	countryCode,
	countryName,
	email,
	contactNumber,
	username,
	password,
	confirmPassword,
	sendNotification
) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/signUp`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				firstName: firstName,
				lastName: lastName,
				contactNumber: contactNumber,
				country: countryName,
				countryCode: countryCode,
				email: email,
				username: username,
				password: password,
				confirmPassword: confirmPassword,
				sendNotification: sendNotification,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from SignUp  services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
