import React from 'react';
import './style.css';

import Registration from '@pages/Course/Sections/Registration';
import OTP from '@components/Otp';
import Call from '@pages/Course/Sections/Call';
import SendBtn from '@assets/course/sendBtn.png';
import BlueBgWhiteRight from '@assets/course/blueBgWhiteRight.png';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';
import NameAndPhoneNoOtpContent from "@components/Popups/NameAndPhoneNoOtpContent";

function WriteToUsView(props) {
	return (
		<>
			<div className='writeToUsView'>
				<Call />

				{props.writeUseSubmitted ?
					(
						<div className='writeToUseSubmittedContainer'>
							<img src={BlueBgWhiteRight} alt='blueBgWhiteRight' />
							<p>Message sent! Our agent will get to it soon...</p>
						</div>
					) : (
						<div className='writeToUseContainer'>
							{props.openRegistration ?
								(
									<>
										{/* START : component Registration */}
										<div className='col-12'>
											<NameAndPhoneNoOtpContent
												SendBtn={SendBtn}
												handleSuccessOtp={props.newHandleSuccessOtp}
											/>
										</div>
										{/* END : component Registration */}
									</>
								) : (
									<>
										<p className='writeToUseError'>{props.writeToUseError}</p>
										<textarea
											onChange={(event) => props.handleWriteToUsChange(event)}
											placeholder='Write to us here...'
											className="form-control"
											rows="3"></textarea>
										<img
											className='sendBtn'
											onClick={() => props.handleWriteToUsSubmit()}
											src={SendBtn} alt='sendBtn' />
									</>
								)}
						</div>

					)}
			</div>
		</>
	);
}

export default WriteToUsView;
