export const updateSeriesDetails = (value) => {
	return {
		type: 'UPDATE_SERIES_DETAILS',
		payload: {
			seriesDetails: value,
		},
	};
};

export const updateCurrentContentType = (value) => {
	return {
		type: 'UPDATE_CURRENT_CONTENT_TYPE',
		payload: {
			currentContentType: value,
		},
	};
};

export const updateCurrentVideoData = (value) => {
	return {
		type: 'UPDATE_CURRENT_VIDEO_DATA',
		payload: {
			currentVideoData: value,
		},
	};
};

export const updateCurrentVideoComments = (value) => {
	return {
		type: 'UPDATE_CURRENT_VIDEO_COMMENTS',
		payload: {
			currentVideoComments: value,
		},
	};
};

export const updateCommentReply = (value) => {
	return {
		type: 'UPDATE_COMMENT_REPLY',
		payload: {
			commentId: value.commentId,
			reply: value.reply,
		},
	};
};

export const updateFavStatus = (value) => {
	return {
		type: 'UPDATE_FAV_STATUS',
		payload: {
			status: value.status,
			videoDocId: value.videoDocId,
		},
	};
};

export const updateUserDetails = (value) => {
	return {
		type: 'UPDATE_USER_DETAILS',
		payload: {
			userDetails: value,
		},
	};
};

export const resetSeriesDetailsReducers = () => {
	return {
		type: 'RESET_SERIES_DETAILS_REDUCER',
	};
};
