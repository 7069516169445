import React from 'react';

import './requestACall.css';

import Peoples from '@assets/requestACall/peoples.svg';
import GrayRightTick from '@assets/requestACall/grayRightTick.png';
import ThankYouGif from '@assets/requestACall/thankYou.gif';
import Content from '@content/RequestACall';

function RequestACallView(props) {
	return (
		<>
			<center>
				<div className='requestACallMainContainer'>
					<div className='row customRowFixer requestACallInnerContainer'>
						{/* START : peopleContainer */}
						<div className='col-12 col-md-6 peopleContainer'>
							<h4 style={{ fontWeight: '700' }}>Today</h4>
							<p style={{ color: '#252525' }}>{props.currentDate}</p>
							<img className='peopleImg' src={Peoples} alt='Peoples' />
							<ul>
								<li>support@emediskill.com</li>
								<li>business@emediskill.com</li>
								<li>legal@emediskill.com</li>
							</ul>
							<p className='note'>{Content.note}</p>
						</div>
						{/* END : peopleContainer */}

						{/* START : formContainer */}
						<div
							className={`col-12 col-md-6 formContainer ${
								!props.isFormSubmittedSuccessfully
									? ''
									: 'formContainer_thankyouFormContainer'
							}`}>
							<span id='thankYouScrollContainer'></span>
							{props.isFormSubmittedSuccessfully ? (
								<>
									<div className='thankYouContainer'>
										<img src={ThankYouGif} alt='ThankYouGif' />
										<h1>Thank You!</h1>
										<p>{Content.thankYouNode}</p>
									</div>
								</>
							) : (
								<>
									<h4 style={{ fontWeight: '700' }}>Request assistance</h4>
									{props.allFieldsRequiredError && (
										<p className='alert alert-danger'>All fields are required</p>
									)}

									<p>Our friendly team would love to hear from you!</p>

									{/* First & last Name Container */}
									<div className='row'>
										<div className='form-group col-6'>
											<input
												className='w-100'
												type='text'
												placeholder='First Name'
												name='firstName'
												id='firstName'
												onChange={props.handleFirstName}
												value={props.firstName}
											/>
										</div>

										<div className='form-group col-6'>
											<input
												className='w-100'
												type='text'
												placeholder='Last Name'
												name='lastName'
												id='lasttName'
												onChange={props.handleLastName}
												value={props.lastName}
											/>
										</div>
									</div>

									{/* Email ID Container */}
									<div className='form-group col-12'>
										<input
											className='w-100'
											type='email'
											placeholder='Email ID'
											name='reqEmail'
											id='reqEmail'
											onChange={props.handleEmail}
											value={props.email}
										/>
									</div>

									{/* Contact no. Container */}
									<div className='row'>
										<div className='col-3'>
											<select
												value={props.countryCode}
												onChange={(event) => {
													props.handleCountryCode(event);
												}}
												className='form-select'>
												<option selected value=''>
													{' '}
												</option>
												{props.countries &&
													props.countries.map((country, index) => (
														<>
															<option key={index} value={country.countryCode}>
																{props.countryCode === country.countryCode
																	? country.countryCode
																	: country.countryName + ' ' + country.countryCode}
															</option>
														</>
													))}
											</select>
										</div>
										<div className='col-9'>
											<input
												value={props.contactNumber}
												onChange={(event) => {
													props.handleContactNumber(event);
												}}
												className='w-100'
												type='tel'
												placeholder='Contact Number'
												name='contactNumber'
												id='contactNumber'
											/>
										</div>
									</div>

									{/* Whatsapp Contact no. Container */}
									<div className='row'>
										<div className='col-3'>
											<select
												value={props.whatsAppCountryCode}
												disabled={props.whatsAppNumber_E_contactNumber}
												onChange={(event) => {
													props.handleWhatsAppCountryCode(event);
												}}
												className='form-select'>
												<option selected value=''>
													{' '}
												</option>
												{props.countries &&
													props.countries.map((country, index) => (
														<>
															<option key={index} value={country.countryCode}>
																{props.whatsAppCountryCode === country.countryCode
																	? country.countryCode
																	: country.countryName + ' ' + country.countryCode}
															</option>
														</>
													))}
											</select>
										</div>
										<div className='col-9'>
											<input
												value={props.whatsAppNumber}
												onChange={(event) => {
													props.handleWhatsAppNumber(event);
												}}
												readOnly={props.whatsAppNumber_E_contactNumber}
												className='w-100'
												type='tel'
												placeholder='WhatsApp Number'
												name='whatsAppNumber'
												id='whatsAppNumber'
											/>
										</div>
									</div>

									{/* handleWhatsAppNumber_E_contactNumber Container */}
									<div className='form-check'>
										<input
											onChange={(event) => {
												props.handleWhatsAppNumber_E_contactNumber(event);
											}}
											className='form-check-input'
											type='checkbox'
											id='whatsAppNumber_E_contactNumber'
										/>
										<label
											style={{ color: '#5C5B5B', marginBottom: '15px' }}
											htmlFor='whatsAppNumber_E_contactNumber'>
											WhatsApp number is same as contact number
										</label>
									</div>

									{/* Query drop down Container */}
									<select
										className='form-select'
										onChange={props.handleTitle}
										value={props.title}>
										<option value=''>Select Issue/Query</option>
										<option value='purchase_issue'>Purchase Issue</option>
										<option value='course_ExamRelatedIssue'>
											Course/Exam Related Issue
										</option>
										<option value='general_Query'>General Query</option>
										<option value='consultation'>Consultation</option>
										<option value='partnership'>Partnership</option>
										<option value='other'>Other</option>
									</select>

									<div className='form-group'>
										<textarea
											placeholder='Describe Issue/Query'
											className='form-control'
											id='exampleFormControlTextarea1'
											rows='6'
											onChange={props.handleDescription}
											value={props.description}></textarea>
									</div>

									{/* Submit btn */}
									<div className='submitBtnContainer'>
										<button onClick={props.handleSubmit}>Submit</button>
									</div>
								</>
							)}
						</div>
						{/* END : formContainer */}
					</div>
				</div>
			</center>
		</>
	);
}

export default RequestACallView;
