import { useEffect, useState, useRef } from 'react';
import ForgotPasswordView from './view';
import { useNavigate } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as LoginActions from '@redux/actions/Login';
import * as LoginServices from '@services/Login';
import { hideShowRWIcons } from '@library/LoginUIHelper';

import Footer from '@components/Footer';
import Navbar from '@components/Navbar';

function ForgotPassword(props) {
	const otpInputRefs = useRef([]);

	const navigate = useNavigate();

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [OTP1, setOTP1] = useState('');
	const [OTP2, setOTP2] = useState('');
	const [OTP3, setOTP3] = useState('');
	const [OTP4, setOTP4] = useState('');
	const [OTP5, setOTP5] = useState('');
	const [isBtnLoading, setIsBtnLoading] = useState(false)
	const [disableCreatePassword, setDisableCreatePassword] = useState(true)

	const [poorPassword, setPoorPassword] = useState(false);
	const [weakPassword, setWeakPassword] = useState(false);
	const [strongPassword, setStrongPassword] = useState(false);
	const [passwordError, setPasswordErr] = useState('');

	const [showOTP, setShowOTP] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [OTPResend, setOTPResend] = useState(false);

	const [showChangePassword, setShowChangePassword] = useState(false);

	useEffect(() => {
		hideShowRWIcons('hide')
		window.scrollTo(0, 0);

		return () => {
			hideShowRWIcons('show')
		}
	}, [])

	const handlePaswordValidation = (value) => {
		setErrorMessage('')
		setDisableCreatePassword(true)
		const passwordValue = value;
		const passwordLength = passwordValue.length;
		const poorRegExp = /[a-z]/;
		const weakRegExp = /(?=.*?[0-9])/;
		const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
		const whitespaceRegExp = /^$|\s+/;
		const poorPassword = poorRegExp.test(passwordValue);
		const weakPassword = weakRegExp.test(passwordValue);
		const strongPassword = strongRegExp.test(passwordValue);
		const whiteSpace = whitespaceRegExp.test(passwordValue);

		if (passwordValue === '') {
			setPasswordErr('Password is Empty');
		} else {
			// to check whitespace
			if (whiteSpace) {
				setPasswordErr('Whitespaces are not allowed');
			}
			// to check poor password
			if (
				passwordLength <= 3 &&
				(poorPassword || weakPassword || strongPassword)
			) {
				setPoorPassword(true);
				setPasswordErr('Password is poor');
			}
			// to check weak password
			if (
				passwordLength >= 4 &&
				poorPassword &&
				(weakPassword || strongPassword)
			) {
				setWeakPassword(true);
				setPasswordErr('Password is weak');
			} else {
				setWeakPassword(false);
			}
			// to check strong Password
			if (passwordLength >= 6 && poorPassword && weakPassword && strongPassword) {
				setStrongPassword(true);
				setPasswordErr('Password is strong');
			} else {
				setStrongPassword(false);
			}

			setPassword(passwordValue);
		}
	};

	const handleConfirmPasword = (value) => {
		setErrorMessage('')
		if (strongPassword) {
			if (password !== value) {
				setDisableCreatePassword(true)
				setPasswordErr('Password is not matching');
			} else {
				setDisableCreatePassword(false)
				setPasswordErr('Password match');
				setConfirmPassword(value);				
			}
		}
	};

	const handleContactNumber = (value) => {
		setErrorMessage('');
		if (value.length <= 10) {
			props.updateContactNumber(value);
		}
	};

	const handleContactConfirmation = async (event) => {
		setIsBtnLoading(true)
		event.preventDefault();
		const { contactNumber } = props;
		const isOTPSent = await LoginServices.verifyContactNumber(contactNumber);

		if (isOTPSent.success === true) {
			setShowOTP(true);			
			props.updateOTPToken(isOTPSent.data.token);
		} else {
			setErrorMessage(isOTPSent.message);
		}
		setIsBtnLoading(false)
	};

	const handlOTPResend = async (event) => {
		event.preventDefault();

		const { contactNumber } = props;
		const isOTPSent = await LoginServices.verifyContactNumber(contactNumber);

		if (isOTPSent.success === true) {
			setOTPResend(true);
			props.updateOTPToken(isOTPSent.data.token);
		} else {
			setErrorMessage(isOTPSent.message);
		}
	};

	const handleOTPConfirmation = async (event) => {
		setIsBtnLoading(true)
		event.preventDefault();

		const { OTPToken } = props;

		let OTPNumber = OTP1 + OTP2 + OTP3 + OTP4 + OTP5;

		const isOTPValid = await LoginServices.verifyForgotPassOTP(
			OTPNumber,
			OTPToken
		);

		if (isOTPValid.success === true) {
			props.updateForgotPassToken(isOTPValid.data.token);
			setShowChangePassword(true);
			setShowOTP(false);
		} else {
			setErrorMessage(isOTPValid.message);
		}
		setIsBtnLoading(false)
	};

	const handleCreatePassword = async (event) => {
		setErrorMessage('');
		setIsBtnLoading(true)
		event.preventDefault();
		const { forgotPassToken } = props;

		const isPasswordUpdated = await LoginServices.resetPassword(
			password,
			confirmPassword,
			forgotPassToken
		);
		if (isPasswordUpdated.success === true) {
			navigate('/login', {
				state: { message: 'Password updated successfully, Please Login again.' },
			});
		} else {
			setErrorMessage(isPasswordUpdated.message);
		}
		setIsBtnLoading(false)
	};


	// START : Fn to handle otp auto fill
	const handleOtpChange = (event, inputId, index) => {
		setErrorMessage('');
		const otp = event.target.value;
		const otpArray = otp.split("");

		let loopLen = 5;
		if (otpArray.length > 0) {
			loopLen = otpArray.length
			if (otpArray.length > 5) {
				loopLen = 5;
			}
		}

		if (otpArray.length === 0) {
			updateOtpValues(otp, index)
		}
		else if (otpArray.length >= 2) {
			for (let i = index; i <= 4; i++) {
				if (i <= loopLen) {
					let newInputId = 'otp_' + parseInt(i + 1);
					if (document.getElementById(newInputId)) {
						let ele = document.getElementById(newInputId)
						updateOtpValues(otpArray[0], i)
						otpArray.shift()
						ele.focus()
					}
				}
			}
		}
		else {
			updateOtpValues(otp, index)
			let newInputId = 'otp_' + parseInt(index + 2);
			if (document.getElementById(newInputId)) {
				let ele = document.getElementById(newInputId)
				ele.focus()
			}
		}
	};


	const updateOtpValues = (otp, index) => {
		if (index === 0) {
			setOTP1(otp);
		} else if (index === 1) {
			setOTP2(otp);
		} else if (index === 2) {
			setOTP3(otp);
		} else if (index === 3) {
			setOTP4(otp);
		} else if (index === 4) {
			setOTP5(otp);
		}
	}
	// END : Fn to handle otp auto fill

	const handleKeyDownOtpChange = (event, index) => {
		if (event.key === 'Backspace' && !event.target.value && index > 0) {
			otpInputRefs.current[index - 1].focus();
		}
	};


	return (
		<>
			<Navbar />
			<ForgotPasswordView
				handlePaswordValidation={handlePaswordValidation}
				handleConfirmPasword={handleConfirmPasword}
				handleOtpChange={handleOtpChange}
				handleKeyDownOtpChange={handleKeyDownOtpChange}
				otpInputRefs={otpInputRefs}
				poorPassword={poorPassword}
				weakPassword={weakPassword}
				strongPassword={strongPassword}
				passwordError={passwordError}
				showOTP={showOTP}
				showChangePassword={showChangePassword}
				handleContactNumber={handleContactNumber}
				contactNumber={props.contactNumber}
				handleContactConfirmation={handleContactConfirmation}
				handleOTPConfirmation={handleOTPConfirmation}
				handleCreatePassword={handleCreatePassword}
				OTPResend={OTPResend}
				handlOTPResend={handlOTPResend}
				errorMessage={errorMessage}

				isBtnLoading={isBtnLoading}
				disableCreatePassword={disableCreatePassword}
				OTP1={OTP1}
				OTP2={OTP2}
				OTP3={OTP3}
				OTP4={OTP4}
				OTP5={OTP5}
			/>
			{/* <Footer /> */}
		</>
	);
}

function mapStateToProps(state) {
	return {
		contactNumber: state.login.contactNumber,
		OTPToken: state.login.OTPToken,
		forgotPassToken: state.login.forgotPassToken,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateContactNumber: LoginActions.updateContactNumber,
			updateOTPToken: LoginActions.updateOTPToken,
			updateForgotPassToken: LoginActions.updateForgotPassToken,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
