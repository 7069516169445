import React, { useEffect, useRef, useState } from 'react';

import UserDetailsView from './view';

function UserDetails(props) {
	const firstNameInput = useRef(null)
	const middleNameInput = useRef(null)
	const lastNameInput = useRef(null)
	const [errorFirstName, setErrorFirstName] = useState('');
	const [errorLastName, setErrorLastName] = useState('');

	const [firstName, setFirstName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');

	useEffect(() => {
		updateUserDetailsOnInput()
	}, [])

	const updateUserDetailsOnInput = () => {
		if (props.userDetails) {
			if (props.userDetails.firstName && firstNameInput.current) {
				setFirstName(props.userDetails.firstName)
				firstNameInput.current.value = props.userDetails.firstName
			}
			if (props.userDetails.middleName && middleNameInput.current) {
				setMiddleName(props.userDetails.middleName)
				middleNameInput.current.value = props.userDetails.middleName
			}
			if (props.userDetails.lastName && lastNameInput.current) {
				setLastName(props.userDetails.lastName)
				lastNameInput.current.value = props.userDetails.lastName
			}
		}
	}

	const handleInputChange = (inputType, event) => {
		setErrorFirstName('');
		setErrorLastName('');

		switch (inputType) {
			case 'fistName':
				setFirstName(event.target.value);
				break;
			case 'middleName':
				setMiddleName(event.target.value);
				break;
			case 'lastName':
				setLastName(event.target.value);
			default:
				break;
		}
	};

	const handleSubmitUserDetails = () => {
		if (firstName === '') {
			setErrorFirstName('Input filed required');
			return;
		}
		if (lastName === '') {
			setErrorLastName('Input filed required');
			return;
		}

		let currentUserDetails = {
			firstName: firstName,
			middleName: middleName,
			lastName: lastName,
		};
		props.handleSidebarView('userDetails', {
			currentUserDetails: currentUserDetails,
		});
	};

	return (
		<UserDetailsView
			handleSubmitUserDetails={handleSubmitUserDetails}
			handleInputChange={handleInputChange}
			errorFirstName={errorFirstName}
			errorLastName={errorLastName}
			firstNameInput={firstNameInput}
			middleNameInput={middleNameInput}
			lastNameInput={lastNameInput}
			{...props}
		/>
	);
}

export default UserDetails;
