import React from 'react';
import FAQView from './view';
import { useSelector } from 'react-redux';

function FAQ() {
	const courseDetails = useSelector((state) => state.course.courseDetails);
	return <FAQView faqDetails={courseDetails?.faqDetails} />;
}

export default FAQ;
