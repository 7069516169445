const initialState = {
	timelineRedirectDetails: {
		courseId: "",
		section: "",
		purchaseId :"",
		yCoordinate : 0
	}
};

const TimelineRedirectReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_TIMELINE_REDIRECT_DETAILS':
			return {
				...state,
				timelineRedirectDetails: action.payload,
			};
		case 'RESET_TIMELINE_REDIRECT_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default TimelineRedirectReducer;
