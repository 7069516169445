import React from 'react';

import './style.css';
import VideoIcon from '@assets/course/videoIcon.png';

export default (props) => (
	<div className='seriesView'>
		<p className='heading'>Online Content</p>

		{/* start : condition to should diff view if series if 1  */}
		{props.seriesDetails && props.seriesDetailsDetails?.length === 1 && <hr />}
		{/* end : condition to should diff view if series if 1  */}

		<div id='seriesAccordionParent'>
			{props.seriesDetails &&
				props.seriesDetails.map((series, seriesIndex) => (
					<div key={seriesIndex}>
						{/* start : condition to should diff view if series if 1  */}
						{props.seriesDetails.length > 1 && (
							<>
								<hr className='seriesHr' />
								<button
									className='accordion-button seriesAccordionBtn collapsed'
									data-bs-toggle='collapse'
									data-bs-target={`#series${seriesIndex}`}>
									{series.title}
								</button>
							</>
						)}
						{/* end : condition to should diff view if series if 1  */}

						<div
							id={`series${seriesIndex}`}
							className={`collapse seriesCollapse ${
								props.seriesDetails.length === 1 && 'show'
							}`}>
							{/* removed as per adb discusssion : it is used to close the accordance data-bs-parent='#seriesAccordionParent' */}
							{series.sections &&
								series.sections.length > 0 &&
								series.sections.map((section, sectionIndex) => (
									<div key={sectionIndex}>
										{sectionIndex > 0 && <hr className='sectionHr' />}
										<span className='sectionNum'>Section {sectionIndex + 1}</span>
										<button
											className='accordion-button sectionAccordionBtn collapsed'
											data-bs-toggle='collapse'
											data-bs-target={`#section${section._id.toString()}`}>
												<span style={{marginLeft: '4px'}}>
													{section.sectionTitle}
												</span>
										</button>
										<div
											id={`section${section._id.toString()}`}
											className='collapse'>
											{/* removed as per adb discusssion : it is used to close the accordance data-bs-parent={`#series${seriesIndex}`} */}
											{section.videos.map((subSection, subSectionIndex) => (
												<div className='subSectionContainer' key={subSectionIndex}>
													<img className='videoIcon' src={VideoIcon} alt='videoIcon' />
													<p className='subSectionTitle'>{subSection.title}</p>
												</div>
											))}
										</div>
									</div>
								))}
						</div>
					</div>
				))}
			<hr className='seriesHr' />
		</div>
	</div>
);
