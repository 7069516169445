import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import './style.css';
function OTP(props) {
	return (
		<>
			<div>
				{props.OTPErrorMsg !== '' && (
					<p className='otpError'>{props.OTPErrorMsg}</p>
				)}
				<div className='otpInputContainer'>
					<input
						type='tel'
						maxLength='1'
						className='text-center otp'
						id='otp_1'
						name='otp_1'
						value={props.OTP1}
						onChange={(event) => props.handleOtpChange(event, 'otp_1', 0)}
						onKeyDown={(event) => props.handleKeyDownOtpChange(event, 0)}
						ref={(el) => (props.otpInputRefs.current[0] = el)}
					/>
					<input
						className='text-center otp'
						type='tel'
						id='otp_2'
						name='otp_2'
						maxLength='1'
						value={props.OTP2}
						onChange={(event) => props.handleOtpChange(event, 'otp_2', 1)}
						onKeyDown={(event) => props.handleKeyDownOtpChange(event, 1)}
						ref={(el) => (props.otpInputRefs.current[1] = el)}
					/>
					<input
						className='text-center otp'
						type='tel'
						id='otp_3'
						name='otp_3'
						maxLength='1'
						value={props.OTP3}
						onChange={(event) => props.handleOtpChange(event, 'otp_3', 2)}
						onKeyDown={(event) => props.handleKeyDownOtpChange(event, 2)}
						ref={(el) => (props.otpInputRefs.current[2] = el)}
					/>
					<input
						className='text-center otp'
						type='tel'
						id='otp_4'
						name='otp_4'
						maxLength='1'
						value={props.OTP4}
						onChange={(event) => props.handleOtpChange(event, 'otp_4', 3)}
						onKeyDown={(event) => props.handleKeyDownOtpChange(event, 3)}
						ref={(el) => (props.otpInputRefs.current[3] = el)}
					/>
					<input
						className='text-center otp'
						type='tel'
						id='otp_5'
						name='otp_5'
						maxLength='1'
						value={props.OTP5}
						onChange={(event) => props.handleOtpChange(event, 'otp_5', 4)}
						onKeyDown={(event) => props.handleKeyDownOtpChange(event, 4)}
						ref={(el) => (props.otpInputRefs.current[4] = el)}
					/>
				</div>

				{/* <br /> */}

				<button onClick={props.handleResendOTPOnPress} className='resendOtpBtn'>
					Resend OTP
				</button>
				{props.OTPResend && (
					<span className='resendText'>&nbsp;OTP sent successfully</span>
				)}

				{/* <p className='tnc'>
					By clicking ‘Next’ you agree to our&nbsp;
					<Link to='/articles'>Terms of Service</Link> and&nbsp;
					<Link to='/articles'>Privacy Policy</Link>
				</p> */}

				<p className='tnc'>
					By clicking ‘Next’ you agree to our&nbsp;
					<Link to='/articles'>Terms of Service</Link> and&nbsp;
					<Link to='/articles'>Privacy Policy</Link>
				</p>

				<div className='otpSubmitBtnContainer'>
					{props.otpLoading ? (
						<>
							<button
								id='otpSubmit'
								style={{
									padding: ' 3px 23px',
									height: ' fit-content',
									borderRadius: '6px',
								}}
								className='col-12 loginSignupPageLoadingBtn'
								disabled>
								<i className='fa fa-spinner fa-spin'></i>
							</button>
						</>
					) : (
						<>
							<button 
								disabled={props.handleNextButtonLoading}
								onClick={props.handleNextButton} className='otpSubmitBtn'>
								{props.handleNextButtonLoading ? "Loading..." : "Next"}
								{props.nextBtnIcon && props.nextBtnIcon !== null && (
									<img src={props.nextBtnIcon} alt='nextBtnIcon' />
								)}
							</button>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default OTP;
