export const getLatestCourses = async () => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getLatestCourses`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Home services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getLatestCoupons = async () => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getLatestCoupons`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Home services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
