import React from "react";

import SeriesCardView from "./view";
import { activateSeries } from "@services/UserDashboard";
import * as UserDashboardActions from "@redux/actions/UserDashboard";
import { useDispatch } from "react-redux";
import { decryptText, encryptText } from "@library/enc-dec";
import { useNavigate } from "react-router-dom";

function SeriesCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSeries = async () => {
    const { series, accordanceConfig } = props;
    const { seriesId, purchaseId } = series;

    const isRedirectActive =
      !accordanceConfig ||
      !(accordanceConfig?.lock || accordanceConfig?.superLock);

    if (isRedirectActive) {
      // Call activate series api
      const authToken = decryptText(localStorage.getItem("eMediat"));
      const response = await activateSeries(seriesId, authToken);
      if (!response.success) {
        dispatch(UserDashboardActions.updateMyProfileError(response.message));
        window.scrollTo(0, 0);
        return;
      }
      dispatch(UserDashboardActions.updateMyProfileError(""));
      dispatch(
        UserDashboardActions.updateSeriesActivation({
          seriesId: seriesId,
          isActivated: "true",
        })
      );

      navigate(`/video/s/${seriesId}/${purchaseId}`);
    }
  };

  return <SeriesCardView handleSeries={handleSeries} {...props} />;
}

export default SeriesCard;
