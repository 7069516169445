import React from 'react';
import ContentLoader from "react-content-loader";
import './style.css';

export default () => (
    <div className='examContainerLoader'>


        {/* START Desktop */}
        <div className='examContainerLoaderDesktop'>
            {[...Array(6)].map((_, index) => (
                <div key={index}>
                    {index === 0 && <h3 className="cHeading">Exam</h3>}
                    {index === 3 && <h3 className="cHeading">Test</h3>}
                    <ContentLoader
                        backgroundColor="#ffffff"
                        // foregroundColor="#a09d9d"
                        viewBox="0 0 400 50">
                        {/* Card 1 */}
                        <rect x="0" y="0" rx="3" ry="3" width="70" height="40" />
                        <rect x="75" y="8" rx="1" ry="1" width="100" height="7" />
                        <rect x="75" y="20" rx="1" ry="1" width="15" height="12" />
                        <rect x="95" y="20" rx="1" ry="1" width="75" height="12" />

                        {/* Card 2 */}
                        <rect x="200" y="0" rx="3" ry="3" width="70" height="40" />
                        <rect x="275" y="8" rx="1" ry="1" width="100" height="7" />
                        <rect x="275" y="20" rx="1" ry="1" width="15" height="12" />
                        <rect x="295" y="20" rx="1" ry="1" width="75" height="12" />

                    </ContentLoader>
                </div>
            ))}
        </div>
        {/* END Desktop */}



        {/* START Mobile */}
        <div className='examContainerLoaderMobile'>
            {[...Array(6)].map((_, index) => (
                <div key={index}>
                    {index === 0 && <h3 className="cHeading">Exam</h3>}
                    {index === 3 && <h3 className="cHeading">Test</h3>}
                    <ContentLoader
                        backgroundColor="#ffffff"
                        // foregroundColor="#a09d9d"
                        viewBox="0 0 100 30">
                        
                        {/* Card 1 */}
                        <rect x="0" y="0" rx="3" ry="3" width="40" height="25" />
                        <rect x="43" y="5" rx="1" ry="1" width="55" height="4" />
                        <rect x="43" y="12" rx="1" ry="1" width="15" height="5" />
                        <rect x="60" y="12" rx="1" ry="1" width="35" height="5" /> 

                    </ContentLoader>
                </div>
            ))}
        </div>
        {/* END Mobile */}
    </div>
);
