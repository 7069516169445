import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OTPView from './view';
import * as OTPActions from '@redux/actions/OTP';

function OTP(props) {
	const dispatch = useDispatch();
	const OTP1 = useSelector((state) => state.otp.OTP1);
	const OTP2 = useSelector((state) => state.otp.OTP2);
	const OTP3 = useSelector((state) => state.otp.OTP3);
	const OTP4 = useSelector((state) => state.otp.OTP4);
	const OTP5 = useSelector((state) => state.otp.OTP5);

	const OTPErrorMsg = useSelector((state) => state.otp.OTPErrorMsg);

	const [OTPResend, setOTPResend] = useState(false);
	const [otpLoading, setOtpLoading] = useState(false);
	const otpInputRefs = useRef([]);
	const [handleNextButtonLoading, setHandleNextButtonLoading] = useState(false)

	useEffect(() => {
		focusInputBox("otp_1")
	})
	const focusInputBox = (eleId) => {
		if (OTP1 === '' && OTP2 === '' && OTP3 === '' && OTP4 === '' && OTP5 === '') {
			let ele = document.getElementById(eleId)
			if (ele) {
				ele.focus()
			}
		}
	}


	const handleResendOTPOnPress = () => {
		try {
			setOTPResend(true);
			props.handleResendOTP();
			setTimeout(function () {
				setOTPResend(false);
			}, 4000);
		} catch (err) {
			console.log('Error coming from handleResendOTPOnPress()', err);
		}
	};

	const handleKeyDownOtpChange = (event, index) => {
		if (event.key === 'Backspace' && !event.target.value && index > 0) {
			otpInputRefs.current[index - 1].focus();
		}
		if (event.key === 'Enter') {
			handleNextButton()
		}
	};

	// START : Fn to handle otp auto fill
	const handleOtpChange = (event, inputId, index) => {
		dispatch(OTPActions.updateOTPErrorMsg(''));
		const otp = event.target.value;
		const otpArray = otp.split('');

		let loopLen = 5;
		if (otpArray.length > 0) {
			loopLen = otpArray.length;
			if (otpArray.length > 5) {
				loopLen = 5;
			}
		}

		if (otpArray.length === 0) {
			updateOtpValues(otp, index);
		} else if (otpArray.length >= 2) {
			for (let i = index; i <= 4; i++) {
				if (i <= loopLen) {
					let newInputId = 'otp_' + parseInt(i + 1);
					if (document.getElementById(newInputId)) {
						let ele = document.getElementById(newInputId);
						updateOtpValues(otpArray[0], i);
						otpArray.shift();
						ele.focus();
					}
				}
			}
		} else {
			updateOtpValues(otp, index);
			let newInputId = 'otp_' + parseInt(index + 2);
			if (document.getElementById(newInputId)) {
				let ele = document.getElementById(newInputId);
				ele.focus();
			}
		}
	};

	const updateOtpValues = (otp, index) => {
		if (index === 0) {
			dispatch(OTPActions.updateOTPNumber({ OTPNumber: otp, colNumber: 1 }));
		} else if (index === 1) {
			dispatch(OTPActions.updateOTPNumber({ OTPNumber: otp, colNumber: 2 }));
		} else if (index === 2) {
			dispatch(OTPActions.updateOTPNumber({ OTPNumber: otp, colNumber: 3 }));
		} else if (index === 3) {
			dispatch(OTPActions.updateOTPNumber({ OTPNumber: otp, colNumber: 4 }));
		} else if (index === 4) {
			dispatch(OTPActions.updateOTPNumber({ OTPNumber: otp, colNumber: 5 }));
		}
	};

	const handleNextButton = () => {
		setHandleNextButtonLoading(true)
		setOtpLoading(true);
		if (OTP1 === '' || OTP2 === '' || OTP3 === '' || OTP4 === '' || OTP5 === '') {
			dispatch(OTPActions.updateOTPErrorMsg('Please enter correct OTP'));
			setOtpLoading(false);
		} else {
			dispatch(OTPActions.updateOTPErrorMsg(''));
			const finalOTP = `${OTP1}${OTP2}${OTP3}${OTP4}${OTP5}`;
			props.handleSuccessOTP(finalOTP);
			setOtpLoading(false);
		}
		setTimeout(function () {
			setHandleNextButtonLoading(false)
		}, 1500)
	};

	return (
		<>
			<OTPView
				OTP1={OTP1}
				OTP2={OTP2}
				OTP3={OTP3}
				OTP4={OTP4}
				OTP5={OTP5}
				OTPErrorMsg={OTPErrorMsg}
				otpLoading={otpLoading}
				OTPResend={OTPResend}
				otpInputRefs={otpInputRefs}
				handleResendOTPOnPress={handleResendOTPOnPress}
				handleKeyDownOtpChange={handleKeyDownOtpChange}
				handleOtpChange={handleOtpChange}
				handleNextButton={handleNextButton}
				handleNextButtonLoading={handleNextButtonLoading}
				{...props}
			/>
		</>
	);
}

export default OTP;
