export const addPageView = async (payload) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addPageView`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from page view services, addPageView fn :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


export const updatePageViewAfterSignUp = async (authToken, payload) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateSignUpFromPage`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
                Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from page view services, updatePageViewAfterSignUp fn :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
