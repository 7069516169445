import React from 'react';

import './ReplyInput.css';

function ReplyInputView(props) {
	return (
		<>
			<div
				className='userCommentContainer'
				id={'replyContainerId_' + props.replyContainerId}>
				<div className='row' style={{ margin: '10px 0px 10px 0px' }}>
					<div className='col-profileImgPlaceholder'>
						<img
							src={props.ProfileImgPlaceholder}
							alt='Profile Placeholder'
							style={{ width: '30px' }}
						/>
					</div>
					<div className='col-userCommentInput' style={{ paddingLeft: '10px' }}>
						<input
							type='text'
							className='w-100'
							placeholder='Add a comment'
							value={props.userComment}
							onChange={(event) => props.handleUserCommentChange(event)}
						/>
					</div>
				</div>

				<div className='row CCBtnContainer'>
					<button
						className='cancleBtn'
						onClick={(event) =>
							props.handleUserCommentCancleBtn(event, props.replyContainerId)
						}>
						Cancel
					</button>

					<button
						className={`continueBtn ${
							props.userComment.length > 0 && 'continueBtnAddOn'
						}`}
						disabled={props.userComment.length > 0 ? false : true}
						onClick={(event) =>
							props.handleCommentReplyToDb(event, props.replyContainerId)
						}>
						Reply
					</button>
				</div>
			</div>
		</>
	);
}

export default ReplyInputView;
