const initialState = {
	loadingBarProgress: 0,
};

const TopLoadingBarReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_LOADING_BAR_PROGRESS':
			return {
				...state,
				loadingBarProgress: action.payload.loadingBarProgress,
			};

		default:
			return state;
	}
};

export default TopLoadingBarReducer;
