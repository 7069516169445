import React from "react";
import { Link } from "react-router-dom";

import Call from "@pages/Course/Sections/Call";
import ContactSupport from "@pages/Course/Sections/ContactSupport";
import "./style.css";
import Ellipse from "@assets/product/ellipse.png";
import Clock from "@assets/product/clock.png";
import PercentageIcon from "@assets/course/percentageIcon.png";

export default (props) => (
  <>
    <div className="desktopTabCardView">
      <img
        className="courseThumbnail w-100"
        src={props.courseDetails?.rectangleThumbnailUrl}
        alt="courseImg"
      />
      <div className="cardDetails">
        <p className="durationContainer">
          <img src={Ellipse} alt="Ellipse" />
          {props.courseDetails?.cardTitle}
        </p>

        <p className="cardDescription">
          {props.courseDetails?.cardDescription}
        </p>

        {props.courseDetails?.cardPointers && (
          <p className="cardPointers" ref={props.cardPointersRef}></p>
        )}

        <hr />

        <div className="cardIcons text-center row">
          {props.courseDetails?.cardIcons?.map((item, index) => (
            <p key={index} className="col-3 mb-0">
              <img src={item.icon} alt="icon" />
              <span className="d-block">{item.pointer}</span>
            </p>
          ))}
        </div>

        <hr />

        {!props.courseDetails.isPurchasedCourse &&
          props.isValidDealEndingTime && (
            <p className="dealEndingContainer">
              <img src={Clock} alt="clock" />
              Offer price ending in&nbsp;<b>{props.dealEndingTime}</b>
            </p>
          )}
        {!props.courseDetails.isPurchasedCourse &&
          props.courseDetails?.pricingDetails?.courseType === "paid" && (
            <div className="priceContainer">
              <p className="alwaysDisplayPrice">
                {props.courseDetails?.pricingDetails?.offerExpiry &&
                  props.courseDetails?.isInOffer && props.isValidDealEndingTime
                  ? props.courseDetails.offerPrice
                  : props.courseDetails.crossedPrice}
              </p>

              {props.courseDetails?.isInOffer && props.isValidDealEndingTime && (
                <p className="crossPrice">
                  {props.courseDetails?.crossedPrice}
                </p>
              )}
              {props.courseDetails?.isInOffer && props.isValidDealEndingTime && (
                <p className="discountedPercentage">
                  {props.offerDiscountPercentage}
                </p>
              )}
            </div>
          )}


        {props.courseDetails?.pricingDetails?.courseType === "free" && (
          <div className="btnContainer">
            {!props.courseDetails.isPurchasedCourse &&
              <button
                disabled={
                  props.addToCartLoading &&
                    props.addToCartLoading === "addingToMyLearning"
                    ? true
                    : false
                }
                style={{
                  background: props.addToCartLoading === "addingToMyLearning" ? "#7fce7d" : "#039800",
                  color: "white"
                }}
                onClick={() => props.handleFreeCourse(props.courseDetails)}
                className="col-12 enrollNowBtn"
              >
                {props.addToCartLoading && props.addToCartLoading === 'addingToMyLearning' ? 'Loading...' : 'Add to my learning'}

              </button>
            }
            
            {props.courseDetails.isPurchasedCourse &&
              <Link
                style={{ textDecoration: "none" }}
                className="col-12 enrollNowBtn text-center"
                to={`/timeline/${props.courseDetails.purchaseId}`}
              >
                Go to Course
              </Link>
            }

          </div>
        )}

        {props.courseDetails?.pricingDetails?.courseType === "comingSoon" && (
          <>
            <h3 className="text-center">Inquire Now</h3>
            <div className="btnContainer">
              <button
                onClick={() => {
                  props.handleContactNow();
                }}
                className="col-12 enrollNowBtn"
              >
                Contact Now
              </button>
            </div>
          </>
        )}

        {props.courseDetails?.pricingDetails?.courseType === "paid" && (
          <>
            {!props.courseDetails.isPurchasedCourse &&
              props.courseDetails?.pricingDetails?.offerExpiry && (
                <p
                  onClick={() => {
                    props.handleRedirectToOfferPage();
                  }}
                  className="discountTextContainer"
                >
                  <img src={PercentageIcon} alt="percentageIcon" />
                  View available offers and bundle discounts
                </p>
              )}
          </>
        )}

        {props.courseDetails?.pricingDetails?.courseType === "paid" && (
          <div className="btnContainer">
            {!props.courseDetails.isPurchasedCourse && (
              <button
                disabled={
                  props.addToCartLoading &&
                    props.addToCartLoading === props.courseDetails._id
                    ? true
                    : false
                }
                onClick={() => props.handleEnrollAndAddToCart("enroll")} // defined in : pages\Course\Main\index.js
                className={`enrollNowBtn ${props.courseDetails.allowToCart ? "col-my5" : "col-12"
                  }`}
              >
                {props.addToCartLoading &&
                  props.addToCartLoading === props.courseDetails._id
                  ? "Loading..."
                  : "Enroll now"}
              </button>
            )}

            {props.courseDetails.isPurchasedCourse ? (
              <Link
                style={{ textDecoration: "none" }}
                className="col-12 enrollNowBtn text-center"
                to={`/timeline/${props.courseDetails.purchaseId}`}                
              >
                Go to Course
              </Link>
            ) : (
              <>
                {props.courseDetails.allowToCart && (
                  <button
                    disabled={
                      props.addToCartLoading &&
                        props.addToCartLoading === props.courseDetails._id
                        ? true
                        : false
                    }
                    onClick={() =>
                      props.handleEnrollAndAddToCart("addOrViewCart")
                    } // defined in : pages\Course\Main\index.js
                    className="col-my5 cartActionBtn"
                  // className={`${props.courseDetails.isPurchasedCourse ? 'col-12 enrollNowBtn' : 'col-my5 cartActionBtn '}`}
                  >
                    {props.addToCartLoading &&
                      props.addToCartLoading === props.courseDetails._id ? (
                      "Loading..."
                    ) : (
                      <>
                        {props.cartItems.some(
                          (item) => item.courseId === props.courseDetails._id
                        )
                          ? "View cart"
                          : "Add to cart"}
                      </>
                    )}
                  </button>
                )}
              </>
            )}

            {/* {props.courseDetails.allowToCart && (
							<button
								onClick={() => props.handleEnrollAndAddToCart('addOrViewCart')} // defined in : pages\Course\Main\index.js
								className={`${props.courseDetails.isPurchasedCourse ? 'col-12 enrollNowBtn' : 'col-my5 cartActionBtn '
									}`}>
								{props.courseDetails.isPurchasedCourse ?
									(
										<>
											<Link to={`/timeline/${props.courseDetails._id}`}>Go to Course</Link>
										</>
									) : (
										<>
											{props.cartItems.some(item => item.courseId === props.courseDetails._id) ?
												"View cart" :
												"Add to cart"
											}
										</>
									)
								}
							</button>
						)} */}
          </div>
        )}
      </div>

      {window.innerWidth > 768 && <Call />}

      <ContactSupport />
    </div>
  </>
);
