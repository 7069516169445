import React, { useRef, useEffect, useState } from 'react';

import ViewOffers from './view';
import OfferBackSide from '@assets/offers/offerBackSide.png';

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import TopLoadingBar from '@components/TopLoadingBar';

import * as OfferServices from '@services/Offers';
import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as NavbarActions from '@redux/actions/Navbar';
import { isUserLogedIn } from '@library/auth';

function Offers(props) {
	const dataFetchedRef = useRef(false);
	const [coupons, setCoupons] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isOfferImageLoaded, setIsOfferImageLoaded] = useState(false);
	let touchFlag = false;

	const handleTouchStart = (event, myImg, index, value) => {
		touchFlag = true;
		if (value === 'show') {
			let ele = event.target;
			ele.src = OfferBackSide;

			let offerDescriptionId = 'offerDetailsM_' + index;
			if (document.getElementById(offerDescriptionId)) {
				let offerDescriptionEle = document.getElementById(offerDescriptionId);
				offerDescriptionEle.style.display = 'block';
			}
		} else {
			let ele = event.target;
			ele.style.display = 'none';

			let offerImageId = 'offerImgM_' + index;
			let offerImageIdEle = document.getElementById(offerImageId);
			offerImageIdEle.src = myImg;
		}
	};

	const handleMouseLeave = (event, myImg, index) => {
		if (!touchFlag) {
			let ele = event.target;
			ele.style.display = 'none';

			let offerImageId = 'offerImgM_' + index;
			let offerImageIdEle = document.getElementById(offerImageId);
			offerImageIdEle.src = myImg;
		}
	};

	const handleMouseEnter = (event, index) => {
		if (!touchFlag) {
			let ele = event.target;
			ele.src = OfferBackSide;

			let offerDescriptionId = 'offerDetailsM_' + index;
			if (document.getElementById(offerDescriptionId)) {
				let offerDescriptionEle = document.getElementById(offerDescriptionId);
				offerDescriptionEle.style.display = 'block';
			}
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		checkLogin();
		getOfferCoupons();
	}, []);

	const checkLogin = async () => {
		const userLogedIn = await isUserLogedIn();
		props.updateLoginState(userLogedIn);
	};

	const getOfferCoupons = async () => {
		setIsLoading(true);
		UpdateTopLoadingBarForThisPage(20);

		try {
			UpdateTopLoadingBarForThisPage(40);

			const allCoupons = await OfferServices.getAllOffers();
			UpdateTopLoadingBarForThisPage(70);

			if (allCoupons?.success) {
				setCoupons(allCoupons);
				setIsLoading(false);
			}
			UpdateTopLoadingBarForThisPage(100);
		} catch (error) {
			console.log('Error coming from getOfferCoupons()', error.message);
			UpdateTopLoadingBarForThisPage(100);
		}

		// setTimeout(function () {
		// 	setIsLoading(false);
		// }, 1000);
	};

	//START: Code for setting the progressof top bar loader
	const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
		if (interval) {
			setTimeout(function () {
				props.updateLoadingBarProgress(props.loadingBarProgress + value);
			}, 500);
		} else {
			props.updateLoadingBarProgress(props.loadingBarProgress + value);
		}
	};
	//END: Code for setting the progressof top bar loader

	const handleOfferImageLoading = () => {
		setIsOfferImageLoaded(true);
	};

	return (
		<>
			<Navbar />
			<TopLoadingBar />
			<ViewOffers
				isLoading={isLoading}
				handleOfferImageLoading={handleOfferImageLoading}
				isOfferImageLoaded={isOfferImageLoaded}
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				offerData={coupons}
				handleTouchStart={handleTouchStart}
			/>
			<Footer />
		</>
	);
}

function mapStateToProps(state) {
	return {
		loadingBarProgress: state.topLoadingBar.loadingBarProgress,
		isLogedIn: state.navbar.loginState,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateLoadingBarProgress: TopLoadingBarActions.updateLoadingBarProgress,
			updateLoginState: NavbarActions.updateLoginState,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
