export const updateCartItems = (value) => {
	return {
		type: 'UPDATE_CART_ITEMS',
		payload: {
			cartItems: value,
		},
	};
};

export const updateCartItemCount = (value) => {
	return {
		type: 'UPDATE_CART_ITEMS_COUNT',
		payload: {
			cartItemCount: value,
		},
	};
};

export const updateCartTotalValue = (value) => {
	return {
		type: 'UPDATE_CART_TOTAL_VALUE',
		payload: {
			cartTotalValue: value,
		},
	};
};

export const updateInstallmentOption = (value) => {
	return {
		type: 'UPDATE_INSTALLMENT_OPTION',
		payload: {
			installmentOption: value,
		},
	};
};

export const updateCartTotalValueInNumbers = (value) => {
	return {
		type: 'UPDATE_CART_TOTAL_VALUE_IN_NUMBERS',
		payload: {
			cartTotalValueInNumbers: value,
		},
	};
};

export const updateUserDetails = (value) => {
	return {
		type: 'UPDATE_USER_DETAILS',
		payload: {
			userDetails: value,
		},
	};
};

export const updateCartDetails = (value) => {
	return {
		type: 'UPDATE_CART_DETAILS',
		payload: {
			cartItems: value.cartItems,
			cartItemCount: value.cartItemCount,
			cartTotalValue: value.cartTotalValue,
			cartTotalValueInNumbers: value.cartTotalValueInNumbers,
			discountPercentage: value.discountPercentage,
			discountValue: value.discountValue,
			discountValueInNumbers: value.discountValueInNumbers,
			offerDetails: value.offerDetails,
		},
	};
};

export const updateSelectedPaymentGateway = (value) => {
	return {
		type: 'UPDATE_SELECTED_PAYMENT_GATEWAY',
		payload: {
			selectedPaymentGateway: value,
		},
	};
};

export const updateGatewayDiscountValue = (value) => {
	return {
		type: 'UPDATE_GATEWAY_DISCOUNT',
		payload: {
			gatewayDiscountValue: value.gatewayDiscountValue,
			gatewayDiscountValueInNumbers: value.gatewayDiscountValueInNumbers,
		},
	};
};

export const resetCartReducers = () => {
	// debugger
	return {
		type: 'RESET_CART_REDUCER',
	};
};
