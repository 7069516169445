import React from 'react';
import './style.css';
import CouserLoader from './loader';

import SeriesCard from '@components/SeriesCard';

import '@assets/userDashboard/course/cgoCourse.png';

export default (props) => (
	<div className='seriesContainer row'>
		{props.isLoading ?
			(
				<>
					<h3 className='cHeading'>Series</h3>
					<CouserLoader />
				</>
			) : (
				<>
					<h3 className='cHeading'>Series</h3>
					{props.seriesList && props.seriesList.data && props.seriesList.data.length > 0 ? (
						<>
							{props.seriesList.data.map((series, index) => (
								<SeriesCard key={index} series={series} index={index} />
							))}
						</>
					) : (
						<center>
							<img
								id='noProduct'
								src='https://stores.maxfashion.in/VendorpageTheme/Enterprise/EThemeForMax/images/product-not-found.jpg'
								alt='noProduct'
							/>
						</center>
					)}

				</>
			)
		}
	</div>
);
