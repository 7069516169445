import React from 'react';

import ContactSupportView from './view';

function ContactSupport() {
	const staticData = [
		{
			heading: 'Contact Support',
			text: 'Problems? Get needed solutions',
			link: '/request-a-call',
		},
	];
	return <ContactSupportView staticData={staticData} />;
}

export default ContactSupport;
