export const updatePurchasedCourse = (value) => {
	return {
		type: 'UPDATE_PURCHASED_COURSE',
		payload: {
			course: value,
		},
	};
};

export const updateTimelineData = (value) => {
	return {
		type: 'UPDATE_TIMELINE_DATA',
		payload: {
			timelineData: value,
		},
	};
};
