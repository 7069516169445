export const updateTypeOfCourses = (value) => {
	return {
		type: 'UPDATE_TYPE_OF_COURSES',
		payload: {
			typeOfCourses: value,
		},
	};
};

export const updateCourseByCategoryId = (value) => {
	return {
		type: 'UPDATE_COURSE_BY_CATEGORY_ID',
		payload: {
			coursesByCategory: value,
		},
	};
};

export const updateCurrentCourseDetails = (value) => {
	return {
		type: 'UPDATE_CURRENT_COURSE_DETAILS',
		payload: {
			currentCourseDetails: value,
		},
	};
};
