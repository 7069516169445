import React from 'react';

import Head from '@pages/Course/Sections/Head';
import CourseCard from '@pages/Course/Sections/CourseCard';
import ContentDescription from '@pages/Course/Sections/ContentDescription';
import Registration from '@pages/Course/Sections/Registration';
import PreviewVideo from '@pages/Course/Sections/PreviewVideo';
import SampleCertificate from '@pages/Course/Sections/SampleCertificate';
import RelatedCourses from '@pages/Course/Sections/RelatedCourses';
import RoadMapMain from '@pages/Course/Sections/RoadMapMain';
import Series from '@pages/Course/Sections/Series';
import ImageCarousel from '@pages/Course/Sections/ImageCarousel';
import FAQ from '@pages/Course/Sections/FAQ';
import Blog from '@pages/Course/Sections/Blog';
import Loaders from '@pages/Course/Loaders/desktop.js'

import './style.css';

function DesktopTabView(props) {
	return (
		<>
			{props.isLoading ? (
				<center>
					<div className='commonProductPageAlignmentContainerHelper'>
						<Loaders />
					</div>
				</center>
			) : (
				<>
					<div className='topBgOverlap' id='topBgOverlap'></div>

					<div className='bgLinesOverlap' id="bgLinesOverlap">
					</div>
					<div className='bgBc1Overlap' id="bgBc1Overlap">
					</div>

					<center>
						<div className='row coursePageHeaderCardRegistrationMainContainer commonProductPageAlignmentContainerHelper'>
							<div className='col-7 col-md-6 col-xl-7 lhs'>
								<div className='col-12' id='courseHeaderContainer'>
									<Head />
								</div>

								{props.courseDetails && props.courseDetails.showInfoParagraph &&
									<div className='col-12'>
										<ContentDescription />
									</div>
								}

								<div className='col-12'>
									<Registration {...props} buttonType={'interestButton'} />
								</div>
							</div>
							<div className='col-4 col-md-5 col-xl-4 rhs'>
								<CourseCard
									handleFreeCourse={props.handleFreeCourse}
									addToCartLoading={props.addToCartLoading}
									handleEnrollAndAddToCart={props.handleEnrollAndAddToCart} />
							</div>
						</div>
					</center>

					<center>
						{props.courseDetails && props.courseDetails.showPreviewVideoLink && props.courseDetails.previewVideoLink && props.courseDetails.previewVideoLink !== "null" && props.courseDetails.previewVideoLink !== null &&
							<div style={{ padding: '0rem 100px' }} className='commonProductPageAlignmentContainerHelper previewVideoLinkMainContainer'>
								<PreviewVideo />
							</div>
						}
					</center>

					{props.courseDetails && props.courseDetails.showCertificate && props.courseDetails.certificateThumbnailUrl &&
						<center>
							<div className='commonProductPageAlignmentContainerHelper sampleCertificateMainContainer'>
								<SampleCertificate
									openOtpPopUp={props.openOtpPopUp}
								/>
							</div>
						</center>
					}

					{props.courseDetails && props.courseDetails.roadmapType && props.courseDetails.roadmapType !== "none" &&
						<center>
							<div className='commonProductPageAlignmentContainerHelper roadMapMainContainer'>
								<RoadMapMain />
							</div>
						</center>
					}

					<center>
						<div className='commonProductPageAlignmentContainerHelper seriesMainContainer'>
							<Series />
						</div>
					</center>

					{props.courseDetails && props.courseDetails.showImageCarousel && props.courseDetails.imageCarousel && props.courseDetails.imageCarousel.length > 0 &&
						<center>
							<div className='commonProductPageAlignmentContainerHelper imageCarouselMainContainer'>
								<ImageCarousel />
							</div>
						</center>
					}

					<center>
						<div className='commonProductPageAlignmentContainerHelper relatedCoursesMainContainer'>
							<RelatedCourses />
						</div>
					</center>

					<center>
						<div className='commonProductPageAlignmentContainerHelper faqMainContainer'>
							<FAQ />
						</div>
					</center>

					<center>
						<div className='commonProductPageAlignmentContainerHelper blogMainContainer'>
							<Blog />
						</div>
					</center>
				</>
			)}
		</>
	);
}

export default DesktopTabView;
