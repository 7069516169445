import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CommentLoader() {
	return (
		<>
			<div className='d-none d-lg-block mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 200 50'>

					{/* START : Comment section */}
					<rect x='0' y='0' rx='1' ry='1' width='30' height='5' />

					{/* START : User Comment */}
					<circle cx='5' cy='12' r='3' />
					<text x='12' y='13' fontSize='4'>
						Add a comment
					</text>
					<rect x='10' y='14' rx='1' ry='1' width='122' height='0.5' />
					{/* END : User Comment */}

					{/* START : Other Comments */}
					<circle cx='5' cy='23' r='3' />
					<rect x='10' y='21' rx='1' ry='1' width='15' height='1.5' />
					<rect x='10' y='24' rx='1' ry='1' width='122' height='1.5' />

					<circle cx='5' cy='35' r='3' />
					<rect x='10' y='33' rx='1' ry='1' width='15' height='1.5' />
					<rect x='10' y='36' rx='1' ry='1' width='122' height='1.5' />

					<circle cx='5' cy='47' r='3' />
					<rect x='10' y='45' rx='1' ry='1' width='15' height='1.5' />
					<rect x='10' y='48' rx='1' ry='1' width='122' height='1.5' />
					{/* END : Other Comments */}

					{/* END : Comment section */}
				</ContentLoader>
			</div>

			<div className='d-block d-lg-none mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 100 80'>

					{/* START : Comment section */}
					<rect x='0' y='0' rx='1' ry='1' width='30' height='4' />

					{/* START : User Comment */}
					<circle cx='5' cy='13' r='4' />
					<text x='12' y='13' fontSize='4'>
						Add a comment
					</text>
					<rect x='10' y='15' rx='1' ry='1' width='88' height='0.5' />
					{/* END : User Comment */}

					{/* START : Other Comments */}
					<circle cx='5' cy='28' r='4' />
					<rect x='11' y='26' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='29.5' rx='1' ry='1' width='88' height='1.5' />

					<circle cx='5' cy='43' r='4' />
					<rect x='11' y='41' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='44.5' rx='1' ry='1' width='88' height='1.5' />

					<circle cx='5' cy='57' r='4' />
					<rect x='11' y='55' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='58.5' rx='1' ry='1' width='88' height='1.5' />

					<circle cx='5' cy='71' r='4' />
					<rect x='11' y='69' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='72.5' rx='1' ry='1' width='88' height='1.5' />
					{/* END : Other Comments */}

					{/* END : Comment section */}
				</ContentLoader>
			</div>
		</>
	);
}