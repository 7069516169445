import React from 'react';
import ContentLoader from "react-content-loader";
import './style.css';

export default () => (
    <div className='seriesContainerLoader'>
        {/* START Desktop */}
        {[...Array(2)].map((_, index) => (
            <div key={index}>
                <ContentLoader
                    className='seriesContainerLoaderDesktop'
                    backgroundColor="#ffffff"
                    // foregroundColor="#a09d9d"
                    viewBox="0 0 400 60">
                    {/* Card 1 */}
                    <rect x="0" y="0" rx="3" ry="3" width="90" height="50" />
                    <rect x="95" y="8" rx="2" ry="2" width="100" height="7" />
                    <rect x="95" y="20" rx="2" ry="2" width="90" height="7" />
                    <rect x="95" y="32" rx="2" ry="2" width="70" height="7" />

                    {/* Card 2 */}
                    <rect x="208" y="0" rx="3" ry="3" width="90" height="50" />
                    <rect x="303" y="8" rx="2" ry="2" width="97" height="7" />
                    <rect x="303" y="20" rx="2" ry="2" width="90" height="7" />
                    <rect x="303" y="32" rx="2" ry="2" width="70" height="7" />
                </ContentLoader>
            </div>
        ))}
        {/* END Desktop */}



        {/* START Mobile */}
        {[...Array(4)].map((_, index) => (
            <div key={index} className='seriesContainerLoaderMobile'>
                <ContentLoader
                    backgroundColor="#ffffff"
                    // foregroundColor="#a09d9d"
                    viewBox="0 0 100 30">
                    {/* Card 1 */}
                    <rect x="0" y="0" rx="3" ry="3" width="40" height="25" />
                    <rect x="42" y="4" rx="1" ry="1" width="57" height="3" />
                    <rect x="42" y="10" rx="1" ry="1" width="47" height="3" />
                    <rect x="42" y="16" rx="1" ry="1" width="37" height="3" />
                </ContentLoader>
            </div>
        ))}
        {/* END Mobile */}
    </div>
);
