// Old Actions 1.0.0--- Start
export const updateUserDetails = (value) => {
	return {
		type: 'UPDATE_USER_DETAILS',
		payload: {
			user: value,
		},
	};
};
export const updatePurchases = (value) => {
	return {
		type: 'UPADATE_PURCHASES',
		payload: {
			purchases: value,
		},
	};
};

export const updateMyProfileError = (value) => {
	return {
		type: 'UPDATE_MY_PROFILE_ERROR',
		payload: {
			myProfileError: value,
		},
	};
};

export const updateProfileError = (value) => {
	return {
		type: 'UPDATE_PROFILE_ERROR',
		payload: {
			profileError: value,
		},
	};
};

export const updateActiveTab = (value) => {
	return {
		type: 'UPDATE_ACTIVE_TAB',
		payload: {
			activeTab: value,
		},
	};
};

export const updateSeriesActivation = (value) => {
	return {
		type: 'UPDATE_SERIES_ACTIVATION',
		payload: {
			seriesActivation: value,
		},
	};
};
// Old Actions 1.0.0--- End


export const updateTestDetails = (value) => {
	return {
		type: 'UPDATED_TEST_DETAILS',
		payload: {
			testDetails: value,
		},
	};
};


export const updateExamDetails = (value) => {
	return {
		type: 'UPDATED_EXAM_DETAILS',
		payload: {
			examDetails: value,
		},
	};
};


export const updateTransactionsDetails = (value) => {
	return {
		type: 'UPDATED_TRANSACTIONS_DETAILS',
		payload: {
			transactionsDetails: value,
		},
	};
};
