export const getPurchasesByUserId = async (payload) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getPurchasesByUserId`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
                body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from UserDashboard/Setting => getPurchasesByUserId services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
