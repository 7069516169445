import React from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';

function Dropdown(props) {
	return (
		<>
			<div className='inputContainerSelectTagTypeB'>
				<p className='inputLable d-flex align-items-center'>
					<span style={{marginRight:'7px'}}>
						{props.text}
						{props.isReq === 'true' && <b className='asterisk'>*</b>}
					</span>
					{props.isReq === 'true' &&
						props.showError &&
						props.showError === 'true' &&
						props.iserror &&
						(!props.value || props.value === '' || props.value === null) && (
							<Tags
								span_font_size='10px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#962020'
								bc='#A62323'
								text='Input Required'
								imgs={InputError}
							/>
						)}
				</p>
				<select
					className='selectTag'
					onChange={(event) => {
						props.onSelect(event, props.onChangeAction);
					}}>
					<option value={props.value}>
						{props.value ? `${props.value}` : 'Select your answer'}
					</option>
					{props.listToLoop.map((item, index) => (
						<option key={index} value={item}>
							{item}
						</option>
					))}
				</select>
			</div>
		</>
	);
}

export default Dropdown;
