import React, { useEffect, useRef, useState } from 'react';

import ETPopupView from './view';

function ETPopup(props) {
	const openPopupBtnRef = useRef(null);
	const modalRef = useRef();

	useEffect(() => {
		const modalElement = modalRef.current;
		modalElement.addEventListener('hidden.bs.modal', handleCancel);

		return () => {
			modalElement.removeEventListener('hidden.bs.modal', handleCancel);
		};
	}, []);

	function openPopup() {
		if (openPopupBtnRef.current) {
			openPopupBtnRef.current.click();
		}
	}

	const handleCancel = () => {
		if (props.closeEtPopupBtnRef.current) {
			props.closeEtPopupBtnRef.current.click();
		}
		props.handleRedirectToEaxm(null);
	};


	if (props.openPopupStatus) {
		openPopup();
	}

	return (
		<>
			<ETPopupView
				modalRef={modalRef}
				openPopupBtnRef={openPopupBtnRef}
				closeEtPopupBtnRef={props.closeEtPopupBtnRef}
				handleCancel={handleCancel}
				etPoupOkBtnLoading={props.etPoupOkBtnLoading}
				{...props}
			/>
		</>
	);
}

export default ETPopup;
