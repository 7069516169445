export const getCoursesByType = async (payload) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getCoursesByCategory`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Explore services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getCoursesByCategoryId = async (catgoryId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCoursesByCategoryId/${catgoryId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Explore services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
