import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';

import './Navbar.css';
import { decryptText } from '@library/enc-dec';

import headerLogoX from '@assets/navbar/headerLogo.png';
import headerLogo from '@assets/navbar/headerLogo.svg';
import hamburgerMenuIcon from '@assets/navbar/hamburgerMenuIcon.png';
import cartIcon from '@assets/navbar/cartIcon.png';
// import DashboardIcon from '@assets/navbar/dashboardIcon.svg';
import DashboardIcon from '@assets/navbar/dashboardIcon.png';
import RequestCallStickyIcon from '@assets/navbar/requestCallStickyIcon.svg';
import WhatsAppStickyIcon from '@assets/navbar/whatsAppStickyIcon.svg';

import MobileHeaderLogo from '@assets/navbar/mobileHeaderLogo.png';

import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import * as CartServices from '@services/Cart';
import * as CourseServices from '@services/Course';

import * as NavbarActions from '@redux/actions/Navbar';
import * as LoginActions from '@redux/actions/Login';

import * as CartActions from '@redux/actions/Cart';

import { isUserLogedIn } from '@library/auth';

function Navbar(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const hamburgerMenuIconRef = useRef(null);
	const collapsibleNavbarRef = useRef(null);

	const [searchText, setSearchText] = useState('');
	const [allCourses, setAllCourses] = useState([]);
	const [tempCourses, setTempCourses] = useState([]);
	const [isAllCourses, setIsAllCourses] = useState(false);
	const [isResultVisible, setIsResultVisible] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event) => {
			setIsResultVisible(false);
			closeNavBar(event)
		};

		window.addEventListener('click', handleClickOutside);

		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		checkLogin();
	}, [props.loginState]);

	const closeNavBar = (event) => {
		let clickover = event.target;
		if (clickover.closest('.navbarMyCloser') === null) {
			closeNavBar2(hamburgerMenuIconRef)
		}
		else {
			let eleId = clickover.closest('.navbarMyCloser').id;
			if (eleId !== "navBar") {
				closeNavBar2(hamburgerMenuIconRef)
			}
		}
	}

	const closeNavBar2 = (r) => {
		if (r.current && collapsibleNavbarRef.current) {
			let collapsibleNavbarRefHasShowClass = collapsibleNavbarRef.current.classList.contains('show');
			if (collapsibleNavbarRefHasShowClass) {
				r.current.click();
			}
		}

		// if (r.current) {
		// 	r.current.click();
		// }
	}
	// Get All Courses to be searched
	const getAllCourses = async () => {
		try {
			let response = await CourseServices.getAllCourses();
			if (response.success) {
				setAllCourses(response.data);
			}
		} catch (err) {
			console.log('Error coming while fetching all courses', err);
		}
	};

	const handleSearch = async (text) => {
		setSearchText(text);
		setIsResultVisible(true);
		if (!isAllCourses) {
			getAllCourses();
			setIsAllCourses(true);
		}
	};

	const checkLogin = async () => {
		try {
			const userLogedIn = await isUserLogedIn();
			if (!userLogedIn) {
				window.localStorage.removeItem('eMediat');
				window.localStorage.removeItem('eMediatt');
				handleCartItemsCount('local');
				return;
			} else {
				props.updateLoginState(true);
				handleCartItemsCount('logedIn');
			}
		} catch (err) {
			console.log('Error coming while handling courseDetails page', err);
		}
	};

	const handleCartItemsCount = async (cureentUserState) => {
		if (cureentUserState === 'logedIn') {
			const authToken = decryptText(localStorage.getItem('eMediat'));
			const cartDetails = await CartServices.getCartItemsCount(authToken);
			if (cartDetails.success) {
				props.updateCartItemCount(cartDetails.data.totalItems);
			} else {
				props.updateCartItemCount(0);
			}
		} else {
			if (localStorage.getItem('eMediCart')) {
				let cartDetails = JSON.parse(localStorage.getItem('eMediCart'));
				if (cartDetails.success) {
					props.updateCartItemCount(cartDetails.data.totalItems);
				} else {
					props.updateCartItemCount(0);
				}
			} else {
				props.updateCartItemCount(0);
			}
		}
	};

	const handleLogout = () => {
		if (localStorage.getItem('eMediat')) {
			props.updateLoginState(false);
			window.localStorage.removeItem('eMediat');
			window.localStorage.removeItem('eMediatt');
			dispatch(LoginActions.updateCurrentLoginStage('phoneNo'));
			dispatch(LoginActions.updateLoginSubHeader('Enter your mobile number'));
			//TODO: Once cart is sync with DB & api uncomment the below line
			window.localStorage.removeItem('eMediCart');
			window.location.reload();
			window.scroll(0, 0);
		}
	};

	const navigateToCourse = (id) => {
		if (document.getElementById('collapsibleNavbar')) {
			document.getElementById('collapsibleNavbar').classList.remove('show');
		}
		setSearchText('');
		navigate('/course/' + id);
	};

	const viewportWidth =
		window.innerWidth || document.documentElement.clientWidth;
	return (
		<>
			<nav className='navbarMyCloser navbar navbar-expand-md sticky-top' id='navBar'>
				<div className='container-fluid'>
					<Link to='/'>
						<img
							className='navbar-brand d-none d-sm-block'
							alt='eMediskill'
							src={headerLogo}
							style={{ width: '160px' }}
						/>
						<img
							className='navbar-brand d-block d-sm-none  navbar-brand-mobile'
							alt='eMediskill'
							src={MobileHeaderLogo}
							style={{ width: '47px' }}
						/>
					</Link>

					{/* Tab View */}
					<div
						style={{
							justifyContent: !props.loginState && viewportWidth < 768 && 'end',
						}}
						className='ms-auto'
						id='navBarTabContainer'>
						<div
							className='d-none d-md-inline-block d-lg-none'
							id='navBarInputSearchFormContainer'>
							<div id='navBarInisResultVisibleputSearchForm'>
								{/* <form>
									<span
										className='fa fa-search form-control-feedback'
										style={{ color: 'black' }}></span>
									<input
										type='text'
										className='form-control'
										placeholder='Search for anything'
										value={searchText}
										onChange={(event) => handleSearch(event.target.value)}
									/>
								</form> */}
							</div>
						</div>

						{/* TODO fix the alignment of dashboard */}
						{props.loginState && props.purchasesCount > 0 && (
							<div
								className='d-md-inline-block d-lg-none text-end'
								here
								style={{ width: 'fit-content' }}>
								<Link to='/dashboard'>
									<img
										alt='dashboard'
										className='mobileDashboardIcon'
										src={DashboardIcon}
										style={{ width: '35px' }}
									/>
								</Link>
							</div>
						)}

						{/* <div
							className={`d-md-inline-block d-lg-none position-relative cartIconContainer ${
								props.loginState && props.purchasesCount > 0
									? ''
									: 'handleCartWhenPurchaseG0'
							}`}>
							<Link to='/cart'>
								<img src={cartIcon} alt='cartIcon' />
								<span className='position-absolute badge'>{props.cartItemCount}</span>
							</Link>
						</div> */}

						{!props.loginState && (
							<>
								<div
									className='d-none d-md-inline-block d-lg-none text-end'
									style={{ width: 'fit-content', marginLeft: '15px' }}>
									<Link className='tabButtonOnNavBar text-center' to='/login'>
										Log in
									</Link>
								</div>

								{/* <div
									className='d-none d-md-inline-block d-lg-none text-end'
									style={{ width: 'fit-content', marginLeft: '15px' }}>
									<Link className='tabButtonOnNavBar text-center' to='/signup'>
										Sign up
									</Link>
								</div> */}
							</>
						)}

						{props.loginState && (
							<li
								className='d-none d-md-inline-block d-lg-none text-end'
								style={{ width: 'fit-content' }}>
								<button
									className='btn btn-sm buttonOnNavBar text-center'
									onClick={handleLogout}>
									Log out
								</button>
							</li>
						)}
					</div>
					{/* End Tab View */}

					<button
						ref={hamburgerMenuIconRef}
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#collapsibleNavbar'
						style={{ width: '3%', boxShadow: 'unset' }}>
						<img
							alt='hamburgerMenuIcon'
							src={hamburgerMenuIcon}
							style={{ width: '40px' }}
						/>
					</button>

					<div
						ref={collapsibleNavbarRef}
						className='collapse navbar-collapse'
						id='collapsibleNavbar'
						style={{
							justifyContent: 'space-evenly',
						}}>
						<div id='navBarInputSearchForm'>
							<form className='d-sm-block d-lg-block'>
								<span
									className='fa fa-search form-control-feedback'
									style={{ color: 'black' }}></span>
								<input
									type='text'
									className='form-control'
									placeholder='Search for anything'
									value={searchText}
									onChange={(event) => handleSearch(event.target.value)}
								/>
							</form>
							<ul className='list-group mt-1 d-sm-block d-lg-block searchResults'>
								{isResultVisible &&
									allCourses &&
									allCourses.map((course) =>
										course.title
											.trim()
											.toLowerCase()
											.includes(searchText.toLowerCase()) ||
											course.menmonic
												.trim()
												.toLowerCase()
												.includes(searchText.toLowerCase()) ? (
											<li
												key={course['_id']}
												className='list-group-item'
												onClick={() => navigateToCourse(course['_id'])}>
												<div className='d-flex gap-3 align-items-center'>
													<img
														src={course.thumbnailUrl}
														alt='Course 1'
														className='searchImg'
													/>
													<div>
														<h6 className='searchText'>{course.title}</h6>
													</div>
												</div>
											</li>
										) : null
									)}
							</ul>
						</div>
						<br />
						<ul className='navbar-nav '>
							{props.loginState && props.purchasesCount > 0 && (
								<li className='nav-item d-none d-md-block'>
									<Link to='/dashboard'>
										<img
											alt='dashboard'
											className=''
											src={DashboardIcon}
											style={{ width: '35px' }}
										/>
									</Link>
								</li>
							)}

							{/* <li
								className='nav-item d-none d-md-block'
								style={{ margin: '7px 10px 7px 4px' }}>
								<div className='position-relative cartIconContainer'>
									<Link to='/cart'>
										<img src={cartIcon} alt='cartIcon' />
										<span className='position-absolute badge'>{props.cartItemCount}</span>
									</Link>
								</div>
							</li> */}

							{!props.loginState && (
								<>
									<li
										className='nav-item d-sm-block d-lg-block'
										style={{ marginTop: '8px' }}>
										<Link
											className='buttonOnNavBar buttonOnNavBarMargin text-center'
											to='/login'>
											Log in
										</Link>
									</li>

									{/* <li
										className='nav-item d-sm-block  d-lg-block'
										style={{ marginTop: '8px' }}>
										<Link
											className='buttonOnNavBar buttonOnNavBarMargin text-center'
											to='/signup'>
											Sign up
										</Link>
									</li> */}
								</>
							)}

							{props.loginState && (
								<li
									className='nav-item d-sm-block  d-lg-block'
									style={{ marginLeft: '6px' }}>
									<button
										className='btn btn-sm buttonOnNavBar text-center'
										onClick={handleLogout}>
										Log out
									</button>
								</li>
							)}
						</ul>
					</div>
				</div>
			</nav>

			<div className='bottomStickyContainer'>
				<Link
					style={{
						display: 'block',
						marginBottom: '15px',
					}}
					to='/request-a-call'>
					<img
						id='NavRequestCallStickyIcon'
						src={RequestCallStickyIcon}
						alt='RequestCallStickyIcon'
					/>
				</Link>

				<Link
					target='_blank'
					to='https://api.whatsapp.com/send/?phone=919822081133&text&type=phone_number&app_absent=0'>
					<img
						id='NavWhatsAppStickyIcon'
						src={WhatsAppStickyIcon}
						alt='whatsAppStickyIcon'
					/>
				</Link>
			</div>
		</>
	);
}

function mapStateToProps(state) {
	return {
		loginState: state.navbar.loginState,
		cartItemCount: state.cart.cartItemCount,
		purchasesCount: state.navbar.purchasesCount,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateLoginState: NavbarActions.updateLoginState,
			updateCartItemCount: CartActions.updateCartItemCount,
		},
		dispatch
	);
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
