const initialState = {
	loginState: false,
	purchasesCount: 0,
};

const NavbarReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_LOGIN_STATE':
			return {
				...state,
				loginState: action.payload.loginState,
			};
		case 'UPDATE_PURCHASES_COUNT':
			return {
				...state,
				purchasesCount: action.payload.purchasesCount,
			};
		default:
			return state;
	}
};

export default NavbarReducer;
