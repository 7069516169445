import React from "react";
import { Link } from "react-router-dom";

import CartAndEnrollView from "@components/Popups/CartAndEnrollView";
import ContentLoader from "react-content-loader";

import "./home.css";
import "@css/common.css";
import Content from "@content/Home";

import OfferScratchCard from "@components/OfferScratchCard";
import ConfirmOrder from "@pages/Home/ConfirmOrder";

import OfferBadgeBg from "@assets/homePage/offerBadgeBgHomePage.png";

import Banner from "@assets/homePage/desktopBanner.svg";
import ExploreArrow from "@assets/homePage/exploreArrow.png";

import AnatomySection from "@assets/homePage/anatomySection.png";
import reviewer1 from "@assets/homePage/reviewer1.png";
import reviewerE1 from "@assets/homePage/reviewerE1.png";
import reviewerBg1 from "@assets/homePage/reviewerBg1.png";

import reviewer2 from "@assets/homePage/reviewer2.png";
import reviewerE2 from "@assets/homePage/reviewerE2.png";
import reviewerBg2 from "@assets/homePage/reviewerBg2.png";

import reviewer3 from "@assets/homePage/reviewer3.png";
import reviewerE3 from "@assets/homePage/reviewerE3.png";
import reviewerBg3 from "@assets/homePage/reviewerBg3.png";

import reviewer4 from "@assets/homePage/reviewer4.png";
import reviewerE4 from "@assets/homePage/reviewerE4.png";
import reviewerBg4 from "@assets/homePage/reviewerBg4.png";

import reviewer5 from "@assets/homePage/reviewer5.png";
import reviewerE5 from "@assets/homePage/reviewerE5.png";
import reviewerBg5 from "@assets/homePage/reviewerBg5.png";

import reviewer6 from "@assets/homePage/reviewer6.png";
import reviewerE6 from "@assets/homePage/reviewerE6.png";
import reviewerBg6 from "@assets/homePage/reviewerBg6.png";

import reviewer7 from "@assets/homePage/reviewer7.png";
import reviewerE7 from "@assets/homePage/reviewerE7.png";
import reviewerBg7 from "@assets/homePage/reviewerBg7.png";

import reviewerF1 from "@assets/homePage/reviewerF1.png";
import reviewerFE1 from "@assets/homePage/reviewerFE1.png";

import reviewerF2 from "@assets/homePage/reviewerF2.png";
import reviewerFE2 from "@assets/homePage/reviewerFE2.png";

import TypewriterX from "@components/TypeWriter/";

export default (props) => {
  return (
    <>
      <center>
        <div
          id="banner"
          className="position-relative homePageMainBannerContainer"
        >
          <img
            alt="Banner"
            onLoad={props.handleBannerImageLoading}
            style={{ display: props.isBannerLoaded ? "block" : "none" }}
            src={Banner}
          />
          {props.isBannerLoaded ? (
            <>
              <div
                className="position-absolute d-none d-lg-block"
                id="bannerTextDivDektop"
              >
                <h3>"Learn Medicine Online</h3>
                <h3 className="gradientHeading">
                  Anytime, Anywhere. Empower Yourself!"
                </h3>
                <div>
                  <p>{Content.bannerSection.description}</p>
                </div>
              </div>

              <div
                className="position-absolute d-block d-lg-none"
                id="bannerTextDivTablet"
              >
                <h3>"Learn Medicine Online</h3>
                <h3 id="gradientHeading">
                  Anytime, Anywhere. Empower Yourself!"
                </h3>
                <div>
                  <p>{Content.bannerSection.description}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-none d-sm-block" style={{ marginTop: "-10px" }}>
                <ContentLoader viewBox="0 0 500 130">
                  <rect x="0" y="0" rx="0" ry="0" width="500" height="130" />
                </ContentLoader>
              </div>

              <div className="d-block d-sm-none">
                <ContentLoader viewBox="0 0 500 500">
                  <rect x="0" y="0" rx="0" ry="0" width="500" height="500" />
                </ContentLoader>
              </div>
            </>
          )}
        </div>
      </center>

      <center>
        <div
          id="courseSection"
          className="homePageContainer homePageMainContainer"
        >
          <h1 style={{ marginTop: "4rem" }} className="heading">
            {Content.section1.title1}
          </h1>
          <p className="subHeading">{Content.section1.description1}</p>

          <div id="courseList">
            <h4 className="heading">{Content.section1.title2}</h4>
            <p className="subHeading">{Content.section1.description2}</p>

            {props.isLoading ? (
              <>
                {props.latestCoursesDisplay ? (
                  <>
                    <div className="d-none d-md-block">
                      <br />
                      <ContentLoader viewBox="0 0 500 70">
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                        <rect
                          x="100"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                        <rect
                          x="200"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                        <rect
                          x="300"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                        <rect
                          x="400"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                      </ContentLoader>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-none d-md-block">
                      <br />
                      <ContentLoader viewBox="0 0 380 70">
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                        <rect
                          x="100"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                        <rect
                          x="200"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                        <rect
                          x="300"
                          y="0"
                          rx="5"
                          ry="5"
                          width="90"
                          height="60"
                        />
                      </ContentLoader>
                    </div>
                  </>
                )}

                <div className="d-block d-md-none">
                  <ContentLoader viewBox="0 0 380 100">
                    <rect x="0" y="0" rx="5" ry="5" width="190" height="100" />
                    <rect
                      x="200"
                      y="0"
                      rx="5"
                      ry="5"
                      width="190"
                      height="100"
                    />
                  </ContentLoader>
                  <br />
                  <br />
                  <ContentLoader viewBox="0 0 380 100">
                    <rect x="0" y="0" rx="5" ry="5" width="190" height="100" />
                    <rect
                      x="200"
                      y="0"
                      rx="5"
                      ry="5"
                      width="190"
                      height="100"
                    />
                  </ContentLoader>
                </div>
              </>
            ) : (
              <>
                <div className="row" style={{ marginTop: "25px" }}>
                  {props.latestCourses.data &&
                    props.latestCourses.data.map((item, index) => (
                      <React.Fragment key={index}>
                        {props.latestCoursesDisplay ? (
                          <div
                            key={index}
                            className="col-6 col-md-3 courseListChild"
                            style={{ width: "20%" }}
                          >
                            <div
                              onClick={(event) => {
                                props.handleCourse(event, item._id);
                              }}
                              className="shineOnCourseCard"
                            >
                              <img
                                alt={item.title}
                                src={item.thumbnailUrl}
                                className="w-100"
                              />
                            </div>

                            <p className="courseDescription">{item.title}</p>
                            <p className="coursePrice">{item.price}</p>
                          </div>
                        ) : (
                          index <= 3 && (
                            <div
                              key={index}
                              className="col-6 col-md-3 courseListChild"
                            >
                              <div
                                onClick={(event) => {
                                  props.handleCourse(event, item._id);
                                }}
                                className="shineOnCourseCard"
                              >
                                <img
                                  alt={item.title}
                                  src={item.thumbnailUrl}
                                  className="w-100"
                                />
                              </div>

                              <p className="courseDescription">{item.title}</p>
                              <p className="coursePrice">{item.price}</p>
                            </div>
                          )
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </>
            )}

            <div
              className="d-flex justify-content-center w-100"
              style={{ marginTop: "20px" }}
            >
              <Link to="/explore" className="homePageBtn">
                Explore all Courses
                <img
                  src={ExploreArrow}
                  alt="ExploreArrow"
                  style={{ width: "18px", marginLeft: "10px" }}
                />
              </Link>
            </div>
          </div>
        </div>
      </center>

      {/* <br />
		<br />
		<br /> */}

      <center>
        <div id="anatomySection" className="homePageMainBannerContainer">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5 textDiv">
              <TypewriterX />

              <p>{Content.section2.description}</p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-7 offerImgDiv">
              <img
                alt="anatomySection"
                src={AnatomySection}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </center>

      {/* Desktop view*/}

      <div
        id="offersDesktopView"
        className="row d-none d-lg-flex homePageMainBannerContainer"
      >
        <center>
          <div
            id="offersDesktopView_internal_div"
            className="homePageMainBannerContainer"
          >
            <div className="col-lg-4 col-xl-5 textDiv">
              <h1 style={{ marginBottom: "20px" }}>
                {props.coupons
                  ? props.coupons?.data?.offerNote?.title
                  : "Special Offer"}
              </h1>
              {/* uncomment below code when autum is there */}
              {/* <img style={{ width: '60%' }} src={OffersTitle} alt='OffersTitle' />
					<h1 style={{ marginBottom: '0px' }}>Offer</h1> */}
              <p style={{ color: "black" }}>
                {props.coupons
                  ? props.coupons?.data?.offerNote?.description
                  : Content.offers.offerDescription}
              </p>

              <button className="exploreOfferesBtn">
                <Link to="/offers" style={{ display: "block" }}>
                  Explore Offers
                </Link>
                <span className="shine"></span>
              </button>
            </div>

            {props.isLoading ? (
              <>
                <div className="mainOfferPage2 homePageUnDomainOfferPage2 row col-lg-8 col-xl-7">
                  <ContentLoader viewBox="0 0 200 120">
                    <rect x="0" y="10" rx="5" ry="5" width="95" height="100" />
                    <rect
                      x="100"
                      y="10"
                      rx="5"
                      ry="5"
                      width="95"
                      height="100"
                    />
                  </ContentLoader>
                </div>
              </>
            ) : (
              <>
                <div className="mainOfferPage2 homePageUnDomainOfferPage2 row col-lg-8 col-xl-7">
                  {props.coupons &&
                    props.coupons.success &&
                    props.coupons.data.coupons.map((offer, index) => (
                      <div
                        key={index}
                        className="offerContainer offerContainerHomePage col-5"
                      >
                        {props.isOfferImageLoaded && (
                          <div className="offerContainer1">
                            <img
                              className="offerPercentageImg"
                              src={OfferBadgeBg}
                              alt="offer"
                            />
                            <p className="offerDiscountPercentage">
                              {offer.discounts && offer.discounts.length > 0 && offer.discounts[offer.discounts.length-1].discount}%
                            </p>
                          </div>
                        )}

                        <img
                          onLoad={props.handleOfferImageLoading}
                          id={"offerImg_" + index}
                          onMouseEnter={(event) => {
                            props.handleMouseEnter(
                              event,
                              index,
                              offer.description
                            );
                          }}
                          className="w-100"
                          src={offer.thumbnailUrl}
                          alt="offerCard"
                        />

                        {props.isOfferImageLoaded && (
                          <>
                            <OfferScratchCard offerCode={offer.couponCode} />
                            {/* desktop */}
                            <p
                              id={"offerDetails_" + index}
                              onMouseLeave={(event) => {
                                props.handleMouseLeave(
                                  event,
                                  offer.thumbnailUrl,
                                  index
                                );
                              }}
                              className="offerDetails"
                            >
                              {/* {offer.description} */}
                            </p>

                            <p className="offerDescription"> {offer.title}</p>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </>
            )}

            {/* START : OLD UI */}
            {/* <div className='col-lg-8 col-xl-8 imgDiv'>
					<div className='position-relative offerImgDiv'>
						<img src={OfferOne} alt='OfferOne' className='offerImage' />
						<div className='position-absolute'>
							<p>
								<img src={OfferStar} alt='OfferStar' className='offerStar' />
								Certificate course in child health
							</p>
							<p>
								<img src={OfferStar} alt='offerStar' className='offerStar' />
								Certificate course in skin and venereal diseases
							</p>
						</div>
						<h4 className='position-absolute'>39,000/-</h4>
					</div>

					<div className='position-relative offerImgDiv'>
						<img src={OfferTwo} alt='OfferTwo' className='offerImage' />
						<div className='position-absolute'>
							<p>
								<img src={OfferStar} alt='OfferStar' className='offerStar' />
								Certificate course in child health
							</p>
							<p>
								<img src={OfferStar} alt='offerStar' className='offerStar' />
								Certificate course in skin and venereal diseases
							</p>
						</div>
						<h4 className='position-absolute'>39,000/-</h4>
					</div>
				</div> */}
          </div>
        </center>
      </div>

      {/*End  of desktop view */}
      {/* START : OLD UI */}

      {/*Tab & mobile view*/}
      <div id="offersTMView" className="d-block d-lg-none ">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-12 col-sm-6 col-md-6 textDiv">
            <h1 style={{ marginBottom: "20px" }}>
              {props.coupons
                ? props.coupons?.data?.offerNote?.title
                : "Special Offer"}
            </h1>
            {/* uncomment below code when autum is there */}
            {/* <img style={{ width: '60%' }} src={OffersTitle} alt='OffersTitle' />
					<h1 style={{ marginBottom: '0px' }}>Offer</h1> */}
            <p style={{ color: "black" }}>
              {props.coupons
                ? props.coupons?.data?.offerNote?.description
                : Content.offers.offerDescription}
            </p>

            <button className="exploreOfferesBtn d-none d-sm-block">
              <Link to="/offers" style={{ verticalAlign: "super" }}>
                Explore Offer
              </Link>
              <span className="shine"></span>
            </button>
          </div>

          {props.isLoading ? (
            <>
              <div className="mainOfferPage2 homePageUnDomainOfferPage2 row col-10 col-sm-6 col-md-6  ">
                <ContentLoader viewBox="0 0 100 120">
                  <rect x="0" y="10" rx="5" ry="5" width="100" height="100" />
                </ContentLoader>
              </div>
            </>
          ) : (
            <>
              <div className="mainOfferPage2 homePageUnDomainOfferPage2 row col-10 col-sm-6 col-md-6  ">
                {props.coupons &&
                  props.coupons.success &&
                  props.coupons.data.coupons.map((offer, index) => (
                    <div key={index}>
                      {index === 0 && (
                        <div
                          key={index}
                          className="offerContainer offerContainerHomePage col-12"
                        >
                          {props.isOfferImageLoaded && (
                            <div className="offerContainer1">
                              <img
                                className="offerPercentageImg"
                                src={OfferBadgeBg}
                                alt="offer"
                              />
                              <p className="offerDiscountPercentage">
                                {offer.discounts && offer.discounts.length > 0 && offer.discounts[offer.discounts.length-1].discount}%
                              </p>
                            </div>
                          )}

                          <img
                            onLoad={props.handleOfferImageLoading}
                            id={"offerImgM_" + index}
                            onTouchStart={(event) => {
                              props.handleTouchStart(
                                event,
                                offer.thumbnailUrl,
                                index,
                                "show"
                              );
                            }}
                            className="w-100"
                            src={offer.thumbnailUrl}
                            alt="offerCard"
                          />

                          {props.isOfferImageLoaded && (
                            <>
                              <OfferScratchCard offerCode={offer.couponCode} />
                              {/* Mobile */}
                              <p
                                id={"offerDetailsM_" + index}
                                onTouchStart={(event) => {
                                  props.handleTouchStart(
                                    event,
                                    offer.thumbnailUrl,
                                    index,
                                    "hide"
                                  );
                                }}
                                className="offerDetails"
                              >
                                {offer.description}
                              </p>

                              <p className="offerDescription"> {offer.title}</p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          )}

          {/* START : OLD UI */}
          {/* <div
					id='offersTMCarousel'
					className='col-10 col-sm-6 col-md-6  carousel slide'
					data-bs-ride='carousel'>
					<div className='carousel-indicators' style={{ bottom: '-41px' }}>
						<button
							type='button'
							data-bs-target='#offersTMCarousel'
							data-bs-slide-to='0'
							className='active'
							aria-current='true'
							aria-label='Slide 1'></button>
						<button
							type='button'
							data-bs-target='#offersTMCarousel'
							data-bs-slide-to='1'
							aria-label='Slide 2'></button>
					</div>
					<div className='carousel-inner'>
						<div className='carousel-item active'>
							<div className='imgDiv'>
								<div className='position-relative offerImgDiv'>
									<img src={OfferOne} alt='OfferOne' className='offerImage' />
									<div className='position-absolute'>
										<p>
											<img src={OfferStar} alt='OfferStar' className='offerStar' />
											Certificate course in child health
										</p>
										<p>
											<img src={OfferStar} alt='offerStar' className='offerStar' />
											Certificate course in skin and venereal diseases
										</p>
									</div>
									<h4 className='position-absolute'>39,000/-</h4>
								</div>
							</div>
						</div>

						<div className='carousel-item'>
							<div className='imgDiv'>
								<div className='position-relative offerImgDiv'>
									<img src={OfferTwo} alt='OfferTwo' className='offerImage' />
									<div className='position-absolute'>
										<p>
											<img src={OfferStar} alt='OfferStar' className='offerStar' />
											Certificate course in child health
										</p>
										<p>
											<img src={OfferStar} alt='offerStar' className='offerStar' />
											Certificate course in skin and venereal diseases
										</p>
									</div>
									<h4 className='position-absolute'>39,000/-</h4>
								</div>
							</div>
						</div>
					</div>
				</div> */}
          {/* START : OLD UI */}
        </div>

        <center>
          <button className="exploreOfferesBtn d-block d-sm-none">
            <Link to="/offers" style={{ verticalAlign: "super" }}>
              Explore Offer
            </Link>
            <span className="shine"></span>
          </button>
        </center>
      </div>
      {/*End of tab & mobile view*/}

      <center>
        <div id="courseSection2" className="homePageMainContainer">
          <div className="homePageContainer">
            <div className="courseSection2InnerSection1">
              <div className="row courseSection2InnerSection2">
                {props.isLoading ? (
                  <>
                    {props.latestCoursesDisplay ? (
                      <>
                        <ContentLoader viewBox="0 0 500 70">
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                          <rect
                            x="100"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                          <rect
                            x="200"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                          <rect
                            x="300"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                          <rect
                            x="400"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                        </ContentLoader>
                      </>
                    ) : (
                      <>
                        <ContentLoader viewBox="0 0 380 70">
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                          <rect
                            x="100"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                          <rect
                            x="200"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                          <rect
                            x="300"
                            y="0"
                            rx="5"
                            ry="5"
                            width="90"
                            height="60"
                          />
                        </ContentLoader>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {props.latestCourses.data &&
                      props.latestCourses.data.map((item, index) => (
                        <React.Fragment key={index}>
                          {props.latestCoursesDisplay ? (
                            <>
                              <div
                                key={index}
                                className="col-6 col-md-3 courseListChild"
                                style={{ width: "20%" }}
                              >
                                <div
                                  onClick={(event) => {
                                    props.handleCourse(event, item._id);
                                  }}
                                  className="shineOnCourseCard"
                                >
                                  <img
                                    alt={item.title}
                                    src={item.thumbnailUrl}
                                    className="w-100"
                                  />
                                </div>

                                <p className="courseDescription">
                                  {item.title}
                                </p>
                                <p className="coursePrice">{item.price}</p>
                              </div>
                            </>
                          ) : (
                            index <= 3 && (
                              <div
                                key={index}
                                className="col-3 col-lg-3 courseSection2InnerSection3"
                              >
                                <div
                                  onClick={(event) => {
                                    props.handleCourse(event, item._id);
                                  }}
                                  className="shineOnCourseCard"
                                >
                                  <img
                                    id={item._id}
                                    alt={item.title}
                                    src={item.thumbnailUrl}
                                    className="w-100"
                                  />
                                </div>
                                <p className="courseDescription">
                                  {item.title}
                                </p>
                                <p className="coursePrice">{item.price}</p>
                              </div>
                            )
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </center>

      <center>
        <div id="learningSection" className="homePageMainContainer">
          <div className="row">
            {/* Remove on demand BUT keep the code */}
            {/* <div className='col-12 col-sm-6 col-md-7 col-lg-4 col-xl-6 textDiv'> */}
            <div className="col-12 textDiv" style={{ marginBottom: "40px" }}>
              <h1 style={{ textAlign: "center", fontSize: "3rem" }}>
                {Content.startLearningSection.title}
              </h1>
              <p>{Content.startLearningSection.description}</p>
              <div className="d-flex justify-content-center w-100">
                <Link className="homePageBtn" to="/explore">
                  Start learning
                  <img
                    src={ExploreArrow}
                    alt="ExploreArrow"
                    style={{ width: "18px", marginLeft: "15px" }}
                  />
                </Link>
              </div>
            </div>

            {/* START : Remove on demand BUT keep the code */}
            {/* <div className='col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3'>
						<div className='card'>
							<img
								src={homeLearningSection1}
								className='card-img'
								alt='homeLearningSection'
							/>
							<div className='card-img-overlay'>
								<h5 className=''>Learn from the best</h5>
								<h4 className=''>500+</h4>
							</div>
						</div>

						<div className='card'>
							<img
								src={homeLearningSection2}
								className='card-img'
								alt='homeLearningSection'
							/>
							<div className='card-img-overlay'>
								<h5 className=''>Record video lecture</h5>
								<h4 className=''>500+</h4>
							</div>
						</div>

						<div className='card'>
							<img
								src={homeLearningSection3}
								className='card-img'
								alt='homeLearningSection'
							/>
							<div className='card-img-overlay'>
								<h5 className=''>Organise Courses</h5>
								<h4 className=''>500+</h4>
							</div>
						</div>
					</div>

					<div className='col-lg-4 col-xl-3 d-none d-lg-flex' id='desktopSection'>
						<div className='card'>
							<img
								src={homeLearningSection1}
								className='card-img'
								alt='homeLearningSection'
							/>
							<div className='card-img-overlay'>
								<h5 className=''>Year of Experience</h5>
								<h4 className=''>500+</h4>
							</div>
						</div>

						<div className='card'>
							<img
								src={homeLearningSection2}
								className='card-img'
								alt='homeLearningSection'
							/>
							<div className='card-img-overlay'>
								<h5 className=''>Student</h5>
								<h4 className=''>500+</h4>
							</div>
						</div>
					</div> */}
            {/* END : Remove on demand BUT keep the code */}
          </div>
        </div>
      </center>

      <center>
        <div className="homePageMainContainer text-center">
          <h1 className="reviewSectionHeading">
            {Content.testimonySection.title}
          </h1>
          <p className="reviewSectionSubHeading">
            {Content.testimonySection.description}
          </p>
        </div>

        <div id="reviewSection" className="homePageMainContainer">
          <div
            onLoad={props.onLoadReviewInnerSection1}
            className="reviewInnerSection1"
            id="reviewInnerSection1"
          >
            <div className="row reviewInnerSection2">
              <div className="col-4" style={{ padding: "5px" }}>
                {/* reviewerDiv 1 */}
                <div
                  className="reviewerDiv"
                  style={{ backgroundImage: `url(${reviewerBg1})` }}
                >
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerE1}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <div className="reviewerLDiv">
                    <img src={reviewer1} alt="reviewerLogo" />
                    <p className="d-inline-block">
                      {
                        Content.testimonySection.testimonials[0]
                          .testimonial_Name
                      }
                      <br />
                      <span>
                        {
                          Content.testimonySection.testimonials[0]
                            .testimonial_Profession
                        }
                      </span>
                    </p>
                  </div>
                  <p className="reviewPara">
                    {Content.testimonySection.testimonials[0].testimony}
                  </p>
                </div>

                {/* reviewerDiv 2 */}
                <div
                  className="reviewerDiv"
                  style={{ backgroundImage: `url(${reviewerBg2})` }}
                >
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerE2}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <div className="reviewerLDiv">
                    <img src={reviewer2} alt="reviewerLogo" />
                    <p className="d-inline-block">
                      {
                        Content.testimonySection.testimonials[1]
                          .testimonial_Name
                      }
                      <br />
                      <span>
                        {
                          Content.testimonySection.testimonials[1]
                            .testimonial_Profession
                        }
                      </span>
                    </p>
                  </div>
                  <p className="reviewPara">
                    {Content.testimonySection.testimonials[1].testimony}
                  </p>
                </div>

                {/* reviewerDiv 3 */}
                <div className="reviewerDiv reviewerFrameDiv">
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerFE1}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <img
                    src={reviewerF1}
                    alt="reviewerF1"
                    className="reviewerFrameImg"
                  />
                </div>
              </div>

              <div className="col-4" style={{ padding: "5px" }}>
                {/* reviewerDiv 4 */}
                <div
                  className="reviewerDiv"
                  style={{ backgroundImage: `url(${reviewerBg3})` }}
                >
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerE3}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <div className="reviewerLDiv">
                    <img src={reviewer3} alt="reviewerLogo" />
                    <p className="d-inline-block">
                      {
                        Content.testimonySection.testimonials[3]
                          .testimonial_Name
                      }
                      <br />
                      <span>
                        {
                          Content.testimonySection.testimonials[3]
                            .testimonial_Profession
                        }
                      </span>
                    </p>
                  </div>
                  <p className="reviewPara">
                    {Content.testimonySection.testimonials[3].testimony}
                  </p>
                </div>

                {/* reviewerDiv 5 */}
                <div className="reviewerDiv reviewerFrameDiv">
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerFE2}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <img
                    src={reviewerF2}
                    alt="reviewerF2"
                    className="reviewerFrameImg"
                  />
                </div>

                {/* reviewerDiv 6 */}
                <div
                  className="reviewerDiv"
                  style={{ backgroundImage: `url(${reviewerBg4})` }}
                >
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerE4}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <div className="reviewerLDiv">
                    <img src={reviewer4} alt="reviewerLogo" />
                    <p className="d-inline-block">
                      {
                        Content.testimonySection.testimonials[5]
                          .testimonial_Name
                      }
                      <br />
                      <span>
                        {
                          Content.testimonySection.testimonials[5]
                            .testimonial_Profession
                        }
                      </span>
                    </p>
                  </div>
                  <p className="reviewPara">
                    {Content.testimonySection.testimonials[5].testimony}
                  </p>
                </div>
              </div>

              <div className="col-4" style={{ padding: "5px" }}>
                {/* reviewerDiv 7 */}
                <div
                  className="reviewerDiv"
                  style={{ backgroundImage: `url(${reviewerBg5})` }}
                >
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerE5}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <div className="reviewerLDiv">
                    <img src={reviewer5} alt="reviewerLogo" />
                    <p className="d-inline-block">
                      {
                        Content.testimonySection.testimonials[6]
                          .testimonial_Name
                      }
                      <br />
                      <span>
                        {
                          Content.testimonySection.testimonials[6]
                            .testimonial_Profession
                        }
                      </span>
                    </p>
                  </div>
                  <p className="reviewPara">
                    {Content.testimonySection.testimonials[6].testimony}
                  </p>
                </div>

                {/* reviewerDiv 8 */}
                <div
                  className="reviewerDiv"
                  style={{ backgroundImage: `url(${reviewerBg6})` }}
                >
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerE6}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <div className="reviewerLDiv">
                    <img src={reviewer6} alt="reviewerLogo" />
                    <p className="d-inline-block">
                      {
                        Content.testimonySection.testimonials[7]
                          .testimonial_Name
                      }
                      <br />
                      <span>
                        {
                          Content.testimonySection.testimonials[7]
                            .testimonial_Profession
                        }
                      </span>
                    </p>
                  </div>
                  <p className="reviewPara">
                    {Content.testimonySection.testimonials[7].testimony}
                  </p>
                </div>

                {/* reviewerDiv 9 */}
                <div
                  className="reviewerDiv"
                  style={{ backgroundImage: `url(${reviewerBg7})` }}
                >
                  <div className="position-relative reviewerEDiv">
                    <img
                      src={reviewerE7}
                      alt="reviewerELogo"
                      className="position-absolute"
                    />
                  </div>
                  <div className="reviewerLDiv">
                    <img src={reviewer7} alt="reviewerLogo" />
                    <p className="d-inline-block">
                      {
                        Content.testimonySection.testimonials[8]
                          .testimonial_Name
                      }
                      <br />
                      <span>
                        {
                          Content.testimonySection.testimonials[8]
                            .testimonial_Profession
                        }
                      </span>
                    </p>
                  </div>
                  <p className="reviewPara">
                    {Content.testimonySection.testimonials[8].testimony}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </center>

      <CartAndEnrollView />

      <ConfirmOrder
        showInVoicePossible={props.showInVoicePossible}
        openhomePageConfirmOrderViewModalBtn={
          props.openhomePageConfirmOrderViewModalBtn
        }
      />
    </>
  );
};
