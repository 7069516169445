const initialState = {
  userDetails: null,
  paymentMethod: "",
  orderAmount: "0",
  orderAmountInNumbers: 0,
  coursesMnemonic: "",
  courses: null,
  continent: "",
  countryName: "India",
  currencySymbol: "",
  currencyCode: "",
  GST: 0,
  grandTotal: 0,
  netTotal: 0,
  subTotal: 0,
  discount: "0",
  discountInNumbers: 0,
  discountInPercentage: 0,
  offerDetails: null,
  gatewayDiscount: "0",
  gatewayDiscountInNumber: 0,
  gatewayDetails: null,
  installmentOption: null,
  paymentStatus: "full",
  installmentNumber: 0,
  expenseType: "course",
};

const PayReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FINAL_PAYMENT_DETAILS":
      return {
        ...state,
        userDetails: action.payload.paymentDetails.userDetails,
        paymentMethod: action.payload.paymentDetails.paymentMethod,
        orderAmount: action.payload.paymentDetails.orderAmount,
        orderAmountInNumbers:
          action.payload.paymentDetails.orderAmountInNumbers,
        coursesMnemonic: action.payload.paymentDetails.coursesMnemonic,
        courses: action.payload.paymentDetails.courses,
        continent: action.payload.paymentDetails.continent,
        countryName: action.payload.paymentDetails.countryName,
        currencySymbol: action.payload.paymentDetails.currencySymbol,
        currencyCode: action.payload.paymentDetails.currencyCode,
        GST: action.payload.paymentDetails.GST,
        grandTotal: action.payload.paymentDetails.grandTotal,
        netTotal: action.payload.paymentDetails.netTotal,
        subTotal: action.payload.paymentDetails.subTotal,
        discount: action.payload.paymentDetails.discount,
        discountInNumbers: action.payload.paymentDetails.discountInNumbers,
        discountInPercentage:
          action.payload.paymentDetails.discountInPercentage,
        offerDetails: action.payload.paymentDetails.offerDetails,
        gatewayDiscount: action.payload.paymentDetails.gatewayDiscount,
        gatewayDiscountInNumber:
          action.payload.paymentDetails.gatewayDiscountInNumber,
        gatewayDetails: action.payload.paymentDetails.gatewayDetails,
        installmentOption: action.payload.paymentDetails.installmentOption,
        paymentStatus: action.payload.paymentDetails.paymentStatus,
        installmentNumber: action.payload.paymentDetails.installmentNumber,
        expenseType: action.payload.paymentDetails.expenseType,
      };
    case "RESET_PAY_REDUCER":
      return {
        ...initialState,
        ccavenueResponse: state.ccavenueResponse,
      };
    default:
      return state;
  }
};

export default PayReducer;
