import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import InvoicePDFView from './view';
import Navbar from '@components/Navbar';
import TopLoadingBar from '@components/TopLoadingBar';

import { isUserLogedIn } from '@library/auth';

import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';
import * as NavbarActions from '@redux/actions/Navbar';

function InvoicePDF() {
	const dataFetchedRef = useRef(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const invoice = location.state?.invoice;

	const loadingBarProgress = useSelector(
		(state) => state.navbar.loadingBarProgress
	);

	useEffect(() => {
		window.scroll(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		checkLogin();
		UpdateTopLoadingBarForThisPage(30);
	});

	const checkLogin = async () => {
		try {
			const userLogedIn = await isUserLogedIn();
			dispatch(NavbarActions.updateLoginState(userLogedIn));
		} catch (err) {
			console.log('Error coming from checkLogin()', err);
		}
	};

	const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
		try {
			if (interval) {
				setTimeout(function () {
					dispatch(
						TopLoadingBarActions.updateLoadingBarProgress(loadingBarProgress + value)
					);
				}, 500);
			} else {
				dispatch(
					TopLoadingBarActions.updateLoadingBarProgress(loadingBarProgress + value)
				);
			}
		} catch (err) {
			console.log('Error coming from UpdateTopLoadingBarForThisPage()', err);
		}
	};

	return (
		<>
			<Navbar />
			<TopLoadingBar />
			<InvoicePDFView invoice={invoice} />
		</>
	);
}

export default InvoicePDF;
