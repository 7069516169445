import React, { useEffect, useState } from "react";
import moment from "moment";

import RequestACallView from "./view";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import { decryptText } from "@library/enc-dec";
import { isUserLogedIn } from "@library/auth";
import { getPurchasesCount } from "@library/helpers";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as NavbarActions from "@redux/actions/Navbar";

import * as CourseContentServices from "@services/CourseContent";
import * as BasicFormServices from "@services/BasicForm";

function RequestACall(props) {
  const countries = require("@assets/countryCode.json");
  const [countryCode, setCountryCode] = useState(null);
  const [whatsAppCountryCode, setWhatsAppCountryCode] = useState(null);
  const [whatsAppNumber_E_contactNumber, setWhatsAppNumber_E_contactNumber] =
    useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");

  const [userId, setUserId] = useState("");
  const [displayPhoto, setDisplayPhoto] = useState("");
  const [username, setUsername] = useState("");
  const [notes, setNotes] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const currentDate = moment().format("h.mm A, D MMMM YYYY");

  const [allFieldsRequiredError, setAllFieldsRequiredError] = useState(false);
  const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] =
    useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    handlePage();
  }, []);

  const handlePage = async () => {
    try {
      const userLogedIn = await isUserLogedIn();
      props.updateLoginState(userLogedIn);

      if (userLogedIn) {
        const purchasesCount = await getPurchasesCount();
        props.updatePurchasesCount(purchasesCount);
        getUserDetails();
      }
    } catch (err) {
      console.log("Error coming while handling request for call page", err);
    }
  };

  const getUserDetails = async () => {
    let authToken = decryptText(localStorage.getItem("eMediat"));
    const response = await BasicFormServices.getUser(authToken);

    if (response.success) {
      setUserId(response.data._id.toString());
      setDisplayPhoto(response.data.displayPhoto);
      setUsername(response.data.username);
      setNotes(response.data.notes);
      setFirstName(response.data.firstName);
      setLastName(response.data.lastName);
      setEmail(response.data.email);
      setCountryCode(response.data.countryCode);
      setWhatsAppCountryCode(response.data.whatsAppCountryCode);
      setContactNumber(response.data.contactNumber);
      setWhatsAppNumber(response.data.whatsAppNumber);
    }
  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleCountryCode = (event) => {
    setCountryCode(event.target.value);
    if (whatsAppNumber_E_contactNumber) {
      setWhatsAppCountryCode(event.target.value);
    }
  };

  const handleWhatsAppCountryCode = (event) => {
    setWhatsAppCountryCode(event.target.value);
  };

  const handleContactNumber = (event) => {
    if (event.target.value.length <= 10) {
      setContactNumber(event.target.value);
      if (whatsAppNumber_E_contactNumber) {
        setWhatsAppNumber(event.target.value);
      }
    }
  };

  const handleWhatsAppNumber = (event) => {
    if (event.target.value.length <= 10) {
      setWhatsAppNumber(event.target.value);
    }
  };

  const handleWhatsAppNumber_E_contactNumber = (event) => {
    setWhatsAppNumber_E_contactNumber(event.target.checked);
    if (event.target.checked) {
      setWhatsAppNumber(contactNumber);
      setWhatsAppCountryCode(countryCode);
    } else {
      setWhatsAppNumber("");
      setWhatsAppCountryCode("");
    }
  };

  const handleSubmit = async () => {
    setAllFieldsRequiredError(false);
    if (document.getElementById("thankYouScrollContainer")) {
      let thankYouScrollContainer = document.getElementById(
        "thankYouScrollContainer"
      );

      var offsetTop =
        thankYouScrollContainer.getBoundingClientRect().top +
        window.scrollY -
        150;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
    try {
      let payload = {
        userId: userId,
        displayPhoto: displayPhoto,
        username: username,
        notes: notes,
        firstName: firstName,
        lastName: lastName,
        email: email,
        contactNumber: contactNumber,
        countryCode: countryCode,
        whatsAppCountryCode: whatsAppCountryCode,
        whatsAppNumber: whatsAppNumber,
        title: title,
        description: description,
        location: "Contact Us",
      };

      const response = await CourseContentServices.reportQuery(payload);
      if (response.success) {
        setIsFormSubmittedSuccessfully(true);
        setTimeout(function () {
          setIsFormSubmittedSuccessfully(false);
        }, 3000);
        resetForm();
        return;
      }
      setAllFieldsRequiredError(true);
    } catch (err) {
      console.log("Error coming while request a call submition", err);
    }
  };

  const resetForm = () => {
    setWhatsAppCountryCode(null);
    setCountryCode(null);
    setWhatsAppNumber_E_contactNumber(null);
    setFirstName("");
    setLastName("");
    setEmail("");
    setContactNumber("");
    setWhatsAppNumber("");
    setTitle("");
    setDescription("");
  };

  return (
    <>
      <Navbar />
      <RequestACallView
        firstName={firstName}
        lastName={lastName}
        email={email}
        title={title}
        description={description}
        handleFirstName={handleFirstName}
        handleLastName={handleLastName}
        handleEmail={handleEmail}
        handleTitle={handleTitle}
        handleDescription={handleDescription}
        countries={countries}
        countryCode={countryCode}
        handleCountryCode={handleCountryCode}
        contactNumber={contactNumber}
        handleContactNumber={handleContactNumber}
        whatsAppNumber={whatsAppNumber}
        handleWhatsAppNumber={handleWhatsAppNumber}
        whatsAppCountryCode={whatsAppCountryCode}
        handleWhatsAppCountryCode={handleWhatsAppCountryCode}
        handleWhatsAppNumber_E_contactNumber={
          handleWhatsAppNumber_E_contactNumber
        }
        whatsAppNumber_E_contactNumber={whatsAppNumber_E_contactNumber}
        currentDate={currentDate}
        handleSubmit={handleSubmit}
        isFormSubmittedSuccessfully={isFormSubmittedSuccessfully}
        allFieldsRequiredError={allFieldsRequiredError}
      />
      <Footer />
    </>
  );
}

function mapStateToProps(state) {
  return {
    isLogedIn: state.navbar.loginState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchasesCount: NavbarActions.updatePurchasesCount,
      updateLoginState: NavbarActions.updateLoginState,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestACall);
