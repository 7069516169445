export const getSeries= async (id, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getSeries/${id}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Series services : getSeries()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};