import React, { useState, useEffect, useRef } from 'react';

import NameAndPhoneNoOtpView from './view';
import * as LoginServices from '@services/Login';

function NameAndPhoneNoOtp(props) {
	const nameAndPhoneNoOtpModalRef = useRef(null);
	const closePopBtnRef = useRef(null);
	const [keepPopupOpen, setKeepPopupOpen] = useState(false);
	const [tempCountryCode, setTempCountryCode] = useState('');
	const [tempPhoneNo, setTempPhoneNo] = useState('');
	const [tempNameAndPhoneNoOtpStage, setTempNameAndPhoneNoOtpStage] =
		useState('');
	const [flushOTPValues, setFlushOTPValues] = useState(false);

	useEffect(() => {
		if (props.countryCode && props.phoneNo) {
			setKeepPopupOpen(false);
			setTempCountryCode(props.countryCode);
			setTempPhoneNo(props.phoneNo);
		}
		if (props.nameAndPhoneNoOtpStage) {
			setKeepPopupOpen(false);
			setTempNameAndPhoneNoOtpStage(props.nameAndPhoneNoOtpStage);
		}
	}, [props.nameAndPhoneNoOtpStage, props.countryCode, props.phoneNo]);

	// To handle modal closer and flushing values
	useEffect(() => {
		const modalElement = nameAndPhoneNoOtpModalRef.current;
		if (!modalElement) return;
		const handleStyleChange = (mutationsList, observer) => {
			for (let mutation of mutationsList) {
				if (mutation.attributeName === 'style') {
					const displayStyle = window
						.getComputedStyle(modalElement)
						.getPropertyValue('display');
					if (displayStyle === 'none') {
						setFlushOTPValues(false);
						props.flushNameAndPhoneNoOtpStage();
					} else {
						setFlushOTPValues(true);
					}
				}
			}
		};
		const observer = new MutationObserver(handleStyleChange);
		observer.observe(modalElement, { attributes: true });
		return () => {
			observer.disconnect();
		};
	}, [nameAndPhoneNoOtpModalRef]);

	useEffect(()=>{
		return () =>{
			const modalBackdrop = document.querySelector('.modal-backdrop')
			if(modalBackdrop){
				modalBackdrop.remove()
			}
		}
	}, [])

	const dubLicateOpenOtpPopUp = (
		countryCode,
		contactNumber,
		stage,
		buttonType
	) => {
		setKeepPopupOpen(true);
		setTempCountryCode(countryCode);
		setTempPhoneNo(contactNumber);
		setTempNameAndPhoneNoOtpStage(stage);
		props.handleEnrollSendOTP(countryCode, contactNumber, buttonType);
	};

	const goBack = () => {
		if (!keepPopupOpen) {
			if (closePopBtnRef.current) {
				closePopBtnRef.current.click();
			}
		} else {
			setTempNameAndPhoneNoOtpStage('init');
		}
	};

	const intermediateHandleSuccessOtp = async (OTP) => {
		try {
			const response = await props.handleLeadsVerifyOTP(OTP);
			if (response.success) {
				if (closePopBtnRef.current) {
					closePopBtnRef.current.click();
				}
			}
		} catch (err) {
			console.log('Error coming from intermediateHandleSuccessOtp()', err);
		}
	};

	return (
		<NameAndPhoneNoOtpView
			tempCountryCode={tempCountryCode}
			tempPhoneNo={tempPhoneNo}
			dubLicateOpenOtpPopUp={dubLicateOpenOtpPopUp}
			tempNameAndPhoneNoOtpStage={tempNameAndPhoneNoOtpStage}
			keepPopupOpen={keepPopupOpen}
			closePopBtnRef={closePopBtnRef}
			goBack={goBack}
			flushOTPValues={flushOTPValues}
			nameAndPhoneNoOtpModalRef={nameAndPhoneNoOtpModalRef}
			intermediateHandleSuccessOtp={intermediateHandleSuccessOtp}
			{...props}
		/>
	);
}

export default NameAndPhoneNoOtp;
