import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CourseLoader() {
	return (
		<>
			<div className='d-none d-lg-block mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 200 100' style={{ marginTop: '20px' }}>
					{/* VideoPlayer */}
					<rect x='0' y='0' rx='1' ry='1' width='132' height='75' />

					{/* START : Video Playlist */}
					<rect x='135' y='2' rx='1' ry='1' width='20' height='2' />
					<rect x='135' y='6' rx='1' ry='1' width='65' height='5' />

					<rect x='135' y='16' rx='1' ry='1' width='20' height='2' />
					<rect x='135' y='20' rx='1' ry='1' width='65' height='5' />

					<rect x='135' y='30' rx='1' ry='1' width='20' height='2' />
					<rect x='135' y='34' rx='1' ry='1' width='65' height='5' />

					<rect x='135' y='44' rx='1' ry='1' width='20' height='2' />
					<rect x='135' y='48' rx='1' ry='1' width='65' height='5' />

					<rect x='135' y='58' rx='1' ry='1' width='20' height='2' />
					<rect x='135' y='62' rx='1' ry='1' width='65' height='5' />

					<rect x='135' y='72' rx='1' ry='1' width='20' height='2' />
					{/* END : Video Playlist */}


					{/* START : Video Description head(title, like, dislike,...) */}
					<rect x='0' y='80' rx='1' ry='1' width='30' height='5' />
					<rect x='64' y='80' rx='1' ry='1' width='20' height='5' />
					<rect x='88' y='80' rx='1' ry='1' width='20' height='5' />
					<rect x='112' y='80' rx='1' ry='1' width='20' height='5' />
					<rect x='0' y='90' rx='1' ry='1' width='132' height='9' />
					{/* END : Video Description head(title, like, dislike,...) */}

				</ContentLoader>
			</div>

			<div className='d-block d-lg-none mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 100 165' style={{ marginTop: '20px' }}>
					{/* VideoPlayer */}
					<rect x='0' y='0' rx='1' ry='1' width='100' height='55' />

					{/* START : Video Description head(title, like, dislike,...) */}
					<rect x='0' y='60' rx='1' ry='1' width='28' height='5' />
					<rect x='46' y='60' rx='1' ry='1' width='16' height='5' />
					<rect x='66' y='60' rx='1' ry='1' width='16' height='5' />
					<rect x='84' y='60' rx='1' ry='1' width='16' height='5' />
					<rect x='0' y='70' rx='1' ry='1' width='100' height='9' />
					{/* END : Video Description head(title, like, dislike,...) */}

					{/* START : Video Playlist */}
					<rect x='0' y='88' rx='1' ry='1' width='22' height='2' />
					<rect x='0' y='93' rx='1' ry='1' width='100' height='5' />

					<rect x='0' y='103' rx='1' ry='1' width='22' height='2' />
					<rect x='0' y='108' rx='1' ry='1' width='100' height='5' />

					<rect x='0' y='119' rx='1' ry='1' width='22' height='2' />
					<rect x='0' y='124' rx='1' ry='1' width='100' height='5' />

					<rect x='0' y='135' rx='1' ry='1' width='22' height='2' />
					<rect x='0' y='140' rx='1' ry='1' width='100' height='5' />

					<rect x='0' y='151' rx='1' ry='1' width='22' height='2' />
					<rect x='0' y='156' rx='1' ry='1' width='100' height='5' />
					{/* END : Video Playlist */}

				</ContentLoader>
			</div>
		</>
	);
}
