import React from 'react';

import './style.css';

export default (props) => (
	<div className='passwordMainContainer'>
		{props.showError !== '' && <p className='error'>{props.showError}</p>}
		<div className='inputConatiner'>
			<input
				onChange={(event) => props.handlePasswordChange('password', event)}
				type='password'
				className='input'
				id='passwordInput'
				placeholder=' '
			/>
			<label className='inputLabel'>Password</label>
		</div>

		<br />

		<div className='inputConatiner'>
			<input
				onChange={(event) => props.handlePasswordChange('confirmPassword', event)}
				type='password'
				className='input'
				id='confirmPasswordInput'
				placeholder=' '
			/>
			<label className='inputLabel'>Confirm password</label>
		</div>

		<div className='form-check showPasswordConatiner'>
			<input
				onChange={(event) => props.handleShowPassword(event)}
				className='form-check-input'
				type='checkbox'
				id='showPasswordCheckBox'
			/>
			<label htmlFor='showPasswordCheckBox'>Show password</label>
		</div>

		<ul className='list-group list-group-horizontal'>
			{props.poorPassword === true ? (
				<li
					className='list-group-item bg-danger col-4'
					style={{ padding: '1px 0px' }}></li>
			) : (
				''
			)}
			{props.weakPassword === true ? (
				<li
					className='list-group-item bg-warning col-4'
					style={{ padding: '1px 0px' }}></li>
			) : (
				''
			)}
			{props.strongPassword === true ? (
				<li
					className='list-group-item bg-success col-4'
					style={{ padding: '1px 0px' }}></li>
			) : (
				''
			)}
		</ul>
		<p className='passwordErrStatus'> {props.passwordError}</p>

		<button className='nextBtn' onClick={props.handlePasswordSubmit}>
			Next
		</button>
	</div>
);
