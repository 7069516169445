export const login = async (contactNumber) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				contactNumber: contactNumber,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Login services :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const verifyOTP = async (OTPNumber, OTPToken, contactNumber, countryCode) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/verifyOTP`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: OTPToken,
			},
			body: JSON.stringify({
				otp: OTPNumber,
				contactNumber: contactNumber,
				countryCode: countryCode
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Verify OTP services of Login :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getLogedInUser = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getLogedInUser`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Login services: getLogedInUser()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateUserInitialDetails = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateUserInitialDetails`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Login services ( updateDisplayName ):',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const verifyContactNumber = async (contactNumber) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/verifyContactNumber`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				contactNumber: contactNumber,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Login services ( VerifyContactNumber ):',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const verifyForgotPassOTP = async (OTPNumber, OTPToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/verifyForgotPassOTP`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: OTPToken,
			},
			body: JSON.stringify({
				otp: OTPNumber,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Login services ( VerifyForgotPassOTP ):',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const resetPassword = async (
	newPassword,
	confirmNewPassword,
	forgotPassToken
) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/resetPassword`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: forgotPassToken,
			},
			body: JSON.stringify({
				newPassword: newPassword,
				confirmNewPassword: confirmNewPassword,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Login services ( resetPassword ):', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getLoginAuth = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getLoginAuth`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Login services: getLoginAuth()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
