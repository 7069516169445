import React from 'react';

import SampleCertificateView from './view';
import { useSelector } from 'react-redux';

function SampleCertificate(props) {
	const courseDetails = useSelector((state) => state.course.courseDetails);
	const loginState = useSelector((state) => state.navbar.loginState);

	return (
		<SampleCertificateView
			openOtpPopUpX={props.openOtpPopUp} // defined in @pages\Course\Main\index.js
			userLogedIn={loginState}
			certificate={courseDetails?.certificateThumbnailUrl}
		/>
	);
}

export default SampleCertificate;
