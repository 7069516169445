export const getCourse = async (authToken, courseId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCourse/${courseId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : getCourse()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getVideo = async (authToken, videoDocId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getVideo/${videoDocId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : getVideo()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getPublicVideo = async (videoDocId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getPublicVideo/${videoDocId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : getPublicVideo()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addComment = async (commentDetails, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addComment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(commentDetails),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : addComment()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getComments = async (authToken, videoDocId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getComments/${videoDocId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : getComments()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getPublicVideoComments = async (videoDocId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getPublicVideoComments/${videoDocId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : getPublicVideoComments()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addReply = async (replyDetails, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(replyDetails),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : addReply()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addReplyOfReply = async (replyDetails, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addReplyOfReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(replyDetails),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : addReplyOfReply()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const likeVideo = async (videoDocId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/likeVideo/${videoDocId}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : likeVideo()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const dislikeVideo = async (videoDocId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/dislikeVideo/${videoDocId}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : dislikeVideo()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateViewCount = async (videoDocId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateViewCount/${videoDocId}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : updateViewCount()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updatePublicVideoViewCount = async (videoDocId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updatePublicVideoViewCount/${videoDocId}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : updatePublicVideoViewCount()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateVideoWatchCount = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateVideoWatchCount`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : updatVideoWatchCount()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const reportQuery = async (payload) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/reportQuery`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : reportQuery()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const reportVideoQuery = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/reportVideoQuery`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : reportVideoQuery()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const reportPublicVideoQuery = async (payload) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/reportPublicVideoQuery`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : reportPublicVideoQuery()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addToFavourite = async (videoDocId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/addToFavourite/${videoDocId}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : addToFavourite()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const likeComment = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/likeComment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : likeComment()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const dislikeComment = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/dislikeComment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : dislikeComment()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const likeReply = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/likeReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : likeReply()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const dislikeReply = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/dislikeReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course Content services : dislikeReply()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
