import React, { useState, useEffect } from 'react';
import './ProgressBar.css';

const ProgressSteps = (props) => {
	const [selectedStep, setSelectedStep] = useState(0);
	const steps = [1, 2, 3, 4];

	useEffect(() => {
		if (props.windowBlurFlag <= 4) {
			if (props.windowBlurFlag > 0) {
				handleStepClick(props.windowBlurFlag);
			}
		} else {
			handleStepClick(3);
		}
	}, [props.windowBlurFlag]);

	const handleStepClick = (stepNum) => {
		progress(stepNum);
	};

	const progress = (stepNum) => {
		let p;

		switch (stepNum) {
			case 1:
				p = 0;
				break;
			case 2:
				p = 32;
				break;
			case 3:
				p = 64;
				break;
			case 4:
				p = 96;
				break;
			default:
				p = 0;
				break;
		}

		document.getElementsByClassName('percent')[0].style.width = `${p}%`;
		setSelectedStep(stepNum);

		// Add styles to selected and completed steps
		const els = document.getElementsByClassName('step');
		Array.prototype.forEach.call(els, (e) => {
			const id = parseInt(e.id);
			if (id === stepNum) {
				// e.classList.add('selected');
				e.classList.add('completed');
			} else if (id < stepNum) {
				e.classList.add('completed');
				e.classList.remove('selected');
			} else {
				e.classList.remove('selected', 'completed');
			}
		});
	};

	return (
		<div className='examProgressBarContainer'>
			<div className='progressContainer'>
				<div className='percent'></div>
			</div>
			<div className='steps'>
				{steps.map((step) => (
					<div
						key={step}
						className={`step  ${step < selectedStep ? 'completed' : ''}`}
						id={step}></div>
				))}
			</div>
		</div>
	);
};

export default ProgressSteps;
